import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { uniqueId } from "library/database/split-id"
import { mutate } from "lib/graphql/mutate"

export const setPlanConfig = api(async function setPlanConfiguration(plan) {
    if (!plan) return null
    return mutate(
        gql`
            mutation PlanConfig($id: String!, $configuration: JSONObject) {
                setPlanConfiguration(id: $id, configuration: $configuration)
            }
        `,
        {
            id: uniqueId(plan._id),
            configuration: plan,
        },
        { returns: "setPlanConfiguration" }
    )
})
