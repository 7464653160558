import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getSchedulesForExport = api(async function getSchedulesForExport(searchTerm, skip = 0, take = 100) {
    return query(
        gql`
            query GetSchedulesForExport($searchTerm: String, $skip: Int, $take: Int) {
                getSchedulesForExport(searchTerm: $searchTerm, skip: $skip, take: $take) {
                    totalCount
                    pageInfo {
                        endCursor
                        hasNextPage
                        hasPreviousPage
                        startCursor
                    }
                    edges {
                        node {
                            id
                            approved
                            draft
                        }
                    }
                }
            }
        `,
        { searchTerm, skip, take },
        { returns: "getSchedulesForExport", fetchPolicy: "network-only" }
    )
})
