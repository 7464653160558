import { registerTab } from "lib/@components/tabs"
import { isEnabled } from "lib/@components/feature"
import { Lazy } from "lib/make-lazy"
import { FEATURE_FLAG_CLIENT_BACKUP } from "plugins/database-reset/database-reset.runtime"
import { PagingSearchBarRight } from "slot-definitions"
import { ClearUsers } from "plugins/database-pack/clear-users"

registerTab({
    tab: "clientAdmin",
    id: "packs",
    title: "Database Packs",
    predicate() {
        return isEnabled(FEATURE_FLAG_CLIENT_BACKUP)
    },
    content: <Lazy priority={10000} importer={() => import("./DatabasePack")} extract="DatabasePack" />,
})

PagingSearchBarRight.plug(
    <ClearUsers priority={200} if={(c) => isEnabled("clientBackup") && !!c.clientUserConfiguration} />
)
