import { Bound } from "lib/@components/binding/Bound"
import { usePayloadTask } from "routes/facilities/lib/use-payload-task"
import { BlockClicks } from "lib/@components/block-clicks"
import { Different } from "routes/regime/components/different"
import { Editable } from "lib/@components/editable"
import { BoundPopup } from "lib/@components/combos/boundPopup"
import { VALID_CLASSIFICATIONS } from "library/SFG20/constants"
import { Classification } from "routes/schedule/tasks/classification"
import { HighlightBox } from "lib/@components/highlightBox"
import { SummaryDataChanged } from "event-definitions"
import { BoundValue } from "lib/@components/bound-field/bound-value"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Payload } from "routes/schedule/controller/payload"
import { assessStatus } from "routes/schedule/assess-status"

export function EditClassification() {
    const { target, schedule, payload, hasAdvancedTailoring } = useBoundContext()

    const task = usePayloadTask()
    return (
        <Payload>
            <BlockClicks>
                <Bound onChange={validate} editMode={hasAdvancedTailoring} target={task}>
                    <Different field="classification" comparedTo={target}>
                        <Editable
                            p={0.5}
                            field="classification"
                            editor={
                                <BoundPopup
                                    contentProps={{ pt: 1 }}
                                    aria-label="Classification"
                                    field="classification"
                                    options={VALID_CLASSIFICATIONS}
                                >
                                    <BoundValue
                                        default={target.classification}
                                        property="classification"
                                        field="classification"
                                    >
                                        <Classification />
                                    </BoundValue>
                                </BoundPopup>
                            }
                        >
                            <HighlightBox path="classification">
                                <BoundValue
                                    default={target.classification}
                                    property="classification"
                                    field="classification"
                                >
                                    <Classification />
                                </BoundValue>
                            </HighlightBox>
                        </Editable>
                    </Different>
                </Bound>
            </BlockClicks>
        </Payload>
    )

    function validate() {
        assessStatus(payload, schedule)
        SummaryDataChanged.raiseOnce()
    }
}
