/**
 * A lookup that takes a Period and returns the number of those in a year, this
 * is used when calculating the number of minutes for a task for a year
 * @type {{Month: number, Year: number, Hour: number, Day: number, Week: number}}
 */
exports.PERIOD_MULTIPLIER = {
    Hour: 365 * 24,
    Day: 365,
    Week: 52,
    Month: 12,
    Year: 1,
    Unknown: 0,
}

exports.PERIOD_SORT = {
    Hour: 1,
    Day: 24,
    Week: 24 * 7,
    Month: 24 * (365 / 12),
    Year: 24 * 365,
}

/**
 * A lookup that returns a colour for a classification
 * @type {{Red: string, Pink: string, Amber: string, Green: string}}
 */
exports.COLOR_FROM_CLASSIFICATION = {
    Red: "#e50f16",
    Green: "#228B22",
    Amber: "#fc6a03",
    Pink: "#FF69B4",
}

exports.CLASSIFICATIONS = ["Red", "Pink", "Amber", "Green"]

exports.ICON_FROM_CLASSIFICATION = {
    Red: "bi:diamond-fill",
    Pink: "fluent:triangle-32-filled",
    Amber: "fluent:circle-24-filled",
    Green: "fluent:triangle-down-12-filled",
}

exports.SORT_FROM_CLASSIFICATION = {
    Red: 0,
    Pink: 1,
    Amber: 2,
    Green: 3,
}

/**
 * The classifications that the system supports
 * @type {string[]}
 */
exports.VALID_CLASSIFICATIONS = ["Red", "Pink", "Amber", "Green"]
exports.VALID_CLASSIFICATIONS_REVERSE = [...exports.VALID_CLASSIFICATIONS].reverse()
exports.OVERARCHING_INTRODUCTION = "34164"
