import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const getTypeSchedulePayload = api(({ id, type, scheduleId }) =>
    query(
        gql`
            query GetTypeSchedulePayload($id: String!, $type: String!, $scheduleId: String!) {
                getTypeSchedulePayload(scheduleId: $scheduleId, id: $id, type: $type)
            }
        `,
        { id, type, scheduleId },
        { returns: "getTypeSchedulePayload" }
    )
)
