export async function singleWorksheetDownload({ fileName, sheetName, data, mergedCells = [] }) {
    const { utils, writeFile } = await import("xlsx")
    if (data.length > 0) {
        const worksheet = utils.json_to_sheet(data, { cellDates: true, dateNF: "DD/MM/YYYY" })
        const workbook = utils.book_new()

        if (mergedCells.length > 0) {
            worksheet["!merges"] = mergedCells
        }

        const widths = Object.keys(data[0]).map((key) => ({
            wch: data.reduce((w, r) => Math.max(w, typeof r[key] === "string" ? `${r[key]}`.length : 0), 10),
            hidden: false,
        }))
        worksheet["!cols"] = widths

        utils.book_append_sheet(workbook, worksheet, sheetName)
        writeFile(workbook, fileName)
    }
}

export async function multiWorksheetDownload({ sheets, fileName }) {
    const { utils, writeFile } = await import("xlsx")
    const workbook = utils.book_new()

    if (sheets.length > 0) {
        sheets.forEach(({ sheetName, data, mergedCells = [] }) => {
            const worksheet = utils.json_to_sheet(data, { cellDates: true })
            worksheet["!cols"][0] = { hidden: false }
            if (mergedCells.length > 0) {
                worksheet["!merges"] = mergedCells
            }

            const widths = Object.keys(data[0]).map((key) => ({
                wch: data.reduce((w, r) => Math.max(w, `${r[key]}`.length), 10),
                hidden: false,
            }))
            worksheet["!cols"] = widths

            utils.book_append_sheet(workbook, worksheet, sheetName)
        })

        writeFile(workbook, fileName)
    }
}
