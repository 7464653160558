import { Box, Button, Typography } from "@mui/material"
import { RightDrawer } from "lib/@components/drawer/right-drawer"
import Iconify from "minimals-template/components/Iconify"
import { useState } from "react"
import { AuditContainer } from "routes/audit/component/container"
import { AuditTopBar } from "routes/audit/component/top-bar"

import { RootPlugs, ScheduleTreeBar } from "slot-definitions"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { createEvent } from "library/local-events"
import { useLocation } from "react-router-dom"
import { getScheduleRecordId } from "library/get-unique-id-from-tree"

ScheduleTreeBar.plug(<Activity type={"customSchedule"} if={showActivityButton} priority={250} />)

function showActivityButton(params) {
    const isCustom = params.schedule.type.Name === "Custom"
    const isNotShareLink = !params.isShare
    return isCustom && isNotShareLink
}

RootPlugs.plug(<ActivityDrawer />)

const ShowActivity = createEvent("ShowActivity")

function ActivityDrawer() {
    const location = useLocation()
    const [{ id, type, forTypeAllClients }, setId] = useState({})
    useLocation(() => {
        setId({})
    }, [location.href])

    ShowActivity.useEvent((id, type, forTypeAllClients) => {
        setId({ id, type, forTypeAllClients })
    })
    return (
        <RightDrawer open={!!id} setOpen={setOpen} dialogActions={<AuditTopBar type={type} />}>
            <Typography variant={"h5"} mb={1}>
                Activity
            </Typography>
            {!!id && <AuditContainer type={type} id={id} setOpen={setOpen} forTypeAllClients={forTypeAllClients} />}
        </RightDrawer>
    )

    function setOpen(value) {
        if (!value) {
            setId({})
        }
    }
}

export function Activity({ type, passedId, ...params }) {
    const { closeNow } = useBoundContext()
    const id = passedId ?? getScheduleRecordId(params.id)
    const forTypeAllClients = !!params.forTypeAllClients
    return (
        <>
            <Box>
                <Button
                    onClick={() => {
                        closeNow()
                        ShowActivity.raise(id, type, forTypeAllClients)
                    }}
                    startIcon={<Iconify icon="ic:round-restore" />}
                    variant="text"
                    id="activityBtn"
                >
                    Activity
                </Button>
            </Box>
        </>
    )
}
