import Iconify from "minimals-template/components/Iconify"
import { Box } from "@mui/material"

export function FolderIcon({ sx, icon = "material-symbols:folder-rounded" }) {
    return (
        <Box data-cy="folder-icon" lineHeight={0}>
            {typeof icon === "object" ? (
                icon
            ) : (
                <Iconify sx={{ width: 32, height: 32, color: "primary.main", ...sx }} icon={icon} />
            )}
        </Box>
    )
}
