import { getTreeId } from "routes/schedule/lib/getTreeId"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"
import { api } from "lib/graphql/api"

export const getGroupPayload = api((group, key, tree = getTreeId(group)) => {
    if (!tree) return null
    if (!key) return null
    // console.log({ group, key, tree })
    return query(
        gql`
            query getPayloadData($tree: String!, $group: String!, $key: String!) {
                getGroupPayload(key: $key, tree: $tree, group: $group)
            }
        `,
        {
            tree,
            group,
            key,
        },
        {
            returns: "getGroupPayload",
        }
    )
})
