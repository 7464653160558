import { Methods, useScheduleByMethod } from "routes/schedule/compare/use-schedule-by-method-id"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function useComparisonSchedule() {
    const { target } = useBoundContext()
    let method
    let id
    if (target?.metadata?.published) {
        // Target should be the previously published version
        id = target._id
        method = Methods.schedule
    } else if (target.originatingScheduleId) {
        // Target should be the derived schedule
        id = target.originatingScheduleId
        method = Methods.schedule // TODO: make this history
    } else {
        // There is no schedule to compare with
    }
    return useScheduleByMethod(method, id, id)
}
