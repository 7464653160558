const { Semaphore } = require("library/semaphore")

exports.rateLimit = function rateLimit(asyncFunction, rate = 25, queueHandler) {
    const semaphore = new Semaphore(rate, queueHandler)

    return async function process(...args) {
        await semaphore.acquire()
        try {
            return await asyncFunction(...args)
        } finally {
            semaphore.release()
        }
    }
}
