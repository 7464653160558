import { useEffect, useRef } from "react"

export function useEffectOnSubsequentChange(fn, deps = []) {
    const first = useRef(true)
    return useEffect(() => {
        if (first.current) {
            first.current = false
            return undefined
        }
        return fn()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps)
}
