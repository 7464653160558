exports.SCHEDULE = "schedule"
exports.GROUP = "group"
exports.FACILITY = "facility"
exports.SPACE = "space"
exports.SIMPLE = "simple"
exports.DRAFT = "draft"
exports.RETIRED = "retired"
exports.isType = function isType(type) {
    return (c) => c?.type === type
}
exports.isNotType = function isType(type) {
    return (c) => c?.type !== type
}

exports.isSchedule = exports.isType(exports.SCHEDULE)
exports.isGroup = exports.isType(exports.GROUP)
exports.isDraft = exports.isType(exports.DRAFT)
exports.isSimple = exports.isType(exports.SIMPLE)
exports.isNotSchedule = exports.isNotType(exports.SCHEDULE)
exports.isNotGroup = exports.isNotType(exports.GROUP)
exports.isNotDraft = exports.isNotType(exports.DRAFT)
exports.isNotSimple = exports.isNotType(exports.SIMPLE)
exports.EDIT_TREE = "myTree"
exports.LIVE_TREE = "myLive"
exports.REVIEW_TREE = "myReview"
exports.READY_TREE = "myReady"
exports.PUBLISHED_TREE = "myPublishedTrees"
exports.REGIME_IDS = [exports.EDIT_TREE, exports.LIVE_TREE, exports.READY_TREE]
