/* eslint-disable react-hooks/exhaustive-deps */
import LoadingScreen from "minimals-template/components/LoadingScreen"
import { useMemo, useRef, useState } from "react"
import { RefreshSchedules } from "event-definitions"
import { getTreeIndex } from "routes/schedule/lib/getTreeIndex"

export default function Schedules(props) {
    const id = useRef(0)
    id.current++

    return <InnerSchedules id={id.current} {...props} />
}

function InnerSchedules({ children, id, ...props }) {
    const [enabled, setEnabled] = useState(!!Object.keys(getTreeIndex()).length)
    RefreshSchedules.useEvent(() => setEnabled(!!Object.keys(getTreeIndex()).length))
    return useMemo(
        () =>
            enabled ? (
                <children.type {...children.props} {...props} />
            ) : (
                <LoadingScreen description="Inner Schedules" />
            ),
        [id, enabled]
    )
}
