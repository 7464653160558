import { useDraft } from "routes/schedule/lib/use-draft"
import { Bound } from "lib/@components/binding/Bound"

export function DraftWrapper({ children, id }) {
    const { schedule } = useDraft(id)
    return (
        !!schedule && (
            <Bound target={schedule} schedule={schedule}>
                {children}
            </Bound>
        )
    )
}
