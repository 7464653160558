import { useSnackbar } from "notistack"

import { PATH_AUTH } from "routes/paths"

import useAsync from "lib/@hooks/useAsync"
import useAuth from "minimals-template/components/@hooks/useAuth"
import AuthGuard from "minimals-template/components/guards/AuthGuard"
import { register } from "lib/routes/register"
import { navigate } from "lib/routes/navigate"

register(
    "/logout",
    <AuthGuard>
        <Logout />
    </AuthGuard>
)

export default function Logout() {
    const { logout } = useAuth()

    const { enqueueSnackbar } = useSnackbar()

    const handleLogout = async () => {
        try {
            await logout()
            navigate(PATH_AUTH.login, { replace: true })
        } catch (error) {
            console.error(error)
            enqueueSnackbar("Unable to logout!", { variant: "error" })
        }
    }

    useAsync(async () => {
        await handleLogout()
    })
    return null
}
