const { noop } = require("library/noop")

exports.Semaphore = class Semaphore {
    constructor(maxConcurrency, callback = noop) {
        this.maxConcurrency = maxConcurrency
        this.currentConcurrency = 0
        this.queue = []
        this.callback = callback
    }

    async acquire() {
        return new Promise((resolve, reject) => {
            if (this.currentConcurrency < this.maxConcurrency) {
                this.currentConcurrency++
                resolve()
            } else {
                if (false !== this.callback("queue", this.currentConcurrency, reject, resolve)) {
                    this.queue.push({ resolve, reject })
                }
            }
        })
    }

    release() {
        if (this.queue.length > 0) {
            this.callback("dequeue", this.currentConcurrency)
            const { resolve } = this.queue.shift()
            resolve()
        } else {
            this.currentConcurrency--
        }
    }
}
