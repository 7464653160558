import { ContextMenu } from "lib/@components/context-menu-bar"
import { ContextMenuItem } from "lib/@components/context-menu-bar/context-menu-item"
import { appRoute } from "routes/app/home.runtime"

import Schedules from "routes/schedule/schedules"
import { isInRegime } from "routes/facilities/lib/when-parent-item"
import { navigate } from "lib/routes/navigate"
import { Lazy } from "lib/make-lazy"

ContextMenu("folder").plug(({ context }) => {
    if (!isInRegime(context)) return null
    if (!context.parentItem.children?.length) {
        return null
    }
    return (
        <ContextMenuItem
            priority={900}
            divider={true}
            icon="cil:spreadsheet"
            label="Maintenance Profile"
            onClick={go}
        />
    )

    function go() {
        navigate(`/app/assetProfile/${context.parentItem.id}`)
    }
})

appRoute.register(
    "assetProfile/:id",
    <Schedules>
        <Lazy importer={() => import("./asset-profile-from-id")} extract="AssetProfileFromId" />
    </Schedules>
)
