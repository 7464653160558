import { Box, ListItemButton } from "@mui/material"
import { RedrawTreeList, RefreshMainTree, SidebarTreeRoots } from "event-definitions"
import { ListItemBox } from "lib/@components/ListItemBox"
import noop from "lib/noop"
import { splitId } from "library/database/split-id"
import { TreeRoot } from "library/tree"
import Iconify from "minimals-template/components/Iconify"
import { useEffect } from "react"
import { getTreeItemRetriever } from "routes/schedule/lib/getTreeItemRetriever"
import { useSelectedItemIds } from "routes/schedule/lib/useSelectedItems"
import { useSelection } from "routes/schedule/lib/useSelection"
import { FolderItemPart } from "routes/schedule/tree/schedules/folder-item-part"
import { parentTreeItem } from "routes/schedule/tree/schedules/makeTreeItemsFromGroup"
import { ScheduleItem } from "routes/schedule/tree/schedules/scheduleItem"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { getMemoryReference } from "lib/@hooks/use-memory-reference"

export function SelectedSchedulesInTree({ last, sx, Component = ListItemButton }) {
    const { refresh, onClick } = useBoundContext()
    const items = useSelectedItemIds()
    RefreshMainTree.useEvent(refresh)
    const hasCurrentSelections = items.length > 0

    return (
        <Component divider={!last} onClick={onClick}>
            <ListItemBox spacing={1}>
                <FolderItemPart />
                <FolderItemPart>
                    <Iconify sx={{ width: 32, height: 32, color: "primary.main", ...sx }} icon="ion:documents" />
                </FolderItemPart>
                {hasCurrentSelections ? (
                    <Box flex={1} sx={{ fontWeight: "bold" }}>
                        Selected ({items.length})
                    </Box>
                ) : (
                    <Box flex={1}>Selected</Box>
                )}
            </ListItemBox>
        </Component>
    )
}

export function SelectedSchedulesInNav() {
    const { refresh } = useBoundContext()
    const [, , selected] = useSelection()
    const items = useSelectedItemIds()
    RefreshMainTree.useEvent(refresh)
    const hasCurrentSelections = items.length > 0
    useEffect(() => {
        RedrawTreeList.raiseOnce()
        return undefined
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(selected)])
    const itemsSelected = hasCurrentSelections ? `(${items.length})` : ""
    return <Box flex={1}>Selected {itemsSelected}</Box>
}

const selectedImpl = <SelectedSchedulesInTree />

// registerConditionalPageNavigation(
//     () => hasDemand("user") && hasDemand("!sharing"),
//     "/app/schedules?id=selected",
//     <SelectedSchedulesInNav />,
//     <Iconify sx={{ width: 32, height: 32 }} icon="ion:documents" />,
//     {
//         group: "Schedules",
//         priority: 199,
//         isActive: isActiveWhen((c) => c.id === "selected"),
//     }
// )

SidebarTreeRoots.handleOnce(async ({ add }) => {
    const selected = {
        content: selectedImpl,
        id: "selected",
        [TreeRoot]: true,
        label: "Selected",
        priority: 10,
        visible: true,
        onExpand: noop,
        onCollapse: noop,
    }
    let running = false
    Object.defineProperty(selected, "children", {
        get() {
            if (running) return []
            try {
                running = true
                const find = getTreeItemRetriever()
                const reference = getMemoryReference({ selectedItems: {} })
                reference.selectedItems ||= {}
                const allItems = Object.entries(reference.selectedItems)
                    .filter((c) => !!c[1])
                    .map((i) => find(...i))
                    .filter(Boolean)
                    .unique("_id")
                return allItems
                    .map((item) => ({
                        $: item.$,
                        id: `selected!${splitId(item._id).id}`,
                        _id: item._id,
                        hasChildren: false,
                        label: item.label,
                        isAlias: true,
                        code: item.code,
                        content: item.content ? (
                            <item.content.type
                                {...item.content.props}
                                schedule={{
                                    ...item,
                                    id: `selected!${splitId(item._id).id}`,
                                    [parentTreeItem]: selected,
                                }}
                            />
                        ) : (
                            <ScheduleItem
                                schedule={{
                                    ...item,
                                    id: `selected!${splitId(item._id).id}`,
                                    [parentTreeItem]: selected,
                                }}
                            />
                        ),
                    }))
                    .sortBy("label")
            } finally {
                running = false
            }
        },
    })
    add(selected)
})
