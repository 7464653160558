import { Box, List, ListItem } from "@mui/material"
import { Repeat } from "lib/@components/repeat"
import { useState } from "react"
import { createEvent } from "library/local-events"
import { RefreshSchedules, RefreshTreeRoots } from "event-definitions"

// RootPlugs.plug(<DebugWindow if={() => window.Cypress} />)

const AddDebugItem = createEvent("AddDebugItem")
let debugItem = 0

export function addDebugItem(text) {
    AddDebugItem.raise(`${++debugItem} - ${text}`)
}

RefreshTreeRoots.handle(() => addDebugItem("Should refresh tree roots"))
RefreshSchedules.handle(() => addDebugItem("Refresh Schedules"))

// eslint-disable-next-line no-unused-vars
function DebugWindow() {
    const [items, setItems] = useState([])
    AddDebugItem.useEvent((item) => setItems((items) => [item, ...items]))
    return (
        <Box
            className="debug-window"
            sx={{
                position: "fixed",
                background: "#fff3",
                zIndex: 90000,
                right: 10,
                top: 8,
                fontSize: 11,
                overflow: "auto",
                pointerEvents: "none",
                padding: 1,
                maxWidth: "30vw",
                bottom: 8,
                height: 1,
                borderRadius: 1,
                backdropFilter: "blur(10px)",
            }}
        >
            <List dense>
                <Repeat list={items} item={<Entry />} />
            </List>
        </Box>
    )
}

function Entry({ item }) {
    return <ListItem>{item}</ListItem>
}
