import { RegimeItemLeft, TreeToolbar } from "slot-definitions"
import { RegimeUserAccessGroup } from "./components/regime-user-access-group"
import { and } from "lib/logic"
import { isEnabled } from "lib/@components/feature"
import { registerFeature } from "lib/features"

import { FeatureFlagDescription } from "routes/regime/plugins/regime-access/feature-flag-description"
import { PlanRoot } from "library/tree/constants"
import { RegimeAccessButton } from "routes/regime/plugins/regime-access/components/regime-access-button"

export const FEATURE_FLAG_REGIME_PERMISSIONS = registerFeature({
    numericalId: 7,
    name: "Regime Permissions",
    id: "regimePermissions",
    readyForProduction: true,
    description: <FeatureFlagDescription $lazy />,
})

TreeToolbar("**").plug(
    <RegimeUserAccessGroup
        $lazy
        priority={-2000}
        if={and(isRegimeRoot, () => isEnabled(FEATURE_FLAG_REGIME_PERMISSIONS))}
    />
)
RegimeItemLeft.plug(
    <RegimeUserAccessGroup $lazy if={and(isRegimeRoot, () => isEnabled(FEATURE_FLAG_REGIME_PERMISSIONS))} />
)

TreeToolbar("**").plug(
    <RegimeAccessButton priority={-1100} if={and(isRegimeRoot, () => isEnabled(FEATURE_FLAG_REGIME_PERMISSIONS))} />
)

function isRegimeRoot(context) {
    return context.parentItem?.[PlanRoot]
}
