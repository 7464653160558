import PropTypes from "prop-types"
// @mui
import { alpha } from "@mui/material/styles"
import { Box, Paper, Typography } from "@mui/material"
// utils
import { fData } from "minimals-template/components/utils/formatNumber"
import getFileData from "minimals-template/components/utils/getFileData"

// ----------------------------------------------------------------------

RejectionFiles.propTypes = {
    fileRejections: PropTypes.array.isRequired,
}

export default function RejectionFiles({ fileRejections }) {
    return (
        <Paper
            variant="outlined"
            sx={{
                py: 1,
                px: 2,
                mt: 3,
                borderColor: "error.light",
                bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
            }}
        >
            {fileRejections.map(({ file, errors }) => {
                const { path, size } = getFileData(file)

                return (
                    <Box key={path} sx={{ my: 1 }}>
                        <Typography variant="subtitle2" noWrap>
                            {path} - {size ? fData(size) : ""}
                        </Typography>

                        {errors.map((error) => (
                            <Box key={error.code} component="li" sx={{ typography: "caption" }}>
                                {error.message}
                            </Box>
                        ))}
                    </Box>
                )
            })}
        </Paper>
    )
}
