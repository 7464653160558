import { RegimeToolbar } from "slot-definitions"
import { PlanRoot } from "library/tree/constants"
import { SecureRegimeTasks } from "routes/regime/plugins/regime-task-security/secure-regime-tasks"
import { Secure } from "lib/authorization/secure"
import { Frag } from "lib/@components/slot/frag"

RegimeToolbar.plug(
    <Secure demand="manageRegime" fallback={<Frag />}>
        <SecureRegimeTasks if={({ parentItem }) => parentItem?.[PlanRoot]} priority={2} />
    </Secure>
)
