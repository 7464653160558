import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getLegislationData = api(async function getLegislationData(ids) {
    return query(
        gql`
            query GetLegislationData($ids: [String]!) {
                getLegislationData(ids: $ids) {
                    ScheduleLegislationID
                    URL
                    HTML
                }
            }
        `,
        { ids },
        { returns: "getLegislationData" }
    )
})
