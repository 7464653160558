import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"

export const retireSchedules = api(async function retireSchedules(retired, ...ids) {
    ids = ids.flat(Infinity)
    return mutate(
        gql`
            mutation Retire($ids: [String!]!, $retired: Boolean) {
                retireSchedules(ids: $ids, retired: $retired)
            }
        `,
        { ids, retired },
        { returns: "retireSchedules" }
    )
})
