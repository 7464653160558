import { useParams } from "react-router-dom"
import { isPublishedId } from "library/id-helpers"
import { Button } from "@mui/material"
import { OpenSchedule } from "event-definitions"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import Iconify from "minimals-template/components/Iconify"
import { parentFind } from "library/tree"
import { PlanRoot } from "library/tree/constants"

export function EditDeltaWhileInSchedule() {
    const { id } = useParams()
    const { editExtension, parentItem, payload } = useBoundContext()
    const isInRegime = parentFind(parentItem, (c) => c[PlanRoot])

    return (
        !isPublishedId(id) &&
        !!isInRegime &&
        !!payload &&
        !editExtension && (
            <Button
                onClick={open}
                size="small"
                variant="outlined"
                color="primary"
                startIcon={<Iconify icon="mdi:edit" />}
                data-cy="tailor-schedule-button"
            >
                Tailor Schedule
            </Button>
        )
    )

    function open() {
        OpenSchedule(id).raiseAsync({ id }, { editEx: true })
    }
}
