import { ListItemText } from "@mui/material"
import { TruncatedTypography } from "./truncated-typography"

export function TruncatedListItemText({ primary, secondary, truncateSecondary = false }) {
    return (
        <ListItemText
            primary={<TruncatedTypography>{primary}</TruncatedTypography>}
            secondary={truncateSecondary ? <TruncatedTypography>{secondary}</TruncatedTypography> : secondary}
        />
    )
}
