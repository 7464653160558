import { useSchedule } from "routes/schedule/lib/useSchedule"
import { Bound } from "lib/@components/binding/Bound"

/**
 * Shows its children only if a specified schedule has passed filtering checks for sharelinks etc
 * @param {string} scheduleId - the id of a schedule
 * @param children - the children to display
 * @returns {JSX.Element}
 */
export function IfValidSchedule({ scheduleId, children }) {
    const { schedule } = useSchedule(scheduleId)

    return !!schedule && <Bound validSchedule={schedule}>{children}</Bound>
}
