import { AcceptedChanged, IsAccepted } from "event-definitions"
import { Box } from "@mui/material"
import { noChange } from "lib/@hooks/useRefresh"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { generate } from "library/guid"

export function Approved({ id, children, width = 24, fill = false }) {
    const { target, acceptId } = useBoundContext()
    // console.log("id", id, acceptId, target.id, id ?? acceptId ?? target.id)
    const isAccepted =
        IsAccepted.call({ isAccepted: false, id: id ?? acceptId ?? target.id }) ||
        IsAccepted.call({ isAccepted: false, id: target.title ?? generate() })
    AcceptedChanged.after.useRefresh(noChange)
    return isAccepted ? (
        <>
            <Box
                width={width}
                alignSelf="stretch"
                display="flex"
                alignItems="center"
                overflow="visible"
                justifyContent="center"
                flexDirection="column"
            >
                {fill && (
                    <Box width={0} height={0}>
                        <Box height={0} overflow="visible">
                            <Box width={2} height={200000} my={-400} ml={-0.3} flex={1} bgcolor="action.hover" />
                        </Box>
                    </Box>
                )}
            </Box>
            {!!fill && <Box width={1 / 2} />}
        </>
    ) : (
        children
    )
}

//
