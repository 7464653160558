import { clone } from "lib/clone"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"
import { getTreeId } from "library/id-helpers"
import { getClient } from "minimals-template/components/contexts/NognitoContext"

export async function getGroup(id) {
    if (!id) {
        console.warn("ID passed as null")
        return null
    }

    const tree = getTreeId(id) ?? (id.includes("~") ? id.split("~").at(-1) : null) ?? getClient()
    const group = id.includes("~") ? id.split("~").at(-2) || id.split("!").at(-1) : id

    if (!group) console.error("Malformed ID", id)

    return clone(
        await query(
            gql`
                query getGroup($tree: String!, $group: String!) {
                    getGroup(tree: $tree, group: $group)
                }
            `,
            {
                tree,
                group,
            },
            { returns: "getGroup" }
        )
    )
}
