import { Box, Card, CardContent, CardHeader, CircularProgress, Typography } from "@mui/material"
import { gql, useQuery } from "@apollo/client"
import { If } from "lib/@components/switch"
import { plug } from "lib/@components/slot/plug"

plug("HomePage.Item", <ScheduleCard priority={25} xs={6} md={4} lg={3} />)

const GET_COUNT = gql`
    query Schedules {
        schedules {
            count
        }
    }
`

function ScheduleCard() {
    const { loading, data } = useQuery(GET_COUNT)

    return (
        <Box p={1}>
            <Card>
                <CardHeader title={"Schedules"} />
                <CardContent>
                    <If
                        truthy={loading}
                        then={
                            <Box textAlign="right" width={1}>
                                <CircularProgress color="primary" />
                            </Box>
                        }
                        else={
                            <Box textAlign="right" width={1}>
                                <Typography variant="h1" component="div">
                                    {Number(data?.schedules?.count ?? "0").toLocaleString()}
                                </Typography>
                            </Box>
                        }
                    />
                </CardContent>
            </Card>
        </Box>
    )
}
