import { TaskRowEnd } from "slot-definitions"
import { IconButton } from "@mui/material"
import { prevent } from "lib/prevent"
import { SummaryDataChanged, TaskListRefresh } from "event-definitions"
import { IsEditMode } from "lib/@components/edit-mode"
import { indexTasksInSchedule } from "routes/schedule/lib/index-tasks-in-schedule"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import Iconify from "minimals-template/components/Iconify"

TaskRowEnd.plug(<RemoveButton priority={200} />)

function RemoveButton() {
    const { schedule, task } = useBoundContext()
    return (
        <IsEditMode>
            <IconButton color="secondary" onClick={prevent(remove)} size="small">
                <Iconify icon="mdi:delete" />
            </IconButton>
        </IsEditMode>
    )

    function remove() {
        schedule.tasks.splice(task.$sort, 1)
        indexTasksInSchedule(schedule)
        TaskListRefresh.raise()
        SummaryDataChanged.raiseOnce()
    }
}
