// form
// @mui
import { FormControlLabel, FormHelperText, Radio } from "@mui/material"
import { BoundRadioGroup } from "lib/@components/binding/bound-components"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

// ----------------------------------------------------------------------

export default function RHFRadioGroup({ name, options, ...other }) {
    const { error } = useBoundContext()
    return (
        <>
            <BoundRadioGroup field={name} row {...other}>
                {options.map((option) => (
                    <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                    />
                ))}
            </BoundRadioGroup>

            {!!error && (
                <FormHelperText error sx={{ px: 2 }}>
                    {error.message}
                </FormHelperText>
            )}
        </>
    )
}
