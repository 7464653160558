import { ContextMenuItem } from "lib/@components/context-menu-bar/context-menu-item"
import { duplicateRegime } from "routes/schedule/my-schedules/my-custom-schedules/controller/duplicate-regime"
import { planConfig } from "routes/plan/controller/planConfig"
import { setPlanConfig } from "routes/plan/controller/setPlanConfig"
import { RegimeContextMenu } from "routes/regime/regime-context-menu"
import { and } from "lib/logic"
import { notInSelector } from "routes/facilities/lib/when-parent-item"
import { hasDemand } from "lib/authorization/has-demand"

RegimeContextMenu.plug(<CopyRegime if={and(notInSelector, () => hasDemand("manageRegime"))} />)

export function CopyRegime({ regime }) {
    return (
        <ContextMenuItem
            onClick={copyRegime}
            icon="material-symbols:folder-copy-outline-rounded"
            label="Duplicate Regime"
        />
    )

    async function copyRegime() {
        const newId = await duplicateRegime(regime._id)
        const config = await planConfig(regime._id)
        if (!config) {
            console.error("No copy", regime._id)
            return
        }
        config._id = newId
        await setPlanConfig(config)
    }
}
