import { RegisterSystemTask } from "event-definitions"
import { handle } from "library/local-events"
import { mutate } from "lib/graphql/mutate"
import { gql } from "@apollo/client"
import { TaskEditor } from "slot-definitions"
import { warningSnackbar } from "lib/snackbar/warning-snackbar"
import { successSnackbar } from "lib/snackbar/success-snackbar"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { replaceAllWith } from "lib/@components/slot/replace-all-with"
import { Frag } from "lib/@components/slot/frag"

RegisterSystemTask.handleOnce(({ tasks }) => {
    const task = {
        name: "Re-index schedules",
        event: "reindex-schedules",
        params: {},
    }
    tasks.push(task)
})

let running = false
const taskName = "Reindex schedules"
handle("reindex-schedules", async () => {
    if (running) {
        errorSnackbar(`${taskName} already running`)
        return
    }
    try {
        running = true
        warningSnackbar(`${taskName} running`)
        if (
            await mutate(
                gql`
                    mutation ReindexSchedules {
                        reindexSchedules
                    }
                `,
                {},
                { returns: "reindexSchedules" }
            ).catch((e) => {
                errorSnackbar(`${taskName} error: ${e.message}`)
                console.error(e)
            })
        ) {
            successSnackbar(`${taskName} complete`)
        }
    } finally {
        running = false
    }
})

TaskEditor("reindex-schedules").plug(replaceAllWith(<Frag />))
