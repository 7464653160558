import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { mutate } from "lib/graphql/mutate"

export const deleteBulletin = api(async function deleteBulletin(id) {
    return mutate(
        gql`
            mutation DeleteBulletin($id: String!) {
                deleteBulletin(id: $id)
            }
        `,
        { id },
        { returns: "deleteBulletin" }
    )
})
