import { Container, Typography } from "@mui/material"
import { MotionContainer } from "../animate"
import { ForbiddenIllustration } from "minimals-template/assets"
import { BounceAnimation } from "./BounceAnimation"

export const PermissionDeniedUI = ({ message, warning, Illustration = ForbiddenIllustration }) => (
    <Container component={MotionContainer} sx={{ textAlign: "center" }} data-cy="permission-denied-container">
        <BounceAnimation>
            <Typography variant="h3" paragraph>
                {warning}
            </Typography>
        </BounceAnimation>

        <BounceAnimation>
            <Typography sx={{ color: "text.secondary" }}>{message}</Typography>
        </BounceAnimation>

        <BounceAnimation>
            <Illustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </BounceAnimation>
    </Container>
)
