import noop from "lib/noop"
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react"
import { useTreeItem } from "routes/schedule/lib/useTreeItem"
import {
    AdvancedFilterChanged,
    AdvancedFilterSetting,
    AsyncScheduleAndGroupFilter,
    RefreshScheduleItem,
    RefreshSchedules,
    RefreshScheduleSelector,
    RenderSelector,
    ScheduleHeaderWrapper,
    TreeReloaded,
} from "event-definitions"
import { Bound } from "lib/@components/binding/Bound"
import { filterSchedules } from "ioc"
import { mappingSort } from "routes/schedule/tree/schedules/mappingSort"
import { Alert, Box, Button, Container, List, Stack } from "@mui/material"
import {
    ScheduleSelectorAboveToolbar,
    ScheduleSelectorAfterList,
    ScheduleSelectorBeforeList,
    ScheduleSelectorList,
    ScheduleSelectorOverrideWrapper,
    ScheduleSelectorPanel,
    ScheduleSelectorPanels,
    ScheduleSelectorToolbar,
    ScheduleSelectorToolbarStart,
    ScheduleSelectorTop,
} from "slot-definitions"
import { NotOnMobile, OnMobile } from "lib/@components/mobile"
import { ScheduleTree } from "routes/schedule/components/schedule-tree"
import { ListItemBox } from "lib/@components/ListItemBox"
import { ScheduleItemList } from "routes/schedule/components/schedule-item-list"
import { resolveAsFunction } from "lib/resolve-as-function"
import { noChange } from "lib/@hooks/useRefresh"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useReference, useReferenceState } from "lib/@hooks/use-reference"
import { showUnlicensed } from "routes/schedule/components/schedule-filter"
import { findInChildren, parentSome } from "library/tree"
import { TakeContext } from "lib/@components/current-context"
import { Frag } from "lib/@components/slot/frag"
import { NoResults } from "lib/@components/no-results/no-results"
import { NotInSelector } from "../my-schedules/my-custom-schedules/select-schedule"
import { useEffectOnSubsequentChange } from "lib/@hooks/use-effect-on-subsequent-change"
import useAsync from "lib/@hooks/useAsync"

import { useBusy } from "lib/@components/busy/use-busy"

const defaultEmpty = []

export function ScheduleSelectorComponent({ id, filter = () => true, onSelect = noop, ...props }) {
    const context = useBoundContext()
    const [parentId, setParentId] = useState(id)
    const [{ filterModes }, save] = useReference({ filterModes: {} }, "filter")
    const [sortMode] = useReferenceState("sortMode")
    useEffect(() => {
        setParentId(id)
    }, [id])

    useEffectOnSubsequentChange(() => {
        RefreshScheduleSelector.raise()
        console.log("Refresh on subsequent sort mode")
    }, [sortMode])

    const parent = useTreeItem(parentId, true)

    if (parent?.label && !context.inSelector) {
        document.title = `${parent.label} | Facilities-iQ`
    }
    const r = RefreshSchedules.useRefresh(noChange)
    const rid = RefreshScheduleItem(parentId).useRefresh(noChange)
    const rss = RefreshScheduleSelector.useRefresh(noChange)
    const tid = TreeReloaded.useRefresh(noChange)
    const [treeVisible] = useReferenceState("treeVisible")

    useLayoutEffect(() => {
        RenderSelector.raise()
    }, [])
    const wrapper = ScheduleHeaderWrapper(parentId).call({ wrapper: <Frag />, parentId, parentItem: parent })

    const schedules = (parent?.children ?? [])
        .filter((i) => resolveAsFunction(i.visible)(context) !== false)
        .sortBy(mappingSort)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const availableSchedules = filterSchedules(schedules)

    const hideUnlicensed = !(showUnlicensed() || parentSome(parent, (p) => p.showUnlicensed))
    const hasUnlicensed = hideUnlicensed && schedules.length !== availableSchedules.length
    const allIds = findInChildren(context.parentItem, (c) => c.type === "schedule").map("id")

    AdvancedFilterChanged.useRefresh(noChange)

    const items = useAsync(
        applyFilter,
        defaultEmpty,
        [
            allIds,
            availableSchedules.map("id"),
            filterModes,
            AdvancedFilterSetting.filters(),
            r.id,
            rid.id,
            rss.id,
            tid.id,
        ],
        (formerValue, newValue) => Array.isArray(newValue) && newValue.length === 0 && Array.isArray(formerValue)
    )
    const selectFn = useCallback(select, [onSelect])

    useBusy(items === defaultEmpty, "Filtering...", 500)
    const contents = useMemo(
        () => (items !== defaultEmpty ? <ScheduleItemList items={items} select={selectFn} /> : <NoResults />),
        [items, selectFn]
    )

    return parent ? (
        <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "row", overflow: "hidden", height: "100%" }}>
            <Bound
                shownItems={items}
                parentId={parentId}
                parentItem={parent}
                hideSettings={parent?.folderHandling === false}
            >
                <ScheduleSelectorPanels.Slot {...props} parent={parent} items={items}>
                    <NotInSelector>
                        <NotOnMobile>
                            <ScheduleTree parentId={parentId} select={select} />
                        </NotOnMobile>
                    </NotInSelector>
                    <Bound isList={true}>
                        <TakeContext>
                            <Stack sx={{ flex: 1, height: "100%" }} mt={1} spacing={0} overflow="hidden">
                                <Box flex={1} display="flex" flexDirection="column" overflow="scroll" width={1}>
                                    <List dense sx={{ px: 1 }}>
                                        <Container>
                                            <Box sx={{ px: treeVisible ? 5 : 0 }}>
                                                <ScheduleSelectorTop.Slot type={parentId} />
                                            </Box>
                                        </Container>
                                        <ScheduleSelectorOverrideWrapper.Wrapper>
                                            <wrapper.type {...wrapper.props}>
                                                <ScheduleSelectorBeforeList.Slot type={parentId} />
                                            </wrapper.type>
                                            <ScheduleSelectorAboveToolbar.Slot type={parentId} />
                                            <ScheduleSelectorPanel.Slot type={parentId} />
                                            <NotOnMobile>
                                                <ListItemBox my={1} alignItems="center" spacing={1}>
                                                    <ScheduleSelectorToolbarStart.Slot type={parentId} />
                                                    <Box flex={1} />
                                                    <ScheduleSelectorToolbar.Slot type={parentId} />
                                                </ListItemBox>
                                            </NotOnMobile>
                                            <OnMobile>
                                                <Box mt={1} />
                                                <ScheduleSelectorToolbarStart.Slot type={parentId} />
                                                <ListItemBox mb={0.5} spacing={1}>
                                                    <Box flex={1} />
                                                    <ScheduleSelectorToolbar.Slot type={parentId} />
                                                </ListItemBox>
                                            </OnMobile>

                                            <ScheduleSelectorList.Slot type={parentId}>
                                                {contents}
                                            </ScheduleSelectorList.Slot>
                                            <ScheduleSelectorAfterList.Slot type={parentId} />
                                        </ScheduleSelectorOverrideWrapper.Wrapper>
                                    </List>

                                    {hasUnlicensed && (
                                        <Box py={1} px={4} mt={4}>
                                            <Alert severity="info" sx={{ "& .MuiAlert-message": { width: 1 } }}>
                                                <ListItemBox>
                                                    <Box>
                                                        There are more schedules that you can license that would be
                                                        shown here, but they have been hidden due to your filter
                                                        settings
                                                    </Box>
                                                    <Box flex={1} />
                                                    <Button
                                                        size="small"
                                                        onClick={makeUnlicensedVisible}
                                                        variant="outlined"
                                                    >
                                                        Show
                                                    </Button>
                                                </ListItemBox>
                                            </Alert>
                                        </Box>
                                    )}
                                </Box>
                            </Stack>
                        </TakeContext>
                    </Bound>
                </ScheduleSelectorPanels.Slot>
            </Bound>
        </Box>
    ) : (
        <Box flex={1}>
            <NoResults>Nothing to see here</NoResults>
        </Box>
    )

    async function applyFilter(update) {
        if (!context.parentItem?.id && !parent) return []
        context.parentItem ??= parent

        try {
            update(defaultEmpty)
            const schedules = availableSchedules.filter(filter)

            return await AsyncScheduleAndGroupFilter.callAsync({ schedules, context })
        } catch (e) {
            console.error(e)
            return []
        }
    }

    function makeUnlicensedVisible() {
        filterModes["+Unlicensed"] = true
        save({ filterModes })
        console.log("Refresh on unlicensed changed")
        RefreshScheduleSelector.raiseOnce()
    }

    function select(item) {
        return async () => {
            setParentId(item.id)
            await onSelect(item)
        }
    }
}
