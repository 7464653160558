import { Bound } from "lib/@components/binding/Bound"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function SubsequentWrapper({ compare, to, children, target, ...params }) {
    const { subsequent } = useBoundContext()
    to = subsequent ? compare : to
    return (
        <Bound compare={compare} to={to} {...params} target={target} editorTarget={target}>
            {children}
        </Bound>
    )
}
