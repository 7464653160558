import { Alert, Box, InputAdornment, Stack, Typography } from "@mui/material"
import { DebouncedTextField } from "lib/@components/debounced-text-field"
import { setFromEvent } from "lib/setFromEvent"
import Iconify from "minimals-template/components/Iconify"
import { AccessFilter } from "routes/regime/plugins/regime-access/components/access-filter"
import { RegimeAccessPermittedUsers } from "./regime-access-permitted-users"

export function RegimeAccessTableFilters({ search, onSetSearch }) {
    return (
        <Stack spacing={2} px={2} pb={2}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <Box sx={{ width: "70%" }}>
                    <DebouncedTextField
                        fullWidth
                        type={"search"}
                        placeholder="Search..."
                        value={search}
                        onChange={setFromEvent(onSetSearch)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Iconify icon="eva:search-fill" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Box sx={{ width: "30%" }}>
                    <AccessFilter />
                </Box>
            </Stack>
            <RegimeAccessPermittedUsers />
            <Box>
                <Alert severity="info">
                    <Typography variant="subtitle2">
                        User roles are set within admin settings, speak to your administrator if you wish for a change
                        to be made.
                    </Typography>
                </Alert>
            </Box>
        </Stack>
    )
}
