import { ActionItem } from "slot-definitions"
import { Avatar, Button, ListItemAvatar } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { uniqueId } from "library/database/split-id"
import { navigate } from "lib/routes/navigate"
import { TruncatedListItemText } from "lib/@components/truncatedListItemText"

ActionItem("publish-plan").plug(<ReviewPlan />)

function ReviewPlan({ action }) {
    return (
        <>
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: "myStuff.main", color: "myStuff.contrastText" }}>
                    <Iconify icon={"icon-park-twotone:plan"} />
                </Avatar>
            </ListItemAvatar>
            <TruncatedListItemText
                primary={`${action.title} is ready to be approved`}
                secondary={`A maintenance regime has been submitted for review`}
            />
            <Button onClick={goRegime} color="primary" size="small" variant="outlined">
                Review
            </Button>
        </>
    )

    function goRegime() {
        navigate(`/app/review/${uniqueId(action.referenceId)}`)
    }
}
