import { hasDemand } from "lib/authorization/has-demand"
import { Lazy } from "lib/make-lazy"
import { registerConditionalPageNavigation } from "lib/routes/register-conditional-page-navigation"
import { appRoute } from "routes/app/home.runtime"

//AD: Moved from SFG20 Admin
// registerTab({
//     tab: "sfg20AdminTabs",
//     id: "system",
//     title: "System Admin",
//     content: (
//         <Lazy importer={() => import("./system-admin")} extract="SystemAdmin" label={"System Admin"} priority={9999}
// /> ), predicate: () => hasDemand("admin"), })

appRoute.register(
    "system-admin",
    <Lazy
        importer={() => import("routes/sfg20-admin/plugins/system-tab/system-admin")}
        extract="SystemAdmin"
        demands={"admin"}
    />
)
registerConditionalPageNavigation(
    () => hasDemand("admin"),
    "/app/system-admin",
    "System Admin",
    "ic:twotone-admin-panel-settings",
    {
        group: "More",
        priority: 250,
    }
)
