import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getCustomSkillCodes = api(async function getCustomSkillCodes(client) {
    return query(
        gql`
            query GetSkillCodes($client: String) {
                getCustomSkillCodes(client: $client) {
                    _id
                    Skilling
                    SkillingCode
                    CoreSkillingID
                    Rate
                }
            }
        `,
        { client },
        { returns: "getCustomSkillCodes", fetchPolicy: "network-only" }
    )
})
