import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const getComplianceReport = api(async function getChartData({
    plan,
    group,
    windowStart,
    effectiveDate,
    queries,
}) {
    return query(
        gql`
            query GetComplianceReport(
                $planId: String!
                $group: String
                $windowStart: Date!
                $effectiveDate: Date!
                $queries: [ChartQueryInput!]!
            ) {
                getChartData(
                    plan: $planId
                    group: $group
                    windowStart: $windowStart
                    effectiveDate: $effectiveDate
                    queries: $queries
                ) {
                    counts
                    rangeTotal
                    total
                }
            }
        `,
        { planId: plan, group, windowStart, effectiveDate, queries },
        { returns: "getChartData" }
    )
})
