import { Box, Stack, Typography } from "@mui/material"
import { UserAvatar } from "minimals-template/components/user-avatar"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function RegimeAccessPermittedUsers() {
    const { permittedUsers } = useBoundContext()
    return (
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography variant="subtitle2">The following people have access : </Typography>
            <Box display="flex" alignItems="center" pl={1}>
                {permittedUsers?.map((user, idx) => (
                    <UserAvatar key={idx} sx={{ mr: 1 }} user={user} />
                ))}
            </Box>
        </Stack>
    )
}
