import { useTabContext } from "@mui/lab"
import { useLayoutEffect } from "react"
import { Tab, Tabs } from "@mui/material"

export function EnforceTabList({ onChange, children, ...props }) {
    const tab = useTabContext()
    const tabHasChildWithValue = children.flat(Infinity).some((child) => child.props?.value === tab.value)
    const selectedValue = tabHasChildWithValue ? tab.value : children[0]?.props?.value

    useLayoutEffect(
        function selectFirstIfTabIsEmpty() {
            if (tab.value && !!children.length && !tabHasChildWithValue) {
                if (selectedValue) onChange(selectedValue)
            }
        },
        [tab.value, onChange, children.length, tabHasChildWithValue, selectedValue]
    )

    return (
        <Tabs
            value={selectedValue}
            onChange={onChange}
            {...props}
            children={[...children, <Tab key="unlikely" value="unlikely" />]}
        />
    )
}
