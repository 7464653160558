import React from "react"
import { useBoundValue } from "lib/@components/binding/use-bound-value"

export function BoundValue({ field, property, default: defaultValue, children }) {
    const [value, setValue] = useBoundValue(field, defaultValue)
    if (typeof children !== "function" && !property) {
        throw new Error("BoundValue must have a function child")
    }
    if (property) {
        return value
            ? React.Children.map(children, (c) => <c.type key={c.key} {...c.props} {...{ [property]: value }} />)
            : children
    }
    return children(value, setValue)
}
