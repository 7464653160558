import { ActionItem } from "slot-definitions"
import { Avatar, Button, ListItemAvatar } from "@mui/material"
import { TruncatedListItemText } from "lib/@components/truncatedListItemText"
import Iconify from "minimals-template/components/Iconify"
import { navigate } from "lib/routes/navigate"

ActionItem("schedule-rejected-draft").plug(<ScheduleRejectedDraft />)

function ScheduleRejectedDraft({ action }) {
    return (
        <>
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: "myStuff.main", color: "myStuff.contrastText" }}>
                    <Iconify icon={"fluent:document-dismiss-16-filled"} />
                </Avatar>
            </ListItemAvatar>
            <TruncatedListItemText
                primary={action.title}
                secondary={`Reason: ${action.payload.rejectionReason}`}
                truncateSecondary
            />
            <Button
                data-cy="schedule-rejected-draft-review-button"
                sx={{ m: 0.5 }}
                onClick={go}
                color="primary"
                variant="outlined"
                size="small"
            >
                Review
            </Button>
        </>
    )

    async function go() {
        navigate(`/app/schedules/allDrafts!${action.payload.draft.id}/edit`)
    }
}
