import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"

export const getPagedSystemDemands = api(({ searchTerm = "", skip = 0, take = 20, orderStmt = ["name"] } = {}) =>
    query(
        gql`
            query PagedSystemDemands($searchTerm: String, $take: Int, $skip: Int, $orderStmt: [String]) {
                pagedSystemDemands(searchTerm: $searchTerm, take: $take, skip: $skip, orderStmt: $orderStmt) {
                    edges {
                        cursor
                        node {
                            name
                            id
                            description
                            group
                        }
                    }
                    pageInfo {
                        endCursor
                        hasNextPage
                        hasPreviousPage
                        startCursor
                    }
                    totalCount
                }
            }
        `,
        { searchTerm, skip, take, orderStmt },
        { returns: "pagedSystemDemands" }
    )
)

export const getSystemDemands = api(({ searchTerm = "" } = {}) =>
    query(
        gql`
            query SystemDemands($searchTerm: String) {
                systemDemands(searchTerm: $searchTerm) {
                    name
                    id
                    description
                    group
                    purpose
                }
            }
        `,
        { searchTerm },
        { returns: "systemDemands" }
    )
)

export const getAllocateableRoleDemands = api(() =>
    query(
        gql`
            query SystemDemands($roleOnly: Boolean) {
                systemDemands(roleOnly: $roleOnly) {
                    name
                    id
                    description
                    group
                    purpose
                    icon
                }
            }
        `,
        { roleOnly: true },
        { returns: "systemDemands" }
    )
)

export function useDemands() {
    const value = getSystemDemands.useResults()
    return value || []
}
