import { Button } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { AuditCommentTools } from "slot-definitions"
import { navigate } from "lib/routes/navigate"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

AuditCommentTools("customSchedule").plug(<ComparePreviousVersionButton />)

function ComparePreviousVersionButton({ group, id, index }) {
    const { close } = useBoundContext()
    if (index === 0) {
        return null
    }

    return (
        <Button
            onClick={goToCompare}
            startIcon={<Iconify icon="ic:round-preview" />}
            variant="text"
            id={`auditPreviousVersionBtn-${group.id}`}
        >
            Compare Previous Version
        </Button>
    )

    function goToCompare() {
        close()
        navigate(`/app/schedules/compare/schedule!${id}/scheduleHistory!${id}-${group.version}`)
    }
}
