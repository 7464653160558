import { api } from "lib/graphql/api"
import { mutate } from "lib/graphql/mutate"
import { gql } from "@apollo/client"

export const setFubspotForUsers = api((id, data) =>
    mutate(
        gql`
            mutation SetFubspotForUsers($id: String!, $data: JSONObject!) {
                setFubspotForUsers(id: $id, data: $data)
            }
        `,
        { id, data },
        { returns: "setFubspotForUsers" }
    )
)
