import { appRoute } from "routes/app/home.runtime"
import { RootPlugs } from "slot-definitions"
import { useEffect } from "react"
import { RefreshNavBar } from "event-definitions"
import { getMyShareLinks } from "routes/shared-with-me/controller/get-my-share-links"
import { SharedWithMe } from "routes/shared-with-me/shared-with-me"
import { registerPageNavigationWithContent } from "lib/routes/register-page-navigation-with-content"
import NavItem from "minimals-template/components/nav-section/vertical/NavItem"
import { OutlinedBadge } from "lib/@components/outlined-badge"

appRoute.register("shared-with-me", <SharedWithMe $lazy />)

RootPlugs.plug(<HasOwnSharing />)

let hasLinks = false
let updatedLinks = 0

function HasOwnSharing() {
    const links = getMyShareLinks.useResults(undefined, 0) ?? null
    hasLinks = !!links?.total
    updatedLinks = links?.outOfDateLinks
    useEffect(() => {
        RefreshNavBar.raiseOnce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasLinks, updatedLinks])
    return null
}

registerPageNavigationWithContent(
    <NavigateSharedWithMe />,
    "/app/shared-with-me",
    "Shared With Me",
    "uil:cloud-share",
    {
        priority: 600,
        predicate: () => hasLinks,
    }
)

function NavigateSharedWithMe({ data, depth, active, isCollapse, onClick }) {
    data.info = <OutlinedBadge content={updatedLinks} color="error.main" />
    return (
        <NavItem
            className="nav-item actions"
            item={data}
            depth={depth}
            active={active}
            isCollapse={isCollapse}
            onClick={onClick}
        />
    )
}
