import { Box } from "@mui/material"
import { useState } from "react"
import { Bound } from "lib/@components/binding/Bound"
import noop from "lib/noop"

export function ChangesFromPublished({ children }) {
    const [target] = useState({})

    return (
        <Bound target={target} refresh={noop}>
            <Box width={1}>{children}</Box>
        </Bound>
    )
}
