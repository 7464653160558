import { TreeToolbar } from "slot-definitions"
import { Button, Divider } from "@mui/material"
import { useComparisonSchedule } from "routes/schedule/edit/useComparisonSchedule"
import { CommitChange, UndoRedoChange } from "event-definitions"
import { IsEditMode } from "lib/@components/edit-mode"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { delay } from "lib/delay"
import { isSame } from "library/normalized-stringify"

export function PublishButton() {
    UndoRedoChange.useRefresh()
    const { target, immediateSave, editRefresh } = useBoundContext()
    const [compare] = useComparisonSchedule() ?? []
    return (
        <IsEditMode>
            <>
                <Button
                    size="small"
                    disabled={isSame(compare, target, skipProps)}
                    variant="contained"
                    color="primary"
                    onClick={publish}
                    data-cy="publish-button"
                >
                    Submit for Review
                </Button>
                <Divider orientation="vertical" flexItem />
            </>
        </IsEditMode>
    )

    async function publish() {
        CommitChange.raise()
        await delay(50)
        target.behaviours.state = "review"
        immediateSave()
        editRefresh()
    }

    function skipProps(key) {
        return key.startsWith("search_")
    }
}

TreeToolbar.plug(<PublishButton priority={2} if={({ target }) => target.behaviours?.state === "default"} />)
