import { useContext } from "react"
import { Button, DialogActions } from "@mui/material"
import { CommitChange } from "event-definitions"
import { OkCancelContext } from "lib/@hooks/useDialog"

export function Done({ ok, okCaption = "Done", value, children }) {
    const okCancel = useContext(OkCancelContext)
    ok = ok || okCancel.ok
    return (
        <DialogActions>
            {children}
            <Button
                onClick={async () => {
                    CommitChange.raise()
                    setTimeout(() => ok(value))
                }}
                color="primary"
                variant="contained"
            >
                {okCaption}
            </Button>
        </DialogActions>
    )
}
