const { RegisterSystemTask } = require("event-definitions")
const { createEvent } = require("library/local-events")
const { mutate } = require("lib/graphql/mutate")
const { gql } = require("@apollo/client")
const { TaskEditor } = require("slot-definitions")
const { replaceAllWith } = require("lib/@components/slot/replace-all-with")
const { Frag } = require("lib/@components/slot/frag")

const CleanupRedis = createEvent("cleanupRedis")

RegisterSystemTask.handleOnce(({ tasks }) => {
    const task = {
        name: "Cleanup Redis",
        event: CleanupRedis.eventName,
        params: {},
    }
    tasks.push(task)
})
TaskEditor(CleanupRedis.eventName).plug(replaceAllWith(<Frag />))
CleanupRedis.handleOnce(async () => {
    console.log("Cleanup REdis")
    await mutate(
        gql`
            mutation Cleanup {
                cleanupRedis
            }
        `,
        {},
        { returns: "cleanupRedis" }
    )
})
