import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getLatestTechnicalBulletin = api(async function getLatestTechnicalBulletin(type) {
    return query(
        gql`
            query GetLatestTechnicalBulletin($type: String!) {
                getLatestTechnicalBulletin(type: $type) {
                    id
                    content
                    date
                    title
                    portedFromLegacy
                    type
                }
            }
        `,
        { type },
        { returns: "getLatestTechnicalBulletin" }
    )
})
