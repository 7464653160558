import { splitId } from "library/database/split-id"
import { Button } from "@mui/material"
import { addToStack } from "routes/schedule/tree/schedules/upDirectory"
import { ScheduleHeaderToolbar } from "slot-definitions"
import { NotOnMobile } from "lib/@components/mobile"
import { navigate } from "lib/routes/navigate"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function TimelineButton() {
    const {
        target: { _id },
    } = useBoundContext()
    const { id } = splitId(_id)
    const { editMode, timelineMode } = useBoundContext()

    return (
        (editMode || timelineMode) && (
            <NotOnMobile>
                <Button size="small" variant="outlined" color="primary" onClick={go}>
                    Timeline
                </Button>
            </NotOnMobile>
        )
    )

    function go() {
        addToStack()
        navigate(`/app/schedules/${id}/timeline`)
    }
}

ScheduleHeaderToolbar.plug(<TimelineButton priority={25} />)
