import noop from "lib/noop"
import { useCallback, useMemo, useRef } from "react"
import { Box } from "@mui/material"
import { Editing } from "lib/@components/editable/editing"
import { Editor } from "minimals-template/components/editor"
import { Comparison } from "routes/schedule/compare/lib/diff3"
import { bind } from "lib/@components/binding/bind"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export const BoundEditableRichText = bind(<EditableRichText />, {
    extract: (v) => v,
})

export function EditableRichText({
    value,
    field,

    sx,
    noDifference,
    transformIn = (v) => v,
    className,
    onChange = noop,
    display,
}) {
    const text = useRef(value)
    const {
        target,
        shouldPopupTextEditor,
        originalTarget = target,
        otherTarget = originalTarget,
        hideDifference,
    } = useBoundContext()
    const original = Object.get(originalTarget, field)
    const other = Object.get(otherTarget, field)
    const doStoreText = useCallback(store, [onChange])
    useMemo(() => {
        text.current = value ?? ""
    }, [value])
    return (
        <Editing
            field={field}
            editor={
                <Box position="relative" width={1} sx={{ wordBreak: "break-word" }}>
                    {shouldPopupTextEditor && (
                        <Box sx={{ opacity: 0 }} width={1}>
                            {display ?? (
                                <Box
                                    width={1}
                                    sx={{ lineHeight: 1.43, opacity: 0 }}
                                    minHeight={10}
                                    dangerouslySetInnerHTML={{ __html: value ?? "" }}
                                />
                            )}
                        </Box>
                    )}
                    <Box
                        position={shouldPopupTextEditor ? "absolute" : undefined}
                        left={9}
                        top={-22}
                        right={11}
                        bottom={0}
                        overflow="visible"
                        sx={{ lineHeight: 1.43 }}
                    >
                        <Box width={1} sx={{ overflowX: "hidden", ...sx }} style={{ overflow: "visible" }}>
                            <Editor className={className} value={text.current} onChange={doStoreText} />
                        </Box>
                    </Box>
                </Box>
            }
            display={
                display ??
                (noDifference || hideDifference ? (
                    <Comparison text={value} original={value} other={value} />
                ) : (
                    <Comparison text={value} original={transformIn(original)} other={transformIn(other)} />
                ))
            }
        />
    )

    function store(value) {
        if (text.current !== value) {
            text.current = value
            onChange(text.current)
        }
    }
}
