import { Avatar, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import Iconify from "minimals-template/components/Iconify"
import { navigate } from "lib/routes/navigate"
import { ScheduleStatusTooltipIcon } from "routes/regime/schedule-code"

export function ScheduleGroupInstance({ item }) {
    return (
        <>
            <ListItemButton onClick={go}>
                <ListItemBox>
                    {/*<Selected size="small" id={item.id} />*/}
                    <ListItemAvatar>
                        <Avatar>
                            <Iconify icon={item.icon ?? "bxs:building"} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText sx={{ flex: 1 }} primary={item.name} />
                    <ScheduleStatusTooltipIcon
                        reasons={item?.tailoredStatus?.reasons}
                        summary={item?.tailoredStatus?.summary}
                    />
                </ListItemBox>
            </ListItemButton>
        </>
    )

    function go() {
        navigate(`/app/schedules?id=${item.groupId}`)
    }
}
