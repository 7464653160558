import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const completions = api(async function ({ regime, folder, startDate, endDate }) {
    return query(
        gql`
            query GetCompletions($regime: String!, $folder: String, $startDate: Date!, $endDate: Date!) {
                getCompletions(regime: $regime, folder: $folder, startDate: $startDate, endDate: $endDate) {
                    count
                    days {
                        date
                        count
                        completions {
                            taskId
                            metadata
                            date
                            user
                        }
                    }
                    schedules {
                        _id
                        version
                        id
                        title
                        code
                        tasks {
                            title
                            id
                            content
                            classification
                            frequency {
                                period
                                interval
                            }
                            skill {
                                Skilling
                                SkillingCode
                            }
                        }
                    }
                }
            }
        `,
        {
            regime,
            folder,
            startDate,
            endDate,
        },
        {
            returns: "getCompletions",
        }
    )
})
