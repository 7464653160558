import { Box, Checkbox } from "@mui/material"
import { prevent } from "lib/prevent"
import { SELECTION_REFERENCE_TYPE, useSelection } from "routes/schedule/lib/useSelection"
import { useTreeItem } from "routes/schedule/lib/useTreeItem"
import { NotInSelector } from "routes/schedule/my-schedules/my-custom-schedules/select-schedule"
import { findInChildren } from "library/tree"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useMemo } from "react"
import { useMemoryReference } from "lib/@hooks/use-memory-reference"
import { filterSchedules } from "ioc"

export function SelectedChildren({ id, depth }) {
    const [isSelected] = useSelection()
    const [, , save] = useMemoryReference({ selectedItems: {} }, SELECTION_REFERENCE_TYPE)
    const { target } = useBoundContext()
    const item = useTreeItem(id ?? target.selectedId ?? target.id)
    const allSchedules = useMemo(
        () => findInChildren(item, (c) => !!c._id && c?.type !== "group", depth),
        [depth, item]
    )
    const availableSchedules = filterSchedules(allSchedules)
    const areAllSelected = availableSchedules.length && availableSchedules?.every((c) => isSelected([c._id, c.id]))
    const areSomeSelected = areAllSelected || availableSchedules?.some((c) => isSelected([c._id, c.id]))

    return (
        <NotInSelector>
            {!!availableSchedules.length && (
                <Box sx={{ lineHeight: 0 }}>
                    <Checkbox
                        className={`checkbox ${
                            !areAllSelected && areSomeSelected
                                ? "indeterminate"
                                : areAllSelected
                                  ? "checked"
                                  : "not-checked"
                        }`}
                        indeterminate={!areAllSelected && areSomeSelected}
                        checked={!!areAllSelected}
                        onClick={prevent(toggle)}
                    />
                </Box>
            )}
        </NotInSelector>
    )

    function toggle() {
        const set = {}
        availableSchedules.forEach((c) => {
            if (areSomeSelected) {
                set[c._id] = false
            } else {
                set[c._id] = c.id ?? true
            }
        })

        save((reference) => ({ ...reference, selectedItems: { ...reference.selectedItems, ...set } }))
    }
}
