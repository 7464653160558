import { StandardSwitch } from "lib/@components/binding/bound-components"
import { useBoundValue } from "lib/@components/binding/use-bound-value"
import { isEnabled } from "lib/@components/feature"
import { If } from "lib/@components/switch"

export function ConformityDocumentsShareLinkSetting() {
    const [demands, setDemands] = useBoundValue("demands", [])
    const [regime] = useBoundValue("regime")

    return (
        <If truthy={regime.startsWith("live|") && isEnabled("plan")}>
            <StandardSwitch
                value={demands.includes("create-conformity")}
                onChange={toggle}
                label="Can Complete Conformity Documents"
            />
        </If>
    )

    function toggle() {
        if (demands.includes("create-conformity")) {
            setDemands((d) => d.filter((d) => d !== "create-conformity"))
        } else {
            setDemands((d) => [...d, "create-conformity"])
        }
    }
}
