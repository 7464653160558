import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useBoundValue } from "lib/@components/binding/use-bound-value"
import { Bound } from "lib/@components/binding/Bound"
import { UomAsset } from "routes/regime/assets/uom-asset"

export function Asset({ item, uom, index }) {
    const { target } = useBoundContext()

    const field = `${uom}_${item + 1}`
    if (typeof target[field] !== "string") {
        target[field] = ""
    }

    const [assetId, setAssetId] = useBoundValue(field, "")

    return (
        <Bound assetId={assetId} setAsset={setAssetId} assetField={field} assetIndex={index} uom={uom}>
            <UomAsset uom={uom} index={item} />
        </Bound>
    )
}
