import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getSharingLinks = api(async function getSharingLinks({ search, skip, take, regime, filters, sort }) {
    try {
        return await query(
            gql`
                query GetSharingLinks(
                    $search: String
                    $skip: Int
                    $take: Int
                    $regime: String
                    $filters: [String]
                    $sort: String
                ) {
                    getSharingLinks(
                        search: $search
                        skip: $skip
                        take: $take
                        regime: $regime
                        filters: $filters
                        sort: $sort
                    ) {
                        total
                        links {
                            _id
                            name
                            url
                            startDate
                            endDate
                            rules
                            regimeName
                            regime
                            classifications
                            groupNames
                            demands
                            skills
                            description
                            enabled
                            groups
                            canDownloadPDF
                            canDownloadExcel
                            canDownloadSchedulePDF
                            canDownloadXml
                            canShareOnwards
                            unchecked
                            webhooks {
                                id
                                accessDomain
                            }
                        }
                    }
                }
            `,
            { search, skip, take, regime, filters, sort },
            { returns: "getSharingLinks" }
        )
    } catch (e) {
        console.error(e)
        return { total: 0, links: [] }
    }
})
