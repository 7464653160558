import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getTechnicalUpdateBulletins = api(async function getTechnicalUpdateBulletins(type) {
    return query(
        gql`
            query GetTechnicalUpdateBulletins($type: String!) {
                getTechnicalUpdateBulletins(type: $type) {
                    id
                    content
                    date
                    title
                    portedFromLegacy
                    type
                }
            }
        `,
        { type },
        { returns: "getTechnicalUpdateBulletins" }
    )
})
