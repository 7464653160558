import { appRoute } from "routes/app/home.runtime"

import Schedules from "routes/schedule/schedules"
import { Compare } from "routes/schedule/compare/compare"

appRoute.register(
    "schedules/compare/:destination/:source/:other",
    <Schedules>
        <Compare $lazy />
    </Schedules>
)
