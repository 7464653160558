import { AuditEntry, AuditLogEntry } from "slot-definitions"
import { getAuditPayload } from "routes/audit/controller/get-audits-by-reference-id"
import { IconButton } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { navigate } from "lib/routes/navigate"
import { uniqueId } from "library/database/split-id"
import { replaceAllWithPredicate } from "lib/@components/slot/replace-all-with-predicate"
import { Frag } from "lib/@components/slot/frag"

AuditEntry("maintenanceRegime").plug(<ShowInfo />)

function ShowInfo({ id, userName, date, desc }) {
    const payload = getAuditPayload.useResults(id)
    return (
        !!payload?.historyId && (
            <IconButton color="primary" size="small" onClick={goToCompare}>
                <Iconify icon="ic:outline-remove-red-eye" />
            </IconButton>
        )
    )

    function goToCompare() {
        navigate(`/app/overview/${uniqueId(payload.historyId)}?title=${desc} by ${userName} on ${date}`)
    }
}

AuditLogEntry("customSchedule").plug(replaceAllWithPredicate(onlyIfPublished, <Frag />))

function onlyIfPublished(_, { group }) {
    return !group?.content.some((c) => c.desc.toLowerCase().includes("published"))
}
