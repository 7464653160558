import { Avatar, Checkbox, Stack, TableCell, TableRow, Typography } from "@mui/material"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { RolesIcons } from "./roles-icon-widget"
import { relevantDemands } from "../regime-access-config"
import { BoundSwitch } from "lib/@components/binding/bound-components"
import { Bound } from "lib/@components/binding/Bound"

export function RegimeAccessTableRow({ row, isItemSelected, handleClick }) {
    const { setChangedUsers } = useBoundContext()
    const demandsLookup = {}
    relevantDemands.forEach((demand) => (demandsLookup[demand] = !!row.demands?.includes(demand)))

    return (
        <Bound target={row} onChange={handleToggle}>
            <TableRow className="access-table-row">
                <TableCell padding="checkbox">
                    <Checkbox checked={isItemSelected} onClick={() => handleClick(row)} />
                </TableCell>
                <TableCell component="th" scope="row">
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar
                            src={row.photoURL}
                            alt={row.displayName}
                            color="primary"
                            sx={{
                                color: "#333",
                            }}
                        />
                        <Typography data-cy="user-description-name" variant="subtitle2" noWrap>
                            {row.displayName}
                        </Typography>
                    </Stack>
                </TableCell>
                <TableCell align="left">{row.jobTitle}</TableCell>
                <TableCell align="left">
                    <RolesIcons demands={demandsLookup} />
                </TableCell>
                <TableCell align="left">
                    <BoundSwitch
                        sideEffectsOnBlur
                        field="hasAccess"
                        default={false}
                        disabled={row.disabled}
                        data-cy="regime-access-toggle"
                    />
                </TableCell>
            </TableRow>
        </Bound>
    )

    function handleToggle() {
        setChangedUsers((prevUsers) => {
            const itemIndex = prevUsers.findIndex((item) => item.userId === row.userId)
            if (itemIndex !== -1) {
                return prevUsers.filter((_, index) => index !== itemIndex)
            } else {
                return [...prevUsers, row]
            }
        })
    }
}
