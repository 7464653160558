import "./schedule-item-types/draft-schedule-item"
import "./schedule-item-types/published-schedule-item"
import "./my-list-of-drafts"
import "routes/schedule/folder-manager/folder-management"
import "./retire"
import "routes/schedule/my-schedules/my-custom-schedules/components/copy-regime"
import "routes/schedule/my-schedules/my-custom-schedules/components/delete-regime"
import "./custom-schedules-tree"
import { getRootOfMaintenance } from "routes/regime/lib/root-of-maintenance"
import { RecycleType } from "event-definitions"
import DemandGuard from "minimals-template/components/guards/DemandGuard"
import { MY_LIST } from "./lib/constants"
import { ScheduleSelectorWrapper } from "slot-definitions"

export { getRootOfMaintenance }

RecycleType.handle(({ item }) => {
    if (item.icon === "fluent:drafts-24-filled" || item.icon === "mdi:file-document-edit-outline") {
        return { type: "Draft Schedule" }
    }
    return undefined
})

RecycleType.handle(({ item }) => {
    if (item.contents?._id?.includes("sfg20/schedule")) {
        return { type: "Schedule" }
    }
    return undefined
})

RecycleType.handle(({ item }) => {
    if (item.icon === "material-symbols:folder") {
        return { type: "Regime Folder" }
    }
    return undefined
})

ScheduleSelectorWrapper.plug(
    <DemandGuard demands="scheduleEditor" redirect={false} if={({ parentItem }) => parentItem.id === MY_LIST} />
)
