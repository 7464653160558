const availableProducts = {}

export function addProduct(
    sku,
    {
        description = "",
        regimes = 0,
        quantity = 0,
        createLicences = 0,
        collaborateLicences = 0,
        viewLicences = 0,
        demands = [],
        hasQuantity = false,
    }
) {
    availableProducts[sku] = {
        sku,
        description,
        regimes,
        quantity,
        createLicences,
        collaborateLicences,
        viewLicences,
        demands,
        hasQuantity,
    }
}

addProduct("FIQT3", {
    regimes: 10,
    quantity: 1,
    demands: ["scheduleEditor", "publishSchedule", "create-conformity", "advancedTailoring"],
})
addProduct("FIQT2", {
    regimes: 5,
    quantity: 1,
    demands: ["create-conformity", "advancedTailoring"],
})
addProduct("FIQT1", {
    regimes: 1,
    quantity: 1,
    demands: ["create-conformity"],
})
addProduct("FIQCREATET3", { createLicences: 1, hasQuantity: true })

addProduct("FIQCREATET2", { createLicences: 1, hasQuantity: true })
addProduct("FIQCREATET1", { createLicences: 1, hasQuantity: true })
addProduct("FIQCOLLABORATE", { collaborateLicences: 1, viewLicences: 1, hasQuantity: true })
addProduct("FIQACCESSEQUILIFT", { demands: ["schedule_type_22"] })

addProduct("FIQCATERING", { demands: ["schedule_type_21"] })
addProduct("FIQFABRIC", { demands: ["schedule_type_27"] })
addProduct("FIQHTMALIGNED", { demands: ["schedule_type_25"] })
addProduct("FIQLABEQUIP", { demands: ["schedule_type_39"] })
addProduct("FIQMANAGEMENT", { demands: ["schedule_type_35"] })
addProduct("FIQMHE", { demands: ["schedule_type_26"] })
addProduct("FIQOPERATIONAL", { demands: ["schedule_type_24"] })
addProduct("FIQRESIDENTIAL", { demands: ["residential_sets"] })
addProduct("FIQS-RESCARHOMES", { demands: ["schedule_type_37"] })
addProduct("FIQS-RESPROP", { demands: ["schedule_type_38"] })
addProduct("FIQS-RESSET", { demands: ["schedule_type_38"] })
addProduct("FIQSECURITY", { demands: ["schedule_type_28"] })
addProduct("FIQSFG30", { demands: ["schedule_type_34"] })
addProduct("FIQWORKSHOPEQUIPT", { demands: ["schedule_type_23"] })
addProduct("FIQTECHSUPPORT", { demands: ["FIQTECHSUPPORT"] })
addProduct("FIQEXCELDOWNLOAD", { demands: ["feature-download-tasks"] })
addProduct("FIQXML", { demands: ["feature-download-xml"] })
addProduct("FIQ5ADDREGIMES", { description: "Additional 5 regimes", regimes: 5, hasQuantity: true })

addProduct("FIQASSETMAPPING", {
    description: "Enable Asset Mapping for Clients",
    demands: ["feature-assets"],
})

export function getAvailableProducts() {
    return availableProducts
}

export function getAvailableProductsArray() {
    return Object.values(availableProducts)
}

export function getFeatureDemandsForProducts() {
    return new Set(
        Object.values(availableProducts)
            .filter((p) => p.demands.some((d) => d.startsWith("feature-")))
            .map((product) => product.demands.find((demand) => demand.startsWith("feature-")))
    )
}
