import { ScheduleSelectorList } from "slot-definitions"
import { isInRegime } from "routes/facilities/lib/when-parent-item"
import { FilterView } from "routes/schedule/plugins/filter/filter-view"

ScheduleSelectorList("*").plug(
    <FilterView
        $lazy
        if={(context) =>
            !isInRegime(context) &&
            context.parentItem.id !== "schedules" &&
            !context.inSelector &&
            !context.parentItem.id.startsWith("my")
        }
        priority={1}
    />
)
