import { Chip, Divider, Stack, Tooltip, Typography } from "@mui/material"
import { tagColor } from "routes/schedule/plugins/baskets/tags/tagColor"
import { useTags } from "routes/schedule/plugins/baskets/tags/useTags"
import noop from "lib/noop"
import { prevent } from "lib/prevent"

export function TagsList({ referenceId, schedule, type = "Tag", onClick = noop }) {
    const coreTags = schedule?.tags ?? schedule?.$?.tags

    const tags = useTags(referenceId, type)
    if (!tags) return null

    return (
        <Stack spacing={1} direction="row">
            {/*
            {!!coreTags?.length && (
                <Box>
                    <Typography variant="caption" color="text.secondary" component="div">
                        Core Tags
                    </Typography>
                    <Stack sx={{ my: 1 }} direction="row" spacing={1}>
                        <Repeat list={coreTags} item={<CoreTag />} />
                    </Stack>
                </Box>
            )*/}
            {!!coreTags?.length && !!tags?.length && <Divider orientation="vertical" sx={{ alignSelf: "stretch" }} />}
            {!!tags.length && (
                <Stack flex={1}>
                    {!!coreTags?.length && (
                        <Typography variant="caption" color="text.secondary" component="div">
                            Custom Tags
                        </Typography>
                    )}

                    <Stack sx={{ mt: 1 }} direction="row" spacing={1}>
                        {tags.map((option) => {
                            const [backgroundColor, color] = tagColor(option)
                            return (
                                !!option && (
                                    <Tooltip key={option.id} title={option.name}>
                                        <Chip
                                            sx={{
                                                "&.MuiChip-root": {
                                                    color,
                                                    backgroundColor: `${backgroundColor} !important`,
                                                },
                                                mr: 1,
                                                mb: 0.5,
                                                maxWidth: 200,
                                            }}
                                            onClick={prevent(() => onClick(option))}
                                            color="primary"
                                            label={option.name}
                                        />
                                    </Tooltip>
                                )
                            )
                        })}
                    </Stack>
                </Stack>
            )}
        </Stack>
    )
}
