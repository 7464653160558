import { Paragraph } from "docx"
import { textFromHtmlNode } from "../text-from-html-node"
import { formatTextAndAddToParagraph } from "../format-text-and-add-to-paragraph"

export function formatList(htmlContent, key) {
    function recurseListHandler(node, prefix = "") {
        let listParagraphs = []
        let index = 1
        for (let childNode of node.childNodes) {
            if (childNode.nodeName === "LI") {
                let currentPrefix = prefix
                let liTextSegments = []
                let nestedLists = []

                if (childNode.parentNode.nodeName === "OL") {
                    currentPrefix = `${prefix}${index}.`
                } else if (childNode.parentNode.nodeName === "UL") {
                    currentPrefix = `${prefix}•`
                }

                for (let grandChildNode of childNode.childNodes) {
                    if (grandChildNode.nodeName === "OL" || grandChildNode.nodeName === "UL") {
                        nestedLists = recurseListHandler(grandChildNode, `${currentPrefix} `)
                    } else {
                        liTextSegments = liTextSegments.concat(textFromHtmlNode(grandChildNode))
                    }
                }

                const liParagraph = new Paragraph({
                    text: "",
                    style: "defaultParagraph",
                })

                formatTextAndAddToParagraph(liParagraph, [`${currentPrefix} `, ...liTextSegments], key)
                listParagraphs.push(liParagraph)
                listParagraphs.push(...nestedLists)
                index++
            } else if (childNode.nodeName === "OL" || childNode.nodeName === "UL") {
                listParagraphs.push(...recurseListHandler(childNode, prefix))
            }
        }

        return listParagraphs
    }

    const formattedList = recurseListHandler(htmlContent)
    return formattedList
}
