import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { summariseTasks } from "routes/asset-register/plugins/regime-display/controller/summarise-tasks"
import { Divider, TableCell, TableRow, Typography } from "@mui/material"
import { Repeat } from "lib/@components/repeat"
import { SummaryEntry } from "routes/asset-register/plugins/regime-display/plugins/grouped-tasks/summary-entry"
import { compareStrings } from "library/compare-strings"
import { useReferenceState } from "lib/@hooks/use-reference"

export function SummaryBox({ filter, path, icon, title, sort = (v) => v, method = summariseTasks }) {
    const { regimeId, group, filterKey = "task_filters" } = useBoundContext()
    const [filters] = useReferenceState(filterKey, [], "filters")
    const result = method.useResults({ regimeId, group, summarise: [path], filters })
    const total = result?.reduce((a, c) => a + c.minutes, 0) ?? Infinity
    const list =
        result?.filter((s) => !!s.value[0] && compareStrings(s.value[0], filter)).sortBy((c) => sort(c.value[0])) ?? []
    return (
        <>
            {!!list.length && (
                <TableRow>
                    <TableCell colSpan={3}>
                        <Typography variant="body2" fontWeight="bold" component="div" sx={{ mt: 2 }}>
                            {title}
                        </Typography>
                        <Divider />
                    </TableCell>
                </TableRow>
            )}

            <Repeat list={list} item={<SummaryEntry icon={icon} total={total} />} />
        </>
    )
}
