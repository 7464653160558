import { mutate } from "lib/graphql/mutate"
import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"

export const addWatch = api((_id, path) => {
    if (_id && path) {
        return mutate(
            gql`
                mutation AddWatch($_id: String!, $path: String!) {
                    addWatch(_id: $_id, path: $path)
                }
            `,
            { _id, path },
            { returns: "addWatch" }
        )
    } else return null
})
