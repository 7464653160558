import { findInChildren, parentFind } from "library/tree"
import { PlanRoot } from "library/tree/constants"
import { AdvancedFilterSetting } from "event-definitions"
import { getAllSchedules } from "routes/regime/plugins/regime-filtering/get-all-schedules"

export async function filterRegime({ schedules, context: { parentItem } }) {
    if (parentFind(parentItem, (c) => c[PlanRoot])) {
        const filters = AdvancedFilterSetting.filters()
        const groups = filters.groupBy("type")
        if (filters.length === 0) return undefined

        const allSchedules = findInChildren(parentItem, (c) => c.type === "schedule")
        const retrieved = await getAllSchedules(allSchedules)
        const lookup = retrieved.keyBy("id")

        for (const [type, list] of Object.entries(groups)) {
            switch (type) {
                case "period":
                    schedules = schedules.filter((s) => {
                        switch (s.type) {
                            case "schedule":
                                return list.some((l) =>
                                    lookup[s.id]?.tasks.some((t) =>
                                        checkFrequency(t.frequency?.label, l.period.frequency)
                                    )
                                )
                            default: {
                                const groupChildren = findInChildren(s, (s) => s.type === "schedule")
                                return groupChildren.some((c) =>
                                    list.some((l) =>
                                        lookup[c.id]?.tasks.some((t) =>
                                            checkFrequency(t.frequency?.label, l.period.frequency)
                                        )
                                    )
                                )
                            }
                        }
                    })
                    break
                case "skill":
                    schedules = schedules.filter((s) => {
                        switch (s.type) {
                            case "schedule":
                                return list.some((l) =>
                                    lookup[s.id]?.tasks.some((t) => t.skill?.Skilling === l.skill.skill)
                                )
                            default: {
                                const groupChildren = findInChildren(s, (s) => s.type === "schedule")

                                return groupChildren.some((c) =>
                                    list.some((l) =>
                                        lookup[c.id]?.tasks.some((t) => t.skill?.Skilling === l.skill.skill)
                                    )
                                )
                            }
                        }
                    })
                    break
                case "criticality":
                    schedules = schedules.filter((s) => {
                        switch (s.type) {
                            case "schedule":
                                return list.some((l) =>
                                    lookup[s.id]?.tasks.some((t) => t.classification === l.criticality)
                                )
                            default: {
                                const groupChildren = findInChildren(s, (s) => s.type === "schedule")

                                return groupChildren.some((c) =>
                                    list.some((l) =>
                                        lookup[c.id]?.tasks.some((t) => t.classification === l.criticality)
                                    )
                                )
                            }
                        }
                    })
                    break
                default:
                    break
            }
        }
    }
    return { schedules }
}

function checkFrequency(f1, f2) {
    if (f1 === f2) return true
    if (f2[1] === "Y") {
        return `${+f2[0] * 12}M` === f1
    }
    return false
}
