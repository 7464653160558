import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const getFubspotForUsers = api((id) =>
    query(
        gql`
            query GetFubspotForUsers($id: String!) {
                getFubspotForUsers(id: $id)
            }
        `,
        { id },
        { returns: "getFubspotForUsers" }
    )
)
