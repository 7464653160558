import { TableCell, Typography } from "@mui/material"
import { plug } from "lib/@components/slot/plug"

plug("ScheduleItem", <Code priority={200} />)

function Code({ schedule }) {
    return (
        <TableCell sx={{ whiteSpace: "nowrap" }}>
            <Typography sx={{ lineHeight: 1, color: "primary.dark" }} variant="caption">
                {schedule.code}
            </Typography>
        </TableCell>
    )
}
