import { DialogBox } from "lib/@components/dialogBox"
import { Button, DialogContent, Typography } from "@mui/material"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function DisassociateDialog({ ok }) {
    const { target, uom } = useBoundContext()
    const number = +target[uom]
    return (
        <DialogBox
            value="disassociate"
            title="Disassociate"
            okCaption="Disassociate"
            actions={
                <Button disabled={number < 2} onClick={() => ok("delete")}>
                    Delete Asset From Schedule
                </Button>
            }
        >
            <DialogContent>
                <Typography>
                    Choose whether you want to just disassociate the asset or also remove the asset from the schedule.
                </Typography>
            </DialogContent>
        </DialogBox>
    )
}
