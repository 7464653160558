import { Box, Button } from "@mui/material"
import { RouterLink } from "lib/routes/router-link"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Feature } from "lib/@components/feature"
import { FEATURE_FLAG_PLANNING } from "./plan.runtime"

export function ViewPlanButton() {
    const { parentItem } = useBoundContext()
    return (
        <Feature feature={FEATURE_FLAG_PLANNING}>
            <Box ml={1}>
                <Button
                    size="small"
                    component={RouterLink}
                    to={`/app/plan/${parentItem.id}`}
                    variant="contained"
                    color="primary"
                >
                    View Plan
                </Button>
            </Box>
        </Feature>
    )
}
