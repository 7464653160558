/**
 * Helper function for JSON.stringify transformer that removes any circular references
 * @returns {(function(*, *): (undefined))|*}
 */
export function noCircular() {
    const seen = new Set()
    return function removeCircular(key, value) {
        if (typeof value === "object") {
            if (seen.has(value)) return undefined
            seen.add(value)
        }
        return value
    }
}
