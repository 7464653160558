import { useLayoutEffect, useState } from "react"
import { Alert, Box, DialogContent, DialogTitle, TextField } from "@mui/material"
import { setFromEvent } from "lib/setFromEvent"
import { convertToMinMaxNumber } from "lib/number"
import { useRefresh } from "lib/@hooks/useRefresh"
import { DatePicker } from "lib/@components/datePicker"
import { OkCancel } from "lib/@components/okCancel"

export function MoveDialog({ tasks, planStart, currentDay, configurations }) {
    const setStart = tasks.every((t) => t.periodIndex === 0)
    const [date, setDate] = useState(new Date(currentDay))
    const canMove = tasks.filter((t) => t.intervalInHours > 72)
    const cantMove = tasks.filter((t) => t.intervalInHours <= 72)
    const [offset, setOffset] = useState(0)
    const maxForward =
        Math.round(Math.min(14 * 24, ...canMove.map("intervalInHours")) / 48) -
        Math.min(...canMove.map((c) => configurations[c.task.id]?.startOffset ?? 0))
    const maxReverse = setStart
        ? 0
        : Math.round(Math.min(...canMove.map("intervalInHours")) / 48) +
          Math.max(...canMove.map((c) => configurations[c.task.id]?.startOffset ?? 0))
    const refresh = useRefresh()
    useLayoutEffect(() => {
        if (date.$d < planStart) {
            setDate(planStart)
            refresh()
        }
    }, [date, planStart, refresh])

    return (
        <>
            <DialogTitle>
                Move {tasks.length} {"Task".pluralize(tasks.length)}
            </DialogTitle>
            <DialogContent sx={{ mt: 3 }}>
                {!!setStart && (
                    <Box mt={1}>
                        <DatePicker
                            key={refresh.id}
                            label="Start Date"
                            autoFocus
                            onChange={(v) => {
                                console.log(v)
                                setDate(new Date(v).addHours(2))
                            }}
                            value={date}
                        />
                    </Box>
                )}
                {!setStart && (
                    <>
                        {!!cantMove.length && (
                            <Alert variant="info" sx={{ my: 2 }}>
                                Could not move {cantMove.map((t) => t.title).join(", ")} as the interval between tasks
                                is too short
                            </Alert>
                        )}
                        {!!canMove.length && (
                            <Box mt={2}>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    type="number"
                                    value={offset}
                                    onChange={setFromEvent(updateOffset)}
                                    label="Offset in Days"
                                    helperText={`${
                                        maxReverse > 0 ? "-" : ""
                                    }${maxReverse} to +${maxForward} days for the selected ${"task".pluralize(
                                        canMove.length
                                    )} - you can use the arrow keys or type a value`}
                                />
                            </Box>
                        )}
                    </>
                )}
            </DialogContent>
            <OkCancel
                beforeOk={(v) => {
                    if (!setStart) {
                        delete v.date
                    }
                    return v
                }}
                value={{ date, offset, canMove }}
            />
        </>
    )

    function updateOffset(v) {
        setOffset(convertToMinMaxNumber(-maxReverse, maxForward)(v))
    }
}
