import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const assetRegister = api(async function assetRegister(id) {
    return id
        ? query(
              gql`
                  query GetAssetRegister($id: String!) {
                      assetRegister(id: $id) {
                          _id
                          name
                          description
                          id
                          systems
                          locations
                          manufacturers
                          types
                          facilities
                          floors
                          spaces
                          categories
                          cobieFiles
                          synchronisationType
                          options
                      }
                  }
              `,
              { id },
              { returns: "assetRegister" }
          )
        : undefined
})
