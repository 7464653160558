import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const getRegimeAccessCount = api(async function getRegimeAccessCount() {
    return query(
        gql`
            query GetRegimeAccessCount {
                getRegimeAccessCount {
                    inUse
                    available
                    canAccess
                    total
                }
            }
        `,
        {},
        {
            returns: "getRegimeAccessCount",
        }
    )
})
