import useAsync from "lib/@hooks/useAsync"
import { PlanRoot } from "library/tree/constants"
import { parentFind } from "library/tree"
import { getClient, useClientInfo } from "minimals-template/components/contexts/NognitoContext"
import { useSave } from "lib/@hooks/use-save"
import { uniqueId } from "library/database/split-id"
import { EDIT_TREE } from "library/constants"
import { planConfig } from "routes/plan/controller/planConfig"
import { setPlanConfig } from "routes/plan/controller/setPlanConfig"
import { registerSymbol } from "library/serialize-circular"

export const PlanId = registerSymbol(Symbol("PlanId"))

export function getPlanId(item) {
    return item.id === EDIT_TREE ? uniqueId(getClient()) : item.id
}

export function getBasePlanId(item) {
    return getPlanId(item).split("|").at(-1)
}

function usePlan(item, published) {
    const client = useClientInfo()
    const result = useAsync(
        async () => {
            if (!client) return null
            if (!item?.[PlanRoot]) return null
            const { id } = item
            let existing = await planConfig(id, published)
            if (!existing) {
                existing = { _id: id, date: new Date().beginningOfDay().toISOString() }
                await setPlanConfig(existing)
            }
            existing[PlanId] = id
            return existing
        },
        null,
        [item?.id, client]
    )
    const save = useSave({ save: setPlanConfig, target: result })
    if (result) {
        result.configurations ??= {}
    }
    return [result, save]
}

export function usePlanFor(item, published) {
    const hasPlan = parentFind(item, (c) => !!c[PlanRoot])
    return usePlan(hasPlan, published)
}
