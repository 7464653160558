const specialDemands = [
    {
        name: "admin",
        group: "System",
        isSpecial: true,
        purpose: "Access all the things",
        description: "This demand is visible because you are in the MAGIC_REALM",
    },
    {
        name: "admin-users",
        group: "System",
        isSpecial: true,
        purpose: "SFG20 Users list",
        description: "Enables visibility of the SFG20 admin users list",
    },
    {
        name: "admin-companies",
        group: "System",
        isSpecial: true,
        purpose: "SFG20 Companies list",
        description: "Enables visibility of the SFG20 admin companues list",
    },
    {
        name: "admin-apitokens",
        group: "System",
        isSpecial: true,
        purpose: "SFG20 API Token visibility",
        description: "Enables management of API tokens",
    },
    {
        name: "admin-helpcentre",
        group: "System",
        isSpecial: true,
        purpose: "SFG20 Help centre administration",
        description: "Enables administration of the SFG20 help centre content",
    },
    {
        name: "admin-adoptclient",
        group: "System",
        isSpecial: true,
        purpose: "SFG20 Adopt client",
        description: "Enables administrators to become other clients",
    },
    {
        name: "admin-clientmigration",
        group: "System",
        isSpecial: true,
        purpose: "SFG20 Migrate client functionality",
        description: "Enables use of the client migration to the new application",
    },
    {
        name: "admin-databasetransfer",
        group: "System",
        isSpecial: true,
        purpose: "SFG20 Database migration",
        description: "Enables database migration",
    },
    {
        name: "admin-fubspot",
        group: "System",
        isSpecial: true,
        purpose: "SFG20 Fake Hubspot",
        description: "Enables mocking of licenses and products",
    },
    {
        name: "admin-debug",
        group: "System",
        isSpecial: true,
        purpose: "SFG20 Debug Client Licenses",
        description: "Enable debugging of client license issues",
    },
]

exports.specialDemands = specialDemands
