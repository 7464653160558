import { useEffect, useState } from "react"
import { createLock } from "./controller/create-lock"
import { Box } from "@mui/material"
import { deleteLock } from "./controller/delete-lock"
import { delay } from "lib/delay"
import { EditEnding } from "event-definitions"
import { LockErrorPage } from "./lock-error-page"
import { Bound } from "lib/@components/binding/Bound"
import { useUser } from "lib/user/use-user"
import { stillEditing } from "./controller/still-editing"

/**
 * `Lock` is a component that limits access to its children to all but the first user to access them.
 * @param {String} id - The id of the child component to be locked.
 * @param {React.ReactNode} children - The child components to render if they're not already locked.
 * @param {String} [breadcrumb="Item Locked"] - Breadcrumb to be passed to LockErrorPage.
 * @param {String} [message="You're trying to access an item that is currently being used by:"] - Message to be passed
 *     to LockErrorPage.
 * @param {React.ComponentType} [whenLocked=LockErrorPage] - The error component to display when the item is locked.
 *
 * @returns {React.ReactNode} - Returns the child components if the user accessing them is the first to do so,
 *     otherwise returns an error page.
 */

export function Lock({
    id,
    children,
    breadcrumb = "Item Locked",
    message = "You're trying to access an item that is currently being used by:",
    whenLocked = <LockErrorPage breadcrumb={breadcrumb} message={message} />,
}) {
    const [isLock, setIsLock] = useState(false)
    const [lockedBy, setLockedBy] = useState("")
    const lockUser = useUser(lockedBy)
    useEffect(() => {
        async function handleLock() {
            await deleteLock(id).then(async () => {
                const check = await createLock(id)
                if (check.canLock === "false") {
                    setIsLock(true)
                    setLockedBy(check.lockedBy)
                }
            })
        }

        handleLock()
    }, [id])

    useEffect(() => {
        const intervalId = setInterval(() => {
            stillEditing(id)
        }, 15000)

        return () => {
            clearInterval(intervalId)
            EditEnding.raise()

            delay(60).then(() => deleteLock(id))
        }
    }, [id])

    if (isLock) {
        return (
            <Box flex={1}>
                <Bound lockedBy={lockUser.user}>{whenLocked}</Bound>
            </Box>
        )
    }

    return <>{children}</>
}
