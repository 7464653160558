import { hasDemand } from "lib/authorization/has-demand"
import { registerConditionalPageNavigation } from "lib/routes/register-conditional-page-navigation"
import DemandGuard from "minimals-template/components/guards/DemandGuard"
import { appRoute } from "routes/app/home.runtime"
import { AuthoringAdmin } from "./authoring-admin"
import { TechnicalUpdateBulletin } from "routes/technical-updates/routes/technical-update-bulletin"
import { CreateTechnicalUpdate } from "routes/technical-updates/routes/create-technical-update"
import { EditTechnicalUpdate } from "routes/technical-updates/routes/edit-technical-update"

appRoute.register(
    "authoring-admin/",
    <DemandGuard demands={["$authoringAdmin-bulletinEditor", "$authoringAdmin-exportSchedule"]}>
        <AuthoringAdmin />
    </DemandGuard>
)

appRoute.register(
    "authoring-admin/:type/:id",
    <DemandGuard demands="$authoringAdmin-bulletinEditor">
        <TechnicalUpdateBulletin section="authoring-admin" parentCrumbTitle="Authoring Admin" />
    </DemandGuard>
)

appRoute.register(
    "authoring-admin/create-update/:type",
    <DemandGuard demands="$authoringAdmin-bulletinEditor">
        <CreateTechnicalUpdate section="authoring-admin" />
    </DemandGuard>
)
appRoute.register(
    "authoring-admin/edit-update/:type/:id",
    <DemandGuard demands="$authoringAdmin-bulletinEditor">
        <EditTechnicalUpdate section="authoring-admin" />
    </DemandGuard>
)

function isAuthorised() {
    if (hasDemand("$authoringAdmin-bulletinEditor") || hasDemand("$authoringAdmin-exportSchedule")) {
        return true
    }
    return false
}

registerConditionalPageNavigation(() => isAuthorised(), "/app/authoring-admin", "Authoring Admin", "mdi:shield-edit", {
    group: "More",
    priority: 260,
    subPaths: true,
})
