import useSettings from "minimals-template/components/@hooks/useSettings"
import { useParams } from "react-router-dom"
import { useMobile } from "lib/@components/mobile"
import Page from "minimals-template/components/Page"
import { Container } from "@mui/material"
import "routes/schedule/components/selected"
import { ScheduleTreeBar, ScheduleTreeBarLeft, ScheduleTreeBarRight, TreeToolbar } from "slot-definitions"
import { ScheduleDetails } from "routes/schedule/scheduleDetails"
import { useScheduleSettings } from "routes/schedule/lib/useScheduleSettings"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Bound } from "lib/@components/binding/Bound"
import { createPortalPair } from "lib/portals"
import { ListItemBox } from "lib/@components/ListItemBox"
import useIsXsScreen from "minimals-template/components/@hooks/useIsXsScreen"

const [TreePortal, TreePortalUser] = createPortalPair()
TreeToolbar.plug(<TreePortal />)

export default function ScheduleDisplay() {
    const { id } = useParams()

    const { themeStretch } = useSettings()
    const { target, isPDF } = useBoundContext()
    const isMobile = useMobile()
    const [{ onePage }] = useScheduleSettings()
    const isXsScreen = useIsXsScreen()
    return (
        <Bound hideDifference={true}>
            <TreePortalUser>
                <ListItemBox sx={{ flexWrap: "nowrap" }}>
                    <ScheduleTreeBar.Slot showLabel={true} id={id} />
                    <ScheduleTreeBarLeft.Slot priority={1} showLabel={true} id={id} />
                    <ScheduleTreeBarRight.Slot priority={1000000} showLabel={true} id={id} />
                </ListItemBox>
            </TreePortalUser>
            <Page title={target.title} sx={{ mx: isXsScreen ? 1.75 : undefined }}>
                <Container
                    maxWidth={themeStretch ? false : !onePage ? "xl" : "lg"}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        padding: "0 !important",
                        overflow: "hidden",
                        maxHeight: isPDF ? undefined : isMobile ? `calc(100vh - 64px)` : `calc(100vh - 92px)`,
                    }}
                >
                    <ScheduleDetails />
                </Container>
            </Page>
        </Bound>
    )
}
