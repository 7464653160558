import { Bound } from "lib/@components/binding/Bound"
import { Box, Card, CardContent, List, Typography } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import { SummaryDataChanged } from "event-definitions"
import { cloneDeep } from "@apollo/client/utilities"
import { extendSchedule } from "library/extend-schedule"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { UOM } from "routes/regime/assets/uom"
import { checkPermission } from "library/authorization"
import { ReadonlyUOM } from "./readonly-uom"
import { PagedRepeat } from "lib/@components/paged-repeat"
import { AssociatedAsset } from "./associated-asset"

export function Inventory() {
    const { schedule, payload, inDialog, storePayload } = useBoundContext()
    const extendedSchedule = cloneDeep(schedule)
    extendSchedule(extendedSchedule, payload, true)
    const unitsOfMeasurement = Object.keys(
        extendedSchedule.tasks.filter((t) => !t.isHeader).groupBy("unitOfMeasure")
    ).filter((c) => c !== "undefined")
    if (payload.units?.Nr && !unitsOfMeasurement.includes("Nr")) {
        unitsOfMeasurement.push("Nr")
    }

    const refresh = SummaryDataChanged.useRefresh()
    const hasAdvancedTailoring = checkPermission("advancedTailoring")
    if (!payload) return null
    payload.units ??= {}
    const cardContent = (
        <CardContent sx={{ p: inDialog ? 0 : undefined }} data-cy="asset-inventory">
            {(hasAdvancedTailoring || inDialog) && (
                <Typography variant="body2" sx={{ color: "text.secondary", mb: 3 }}>
                    Specify the inventory based on the units of measurement for this schedule.
                </Typography>
            )}

            {!payload.assets?.length &&
                unitsOfMeasurement.map((uom) =>
                    hasAdvancedTailoring || inDialog ? <UOM uom={uom} key={uom} /> : <ReadonlyUOM uom={uom} key={uom} />
                )}
            {!!payload.assets?.length && (
                <Bound readOnly>
                    <Typography sx={{ mt: 1 }} variant="subtitle1" component="div" gutterBottom>
                        System Assets
                    </Typography>
                    <List>
                        <PagedRepeat pageSize={5} item={<AssociatedAsset />} list={(payload.assets ?? []).map("id")} />
                    </List>
                </Bound>
            )}
        </CardContent>
    )

    return (
        <Bound target={payload.units} onChange={storePayload} refresh={refresh}>
            <Box my={2}>
                {!!inDialog && cardContent}
                {!inDialog && (
                    <Card>
                        <ListItemBox sx={{ bgcolor: "action.selected", pl: 2, pt: 1, pb: 1 }}>
                            <Box>
                                <Typography variant="subtitle2">Inventory</Typography>
                            </Box>
                        </ListItemBox>
                        {cardContent}
                    </Card>
                )}
            </Box>
        </Bound>
    )
}
