import { registerConformityStep } from "routes/conformity/lib/register-conformity-step"
import { Lazy } from "lib/make-lazy"

registerConformityStep({
    title: "Your Details",
    icon: "mdi:account-plus",
    content: (
        <Lazy importer={() => import("routes/conformity/plugins/your-details/your-details")} extract="YourDetails" />
    ),
    priority: 10,
})
