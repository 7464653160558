import { gql } from "@apollo/client"
import { selectItems } from "routes/schedule/lib/useSelection"
import { convertFolderName } from "routes/schedule/folder-manager/folder-management"
import { AddToGroup } from "event-definitions"
import { getTreeIdFromItem } from "lib/get-tree-id-from-item"
import { mutate } from "lib/graphql/mutate"

export async function addSchedulesToFolder(parentItem, toAdd) {
    console.log("Add Schedules >>>", parentItem, getTreeIdFromItem(parentItem), convertFolderName(parentItem?.id))
    await mutate(
        gql`
            mutation addSchedules($tree: String!, $group: String!, $ids: [String]!) {
                addReferenceToGroup(tree: $tree, group: $group, ids: $ids, type: "schedule")
            }
        `,
        {
            tree: getTreeIdFromItem(parentItem),
            group: convertFolderName(parentItem.id),
            ids: toAdd.map("id"),
        }
    )
    await AddToGroup.callAsync({ toAdd, toGroup: parentItem })
    selectItems(() => Object.fromEntries(toAdd.map((r) => [r._id, r.id])))
}
