import { cloneDeep } from "@apollo/client/utilities"
import { Alert, Box, Button, IconButton, ListItemButton } from "@mui/material"
import { RefreshMainTree, RefreshNavBar, SearchFor, SidebarTreeRoots, TreeGoto } from "event-definitions"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { ListItemBox } from "lib/@components/ListItemBox"
import { getReference, setReference, useReference } from "lib/@hooks/use-reference"
import { hasDemand } from "lib/authorization/has-demand"
import { getApolloClient } from "lib/graphql/client"
import noop from "lib/noop"
import { prevent } from "lib/prevent"
import { navigate } from "lib/routes/navigate"
import { registerConditionalPageNavigation } from "lib/routes/register-conditional-page-navigation"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { SCHEDULE } from "library/constants"
import { splitId } from "library/database/split-id"
import { TreeRoot } from "library/tree"
import Iconify from "minimals-template/components/Iconify"
import { HelpTag } from "routes/help/helpTag"
import { GET_SCHEDULES } from "routes/schedule/components/get-schedules"
import { isActiveWhen } from "routes/schedule/components/is-active-when"
import { FolderItemPart } from "routes/schedule/tree/schedules/folder-item-part"
import { ScheduleItem } from "routes/schedule/tree/schedules/scheduleItem"
import { ScheduleSelectorBeforeList } from "slot-definitions"
import { refreshTree } from "ioc"
import { notInSelector } from "routes/facilities/lib/when-parent-item"
import { busyWhile } from "lib/@components/busy/busy-while"
import { HELPTAG_SEARCH_RESULTS } from "routes/help/help-tag-constants"

registerConditionalPageNavigation(
    () => hasDemand("user") && hasDemand("!sharing") && hasDemand("schedules"),
    "/app/schedules?id=search",
    "Search Results",
    "dashicons:search",
    {
        group: "Schedules",
        isVisible: () => getReference().searchResults?.query,
        isActive: isActiveWhen((c) => c.id === "search"),
        priority: 255,
    }
)

SearchFor.handleOnce(searchFor)

async function searchFor(query) {
    query = query.trim().toLowerCase()
    const { data, error } = await busyWhile(
        async () =>
            getApolloClient().query({
                query: GET_SCHEDULES,
                variables: {
                    type: "TitleAndContent",
                    query,
                },
            }),
        "Querying"
    )
    if (error) {
        errorSnackbar(error.message ?? error)
        return
    }
    const {
        scheduleSearch: { count, schedules },
    } = cloneDeep(data)
    schedules.forEach(
        (schedule) =>
            (schedule.allCodes = schedule.allCodes?.filter((c) => c.code.toLowerCase().startsWith(query) ?? []))
    )
    setReference((data) => {
        data.sortMode = "natural"
        data.searchResults = {
            time: Date.now(),
            query,
            count,
            schedules,
        }
    })
    refreshTree()
    RefreshNavBar.raise()
    navigate("/app/schedules?id=search")
}

export function Search({ last, sx, Component = ListItemButton }) {
    const { refresh, onClick = noop } = useBoundContext()
    const [{ searchResults }, save] = useReference({ searchResults: { query: "" } }, "search", "sortMode")
    RefreshMainTree.useEvent(refresh)
    TreeGoto.useEvent((id) => {
        if (id === "search") {
            initializeSortMode()
        }
    })
    return (
        !!searchResults.query && (
            <Component divider={!last} onClick={prevent(showSearch)}>
                <ListItemBox spacing={1}>
                    <FolderItemPart />
                    <FolderItemPart>
                        <Iconify sx={{ width: 32, height: 32, color: "primary.main", ...sx }} icon="dashicons:search" />
                    </FolderItemPart>
                    <Box flex={1}>Search for &quot;{searchResults.query}&quot;</Box>
                    <IconButton size="small" onClick={prevent(remove)}>
                        <Iconify icon="ic:round-clear" />
                    </IconButton>
                </ListItemBox>
            </Component>
        )
    )

    function remove() {
        save({ searchResults: { query: "" } })
        navigate("/app/schedules?id=schedules")
    }

    function initializeSortMode() {
        setTimeout(() => {
            save({ sortMode: "natural" })
        }, 50)
    }

    function showSearch() {
        onClick()
        initializeSortMode()
    }
}

const searchImpl = <Search />

ScheduleSelectorBeforeList("search").plug(<SearchInfo if={notInSelector} />)
ScheduleSelectorBeforeList("search").plug(<HelpTag tags={[HELPTAG_SEARCH_RESULTS]} />)

function SearchInfo() {
    const [{ searchResults }] = useReference({ searchResults: {} })
    if (!searchResults.query) return null
    return (
        <Box mb={2} px={3}>
            <Alert sx={{ "& .MuiAlert-message": { width: 1 } }} severity="info">
                <ListItemBox spacing={1} width={1}>
                    <Box>
                        Search for &quot;{searchResults.query}&quot; found {searchResults.count} schedules when it ran{" "}
                        {Date.create(searchResults.time).relative()}
                    </Box>
                    <Box flex={1} />
                    <Button variant="outlined" color="primary" size="small" onClick={runSearch}>
                        Refresh
                    </Button>
                </ListItemBox>
            </Alert>
        </Box>
    )

    function runSearch() {
        SearchFor.raise(searchResults.query)
    }
}

SidebarTreeRoots.handleOnce(async ({ add }) => {
    const search = {
        content: searchImpl,
        id: "search",
        label: "Search",
        [TreeRoot]: true,
        priority: 375,
        onExpand: noop,
        onCollapse: noop,
    }
    Object.defineProperty(search, "visible", {
        get() {
            const reference = getReference({ searchResults: {} })
            return !!reference.searchResults.query
        },
    })
    Object.defineProperty(search, "children", {
        get() {
            const {
                searchResults: { schedules = [] },
            } = getReference({ searchResults: { schedules: [] } })
            return schedules.map((item, order) => ({
                $: { ...item, type: SCHEDULE },
                $order: order,
                allCodes: item.allCodes,
                id: `search!${splitId(item._id).id}`,
                _id: item._id,
                hasChildren: false,
                type: SCHEDULE,
                isAlias: true,
                notLicensed: item.notLicensed,
                tags: item.coreTagDescriptions,
                label: item.title,
                code: item.code,
                content: (
                    <ScheduleItem
                        schedule={{
                            label: item.title,
                            notLicensed: item.notLicensed,
                            code: item.code,
                            tags: item.coreTagDescriptions,
                            allCodes: item.allCodes,
                            id: `search!${item.id}`,
                            _id: item._id,
                        }}
                    />
                ),
            }))
        },
    })
    add(search)
})
