import { api } from "lib/graphql/api"
import { mutate } from "lib/graphql/mutate"
import { gql } from "@apollo/client"

export const setFubspot = api((id, data) =>
    mutate(
        gql`
            mutation SetFubspot($id: String, $data: JSONObject!) {
                setFubspot(id: $id, data: $data)
            }
        `,
        { id, data },
        { returns: "setFubspot" }
    )
)
