import { TaskListFilter } from "event-definitions"
import { lazy } from "react"
import { COLOR_FROM_CLASSIFICATION, ICON_FROM_CLASSIFICATION } from "library/SFG20/constants"
import { tasksWithTimings } from "routes/schedule/plugins/task-summary/annual-timing"
import { SummaryClassification } from "slot-definitions"
import { getSelectables } from "routes/schedule/components/selectable/get-selectables"
import { headerOr } from "routes/schedule/tasks/lib/header-or"
import { predicate } from "lib/@components/slot/predicate"

const PredicateTasks = lazy(() => import("./predicate-tasks"))
const TotalTasks = lazy(() => import("./total-tasks"))

TaskListFilter.handleOnce((info) => {
    const selected = getSelectables()
    if (!selected.classification?.length) return
    const all = selected.classification.map((filter) => info.tasks.filter(headerOr(filter)))
    info.tasks = all.flat(1).unique()
})

SummaryClassification.plug(
    predicate(({ context }) => {
        const allTasks = tasksWithTimings(context.target.tasks)
        const groups = allTasks.groupBy("classification")
        return Object.keys(groups).length > 1
    }, <TotalTasks priority={10} />)
)

SummaryClassification.plug(
    <PredicateTasks
        group="classification"
        icon={ICON_FROM_CLASSIFICATION.Red}
        color={COLOR_FROM_CLASSIFICATION.Red}
        predicate={(v) => v.classification === "Red"}
        title="Red"
        priority={20}
    />
)
SummaryClassification.plug(
    <PredicateTasks
        group="classification"
        icon={ICON_FROM_CLASSIFICATION.Pink}
        color={COLOR_FROM_CLASSIFICATION.Pink}
        predicate={(v) => v.classification === "Pink"}
        title="Pink"
        priority={30}
    />
)

SummaryClassification.plug(
    <PredicateTasks
        group="classification"
        predicate={(v) => v.classification === "Amber"}
        title="Amber"
        icon={ICON_FROM_CLASSIFICATION.Amber}
        color={COLOR_FROM_CLASSIFICATION.Amber}
        priority={50}
    />
)
SummaryClassification.plug(
    <PredicateTasks
        group="classification"
        icon={ICON_FROM_CLASSIFICATION.Green}
        color={COLOR_FROM_CLASSIFICATION.Green}
        predicate={(v) => v.classification === "Green"}
        title="Green"
        priority={60}
    />
)
