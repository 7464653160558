import { registerTab } from "lib/@components/tabs"
import { REGIME_ASSETS_TAB } from "routes/asset-register/plugins/regime-display/regime-display.runtime"
import { UpdatedSFG20 } from "routes/asset-register/plugins/schedules-out-of-date/updated-sfg20"
import { and } from "lib/logic"
import { isInEditableRegime } from "routes/facilities/lib/when-parent-item"
import { isEnabled } from "lib/@components/feature"
import { FEATURE_FLAG_REGIME_TASK_MANAGEMENT } from "routes/asset-register/plugins/regime-display/regime-task-management-feature"

registerTab({
    tab: REGIME_ASSETS_TAB,
    id: "updated-schedules",
    title: "Schedule Library Updates",
    predicate: and(isInEditableRegime, () => isEnabled(FEATURE_FLAG_REGIME_TASK_MANAGEMENT)),
    content: <UpdatedSFG20 $lazy priority={170} />,
})
