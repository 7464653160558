/**
 * Merges a target and multiple sources
 * @param {object} target - destination object
 * @param {...object} sources - sources to merge
 * @returns {object} the target object after merging
 */
export function merge(target, ...sources) {
    for (const source of sources) {
        mergeValue(target, source)
    }

    return target

    function innerMerge(target, source) {
        for (const [key, value] of Object.entries(source)) {
            if (key === "__proto__" || key === "constructor") throw new Error("Invalid Merge")
            target[key] = mergeValue(target[key], value)
        }
    }

    function mergeValue(targetValue, value) {
        if (Array.isArray(value)) {
            if (!Array.isArray(targetValue)) {
                return [...value]
            }
            for (let i = 0, l = value.length; i < l; i++) {
                targetValue[i] = mergeValue(targetValue[i], value[i])
            }
            return targetValue
        }
        if (typeof value === "object") {
            if (targetValue && typeof targetValue === "object") {
                innerMerge(targetValue, value)
                return targetValue
            }
            return value ? { ...value } : value
        }
        return value ?? targetValue ?? undefined
    }
}
