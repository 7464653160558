import { useMemo } from "react"
import { PERIOD_MULTIPLIER } from "library/SFG20/constants"

/**
 * Create a label for a frequency entry
 * @param {object} frequency - the object containing the period and interval to calculate
 * @returns {string} The label for the frequency
 */
export function periodLabel(frequency) {
    if (!frequency) return ""
    return `${frequency.period}${frequency.interval}`
}

export function periodDurationPerAnnum(task) {
    return (PERIOD_MULTIPLIER[task.frequency?.period] * task.minutes) / task.frequency.interval
}

export function periodDuration(task) {
    return PERIOD_MULTIPLIER[task.frequency?.period] / task.frequency?.interval
}

export function getPeriodTimingsPerTask(schedule) {
    return schedule.serviceTimings.reduce(
        (a, c) => ({
            ...a,
            [periodLabel(c.frequency)]:
                periodDurationPerAnnum(c) /
                schedule.tasks.reduce((a, t) => {
                    if (
                        c.frequency?.period === t.frequency?.period &&
                        c.frequency?.interval === t.frequency?.interval
                    ) {
                        return a + 1
                    }
                    return a
                }, 0),
        }),
        {}
    )
}

/**
 * Creates a lookup that indicates how long a single task
 * takes based on its period and interval
 * @param {object} schedule - the schedule whose period timings should be calculated
 * @returns {Record<string, number>} a lookup of the task label e.g. M3, to the number of minutes for an individual task in that period. You can use periodLabel to create a label for a frequency
 */
export function usePeriodTimingsPerTask(schedule) {
    return useMemo(() => getPeriodTimingsPerTask(schedule), [schedule])
}
