import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"

export const getItemBaskets = api((referenceId, type) => {
    if (!referenceId) {
        return null
    }
    return query(
        gql`
            query Tags($referenceId: String!, $type: String!) {
                itemBaskets(referenceId: $referenceId, type: [$type]) {
                    name
                    id
                    item(id: $referenceId) {
                        added
                    }
                }
            }
        `,
        { referenceId, type },
        { returns: "itemBaskets" }
    )
})

export function useTags(referenceId, type) {
    return getItemBaskets.useResults(referenceId, type)
}
