// form
// @mui
import { FormHelperText } from "@mui/material"
//
import { BoundTextEditor } from "minimals-template/components/editor"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export default function RHFEditor({ name, ...other }) {
    const { error } = useBoundContext()
    return (
        <BoundTextEditor
            field={name}
            error={!!error}
            helperText={
                <FormHelperText error sx={{ px: 2, textTransform: "capitalize" }}>
                    {error?.message}
                </FormHelperText>
            }
            {...other}
        />
    )
}
