import { Box, Button, Card, CardContent, Stack, Tooltip, Typography } from "@mui/material"
import { openDialog } from "lib/@hooks/useDialog"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { successSnackbar } from "lib/snackbar/success-snackbar"
import Iconify from "minimals-template/components/Iconify"
import { createCustomerSupportEmail } from "routes/contact-us/controller/create-support-email"
import { ContactUsDialog } from "../contact-us/contact-us-dialog"
import { HelpSearchBar } from "./helpSearchBar"

export function HelpBanner({ image, title, isHome }) {
    return (
        <Card
            id="helpCentreBanner"
            sx={{
                ...(image && {
                    backgroundImage: `linear-gradient(rgba(22, 28, 36, 0.8), rgba(22, 28, 36, 0.8)), url(${image})`,
                }),
                ...(!image && { backgroundColor: "grey.500" }),
                backgroundSize: "cover",
                height: 220,
            }}
        >
            <CardContent sx={{ pt: 1.75, "&:last-child": { pb: 1.75 } }}>
                <Stack spacing={"15px"} height="190px" justifyContent={"space-between"}>
                    <Box
                        id="helpBannerIcon"
                        sx={{
                            width: 38,
                            height: 38,
                            borderRadius: "50%",
                            bgcolor: "primary.main",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Iconify icon="ic:round-help" sx={{ color: "common.white" }} width={30} height={30} />
                    </Box>

                    <Stack spacing={"15px"}>
                        <Tooltip title={title}>
                            <Typography
                                id="helpBannerTitle"
                                sx={{
                                    color: "common.white",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                }}
                                variant="h5"
                            >
                                {title}
                            </Typography>
                        </Tooltip>
                        {!!isHome && <HelpSearchBar />}
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                bottom: 0,
                            }}
                        >
                            <Typography variant="subtitle1" sx={{ color: "common.white" }}>
                                Need additional help?
                            </Typography>
                            <Button
                                id="helpBannerContactUsBtn"
                                variant={"contained"}
                                startIcon={<Iconify icon="ic:round-support-agent" />}
                                onClick={openContactUs}
                            >
                                Contact Us
                            </Button>
                        </Box>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}
export async function openContactUs() {
    const email = await openDialog(<ContactUsDialog />)
    if (email) {
        const retVal = await createCustomerSupportEmail(email)
        if (retVal) {
            successSnackbar("Support request sent")
        } else {
            errorSnackbar("Support request failed")
        }
    }
}
