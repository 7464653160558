import { registerTab } from "lib/@components/tabs"
import rics from "./rics.png"
import uniclass from "./uniclass.png"
import { Codes } from "routes/schedule/plugins/codes-tab/codes"

export const imageForClass = {
    rics,
    uniclass,
}

registerTab({
    tab: "ScheduleRightBar",
    id: "Codes",
    predicate(context) {
        return context.target.nrm?.length || context.target.uniclass?.length
    },
    content: <Codes $lazy priority={60} />,
})
