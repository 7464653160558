import { useRef, useState } from "react"
import { raise } from "library/local-events"
import { DocumentEditor } from "lib/@components/document-editor"
import { Box } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import { Comparison } from "routes/schedule/compare/lib/diff3"
import { register } from "lib/routes/register"

global.raise = raise

const o = "<p>Some text that will later change and I will insert stuff\nAgain again</p>"
const a = "<p>Some text that will maybe later change and then I will insert stuff\nAgain more</p>"
const b = "<p>Some text that will not now change and I will stop\nAgain instead</p>"

register("/test", <Test />)

function Test() {
    return <Comp />
}

function Comp() {
    const [text, setText] = useState(a)
    const preview = useRef()
    return (
        <ListItemBox
            sx={{
                "& .ck.ck-editor__main": {
                    maxHeight: 400,
                    overflowY: "auto",
                },
            }}
        >
            <Box width={1 / 2}>
                <DocumentEditor onScroll={scrollIt} value={text} onChange={setText} />
            </Box>
            <Box p={1} ref={preview} width={1 / 2} mt={5} sx={{ maxHeight: 400, overflow: "hidden" }}>
                <Comparison text={text} original={o} other={b} />
            </Box>
        </ListItemBox>
    )

    function scrollIt(event, editor) {
        if (preview.current) {
            preview.current.scrollTop = editor.scrollTop
        }
    }
}
