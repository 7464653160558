import { ClientUserProfilePanels, ClientUserProfileTabs } from "slot-definitions"

import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useRefresh } from "lib/@hooks/useRefresh"
import { Bound } from "lib/@components/binding/Bound"
import React, { useState } from "react"
import { Grid, List, Typography } from "@mui/material"
import { BoundCheckBox } from "lib/@components/standard-checkbox"

import { useSave } from "lib/@hooks/use-save"
import { getFubspotForUsers } from "routes/settings/plugins/fubspotForUsers/controller/get-fubspot-for-users"
import { setFubspotForUsers } from "routes/settings/plugins/fubspotForUsers/controller/set-fubspot-for-users"
import { fubspotEnabled } from "routes/settings/plugins/fubspot/controller/fubspot-enabled"
import { SecureTab, SecureTabPanel } from "minimals-template/components/secure-tabs"
import { Frag } from "lib/@components/slot/frag"

ClientUserProfileTabs.plug(
    <SecureTab demand={"admin-fubspot"} hide={true} fallback={<Frag />} label="FubSpot User" value="FubSpot User" />
)

ClientUserProfilePanels.plug(
    <SecureTabPanel demand={"admin-fubspot"} hide={true} fallback={<Frag />} value="FubSpot User">
        <FubspotForUserPanel priority={150} />
    </SecureTabPanel>
)

export function FubspotForUserPanel() {
    const isFubspotEnabled = fubspotEnabled.useResults() ?? false
    console.log({ isFubspotEnabled })
    if (!isFubspotEnabled) {
        return <>FubSpot not enabled on this environment</>
    }
    return <FubSpotForUsers />
}

export function FubSpotForUsers() {
    const { userId: user } = useBoundContext()
    const [newState] = useState({})
    const userFubspotData = getFubspotForUsers.useResults(user) ?? newState
    // get fubspot data for user

    const onChange = useSave({ save, target: userFubspotData, id: user })
    const refresh = useRefresh()

    return (
        !!userFubspotData && (
            <Bound onChange={onChange} refresh={refresh} target={userFubspotData}>
                <List className="fubspot-products">
                    <Grid container spacing={1} mt={2}>
                        <Grid container spacing={2} mt={2}>
                            <Grid item xs={8}>
                                <Typography>
                                    NOTE: For Personal Licence Fubspot to work, you must also assign a collaborate
                                    licence to the user
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={2}>
                            <Grid item xs={8}>
                                <BoundCheckBox label="Has Subscription" field="hasSubscription" />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={2}>
                            <Grid item xs={8}>
                                <BoundCheckBox label="Subscription Expired" field="isExpired" />
                            </Grid>
                        </Grid>
                    </Grid>
                </List>
            </Bound>
        )
    )

    async function save(data) {
        await setFubspotForUsers(user, data)
    }
}
