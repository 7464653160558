import { Box, Typography } from "@mui/material"

export function Watermark({ text, color = "text.primary", sx }) {
    return (
        <Box
            ref={fadeIn}
            sx={{
                color,
                position: "absolute",
                bottom: "50%",
                right: "50%",
                transition: "opacity 0.1s linear",
                transitionDelay: "0.1s",
                transform: "translate(50%, 50%) rotate(315deg)",
                zIndex: 1199,
                borderColor: color,
                borderWidth: 4,
                borderStyle: "solid",
                ...sx,
                opacity: 0,
            }}
            className="watermark"
        >
            <Typography component="div" variant="h1" sx={{ px: 3 }}>
                {text}
            </Typography>
        </Box>
    )

    function fadeIn(element) {
        if (!element) return
        setTimeout(() => {
            element.style.opacity = sx?.opacity ?? 0.1
        })
    }
}
