import { useDialog } from "lib/@hooks/useDialog"
import { useSelectedItemIds } from "routes/schedule/lib/useSelectedItems"
import { createBasket } from "routes/schedule/plugins/baskets/controller/createBasket"
import { mapIdsToBasketSchedules } from "routes/schedule/plugins/baskets/controller/mapIdsToBasketSchedules"
import { Button } from "@mui/material"
import { CreateBasketDialog } from "routes/schedule/plugins/baskets/create-basket/create-basket-dialog"
import Iconify from "minimals-template/components/Iconify"

export function CreateBasketWithItems() {
    const items = useSelectedItemIds()
    const openDialog = useDialog(<CreateBasketDialog />)

    return (
        !!items.length && (
            <Button
                variant="contained"
                color="primary"
                onClick={nameAndAdd}
                startIcon={<Iconify icon={"ic:round-add"} />}
            >
                Create A Favourites List Containing The {items.length} Selected {"schedule".pluralize(items.length)}
            </Button>
        )
    )

    async function nameAndAdd() {
        const name = await openDialog()
        if (!name) return
        await createBasket(name, mapIdsToBasketSchedules(items))
    }
}
