import { generate } from "library/guid"
import React from "react"

export function replaceAllWith(something, priority = 1000) {
    replacer.priority = priority
    replacer.$id = generate()
    return replacer

    function replacer({ items, context }) {
        if (something.props.predicate) {
            if (!something.props.predicate(context ?? {})) {
                return undefined
            }
        }
        if (something.props.if) {
            if (!something.props.if(context ?? {})) {
                return undefined
            }
        }
        const original = Array.isArray(items) ? [...(items ?? [])] : null
        if (items) {
            items.length = 0
        }
        return <something.type key={something.key} {...something.props} originalchildren={original} />
    }
}
