import { useRef } from "react"
import { Box } from "@mui/material"

function isScrollable(ele) {
    const hasScrollableContent = ele.scrollHeight > ele.clientHeight

    const overflowYStyle = window.getComputedStyle(ele).overflowY
    const isOverflowHidden = overflowYStyle.indexOf("hidden") !== -1

    return hasScrollableContent && !isOverflowHidden
}

function getScrollableParent(ele) {
    return !ele || ele === document.body ? document.body : isScrollable(ele) ? ele : getScrollableParent(ele.parentNode)
}

export function ScrollIntoView({ always, smooth, delay = 0 }) {
    const hasScrolled = useRef(false)
    return <Box ref={scroll} component="span" sx={{ width: 0, height: 0, flex: 0 }} />

    function scroll(container) {
        if (hasScrolled.current) return
        if (container) {
            hasScrolled.current = true
            setTimeout(() => {
                const scrollableParent = getScrollableParent(container)
                const boundingClientRect = container.getBoundingClientRect()
                if (always || boundingClientRect.bottom > scrollableParent.clientHeight) {
                    container.scrollIntoView({ behavior: smooth ? "smooth" : "auto", block: "center" })
                }
            }, delay)
        }
    }
}
