import { ScheduleItemMain } from "slot-definitions"
import { useTreeItem } from "routes/schedule/lib/useTreeItem"
import { Typography } from "@mui/material"
import { allParents } from "library/tree"

ScheduleItemMain.plug(<SearchResultPathInfo if={({ parentItem }) => parentItem.id === "search"} />)

function SearchResultPathInfo({ schedule }) {
    const item = useTreeItem(schedule?.id.split("!").at(-1), true)

    if (item) {
        const parents = allParents(item).reverse().slice(2, -1)
        return <Typography variant="caption">{parents.map("label").join(" / ")}</Typography>
    }
    return null
}
