import { gql } from "@apollo/client"
import {
    AdoptClient,
    AuthorizationUpdated,
    CurrentClientChanged,
    FlushGraphQLCache,
    InvalidLink,
    PermissionsUpdated,
    SwitchUserClient,
    UserAuthenticated,
    UserInitialized,
    UserLoggedOut,
    WindowReload,
} from "event-definitions"
import { Bound } from "lib/@components/binding/Bound"
import { alert } from "lib/@dialogs/alert"
import useAsync from "lib/@hooks/useAsync"
import { noChange, useRefresh } from "lib/@hooks/useRefresh"
import { notiftyAuthInitialized } from "lib/graphql/auth-completed"
import { query } from "lib/graphql/query"
import { navigate } from "lib/routes/navigate"
import { snackbar } from "lib/snackbar"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import LoadingScreen from "minimals-template/components/LoadingScreen"
import { createContext, useContext, useEffect, useState } from "react"
import { PATH_AUTH, PATH_DASHBOARD } from "routes/paths"
import { currentSession, currentUser, requestSignInLink, setCurrentSession, signOut, verifyUser } from "./passport"

PermissionsUpdated.handleOnce(() => WindowReload.raise())

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
    isLoggingIn: false,
}

const AuthContext = createContext({
    ...initialState,
    method: "cognito",
    login: () => Promise.resolve(),
    register: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    resendCode: () => Promise.resolve(),
    verifyCode: () => Promise.resolve(),
    adoptInvite: () => Promise.resolve(),
})

function createUserData({ user, token }) {
    if (!user) return null
    return {
        ...user,
        displayName: user.displayName || user?.attributes?.name || user?.attributes?.displayName,
        role: "User",
        token,
    }
}

UserInitialized.handleOnce(notiftyAuthInitialized)

let currentClient = ""
let currentClientInfo = {}
let currentDemands = []

export function getClient() {
    return currentClient
}

export function getClientInfo() {
    return currentClientInfo
}

export function useClientInfo() {
    CurrentClientChanged.useRefresh()
    return currentClientInfo
}

let currentClientId = ""

export function getCurrentClientId() {
    return currentClientId
}

export function getCurrentToken() {
    return currentSession()
}

let shown = false

const retryWithBackoff = async (fn, maxRetries = 8, initialDelay = 1000) => {
    let delay = initialDelay
    for (let i = 0; i < maxRetries; i++) {
        try {
            return await fn()
        } catch (error) {
            const isLastAttempt = i === maxRetries - 1
            if (isLastAttempt) throw error
            // eslint-disable-next-line no-loop-func
            await new Promise((resolve) => setTimeout(resolve, delay))
            delay *= 2
        }
    }
    return undefined
}

InvalidLink.handleOnce(async () => {
    if (shown) return
    shown = true
    FlushGraphQLCache.raise()
    AdoptClient.raise(undefined)
    navigate("/app/?share=clear")
    await alert("You are not authorised to use the link supplied", "Security Error", "error")
    AdoptClient.raise(undefined)
    navigate("/app/?share=clear")
    AdoptClient.raise(undefined)
    shown = false
    WindowReload.raise()
})

export function AuthorizationProvider({ children }) {
    const current = useContext(AuthContext)
    const refresh = PermissionsUpdated.useRefresh(noChange)
    const [clientId, setClientId] = useState()

    AdoptClient.useEvent((...params) => {
        const [localClient] = params
        if (!localClient) return
        if (currentClient === localClient) return
        FlushGraphQLCache.raise()
        setClientId(localClient)
    })

    SwitchUserClient.useEvent(() => {
        WindowReload.raise("/app")
    })

    const [demands, getCurrentClient] = useAsync(
        async () => {
            const getDemands = async () => {
                const { demands, getCurrentClient } = await query(
                    gql`
                        query getDemands {
                            demands
                            getCurrentClient {
                                code
                                name
                                id
                            }
                        }
                    `,
                    {},

                    { fetchPolicy: "network-only" }
                )
                return [demands, getCurrentClient]
            }

            try {
                return await retryWithBackoff(getDemands)
            } catch (e) {
                sessionStorage.removeItem("auth-email")
                localStorage.removeItem("sfg20AccessToken")
                return [null, null]
            }
        },
        [null, null],
        [current?.user, refresh.id, clientId]
    )

    currentDemands = demands ?? currentDemands ?? []
    currentClientInfo = getCurrentClient
    currentClient = getCurrentClient?.id
    currentClientId = getCurrentClient?.id

    window._authorization = {
        demands,
        currentClientInfo,
        user: current?.user,
    }

    useEffect(() => {
        AuthorizationUpdated.raise(window._authorization)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(window._authorization)])

    useEffect(() => {
        if (currentClient) CurrentClientChanged.raiseLater(currentClient)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentClient, JSON.stringify(currentClientInfo), demands])

    return (
        <Bound authorization={demands} currentClient={currentClient}>
            {children}
        </Bound>
    )
}

function AuthProvider({ children }) {
    const [authContext] = useState({})
    const refresh = useRefresh()

    const current = useContext(AuthContext)
    useAsync(async () => {
        try {
            const user = createUserData(await getAuthenticatedUser())
            if (user) {
                authContext.user = user
                authContext.isAuthenticated = true
                UserAuthenticated.raiseLater()
            }
            authContext.isInitialized = true
            UserInitialized.raiseLater(false)
        } catch (e) {
            if (authContext.user) {
                UserLoggedOut.raise()
            }
            console.log(e?.message)
            authContext.user = null
            authContext.isAuthenticated = false
            authContext.isInitialized = true
            UserInitialized.raiseLater()
        }
    })

    const securityContext = {
        ...current,
        ...authContext,
        login,
        register,
        logout,
        verifyCode,
        resendCode,
        refresh,
        getAuthenticatedUser,
        getAuthenticatedSession,
    }

    return (
        <AuthContext.Provider value={securityContext}>
            {authContext.isInitialized ? children : <LoadingScreen description="Has Auth" />}
        </AuthContext.Provider>
    )

    async function login(email, requiredClient, url) {
        try {
            await requestSignInLink(email)
            sessionStorage.setItem("auth-email", email)
            snackbar("We've sent a confirmation code to your email address, please enter it.")

            const newUrl = `${PATH_AUTH.verify}?email=${encodeURIComponent(email)}&requiredClient=${encodeURIComponent(
                requiredClient
            )}&redirect=${encodeURIComponent(url)}`

            navigate(newUrl, {
                state: { requiredClient, email },
            })
        } catch (err) {
            console.error(err)
            throw err
        }
    }

    async function verifyCode(code, requiredClient, redirectUrl, email) {
        try {
            const username = decodeURIComponent(email) || sessionStorage.getItem("auth-email")

            const user = await verifyUser(username, code)
            authContext.isLoggingIn = true
            authContext.user = createUserData(user)
            setCurrentSession(user?.token)
            sessionStorage.setItem("auth-email", username)
            authContext.isAuthenticated = true

            const url = new URL(redirectUrl || PATH_DASHBOARD.root, window.location.href)
            console.log("Redirecting to", url.toString())
            url.searchParams.delete("requiredClient")
            if (requiredClient) {
                url.searchParams.set("requiredClient", requiredClient)
            }
            window.location.href = url.toString()
        } catch (e) {
            if (e.status === 401) errorSnackbar("Unauthorized: Incorrect code")
            else errorSnackbar("An error occurred. Please try again")
        }
        return null
    }

    async function getAuthenticatedUser() {
        return currentUser()
    }

    async function getAuthenticatedSession() {
        return currentSession()
    }

    async function resendCode(email) {
        email = email || sessionStorage.getItem("auth-email")
        try {
            await requestSignInLink(decodeURIComponent(email))
            snackbar("We've sent a confirmation code to your email address, please enter it.")
        } catch (e) {
            errorSnackbar(e.message)
        }
    }

    async function register(email, firstName, lastName, requiredClient, queryParams) {
        try {
            await requestSignInLink(email, firstName, lastName)
            sessionStorage.setItem("auth-email", email)
            snackbar("We've sent a confirmation code to your email address, please enter it.")

            const newUrl = encodeURI(
                `${PATH_AUTH.verify}?email=${encodeURIComponent(email)}&requiredClient=${encodeURIComponent(
                    requiredClient
                )}&queryParams=${queryParams}`
            )

            navigate(newUrl, {
                state: { requiredClient, email },
            })
        } catch (err) {
            console.error(err)
            throw err
        }
    }

    async function logout() {
        await signOut()
        authContext.isAuthenticated = false
        authContext.user = null
        UserLoggedOut.raise()
        refresh()
    }
}

export { AuthContext, AuthProvider }
