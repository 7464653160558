import { ActionItem } from "slot-definitions"
import { Avatar, Button, ListItemAvatar, Stack } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { uniqueId } from "library/database/split-id"
import { createDraft } from "controller/create-draft-schedule"
import { navigate } from "lib/routes/navigate"
import { useBoundValue } from "lib/@components/binding/use-bound-value"
import { TruncatedListItemText } from "lib/@components/truncatedListItemText"

ActionItem("schedule-out-of-date").plug(<ScheduleOutOfDate />)

function ScheduleOutOfDate({ action }) {
    const [originalId] = useBoundValue("payload.originalId")
    const [originalVersion] = useBoundValue("payload.originalVersion")
    const [hasCompared] = useBoundValue("payload.hasCompared")
    const isSaved = +hasCompared === +originalVersion
    const color = isSaved ? "success" : "warning"
    return (
        <>
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: `${color}.main`, color: `${color}.contrastText` }}>
                    <Iconify icon="fluent-mdl2:upgrade-analysis" />
                </Avatar>
            </ListItemAvatar>
            <TruncatedListItemText
                primary={action.title}
                secondary={`There has been an update to the schedule from which this one is derived.${
                    isSaved ? " The updates have been compared and a draft schedule saved." : ""
                }`}
            />
            <Stack
                alignItems="stretch"
                spacing={1}
                sx={{
                    "& .MuiButton-root": {
                        flex: 1,
                        display: "block",
                    },
                }}
            >
                {isSaved && (
                    <Button onClick={publishGo} color="primary" variant="outlined" size="small">
                        Publish Changes
                    </Button>
                )}
                <Button onClick={go} color="primary" variant={isSaved ? undefined : "outlined"} size="small">
                    Differences
                </Button>
            </Stack>
        </>
    )

    async function publishGo() {
        navigate(`/app/schedules/allDrafts!${uniqueId(action.referenceId)}/edit`)
    }

    async function go() {
        await createDraft(action.referenceId)
        navigate(
            `/app/schedules/compare/draft!${uniqueId(action.referenceId)}/scheduleHistory!${uniqueId(
                originalId
            )}-${originalVersion}/schedule!${uniqueId(originalId)}`
        )
    }
}
