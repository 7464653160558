import { useState } from "react"
import { useDialog } from "lib/@hooks/useDialog"
import { FacilityConfiguration } from "routes/schedule/my-schedules/my-group-types/facility/facility-configuration"
import { ContextMenuItem } from "lib/@components/context-menu-bar/context-menu-item"
import { storeGroupData } from "routes/schedule/controller/store-group-data"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function CreateFacility() {
    const { parentItem } = useBoundContext()
    const [existingFacility] = useState({ image: parentItem.data?.image })

    const facility = useDialog(<FacilityConfiguration existingFacility={existingFacility} parentItem={parentItem} />, {
        maxWidth: "lg",
        fullScreen: true,
        className: "facility-dialog",
    })
    return <ContextMenuItem divider={true} icon="bxs:building" label="Convert to facility" onClick={create} />

    async function create() {
        const result = await facility()

        if (result) {
            await storeGroupData(parentItem.id, result, result.name)
        }
    }
}
