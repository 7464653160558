import noop from "lib/noop"
import { Box, Divider, MenuItem } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { ListItemBox } from "lib/@components/ListItemBox"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function ContextMenuItem({ icon, divider, label, children, noTitleize = false, onClick = noop, ...props }) {
    const { close, contextMenuItems } = useBoundContext()
    return (
        typeof close === "function" && (
            <>
                {divider && contextMenuItems > 1 && <Divider />}
                <MenuItem onClick={close(onClick)} {...props}>
                    <ListItemBox>
                        <Box sx={{ lineHeight: 0 }} mr={1}>
                            {typeof icon === "string" ? <Iconify icon={icon} /> : icon}
                        </Box>
                        <Box>
                            {noTitleize ? label : label.titleize()}
                            {children}
                        </Box>
                    </ListItemBox>
                </MenuItem>
            </>
        )
    )
}
