import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"

export const createTree = api(function createTree(name) {
    return mutate(
        gql`
            mutation CreateTree($name: String!) {
                createTree(name: $name)
            }
        `,
        { name },
        { returns: "createTree" }
    )
})
