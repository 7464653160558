import PropTypes from "prop-types"
// @mui
import { styled } from "@mui/material/styles"
import { Typography } from "@mui/material"
//
import Image from "minimals-template/components/Image"

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
    height: "100%",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(8, 2),
}))

// ----------------------------------------------------------------------

EmptyContent.propTypes = {
    title: PropTypes.string.isRequired,
    img: PropTypes.string,
    description: PropTypes.string,
}

export default function EmptyContent({ title, description, img, ...other }) {
    return (
        <RootStyle {...other}>
            <Image
                disabledEffect
                visibleByDefault
                alt="empty content"
                src={img || "/assets/illustrations/illustration_empty_content.svg"}
                sx={{ height: 240, mb: 3 }}
            />

            <Typography variant="h5" gutterBottom>
                {title}
            </Typography>

            {description && (
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    {description}
                </Typography>
            )}
        </RootStyle>
    )
}
