import { HasInvalidated, SidebarTreeRoots } from "event-definitions"
import { cacheTreeRoot, removeCache } from "lib/cached"
import { gql } from "@apollo/client"
import { uniqueId } from "library/database/split-id"
import { parentTreeItem } from "routes/schedule/tree/schedules/makeTreeItemsFromGroup"
import { ScheduleItem } from "routes/schedule/tree/schedules/scheduleItem"
import { TreeRoot } from "library/tree"
import { Bound } from "lib/@components/binding/Bound"
import { DraftItem } from "routes/schedule/my-schedules/my-custom-schedules/schedule-item-types/draft-schedule-item/draftItem"
import { MyPublished } from "routes/schedule/my-schedules/my-custom-schedules/my-published"
import { getTreeItemRetriever } from "routes/schedule/lib/getTreeItemRetriever"
import { SCHEDULE } from "library/constants"
import { MY_DRAFTS, MY_PUBLISHED, MY_RETIRED } from "routes/schedule/my-schedules/my-custom-schedules/lib/constants"
import { query } from "lib/graphql/query"
import { addDebugItem } from "debug-window"
import { Frag } from "lib/@components/slot/frag"

HasInvalidated("getMySchedules").before.handleOnce(() => {
    removeCache("drafts")
})

SidebarTreeRoots.handleOnce(
    cacheTreeRoot("drafts", async ({ add }) => {
        addDebugItem("Refresh drafts")
        const results = await query(gql`
            query GetDrafts {
                getMySchedules(drafts: true, published: true, retired: true, limit: 20000) {
                    scheduleItems {
                        _id
                        title
                        code
                        itemType
                        taskTypes {
                            Red
                            Amber
                            Green
                            Pink
                        }
                    }
                }
            }
        `)

        if (!results?.getMySchedules) return
        const {
            getMySchedules: { scheduleItems },
        } = results
        const allDrafts = {
            id: "allDrafts",
            label: "All drafts",
            content: <Frag />,
            visible: false,
            children: scheduleItems
                .filter((t) => t.itemType === "draft")
                .map((item) =>
                    Object.defineProperty(
                        {
                            id: `allDrafts!${uniqueId(item._id)}`,
                            _id: item._id,
                            label: item.title,
                            isDraft: true,
                            custom: true,
                            code: item.code,
                            content: (
                                <Bound target={item}>
                                    <DraftItem />
                                </Bound>
                            ),
                        },
                        parentTreeItem,
                        {
                            get() {
                                const retriever = getTreeItemRetriever()
                                return retriever(MY_DRAFTS)
                            },
                        }
                    )
                ),
        }

        const allPublished = {
            id: "allPublished",
            label: "All published",
            content: <MyPublished />,
            [TreeRoot]: true,
            hasChildren: true,
            visible: true,
            type: "rootGroup",
            children: scheduleItems
                .filter((t) => t.itemType === SCHEDULE)
                .map((item) => {
                    const treeItem = {
                        $: item,
                        id: `allPublished!${uniqueId(item._id)}`,
                        _id: item._id,
                        custom: true,
                        label: item.title,
                        code: item.code,
                        type: SCHEDULE,
                    }
                    treeItem.content = <ScheduleItem schedule={treeItem} />
                    return Object.defineProperty(treeItem, parentTreeItem, {
                        get() {
                            const retriever = getTreeItemRetriever()
                            return retriever(MY_PUBLISHED)
                        },
                    })
                }),
        }
        const allRetired = {
            id: "allRetired",
            label: "All Retired",
            content: <Frag />,
            [TreeRoot]: true,
            hasChildren: true,
            visible: true,
            type: "rootGroup",
            children: scheduleItems
                .filter((t) => t.itemType === "retired")
                .map((item) => {
                    const treeItem = {
                        $: item,
                        id: `allRetired!${uniqueId(item._id)}`,
                        _id: item._id,
                        label: item.title,
                        code: item.code,
                        custom: true,
                        isRetired: true,
                        type: SCHEDULE,
                    }
                    treeItem.content = <ScheduleItem schedule={treeItem} />
                    return Object.defineProperty(treeItem, parentTreeItem, {
                        get() {
                            const retriever = getTreeItemRetriever()
                            return retriever(MY_RETIRED)
                        },
                    })
                }),
        }
        add(allDrafts)
        add(allPublished)
        add(allRetired)
    })
)
