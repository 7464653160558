/* eslint-disable react/no-danger */
import { getCurrentNamespace } from "./controller/getCurrentNamespace"

import Iconify from "minimals-template/components/Iconify"

import { Typography } from "@mui/material"
import { HeaderBarRight } from "slot-definitions"
import { IconButtonAnimate } from "minimals-template/components/animate"

HeaderBarRight.plug(<NamespaceOverlay priority={-1000} />)

export function NamespaceOverlay() {
    const namespace = getCurrentNamespace.useResults()

    if (!namespace) return null
    return (
        <IconButtonAnimate size="medium" color={"secondary"}>
            <div>
                <Iconify icon="ic:outline-warning" />
            </div>

            <Typography component={"div"} sx={{ ml: 1, color: "red" }}>
                SERVER IS NAMESPACED: &quot;sfg20-{namespace}&quot;
            </Typography>
        </IconButtonAnimate>
    )
}
