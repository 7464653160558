import { appRoute } from "routes/app/home.runtime"
import { Lazy } from "lib/make-lazy"
import DemandGuard from "minimals-template/components/guards/DemandGuard"

appRoute.register(
    "/app/admin/users/:id",
    <DemandGuard demands={["clientAdmin", "admin-users", "admin-companies"]}>
        <Lazy importer={() => import("./components/edit/client-user-profile")} extract="ClientUserProfile" />
    </DemandGuard>
)
