import { registerConformityStep } from "routes/conformity/lib/register-conformity-step"
import { Stack, Typography } from "@mui/material"
import { PageBreadCrumbs } from "slot-definitions"
import { Lazy } from "lib/make-lazy"

registerConformityStep({
    title: "Site Details",
    icon: "mdi:hammer-screwdriver",
    content: <SiteDetails />,
    priority: 1,
})

function SiteDetails() {
    return (
        <Stack spacing={2}>
            <PageBreadCrumbs.Plug>
                <>Site Details</>
            </PageBreadCrumbs.Plug>
            <Typography variant="subtitle1">Site information</Typography>
            <Lazy importer={() => import("./site-details-card")} extract="SiteDetailsCard" />
            <Lazy importer={() => import("./folder-details-card")} extract="FolderDetailsCard" />

            <Typography variant="subtitle1">Enter job information</Typography>
            <Lazy importer={() => import("./job-card")} extract="JobCard" />

            <Typography variant="subtitle1">Enter site contact details for the job</Typography>
            <Lazy importer={() => import("./contact-card")} extract="ContactCard" />
        </Stack>
    )
}
