import { scheduleOptions } from "routes/asset-register/plugins/schedule-options/controller/schedule-options"
import { ListItemBox } from "lib/@components/ListItemBox"
import { Box } from "@mui/material"
import { ListOptions } from "routes/asset-register/plugins/schedule-options/list-options"

export function ScheduleOptions({ item: { chosen }, scheduleId, treeItem: { notLicensed } }) {
    const options = scheduleOptions.useResults(scheduleId) ?? []

    if (!chosen || notLicensed || !options.length) return null

    return (
        <ListItemBox>
            <Box width={50} />
            <ListOptions scheduleId={scheduleId} options={options} />
        </ListItemBox>
    )
}
