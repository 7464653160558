import { Button } from "@mui/material"
import noop from "lib/noop"

/**
 * A styled Mui button with theme warning colors, useful for in warning severity alerts.
 *
 * @param {string} text - The text printed inside the button.
 * @param {function} onClick - The function to run when the button is clicked.
 * @returns A pre styled Mui Button.
 */
export function WarningButton({ text, onClick = noop, sx, ...props }) {
    return (
        <Button
            sx={{
                color: "warning.darker",
                borderColor: "warning.darker",
                "&:hover": {
                    borderColor: "warning.darker",
                    backgroundColor: "#664D0314",
                },
                ...sx,
            }}
            variant="outlined"
            onClick={onClick}
            {...props}
        >
            {text}
        </Button>
    )
}
