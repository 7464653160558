import { useTheme } from "@mui/material"
import { Bound } from "lib/@components/binding/Bound"
import { PictureCard } from "lib/@components/picture-card"
import useAuth from "minimals-template/components/@hooks/useAuth"
import { useUserMetadata } from "lib/store/user"

export function UserCard({ children, avatar }) {
    const { user } = useAuth()
    const meta = useUserMetadata()
    const theme = useTheme()
    return (
        <Bound user={{ ...meta, ...user }}>
            <PictureCard
                panelColor={
                    theme.palette.mode === "dark" ? theme.palette.primary.darker : theme.palette.primary.lighter
                }
                smallImage={avatar}
            >
                {children}
            </PictureCard>
        </Bound>
    )
}
