import { registerTab } from "lib/@components/tabs"
import { hasDemand } from "lib/authorization/has-demand"
import { Lazy } from "lib/make-lazy"
import { MainStyleStart } from "slot-definitions"
import { SessionAlertBanner } from "./plugins/session-alert-banner"
import { registerFeature } from "lib/features"
import { isEnabled } from "lib/@components/feature"
import { Box, List, ListItem, ListItemText, Typography } from "@mui/material"

registerTab({
    tab: "clientAdmin",
    id: "adoptClientRestriction",
    title: "SFG20 Remote Access",
    content: (
        <Lazy importer={() => import("./adopt-client-restriction")} extract="AdoptClientRestriction" priority={175} />
    ),
    predicate: () => hasDemand("clientAdmin") && isEnabled(FEATURE_FLAG_ADOPT_CLENT),
})

MainStyleStart.plug(<SessionAlertBanner />)
export const FEATURE_FLAG_ADOPT_CLENT = registerFeature({
    numericalId: 4,
    name: "Adopt Client",
    id: "adoptClient",
    description: <FeatureFlagDescription />,
})

function FeatureFlagDescription() {
    return (
        <Box>
            <Typography pb={1}>When this flag is enabled, 2 new tabs will be available:</Typography>
            <List disablePadding>
                <ListItem
                    sx={{
                        p: 0,
                        pb: 1,
                    }}
                >
                    <ListItemText
                        primary={"1) Client Admin > SFG20 Remote Access:"}
                        secondary={
                            "This tab allows client admin users to create a remote login session (between 1 hour and 1 week) for SFG20 admins to access their data."
                        }
                    />
                </ListItem>

                <ListItem
                    sx={{
                        p: 0,
                        pb: 1,
                    }}
                >
                    <ListItemText
                        primary={"2) SFG20 Admin > Adopt Client:"}
                        secondary={
                            "This tab allows you to see all clients. If that client has a remote session available that they have created, there will be a button for the SFG20 admin to adopt their client."
                        }
                    />
                </ListItem>
            </List>
            <Typography pt={1}>
                This feature is to allow SFG20 admins to adopt and access any client, if that client has allowed. The
                idea being that if a client has an issue they need resolved we will be able to investigate first hand.
            </Typography>
        </Box>
    )
}
