import { Bound } from "lib/@components/binding/Bound"
import { useSearchParam } from "lib/@hooks/use-search-param"
import { Alert, Button, Container, Grid, Typography } from "@mui/material"
import { ScheduleItemCompleteList } from "routes/schedule/components/schedule-item-complete-list"
import { SearchBoxUsingSearchParam } from "lib/@components/searchBoxUsingSearchParam"
import { findInChildren } from "library/tree"
import { useRef } from "react"
import { CurrentSkills } from "routes/@lib/currentSkills"
import { Secure } from "lib/authorization/secure"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useReferenceState } from "lib/@hooks/use-reference"

export function FoldersAndSchedules() {
    const { facility, facilityGroup, select } = useBoundContext()
    const [viewAll, setViewAll] = useReferenceState("showAllSchedules", false)
    const [search] = useSearchParam("search", "")
    const target = facilityGroup ?? facility
    const removedSome = useRef(false)
    const groups = target.children.filter((s) => {
        const filteredBySkill = !!findInChildren(s, (s) => s.type !== "group" && !s.notInSkillSet).length
        removedSome.current ||= !filteredBySkill
        return s.type === "group" && (filteredBySkill || viewAll)
    })
    const schedules = target.children.filter(filter)
    return (
        <Container maxWidth="lg">
            <Bound inSelector={true}>
                <Grid container spacing={1} justifyContent="flex-start">
                    {removedSome.current && (
                        <Secure hide demand="see-all-schedules">
                            <Alert severity="warning" sx={{ width: 1, mt: 5, mb: 2 }}>
                                {!viewAll && (
                                    <>
                                        <Typography gutterBottom component="div">
                                            Some schedules/folders have been hidden because they do not match your
                                            specified
                                            <CurrentSkills />
                                        </Typography>

                                        <Button
                                            sx={{ mt: 2, mb: 3 }}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => setViewAll(true)}
                                        >
                                            See all schedules
                                        </Button>
                                    </>
                                )}
                                {viewAll && (
                                    <>
                                        <Typography gutterBottom component="div">
                                            Some schedules or folders are being shown that do not match your specified
                                            <CurrentSkills />
                                        </Typography>

                                        <Button
                                            sx={{ mt: 2, mb: 3 }}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => setViewAll(false)}
                                        >
                                            Hide Unmatched Schedules
                                        </Button>
                                    </>
                                )}
                            </Alert>
                        </Secure>
                    )}
                    <ScheduleItemCompleteList items={groups} select={select} />
                    <Grid item xs={12}>
                        <SearchBoxUsingSearchParam />
                    </Grid>
                    <ScheduleItemCompleteList items={schedules} select={select} />
                </Grid>
            </Bound>
        </Container>
    )

    function filter(s) {
        return (viewAll || !s.notInSkillSet) && s.type !== "group" && checkSchedule(s, search)
    }
}

function checkSchedule(s, search) {
    return s.type !== "group" && (s.code.startsWith(search) || s.label?.toLowerCase().includes(search))
}
