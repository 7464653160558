const { ensureArray } = require("library/ensure-array")
const { raise } = require("library/local-events")
const { resolveAsFunction } = require("library/resolve-as-function")

const NOT_AUTHORIZED = "Not authorized"

function withPermissions(permission, fn) {
    return checkPermissions(permission, fn, true)
}

function withSomePermissions(permission, fn) {
    return checkPermissions(permission, fn, false)
}

function checkPermissions(permission, fn, and) {
    if (typeof fn === "object") {
        return Object.fromEntries(Object.entries(fn).map(([name, fn]) => [name, checkPermissions(permission, fn, and)]))
    }
    return async (...params) => {
        validatePermission(permission, and)
        return fn(...params)
    }
}

function isAdmin() {
    const [{ demands }] = raise("GetDemands", { demands: [] })
    return demands?.some((demand) => demand.includes("admin"))
}

function validatePermission(permission, and = true) {
    if (!checkPermission(permission, and)) {
        throw new Error(NOT_AUTHORIZED)
    }
}

function checkPermission(permission, and = true) {
    const [{ demands }] = raise("GetDemands", { demands: [] })
    permission = ensureArray(permission)
        .map((s) => s.trim())
        .filter(Boolean)
    return recurseProcessDemands(permission, and, demands.flat(Infinity))
}

function recurseProcessDemands(demands, and, userDemands) {
    if (Array.isArray(demands)) {
        if (!demands.length) return true
        if (and) {
            return demands.every((d) => recurseProcessDemands(d, !and, userDemands))
        }
        return demands.some((d) => recurseProcessDemands(d, !and, userDemands))
    }
    return checkIndividualPermission(demands, userDemands)
}

function checkIndividualPermission(demand, demands) {
    demand = resolveAsFunction(demand)()
    if (typeof demand === "boolean") return demand
    if (typeof demand !== "string") return false
    if (!demand) return false
    const requiredDemand = demand.charAt(0) === "$"
    if (requiredDemand) {
        demand = demand.slice(1)
    }
    const invertedDemand = demand.charAt(0) === "!"
    if (invertedDemand) demand = demand.slice(1)

    if (demands.includes("admin") && !requiredDemand) return true
    // if (demands.includes("admin") && requiredDemand && invert) return !invertedDemand
    const hasDemand = demands.includes(demand)
    return invertedDemand ? !hasDemand : hasDemand
}

function hasPermission(permission) {
    return function check() {
        return checkPermission(permission)
    }
}

exports.hasPermission = hasPermission
exports.validatePermission = validatePermission
exports.checkPermission = checkPermission
exports.withPermissions = withPermissions
exports.withSomePermissions = withSomePermissions
exports.isAdmin = isAdmin
exports.NOT_AUTHORIZED = NOT_AUTHORIZED
