import { useScheduleGroup } from "routes/facilities/lib/use-schedule-group"
import { Box, Button, Typography } from "@mui/material"
import { BoundTextEditor } from "minimals-template/components/editor"
import { useState } from "react"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function AdditionalNotes() {
    const name = useScheduleGroup()
    const { target, individualTask, hasAdvancedTailoring } = useBoundContext()
    const [show, setShow] = useState(!!target.notes)

    const button = hasAdvancedTailoring && (
        <Button variant="outlined" size="small" onClick={() => setShow(true)}>
            Add Notes {!!name && "for"} {name}
        </Button>
    )

    const content = hasAdvancedTailoring ? (
        <BoundTextEditor updateOnBlur field="notes" label={`Additional notes ${name ? "for" : ""} ${name}`} />
    ) : (
        <Typography dangerouslySetInnerHTML={{ __html: target.notes }} />
    )

    return (
        !individualTask && (
            <Box p={1} pr={6}>
                {show ? content : button}
            </Box>
        )
    )
}
