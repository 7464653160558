import { saveAs } from "file-saver"
import { Packer } from "docx"
import { createDocX } from "./create-doc-x"

export async function downloadDocX(content) {
    const { code, version, title } = content
    const doc = await createDocX(content)
    const blob = await Packer.toBlob(doc)
    saveAs(blob, `${code}-${version}-${title}.docx`)
    console.log("Document created successfully")
}
