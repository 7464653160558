import { registerTab } from "lib/@components/tabs"
import { hasDemand } from "lib/authorization/has-demand"
import { Lazy } from "lib/make-lazy"

registerTab({
    tab: "clientAdmin",
    id: "profile",
    title: "Profile",
    content: <Lazy importer={() => import("./profile")} extract="Profile" priority={-100} />,
    predicate: () => hasDemand("clientAdmin"),
})
