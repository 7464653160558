import Chart, { useChart } from "lib/@components/chart"
import { fNumber } from "minimals-template/components/utils/formatNumber"
import { Box, Card, Typography } from "@mui/material"
import { TrendingInfo } from "routes/facilities/plugins/overview/plugins/summaries/trending-info"

export function WidgetSummary({ title, percent, total, chart, sx, ...other }) {
    const { colors, series, options } = chart

    const chartOptions = useChart({
        colors,
        chart: {
            animations: {
                enabled: false,
            },
            sparkline: {
                enabled: true,
            },
        },
        stroke: {
            width: 2,
        },
        tooltip: {
            x: {
                show: false,
            },
            y: {
                formatter: (value) => fNumber(value),
                title: {
                    formatter: () => "",
                },
            },
            marker: {
                show: false,
            },
        },
        ...options,
    })

    return (
        <Card sx={{ display: "flex", position: "relative", alignItems: "center", p: 3, ...sx }} {...other}>
            <Box sx={{ position: "absolute", left: 0, right: 0, top: 0, height: 12, bgcolor: colors[0] }} />
            <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle2" paragraph>
                    {title}
                </Typography>

                <Typography variant="h3" gutterBottom>
                    {fNumber(total)}
                </Typography>

                <TrendingInfo percent={percent} />
            </Box>
            <Box position="relative" alignSelf="flex-start">
                <Box position="absolute" sx={{ right: 0, top: 20 }}>
                    <Chart type="line" series={[{ data: series }]} options={chartOptions} width={120} height={80} />
                </Box>
            </Box>
        </Card>
    )
}
