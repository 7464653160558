import { api } from "lib/graphql/api"
import { mutate } from "lib/graphql/mutate"
import { gql } from "@apollo/client"

export const setTypeSchedulePayload = api(({ id, type, scheduleId, payload }) =>
    mutate(
        gql`
            mutation SetTypeSchedulePayload($id: String!, $type: String!, $scheduleId: String!, $payload: JSONObject!) {
                setTypeSchedulePayload(id: $id, scheduleId: $scheduleId, type: $type, payload: $payload)
            }
        `,
        { id, type, scheduleId, payload },
        { returns: "setTypeSchedulePayload" }
    )
)
