import { Box, Card, useTheme } from "@mui/material"

function WidgetCard({ bannerColor = "primary", noFlex, children, ...other }) {
    const theme = useTheme()
    return (
        <Card
            style={{
                display: noFlex ? " block" : "flex",
                position: "relative",
                minWidth: 240,
                alignItems: "center",
                borderRadius: theme.spacing(1),
                padding: theme.spacing(3),
                backgroundColor: "background.paper",
                marginTop: theme.spacing(2),
            }}
            {...other}
            sx={{
                textAlign: "left",
                width: 1,
                border: "none",
                ...other.sx,
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    height: 8,
                    backgroundColor: bannerColor,
                }}
            />

            {children}
        </Card>
    )
}

export default WidgetCard
