import { OneCard } from "lib/@components/one-card"
import { ListItemBox } from "lib/@components/ListItemBox"
import { Avatar, Box, useTheme } from "@mui/material"
import { NotOnMobile } from "lib/@components/mobile"
import SvgIconStyle from "minimals-template/components/SvgIconStyle"

export function PictureCard({ sx, size = 144, children, smallImage, panelColor, panelImage }) {
    panelImage = typeof panelImage === "string" ? `url(${panelImage})` : panelImage
    const ratio = size / 144
    const theme = useTheme()
    return (
        <OneCard sx={sx}>
            <ListItemBox alignItems="flex-start">
                <Box flex={1} p={2} pl={3}>
                    {children}
                </Box>
                <NotOnMobile>
                    <Box
                        minWidth={200}
                        width="18%"
                        maxWidth={400}
                        justifySelf="stretch"
                        alignSelf="stretch"
                        minHeight={160}
                        maxHeight={650}
                        sx={{
                            position: "relative",
                            overflow: "hidden",
                            backgroundSize: "cover",
                            backgroundPosition: "50% 50%",
                            backgroundImage: panelImage,
                            backgroundColor: panelColor,
                        }}
                    >
                        <Box
                            position="absolute"
                            sx={{
                                top: 0,
                                bottom: 0,
                                left: 0,
                                width: 40,
                                bgcolor: "background.paper",
                            }}
                        />
                        {!!panelImage && (
                            <Box
                                sx={{
                                    height: 100,
                                    position: "absolute",
                                    left: 0,
                                    right: 0,
                                    top: 550,
                                    backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.paper}00, ${theme.palette.background.paper})`,
                                }}
                            />
                        )}

                        {!!smallImage && (
                            <>
                                <SvgIconStyle
                                    src="https://minimal-assets-api-dev.vercel.app/assets/icons/shape-avatar.svg"
                                    sx={{
                                        width: size,
                                        height: 62 * ratio,
                                        zIndex: 10,
                                        transform: "rotate(90deg)",
                                        left: -27,
                                        top: 50,
                                        mx: "auto",
                                        position: "absolute",
                                        color: "background.paper",
                                    }}
                                />
                                {typeof smallImage === "string" ? (
                                    <Avatar
                                        alt={`Card picture`}
                                        src={
                                            smallImage ??
                                            "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                        }
                                        sx={{
                                            width: 72 * ratio,
                                            height: 72 * ratio,
                                            zIndex: 11,
                                            left: 0,
                                            top: 45,
                                            mx: "auto",
                                            position: "absolute",
                                        }}
                                    />
                                ) : (
                                    <Avatar
                                        alt={`Inset picture`}
                                        sx={{
                                            width: 72 * ratio,
                                            height: 72 * ratio,
                                            zIndex: 11,
                                            bgcolor: "transparent",
                                            left: -13 + 12 * ratio,
                                            top: 45,
                                            mx: "auto",
                                            position: "absolute",
                                        }}
                                    >
                                        {smallImage}
                                    </Avatar>
                                )}
                            </>
                        )}
                    </Box>
                </NotOnMobile>
            </ListItemBox>
        </OneCard>
    )
}
