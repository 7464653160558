import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { mutate } from "lib/graphql/mutate"

export const grantRegimeAccess = api(async (users, regimeId) =>
    mutate(
        gql`
            mutation GrantRegimeAccess($users: [String], $regimeId: String) {
                grantRegimeAccess(users: $users, regimeId: $regimeId)
            }
        `,
        { users, regimeId },
        { returns: "grantRegimeAccess" }
    )
)
