import { api } from "lib/graphql/api"
import { mutate } from "lib/graphql/mutate"
import { gql } from "@apollo/client"

export const setTreeSecurity = api(async (id, rules) =>
    mutate(
        gql`
            mutation setTreeSecurity($id: String!, $rules: [String!]!) {
                setTreeSecurity(id: $id, rules: $rules)
            }
        `,
        { id, rules },
        { returns: "setTreeSecurity" }
    )
)
