import { ScheduleItemMainExtra } from "slot-definitions"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Chip } from "@mui/material"
import { useTreeItem } from "routes/schedule/lib/useTreeItem"

ScheduleItemMainExtra.plug(<WhenRetired />)

function WhenRetired() {
    const { target } = useBoundContext()
    const item = useTreeItem(target?._id)

    return item?.$?.itemType === "retired" && <Chip size="small" sx={{ mx: 1 }} label="Retired" />
}
