import { Bound } from "lib/@components/binding/Bound"
import { Repeat } from "lib/@components/repeat"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Frag } from "lib/@components/slot/frag"

export function ScheduleItemCompleteList({ items = [], select, container = <Frag /> }) {
    const { transformScheduleItem = (v) => v } = useBoundContext()
    if (!items.length) return null
    return (
        <Repeat
            sx={{ mt: 1 }}
            list={items
                .filter((item) => !!item.content)
                .map((item, index, array) => (
                    <Bound key={item._id ?? item.id} target={item} onClick={() => select(item)}>
                        {transformScheduleItem(
                            <item.content.type {...item.content.props} last={array.length === index + 1} />,
                            item
                        )}
                    </Bound>
                ))}
            pass="children"
            item={container}
        />
    )
}
