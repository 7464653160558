const { uniqueIdFromTreeId } = require("library/unique-id-from-tree-id.js")

function wrapParagraph(item) {
    if (!item) return ""
    if (item.startsWith("<p>")) return item
    return `<p>${item}</p>`
}

const validCountUnits = ["Nr"]

const doNotTailor = Symbol("doNotTailor")

exports.extendSchedule = (schedule, payload, doNotRemoveTasks, key) => {
    if (!payload || !schedule) return

    if (payload.tailoringName) {
        schedule.title = `${schedule.title} (${payload.tailoringName})`
        // console.trace("Extended to", schedule.title) // Uncomment to see extensions to schedule
    }

    const shouldTailorSchedule = !schedule[doNotTailor]

    if (payload.uniqueCode && !schedule.$code && shouldTailorSchedule) {
        schedule.code = `${schedule.code}-${`${payload.uniqueCode}`.padLeft(4, "0")}`
        schedule.$code = true
    }
    if (schedule.intro) {
        schedule.intro.content = `${wrapParagraph(payload.introduction)}${wrapParagraph(schedule.intro.content)}`
        schedule.intro.note = `${wrapParagraph(schedule.intro.note)}${wrapParagraph(payload.notes)}`
    }

    if (!schedule.tasks) {
        schedule.tasks = []
    }

    schedule.assets = getAssets(schedule, payload, key)
    schedule.systemAssets = payload.assets
    schedule.units = payload.units

    if (shouldTailorSchedule) {
        schedule.tasks = schedule.tasks
            .map((t) => ({ ...t }))
            .map((task) => {
                if (!task) return null
                const payloadTask = payload?.tasks?.[task.id]
                // Adjust the minutes for this task based on the number of assets, or the measurement etc
                const originalMinutes = task.minutes
                task.count = validCountUnits.includes(task.unitOfMeasure) ? payload.units?.[task.unitOfMeasure] || 1 : 1
                task._minutes = +(payloadTask?.minutes ?? task.minutes ?? 0)
                task.minutes = +(payloadTask?.minutes ?? task.minutes ?? 0) * task.count

                if (!payloadTask) {
                    return task
                }
                if (payloadTask.included === false && !doNotRemoveTasks) {
                    return null
                }

                task.included = payloadTask.included
                task.classification = payloadTask.classification ?? task.classification
                task.unitOfMeasure = payloadTask.unitOfMeasure ?? task.unitOfMeasure

                // See if we should show the number of minutes for the task (it's been tailored)
                if ((payloadTask?.minutes ?? originalMinutes ?? 0) !== originalMinutes) {
                    task.$showMinutes = true
                }

                task.frequency = payloadTask.frequency ?? task.frequency
                task.content = `${wrapParagraph(payloadTask.introduction)}${wrapParagraph(task.content)}`
                task.note = `${wrapParagraph(task.note)}${wrapParagraph(payloadTask.notes)}`
                task.skillSet = payloadTask.skillSet ?? task.skillSet

                return task
            })
            .filter(Boolean)
    }
}

exports.payloadKey = payloadKey
exports.doNotTailor = doNotTailor

function payloadKey(tree, group, key = "default") {
    key = uniqueIdFromTreeId(key)
    if (key.startsWith(`GT*`) && (!group || group === tree)) {
        return `global!${key}`
    }
    return `${uniqueIdFromTreeId(group)}!${key}`
}

function getAssets(schedule, payload, key) {
    return schedule.tasks
        .map("unitOfMeasure")
        .unique()
        .filter((f) => validCountUnits.includes(f))
        .map((unit) => {
            const number = +(payload.units?.[unit] || 1)
            const output = []
            for (let i = 0; i < number; i++) {
                const asset = payload.units?.[`${unit}_${i + 1}`]
                const validAsset = asset && typeof asset === "string" ? asset : undefined
                output.push(validAsset || `*${key ?? "Unspecified Asset"}.${i + 1}`)
            }
            return output
        })
        .flat(Infinity)
}
