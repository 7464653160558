import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { mutate } from "lib/graphql/mutate"

export const deleteLock = api(async function deleteLock(id) {
    return mutate(
        gql`
            mutation DeleteLock($id: String!) {
                deleteLock(id: $id)
            }
        `,
        { id },
        { returns: "deleteLock" }
    )
})
