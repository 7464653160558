import { Box, Stack, Typography } from "@mui/material"
import { PageBreadcrumbContainer } from "lib/page-breadcrumb-container"
import { EmptyContentIllustration as Illustration } from "minimals-template/assets"
import { TreeToolbar } from "slot-definitions"
import { replaceAllWith } from "lib/@components/slot/replace-all-with"
import { Frag } from "lib/@components/slot/frag"
import { BackButton } from "routes/schedule/tree/schedules/upDirectory"

export function NoResults({ children = "No Results", width = 275, mt = 7 }) {
    return (
        <Stack data-cy={"noResultsFoundStack"} alignItems="center" spacing={2} mt={mt}>
            <Box sx={{ width }}>
                <Illustration />
            </Box>
            <Typography component="div" variant="body2" color="text.secondary">
                {children}
            </Typography>
        </Stack>
    )
}

export function NoResultsContainer({ message = "No Results", title = message, onBackClick }) {
    TreeToolbar.usePlug(replaceAllWith(<Frag />, 2000))

    return (
        <PageBreadcrumbContainer title={title} sx={{ height: 1 }} backButton={<BackButton onClick={onBackClick} />}>
            <Box flex={1}>
                <NoResults>{message}</NoResults>
            </Box>
        </PageBreadcrumbContainer>
    )
}
