import { useState } from "react"
import { Bound } from "lib/@components/binding/Bound"
import { DialogBox } from "lib/@components/dialogBox"
import { Menu, MenuItem, Portal, Typography } from "@mui/material"
import { AssetSelector } from "routes/regime/assets/asset-selector"
import { Repeat } from "lib/@components/repeat"
import { RegisterEntry } from "routes/regime/assets/register-entry"

export function AssetDialog({ assetRegisters }) {
    const [asset, setAsset] = useState()
    const [anchor, setAnchor] = useState()

    return (
        <Bound setAsset={setAsset} current={asset} assetRegisters={assetRegisters}>
            <DialogBox title="Select Asset" value={asset}>
                <AssetSelector />
            </DialogBox>
            <Portal>
                <Menu open={!!anchor} anchorEl={anchor} onClose={() => setAnchor(null)}>
                    <MenuItem disabled>
                        <Typography variant="caption">Choose Asset Register</Typography>
                    </MenuItem>
                    <Repeat list={assetRegisters} item={<RegisterEntry />} />
                </Menu>
            </Portal>
        </Bound>
    )
}
