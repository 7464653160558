import { ContextMenuItem } from "lib/@components/context-menu-bar/context-menu-item"
import { OpenSchedule } from "event-definitions"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function EditScheduleMenuItem() {
    const { schedule } = useBoundContext()
    return <ContextMenuItem icon="clarity:edit-solid" label="Tailor Schedule" onClick={go} />

    function go() {
        OpenSchedule(schedule.id).raise(schedule, { editEx: true })
    }
}
