import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { mutate } from "lib/graphql/mutate"

export const stillEditing = api(async function stillEditing(id) {
    if (!id) return null

    return mutate(
        gql`
            mutation StillEditing($id: String!) {
                stillEditing(id: $id)
            }
        `,
        { id },
        { returns: "stillEditing" }
    )
})
