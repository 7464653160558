import { XmlGroupTypes } from "event-definitions"

export function getXmlGroupTypes() {
    const { groupTypes } = XmlGroupTypes.call({ groupTypes: [] })
    const sortedTypes = groupTypes.sortBy((c) => c.priority)
    const output = {}
    sortedTypes.forEach((item) => {
        output[item.type] = item
    })
    return output
}
