import { mutate } from "lib/graphql/mutate"
import { gql } from "@apollo/client"

export async function setGroupPayloadUsingIds(tree, group, key, payload) {
    console.trace("setGroupPayloadUsingIds")
    return mutate(
        gql`
            mutation setPayloadData($tree: String!, $group: String!, $key: String!, $payload: JSONObject!) {
                setGroupPayload(payload: $payload, tree: $tree, group: $group, key: $key)
            }
        `,
        {
            tree,
            group,
            payload,
            key,
        }
    )
}
