import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { mutate } from "lib/graphql/mutate"

export const updateBulletin = api(async function updateBulletin(id, bulletinData) {
    const { date, content, type, title = "" } = bulletinData
    return mutate(
        gql`
            mutation UpdateBulletin($id: String!, $date: String!, $content: String!, $type: String!, $title: String) {
                updateBulletin(id: $id, date: $date, content: $content, type: $type, title: $title)
            }
        `,
        { id, date, content, title, type },
        { returns: "updateBulletin" }
    )
})
