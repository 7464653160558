import { useCallback, useState } from "react"
import { resolveAsFunction } from "lib/resolve-as-function"
import { noChange, useRefresh } from "lib/@hooks/useRefresh"
import { Bound } from "lib/@components/binding/Bound"
import { OkCancel } from "lib/@components/okCancel"
import { useYupContext } from "lib/@components/binding/use-yup-wrapper"
import { debounce } from "lib/debounce"
import { cloneDeep } from "lib/clone"

export function DataInputDialog({ initialValue = {}, children, ...props }) {
    const [target] = useState(() => cloneDeep(resolveAsFunction(initialValue)()))
    const [yupContext, validate] = useYupContext()
    const refresh = useRefresh(noChange)
    const [disabled, setDisabled] = useState(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const checkContext = useCallback(debounce(_checkContext, 100), [validate])
    return (
        <Bound onChange={checkContext} target={target} refresh={refresh} yupContext={yupContext}>
            {children}
            <OkCancel
                {...props}
                okDisabled={checkDisabled}
                value={Object.values(target).filter(Boolean).length ? target : undefined}
            />
        </Bound>
    )

    function checkDisabled(...params) {
        if (props.okDisabled) {
            if (props.okDisabled(...params)) return true
        }
        return disabled
    }

    function _checkContext() {
        setDisabled(!validate())
        return false
    }
}
