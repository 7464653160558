import { appRoute } from "routes/app/home.runtime"
import { ScheduleHeaderToolbar } from "slot-definitions"

import Schedules from "routes/schedule/schedules"
import { SaveButton } from "routes/schedule/compare/save-button"
import { Compare2 } from "routes/schedule/compare/compare2"

appRoute.register(
    "schedules/compare/:destination/:source",
    <Schedules>
        <Compare2 $lazy />
    </Schedules>
)

ScheduleHeaderToolbar.plug(<SaveButton />)
