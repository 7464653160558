import { Bound } from "lib/@components/binding/Bound"
import { Box, Card, Divider, Grid, ImageList, ImageListItem } from "@mui/material"
import { Repeat } from "lib/@components/repeat"
import Iconify from "minimals-template/components/Iconify"
import { ListItemBox } from "lib/@components/ListItemBox"
import { RegimeItemLeft, RegimeItemRight, RegimeItemTitle } from "slot-definitions"
import TextMaxLine from "minimals-template/components/TextMaxLine"
import { BlockClicks } from "lib/@components/block-clicks"
import { Secure } from "lib/authorization/secure"
import { ContextMenuBar } from "lib/@components/context-menu-bar"
import { navigate } from "lib/routes/navigate"
import { TruncatedTypography } from "lib/@components/truncated-typography"
import { Frag } from "lib/@components/slot/frag"
import { DivStyleButton } from "lib/accessibility/div-style-button"

export function RegimeItem({ item, go, hideMenu }) {
    go ??= defaultGo
    const list = item.$?.images?.filter(Boolean).slice(0, 3) ?? []
    return (
        <Bound parentItem={item}>
            <Grid item xs={12} lg={4} xl={3}>
                <Card
                    data-cy={"regime-card"}
                    sx={{ height: 1, color: "text.primary", display: "flex", flexDirection: "column" }}
                >
                    <Box
                        sx={{
                            cursor: "pointer",
                            display: "flex",
                            willChange: "transform",
                            transform: "translateZ(0)",
                            color: "text.primary",
                            height: 1,
                            flexDirection: "column",
                        }}
                        component={DivStyleButton}
                        aria-label={`Select ${item.label}`}
                        onClick={() => go(item)}
                        className="regimeItem"
                    >
                        <ImageList
                            sx={{ width: 1, height: 160, overflow: "hidden" }}
                            cols={Math.min(3, item.$?.images?.length ?? 1)}
                            rowHeight={160}
                        >
                            <Repeat list={list} item={<Picture />} />
                            {!list.length && (
                                <Box
                                    sx={{
                                        width: 1,
                                        height: 1,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontSize: 64,
                                        opacity: 0.4,
                                        color: "myStuff.main",
                                    }}
                                >
                                    <Iconify icon="pajamas:issue-type-maintenance" />
                                </Box>
                            )}
                        </ImageList>
                        <ListItemBox p={1} pb={0} spacing={1} justifyContent="flex-start" flexWrap="wrap">
                            <RegimeItemTitle.Slot regime={item}>
                                <>
                                    <TruncatedTypography
                                        sx={{ pr: 1 }}
                                        gutterBottom
                                        variant="subtitle2"
                                        component="div"
                                    >
                                        {item.label.replaceAll("Live:", "").replaceAll("Ready:", "")}
                                    </TruncatedTypography>
                                </>
                            </RegimeItemTitle.Slot>
                        </ListItemBox>

                        <Divider />
                        <Box px={1} pb={1}>
                            <TextMaxLine
                                variant="caption"
                                component="div"
                                line={3}
                                sx={{ color: "text.secondary", pt: 0.5 }}
                            >
                                <TruncatedTypography
                                    variant="caption"
                                    component="div"
                                    line={3}
                                    sx={{ color: "text.secondary", pt: 0.5 }}
                                >
                                    {!!item.$.facilities?.length &&
                                        item.$.facilities
                                            .sort(
                                                new Intl.Collator("en", {
                                                    numeric: true,
                                                    sensitivity: "accent",
                                                }).compare
                                            )
                                            .join(", ")}
                                </TruncatedTypography>
                                {!item.$.facilities?.length && "No facilities"}
                            </TextMaxLine>
                        </Box>
                        <Box flex={1} />
                    </Box>
                    {!hideMenu && (
                        <BlockClicks sx={{ width: 1, display: "block" }}>
                            <ListItemBox sx={{ mr: -0.2, pl: 1 }} spacing={1}>
                                <RegimeItemLeft.Slot regime={item} />
                                <Box flex={1} />
                                <RegimeItemRight.Slot regime={item}>
                                    <Secure demand="manageRegime" hide fallback={<Frag />} priority={1000}>
                                        <ContextMenuBar type="regime" regime={item} />
                                    </Secure>
                                </RegimeItemRight.Slot>
                            </ListItemBox>
                        </BlockClicks>
                    )}
                </Card>
            </Grid>
        </Bound>
    )

    function defaultGo() {
        navigate(`/app/schedules?id=${item.id}`)
    }
}

export function Picture({ item }) {
    return (
        <ImageListItem
            data-cy="image-list-item"
            sx={{ overflow: "hidden", justifyContent: "center", alignItems: "center" }}
        >
            <img src={`${item}`} srcSet={`${item}`} alt={"Facility"} loading="lazy" />
        </ImageListItem>
    )
}
