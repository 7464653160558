function splitId(recordId) {
    if (recordId?._id) return splitId(recordId._id)
    if (typeof recordId !== "string" || !recordId.includes(":"))
        return {
            id: recordId,
            recordId,
            create(...params) {
                return `${recordId}:${params.join("/")}`
            },
        }
    const [id = "", source = ""] = recordId.split(":")
    const [database = "", table = ""] = source.split("/")
    return Object.assign(recordId, { database, table, id, source, recordId, create })

    function create(...params) {
        return `${id}:${params.join("/")}`
    }
}

function uniqueId(id) {
    return splitId(id).id
}

module.exports = { splitId, uniqueId }
