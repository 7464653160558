import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getLegacyCustomSchedules = api(async function getLegacyCustomSchedules(subscriptionId) {
    return query(
        gql`
            query GetLegacyCustomSchedules($subscriptionId: Int!) {
                getLegacyCustomSchedules(subscriptionId: $subscriptionId) {
                    customScheduleId
                    code
                    title
                    label
                }
            }
        `,
        { subscriptionId },
        { returns: "getLegacyCustomSchedules" }
    )
})
