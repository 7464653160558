import { Stack, Typography } from "@mui/material"
import { Bound } from "lib/@components/binding/Bound"
import { BoundAutocomplete } from "lib/@components/binding/bound-components"
import { DialogBox } from "lib/@components/dialogBox"
import { noChange, useRefresh } from "lib/@hooks/useRefresh"
import { useState } from "react"
import { getXmlTaskContentTypes } from "./get-xml-task-content-types"
import { getXmlGroupTypes } from "./get-xml-group-types"

export function XmlOutputOptionsDialog() {
    const refresh = useRefresh(noChange)
    const contentTypes = getXmlTaskContentTypes()
    const groupTypes = getXmlGroupTypes()

    const [target] = useState({
        contentType: Object.keys(contentTypes)[0],
        scheduleGroup: Object.keys(groupTypes)[0],
    })

    return (
        <Bound target={target} refresh={refresh}>
            <DialogBox title="Download XML" value={target}>
                <Stack spacing={2} mt={1}>
                    <Typography variant="body">
                        Select how you would like schedule task content to be displayed:
                    </Typography>
                    <BoundAutocomplete
                        data-cy="xml-task-autocomplete"
                        field="contentType"
                        options={Object.keys(contentTypes)}
                        getOptionLabel={(option) => contentTypes[option].label || ""}
                        disableClearable
                        sideEffects
                    />
                    <Typography variant="body2">{contentTypes[target.contentType]?.description}</Typography>
                    <Stack spacing={2} pt={3}>
                        <Typography variant="body">Select the schedule group source for schedules:</Typography>
                        <BoundAutocomplete
                            data-cy="xml-group-autocomplete"
                            field="scheduleGroup"
                            options={Object.keys(groupTypes)}
                            getOptionLabel={(option) => groupTypes[option].label || ""}
                            disableClearable
                            sideEffects
                        />
                        <Typography variant="body2">{groupTypes[target.scheduleGroup]?.description}</Typography>
                    </Stack>
                </Stack>
            </DialogBox>
        </Bound>
    )
}
