import { Box, Collapse, ListItem, ListItemButton, ListItemText } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { prevent } from "lib/prevent"
import { forwardRef } from "react"
import noop from "lib/noop"
import { ListItemBox } from "lib/@components/ListItemBox"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export const Accordion = forwardRef(function Accordion(
    {
        open,
        className,
        alwaysOpen,
        title,
        sx,
        titleSx,
        additional = null,
        noCollapse = false,
        subTitle,
        onChange = noop,
        children,
        ...props
    },
    ref
) {
    const { editMode } = useBoundContext()
    const isOpen = alwaysOpen || open
    const Component = editMode || alwaysOpen ? ListItem : ListItemButton
    return (
        <>
            <ListItem
                component="div"
                className={`${className} header`}
                ref={ref}
                onClick={prevent(toggle)}
                {...props}
                sx={{
                    py: 0,
                    pr: 1,
                    mb: 0,
                    flex: 0,
                    ...sx,
                }}
                tabIndex={0}
            >
                <ListItemBox sx={titleSx}>
                    <Component component="div" sx={{ px: 0 }}>
                        <ListItemText disableTypography primary={title} secondary={subTitle} component="div" />
                    </Component>

                    {additional}
                </ListItemBox>
                {!alwaysOpen && (
                    <Box lineHeight={0}>
                        <Iconify
                            icon={isOpen ? "eva:arrow-ios-downward-fill" : "eva:arrow-ios-forward-fill"}
                            sx={{ width: 16, height: 16, ml: 1, cursor: "pointer" }}
                        />
                    </Box>
                )}
            </ListItem>
            {noCollapse && isOpen && children}
            {!noCollapse && (
                <Collapse className={`${className} content`} in={!!isOpen} mountOnEnter unmountOnExit>
                    {children}
                </Collapse>
            )}
        </>
    )

    function toggle() {
        onChange(!open)
    }
})
