import { useBrowserEvent } from "lib/@hooks/useEvent"
import { useContext, useEffect, useState } from "react"
import { resolveAsFunction } from "lib/resolve-as-function"
import { Button, DialogActions, Stack } from "@mui/material"
import { OkCancelContext } from "lib/@hooks/useDialog"
import Iconify from "minimals-template/components/Iconify"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import noop from "lib/noop"
import { createPortalPair } from "lib/portals"

export const [DialogActionPortal, DialogActionUser] = createPortalPair("DialogActionPortal")

export function OkCancel({
    value,
    ok,
    cancel,
    disableKeys,
    okDisabled = () => false,
    okDisabledAsync = async () => false,
    beforeOk = async (v) => v,
    okCaption = "Confirm",
    okColor = "primary",
    cancelColor = "primary",
    okIcon = "mdi:tick",
    cancelIcon = "material-symbols:cancel",
    cancelCaption = "Cancel",
    children,
}) {
    const [, refresh] = useState(0)
    const [disabled, setDisabled] = useState(false)
    useBrowserEvent("keydown", async (evt) => {
        if (disableKeys) return
        if (evt.key === "Enter") {
            if (value === null || value === undefined || value === "" || !!okDisabled(value)) {
                return
            }
            setTimeout(async () => ok(await beforeOk(value)))
        }
    })
    const context = useBoundContext()
    useEffect(() => {
        const { onChange = noop } = context
        context.onChange = () => {
            onChange()
            checkAsync()
                .then(() => refresh((a) => a + 1))
                .catch(console.error)
        }
        checkAsync().catch(console.error)
        return () => (context.onChange = onChange)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context, value])

    const okCancel = useContext(OkCancelContext)
    okDisabled = resolveAsFunction(okDisabled)
    ok = ok || okCancel.ok
    cancel = cancel || okCancel.cancel

    return (
        <DialogActions>
            <Stack alignItems="flex-end">
                <DialogActionPortal />
                <Stack direction="row" spacing={1}>
                    <Button
                        startIcon={<Iconify icon={cancelIcon} />}
                        onClick={cancel}
                        color={cancelColor}
                        data-cy="dialogActionsCancelButton"
                    >
                        {cancelCaption}
                    </Button>
                    {children}
                    <Button
                        startIcon={<Iconify icon={okIcon} />}
                        disabled={
                            disabled ||
                            value === null ||
                            value === undefined ||
                            value === "" ||
                            value === false ||
                            !!okDisabled(value)
                        }
                        onClick={async () => ok(await beforeOk(value))}
                        color={okColor}
                        variant="contained"
                        data-cy="dialogActionsOkButton"
                    >
                        {okCaption}
                    </Button>
                </Stack>
            </Stack>
        </DialogActions>
    )

    async function checkAsync() {
        setDisabled(await okDisabledAsync(value))
    }
}
