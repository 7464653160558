import { Bound } from "lib/@components/binding/Bound"
import { ExportScheduleSearch } from "./components/export-schedule-search"
import { useState } from "react"
import { SchedulesTable } from "./components/table/schedules-table"
import { PageBreadCrumbs } from "slot-definitions"
import { Frag } from "lib/@components/slot/frag"

export function ExportSchedule() {
    const [payload, setPayload] = useState()
    return (
        <Bound target={payload} setPayload={setPayload}>
            <PageBreadCrumbs.Plug>
                <Frag>{`Export Schedule`}</Frag>
            </PageBreadCrumbs.Plug>
            <ExportScheduleSearch />
            <SchedulesTable />
        </Bound>
    )
}
