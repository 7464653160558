import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const asset = api(async function asset(id) {
    if (!id) return null
    return query(
        gql`
            query GetAsset($id: String!) {
                asset(id: $id) {
                    raw
                }
            }
        `,
        {
            id,
        },
        {
            returns: "asset.raw",
        }
    )
})
