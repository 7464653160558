import { RegisterSystemTask } from "event-definitions"
import { handle } from "library/local-events"
import { warningSnackbar } from "lib/snackbar/warning-snackbar"
import { gql } from "@apollo/client"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { TaskEditor } from "slot-definitions"
import { query } from "lib/graphql/query"
import { successSnackbar } from "lib/snackbar/success-snackbar"
import { replaceAllWith } from "lib/@components/slot/replace-all-with"
import { Frag } from "lib/@components/slot/frag"

RegisterSystemTask.handleOnce(({ tasks }) => {
    const task = {
        name: "Analyse Schedules",
        event: "analyse-schedules",
        params: {},
    }
    tasks.push(task)
})

handle("analyse-schedules", async () => {
    warningSnackbar("Requesting Schedule Analysis", { autoHideDuration: 10000 })
    const result = await query(
        gql`
            query Analyse {
                analyseSchedules
            }
        `,
        {},
        { returns: "analyseSchedules", fetchPolicy: "network-only" }
    ).catch((e) => {
        console.error(e)
        errorSnackbar(e.message, { autoHideDuration: null })
    })

    const dlAnchorElem = document.createElement("a")
    dlAnchorElem.setAttribute("href", result)
    dlAnchorElem.setAttribute("download", "analysis.xlsx")
    dlAnchorElem.click()
    successSnackbar("Schedule Analysis Complete")
})

TaskEditor("analyse-schedules").plug(replaceAllWith(<Frag />))

RegisterSystemTask.handleOnce(({ tasks }) => {
    const task = {
        name: "Process All Schedules",
        event: "process-schedules",
        params: {},
    }
    tasks.push(task)
})

handle("process-schedules", async () => {
    warningSnackbar("Requesting Schedule Processing", { autoHideDuration: 10000 })
    const result = await query(
        gql`
            query Analyse {
                allSchedulesToXL
            }
        `,
        {},
        { returns: "allSchedulesToXL", fetchPolicy: "network-only" }
    ).catch((e) => {
        console.error(e)
        errorSnackbar(e.message, { autoHideDuration: null })
    })

    const dlAnchorElem = document.createElement("a")
    dlAnchorElem.setAttribute("href", result)
    dlAnchorElem.setAttribute("download", "schedules.xlsx")
    dlAnchorElem.click()
    successSnackbar("Schedule Processing Complete")
})

TaskEditor("process-schedules").plug(replaceAllWith(<Frag />))
