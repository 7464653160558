import { Box, ListItemButton } from "@mui/material"
import { FolderIcon } from "routes/schedule/tree/schedules/folder-icon"
import { ListItemBox } from "lib/@components/ListItemBox"
import { FolderItemPart } from "routes/schedule/tree/schedules/folder-item-part"
import { SelectedChildren } from "routes/schedule/components/selected-children"
import { useTreeItem } from "routes/schedule/lib/useTreeItem"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function GroupItem({ group: { label }, last, Component = ListItemButton }) {
    const { onClick, inSelector, target } = useBoundContext()
    const item = useTreeItem(target.selectedId || target.id)
    return (
        (item.children?.length || !inSelector) && (
            <Component className="group-item" sx={{ flex: 1 }} divider={!last} onClick={onClick}>
                <ListItemBox spacing={1}>
                    <FolderItemPart>
                        <SelectedChildren />
                    </FolderItemPart>
                    <Box>
                        <FolderIcon />
                    </Box>
                    <Box sx={{ lineHeight: 1.3, maxWidth: "20em", width: "max-content" }} flexGrow={1}>
                        {label}
                    </Box>
                </ListItemBox>
            </Component>
        )
    )
}
