import { IconButton, Tooltip } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { useScheduleSettings } from "routes/schedule/lib/useScheduleSettings"
import { CollapseAllTasks } from "event-definitions"
import { TreeToolbar } from "slot-definitions"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

TreeToolbar.plug(<CollapseAll priority={500} />)

function CollapseAll() {
    const { schedule, isTimeline } = useBoundContext()
    const [{ showDetail }] = useScheduleSettings()
    return (
        !showDetail &&
        !isTimeline &&
        !!schedule && (
            <Tooltip title="Collapse all sections">
                <IconButton size="small" onClick={() => CollapseAllTasks.raise()}>
                    <Iconify icon="ph:arrows-in-line-vertical-light" />
                </IconButton>
            </Tooltip>
        )
    )
}
