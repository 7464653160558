import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { mutate } from "lib/graphql/mutate"

export const createBulletin = api(async function createBulletin(bulletinData) {
    const { date, content, type, title = "" } = bulletinData
    return mutate(
        gql`
            mutation CreateBulletin($date: String!, $content: String!, $type: String!, $title: String) {
                createBulletin(date: $date, content: $content, type: $type, title: $title)
            }
        `,
        { date, content, title, type },
        { returns: "createBulletin" }
    )
})
