import { FacilityPlugs, StatsPanels, StatsTabs } from "slot-definitions"
import { Box, Tab } from "@mui/material"
import { TabPanel } from "@mui/lab"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Profile } from "routes/facilities/plugins/maintenance-profile-tab/profile"
import { registerTab } from "lib/@components/tabs"
import { REGIME_ASSETS_TAB } from "routes/asset-register/plugins/regime-display/regime-display.runtime"
import { FEATURE_FLAG_REGIME_TASK_MANAGEMENT } from "routes/asset-register/plugins/regime-display/regime-task-management-feature"
import { isEnabled } from "lib/@components/feature"

FacilityPlugs.plug(<MaintenanceProfileTab />)

function MaintenanceProfileTab() {
    const context = useBoundContext()
    context.LinkComponent = MyLink
    StatsTabs.usePlug(<Tab value="Profile" label="Profile" />)
    StatsPanels.usePlug(
        <TabPanel value="Profile">
            <Profile $lazy />
        </TabPanel>
    )
    return null
}

function MyLink({ children }) {
    return <Box sx={{ fontSize: 13 }}>{children}</Box>
}

registerTab({
    tab: REGIME_ASSETS_TAB,
    id: "profile",
    title: "Overview",
    predicate: () => isEnabled(FEATURE_FLAG_REGIME_TASK_MANAGEMENT),
    content: <Profile $lazy priority={140} />,
})
