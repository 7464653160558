import { Box, ButtonGroup, Stack } from "@mui/material"
import { BasketTopBar, ScheduleSelectorToolbar } from "slot-definitions"
import { ContextMenu, ContextMenuBar } from "lib/@components/context-menu-bar"
import { NotOnMobile, OnMobile } from "lib/@components/mobile"
import { conditionalComponent } from "lib/@components/slot/conditional-component"

ScheduleSelectorToolbar("*").plug(conditionalComponent(isABasket, <BasketTopToolBar />))

export const BasketContextMenu = ContextMenu("basket")

export function isABasket({ context: { parentId } }) {
    return parentId?.includes("-ScheduleBasket-")
}

function BasketTopToolBar(props) {
    return (
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
            <Stack direction="row" spacing={1} alignItems="center" flexWrap="wrap" justifyContent="center">
                <BasketTopBar.Slot type="start" {...props} />
                <NotOnMobile>
                    <Box flex={1} />
                </NotOnMobile>
                <NotOnMobile>
                    <ButtonGroup>
                        <BasketTopBar.Slot {...props} type="" />
                    </ButtonGroup>
                </NotOnMobile>
                <OnMobile>
                    <BasketTopBar.Slot {...props} Container={<Box sx={{ flexShrink: 1, py: 0.4 }} />} type="" />
                </OnMobile>
                <BasketTopBar.Slot type="end" {...props} />
                <Box flex={1} />
            </Stack>
            <ContextMenuBar type="basket" />
        </Stack>
    )
}
