import { Box, Button, ListItemButton, ListItemText } from "@mui/material"
import { AddToCore, AddToLibrary, RefreshMainTree } from "event-definitions"
import { ListItemBox } from "lib/@components/ListItemBox"
import noop from "lib/noop"
import { FolderIcon } from "routes/schedule/tree/schedules/folder-icon"
import { FolderItemPart } from "routes/schedule/tree/schedules/folder-item-part"
import { makeTreeItemsFromGroup } from "routes/schedule/tree/schedules/makeTreeItemsFromGroup"
import { findInChildren, parentSome, TreeRoot } from "library/tree"
import { SelectedChildren } from "routes/schedule/components/selected-children"
import { RootPlugs, ScheduleSelectorBeforeList } from "slot-definitions"
import { isSchedule } from "library/constants"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useTreeItemIndex } from "routes/schedule/lib/useTreeItemIndex"
import { showUnlicensed } from "routes/schedule/components/schedule-filter"
import Iconify from "minimals-template/components/Iconify"

function FunctionalSets({ last, Component = ListItemButton, label = "Functional Sets" }) {
    const { refresh, onClick } = useBoundContext()
    RefreshMainTree.useEvent(refresh)
    return (
        <Component divider={!last} onClick={onClick}>
            <ListItemBox spacing={1}>
                <FolderItemPart />
                <FolderItemPart>
                    <FolderIcon />
                </FolderItemPart>
                <Box flex={1}>{label}</Box>
            </ListItemBox>
        </Component>
    )
}

function FunctionalSet({ item, sx, unlicensed, treeSx, last, divider, icon = "ic:twotone-folder" }) {
    const schedules = findInChildren(item, isSchedule)
    const { onClick = noop, inTree } = useBoundContext()
    divider ??= !last && !inTree
    const useSx = { ...sx, ...(inTree ? treeSx : {}) }

    const unlicensedVisible = showUnlicensed() || unlicensed || parentSome(item, (p) => p.showUnlicensed)
    return (
        <ListItemButton size="small" dense sx={useSx} divider={divider} onClick={select} className="functional-set">
            <ListItemBox>
                <FolderItemPart>
                    <SelectedChildren />
                </FolderItemPart>
                <Box mr={1} />
                <FolderItemPart>
                    <FolderIcon
                        className={schedules.every((s) => s.notLicensed) ? "folder" : icon}
                        icon={schedules.every((s) => s.notLicensed) ? "mdi:folder" : icon}
                        sx={{
                            color:
                                !schedules.some((s) => s.notLicensed) || !unlicensedVisible
                                    ? "primary.main"
                                    : "unlicensed.dark",
                        }}
                    />
                </FolderItemPart>
                <Box
                    ml={1}
                    flex={1}
                    sx={{
                        color:
                            !schedules.every((s) => s.notLicensed) || !unlicensedVisible
                                ? undefined
                                : "unlicensed.dark",
                    }}
                >
                    {item.label}
                </Box>
            </ListItemBox>
        </ListItemButton>
    )

    function select() {
        if (schedules.length) {
            onClick()
        } else {
            onClick()
            errorSnackbar("You don't own that set")
        }
    }
}

// Functional Sets
AddToLibrary.handleOnce(async ({ library }) => {
    const specialist = {
        content: <FunctionalSets label="Specialist Sets" />,
        id: "functionalSets",
        priority: 75,
        label: "Specialist Sets",
        onExpand: noop,
        onCollapse: noop,
        showUnlicensed: true,
    }
    specialist.children = await makeTreeItemsFromGroup({
        tree: "specialist",
        isAlias: false,
        showUnlicensed: true,
        adaptId: createSpecialistId,
        useAsParent: specialist,
        makeGroup: (item) => [
            <FunctionalSet unlicensed={true} key={item.id} item={item} />,
            !!findInChildren(item, (c) => c.type === "schedule").length,
        ],
    })
    library.push(specialist)
})

function createSpecialistId(id, isGroup) {
    return !isGroup ? `${id.replace(/CS/g, "")}CS` : `${id}CS`
}

AddToCore.handleOnce(async ({ core }) => {
    const sets = await makeTreeItemsFromGroup({
        tree: "specialist",
        makeGroup: (item) => [
            <FunctionalSet divider={false} treeSx={{ ml: -2 }} key={item.id} item={item} />,
            !!findInChildren(item, isSchedule).length,
        ],
    })
    core.push(...sets)
})

RootPlugs.plug(<SpecialistTree />)

function SpecialistTree() {
    const index = useTreeItemIndex()
    if (index) {
        index.specialist = { id: "specialist", [TreeRoot]: true }
    }
    return null
}

AddToLibrary.handleOnce(async ({ library }) => {
    library.push({
        content: <FunctionalSets label="NRM" />,
        id: "nrm",
        priority: 115,
        label: "NRM",
        showUnlicensed: false,
        onExpand: noop,
        onCollapse: noop,
        children: await makeTreeItemsFromGroup({
            tree: "nrm",
            isAlias: true,
            makeGroup: (item) => [
                <FunctionalSet key={item.id} item={item} />,
                !!findInChildren(item, isSchedule).length,
            ],
        }),
    })
})

AddToLibrary.handleOnce(async ({ library }) => {
    library.push({
        content: <FunctionalSets label="Functional Sets" />,
        id: "functional",
        priority: 150,
        label: "Functional Sets",
        showUnlicensed: true,
        onExpand: noop,
        onCollapse: noop,
        children: await makeTreeItemsFromGroup({
            tree: "functional",
            isAlias: true,
            makeGroup: (item) => [
                <FunctionalSet key={item.id} item={item} />,
                !!findInChildren(item, isSchedule).length,
            ],
        }),
    })
})

AddToLibrary.handleOnce(async ({ library }) => {
    library.push({
        content: <FunctionalSets label="Legislation" />,
        id: "legislation",
        priority: 140,
        label: "Legislation",
        onExpand: noop,
        onCollapse: noop,
        children: await makeTreeItemsFromGroup({
            parent: "legislation",
            tree: "legislation",
            isAlias: true,
            makeGroup: (item) => [
                <FunctionalSet key={item.id} item={item} icon="ic:twotone-snippet-folder" />,
                !!findInChildren(item, isSchedule).length,
            ],
        }),
    })
})

AddToLibrary.handleOnce(async ({ library }) => {
    const functionalSetData = {
        content: <FunctionalSets label="Uniclass" />,
        id: "uniclass",
        priority: 120,
        label: "Uniclass",
        onExpand: noop,
        onCollapse: noop,
        children: await makeTreeItemsFromGroup({
            tree: "uniclass",
            isAlias: true,
            makeGroup: (item) => [
                <FunctionalSet key={item.id} item={item} />,
                !!findInChildren(item, isSchedule).length,
            ],
        }),
    }
    library.push(functionalSetData)
})

ScheduleSelectorBeforeList("*").plug(<SelectorURL if={(c) => c.parentItem?.$?.legislation && c.parentItem?.$.url} />)

function SelectorURL() {
    const { parentItem } = useBoundContext()

    return (
        <Box my={2}>
            <Button
                fullWidth
                sx={{ textAlign: "left" }}
                onClick={() => window.open(`https://${parentItem.$.url}`, "legislation")}
            >
                <ListItemBox>
                    <ListItemText primary={parentItem.$.label} />
                    <Box flex={1} />
                    <Box sx={{ ml: 1, color: "primary.main" }}>
                        <Iconify icon="ci:external-link" />
                    </Box>
                </ListItemBox>
            </Button>
        </Box>
    )
}
