import { registerFeature } from "lib/features"

const featureFlagDesc = `Download task lists to Excel.`
export const FEATURE_FLAG_DOWNLOAD_TASKS = registerFeature({
    numericalId: 3,
    name: "Download Tasks",
    id: "download-tasks",
    description: featureFlagDesc,
    readyForBeta: false,
    readyForProduction: true,
    internalUseOnly: false,
})
