import { gql } from "@apollo/client"
import { clone } from "lib/clone"
import noop from "lib/noop"
import { GROUP } from "library/constants"
import { parentTreeItem } from "library/tree"
import memoize from "memoizee"
import { GroupItem } from "routes/schedule/tree/schedules/groupItem"
import { mappingSort } from "routes/schedule/tree/schedules/mappingSort"
import { ScheduleItem } from "routes/schedule/tree/schedules/scheduleItem"
import { query } from "lib/graphql/query"
import { FlushAllCache } from "library/server-event-definitions"

const GET_TREE = gql`
    query tree($tree: String!) {
        groupToSchedules(tree: $tree) {
            groupTree
        }
    }
`

export { parentTreeItem }

export const getTreeItemIndexForTree = memoize(
    async function getTreeItemIndex(tree) {
        const data = await query(GET_TREE, { tree })
        const groupTree = clone(data?.groupToSchedules?.groupTree)
        const index = {}
        recurseAdd(groupTree)
        index[tree] = index[groupTree.id]
        return [groupTree || {}, index]

        function recurseAdd(item) {
            if (!item) return
            index[item.id] = item
            for (const child of item.children ?? []) {
                if (!(parentTreeItem in child)) {
                    child[parentTreeItem] = item
                }
                recurseAdd(child)
            }
        }
    },
    { promise: true, maxAge: 4000, primitive: true }
)

FlushAllCache.handleOnce(() => getTreeItemIndexForTree.clear())

export async function makeTreeItemsFromGroup({
    parent = "_root",
    tree = "sfg20",
    useAsParent,
    isAlias,
    adaptId = (v) => v,
    showUnlicensed,
    makeGroup = (item) => [<GroupItem key={item.id} group={item} />, true],
} = {}) {
    const [, index] = await getTreeItemIndexForTree(tree)
    if (tree === "sfg20") {
        console.log("SFG20", { index })
    }
    const parentNode = typeof parent === "string" ? index[parent] : parent
    return [...parentNode.children.map((child) => build(child, useAsParent)).sortBy(mappingSort)]

    function build(item, parent) {
        item = { ...item, id: adaptId(item.id) }
        switch (item.type) {
            case GROUP: {
                const [content, processChildren] = makeGroup(item)

                const parts = item.id.split("!") // ~sfg20!5
                const result = {
                    $: item,
                    id: adaptId(`${parts[1]}${parts[0]}`, true),
                    hasChildren: processChildren,
                    onExpand: noop,
                    onCollapse: noop,
                    showUnlicensed,
                    label: item.label,
                    content,
                }
                if (parent) {
                    result[parentTreeItem] = parent
                }
                result.children = processChildren
                    ? item.children.map((child) => build(child, result)).sortBy(mappingSort)
                    : undefined
                return result
            }
            default: {
                const result = {
                    $: item,
                    id: adaptId(item.id),
                    notLicensed: item.notLicensed,
                    showUnlicensed,
                    notInSkillSet: item.notInSkillSet,
                    _id: item._id,
                    hasChildren: false,
                    label: item.label,
                    code: item.code,
                    isAlias,
                    content: <ScheduleItem schedule={item} />,
                }
                if (parent) {
                    result[parentTreeItem] = parent
                }
                return result
            }
        }
    }
}
