import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getDisplayActions = api(async function getDisplayActions({ search, take, skip, type, filter }) {
    return query(
        gql`
            query GetDisplayActions($search: String, $take: Int, $skip: Int, $type: ActionType!, $filter: String) {
                getDisplayActions(skip: $skip, search: $search, take: $take, type: $type, filter: $filter) {
                    total
                    recent
                    all
                    overdue
                    starred
                    silenced
                    actions {
                        _id
                        type
                        title
                        dueDate
                        payload
                        created
                        referenceId
                        completedBy {
                            id
                        }
                        completedOn
                    }
                }
            }
        `,
        { search, take, skip, type, filter },
        { returns: "getDisplayActions" }
    )
})
