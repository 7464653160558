import { HeaderBarRight } from "slot-definitions"
import { Box, Stack, Tooltip, Typography } from "@mui/material"
import { createEvent } from "library/local-events"
import { useState } from "react"
import Iconify from "minimals-template/components/Iconify"

export const ToBeSaved = createEvent("ToBySaved")

export function SaveIndicator({ id }) {
    return (
        <HeaderBarRight.Plug>
            <InternalIndicator key={id} priority={-200} id={id} />
        </HeaderBarRight.Plug>
    )
}

function InternalIndicator({ id }) {
    const [changes, setChanges] = useState(false)
    ToBeSaved(id).useEvent((value) => {
        setChanges(value)
    })
    const tooltip =
        changes && !(changes instanceof Error)
            ? "Save will complete if you leave the page, but not if you close the browser"
            : undefined
    return (
        <Tooltip arrow title={tooltip}>
            <Box sx={{ color: "text.primary", minWidth: 80 }} priority={-200} data-cy="save-indicator">
                <Stack direction="row" spacing={1} sx={{ color: "text.secondary" }} alignItems="center">
                    <Box sx={{ lineHeight: 0 }}>
                        {changes && !(changes instanceof Error) && (
                            <Iconify sx={{ color: "warning.main" }} icon="teenyicons:save-solid" />
                        )}
                        {changes && changes instanceof Error && (
                            <Iconify sx={{ color: "error.main" }} icon="teenyicons:save-solid" />
                        )}
                        {!changes && <Iconify sx={{ color: "success.main" }} icon="mdi:tick" />}
                    </Box>
                    <Typography variant="body2" component="div">
                        {changes && !(changes instanceof Error) && "Pending"}
                        {changes && changes instanceof Error && "Error"}
                        {!changes && "Saved"}
                    </Typography>
                </Stack>
            </Box>
        </Tooltip>
    )
}
