import { useReferenceState } from "lib/@hooks/use-reference"
import { StandardCombo } from "lib/@components/combos/standardCombo"
import { filterStates } from "../regime-access-config"

const filterStateKeys = Object.keys(filterStates)

export function AccessFilter() {
    const [accessFilter, save] = useReferenceState("accessFilter", filterStates.All)
    return (
        <StandardCombo
            data-cy="access-filter"
            label="Filter Access"
            onChange={setFilter}
            getOptionLabel={(o) => o.titleize()}
            options={filterStateKeys}
            value={accessFilter.label}
        />
    )
    function setFilter(e) {
        const state = e.target.value
        save(filterStates[state])
    }
}
