const stringSimilarity = require("string-similarity")

/**
 * This function is designed to check whether all words in a search string have a sufficient similarity to some words in a target string.
 *
 * NB: Due to the preprocessing of the strings, this function will return search strings that don't seem to match the target string.
 * This is intentional.
 *
 * @param {string} target - Target string.
 * @param {string} search - String to compare with target.
 * @param {number} match - Accuracy threshold for compared strings.
 * @returns {boolean} - Returns true if target and search compare, otherwise returns false.
 */

exports.compareStrings = (target, search, match = 0.95) => {
    if (!search) return true
    target = target
        .toString()
        .toLowerCase()
        .trim()
        .replace(/(\s|\W)+/g, " ")
        .replace(/z/g, "s")
        .replace(/y/g, "i")
        .replace(/[aeiou]/g, "a")
        .replace(/[^\w\s]|(.)(?=\1)/gi, "")
    search = search
        .toLowerCase()
        .trim()
        .replace(/(\s|\W)+/g, " ")
        .replace(/z/g, "s")
        .replace(/y/g, "i")
        .replace(/[aeiou]/g, "a")
        .replace(/[^\w\s]|(.)(?=\1)/gi, "")

    const set1 = target.split(" ")
    return search
        .split(" ")
        .every((c) => set1.some((s) => stringSimilarity.compareTwoStrings(c, s.slice(0, c.length)) >= match))
}

exports.compareByInclusion = (target, search) => {
    if (!search?.trim()) return true
    return target.toLowerCase().includes(search.toLowerCase())
}
