import { Bound } from "lib/@components/binding/Bound"
import { usePayloadTask } from "routes/facilities/lib/use-payload-task"
import { BlockClicks } from "lib/@components/block-clicks"
import { Different } from "routes/regime/components/different"
import { SkillSet } from "routes/schedule/tasks/skill-set"
import { SummaryDataChanged } from "event-definitions"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Payload } from "routes/schedule/controller/payload"
import { assessStatus } from "routes/schedule/assess-status"

export function EditSkills() {
    const { target, schedule, payload } = useBoundContext()
    const task = usePayloadTask()
    return (
        <Payload>
            <BlockClicks>
                <Bound onChange={validate} editMode={true} target={task}>
                    <Different field="skillSet" comparedTo={target}>
                        <SkillSet defaultSkillSet={target.skillSet} />
                    </Different>
                </Bound>
            </BlockClicks>
        </Payload>
    )

    function validate() {
        assessStatus(payload, schedule)
        SummaryDataChanged.raiseOnce()
    }
}
