import { useEffect } from "react"
import { ScheduleBottom, ScheduleTopmost } from "routes/schedule/scheduleIntroduction"
import { Pictures } from "routes/regime/components/pictures"
import { DisplayInventory } from "routes/regime/assets/display-inventory"
import { extendSchedule } from "library/extend-schedule"
import { AdaptSchedule, RefreshSchedule } from "event-definitions"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function DisplayUpdates({ children }) {
    const { payload } = useBoundContext()
    useEffect(() => {
        if (payload) {
            RefreshSchedule.raise()
        }
    }, [payload])
    AdaptSchedule.useEvent(
        ({ schedule }) => {
            if (!schedule) return
            extendSchedule(schedule, payload)
        },
        [payload]
    )
    ScheduleTopmost.usePlug(<DisplayInventory priority={1} />)
    ScheduleBottom.usePlug(<Pictures priority={10} />)

    return children
}
