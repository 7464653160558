/* eslint-disable */
import { noop } from "library/noop"
import { Editor } from "minimals-template/components/editor"
import { bind } from "lib/@components/binding/bind"

export const BoundDocumentEditor = bind(<DocumentEditor />, {
    extract: (v) => v,
})

// eslint-disable-next-line no-unused-vars
export function DocumentEditor({ value, onChange, onScroll = noop, onEditor = noop, config = {}, ...props }) {
    return <Editor {...props} value={value} onChange={onChange} />
}
