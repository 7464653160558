import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"

export const getClientUsersForRegimeAccess = api(async ({ regimeId }) =>
    query(
        gql`
            query GetClientUsersForRegimeAccess($regimeId: String!) {
                getClientUsersForRegimeAccess(regimeId: $regimeId) {
                    userId
                    displayName
                    userName
                    photoURL
                    demands
                    jobTitle
                    hasAccess
                    isCurrentUser
                    disabled
                }
            }
        `,
        { regimeId },
        { returns: "getClientUsersForRegimeAccess", fetchPolicy: "network-only" }
    )
)
