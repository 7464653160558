import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const maintenanceReports = api(({ fromDate, regime, group, done, notDone, toDate, search, skip, take }) =>
    query(
        gql`
            query MaintenanceReports(
                $regime: String!
                $group: String
                $fromDate: Date
                $toDate: Date
                $search: String
                $skip: Int
                $take: Int
                $done: Boolean
                $notDone: Boolean
            ) {
                maintenanceReports(
                    regime: $regime
                    group: $group
                    fromDate: $fromDate
                    toDate: $toDate
                    search: $search
                    skip: $skip
                    take: $take
                    done: $done
                    notDone: $notDone
                ) {
                    total
                    reports {
                        id
                        taskId
                        schedule
                        regime
                        report
                        reportDate
                        group
                        attachments
                        metadata
                        user
                        closedDate
                        closedBy
                    }
                }
            }
        `,
        {
            fromDate,
            toDate,
            search,
            skip,
            done,
            notDone,
            take,
            regime,
            group,
        },
        {
            returns: "maintenanceReports",
        }
    )
)
