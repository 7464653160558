import { Box, FormControlLabel, MenuItem, Pagination, Select, Typography } from "@mui/material"
import { defaultNumberOfRecords } from "lib/@components/pagination/default-number-of-records"

export function TablePaginator({ items, isMobile, pageSize, page, setPage, setPageSize }) {
    return (
        <Box sx={{ display: "flex" }} py={1} data-cy="tabled-repeat-paginator">
            <Box flexGrow={1} />
            <FormControlLabel
                label={<Typography variant="body2">Rows per page: </Typography>}
                labelPlacement="start"
                control={
                    <Select
                        sx={{ mr: 2, ml: 2, fontSize: isMobile ? 16 : 13 }}
                        variant="standard"
                        labelId="rows-per-page-label"
                        value={pageSize}
                        onChange={(event) => {
                            setPageSize(event.target.value)
                            setPage(1)
                        }}
                    >
                        {defaultNumberOfRecords.map((rowCountValue) => (
                            <MenuItem key={rowCountValue} value={rowCountValue}>
                                {rowCountValue}
                            </MenuItem>
                        ))}
                    </Select>
                }
            />
            <Pagination
                size={isMobile ? "large" : "small"}
                onChange={(event, value) => setPage(value)}
                count={Math.ceil(items.length / pageSize)}
                color="primary"
                page={page}
            />
        </Box>
    )
}
