import { ScheduleItemBeneath, ScheduleItemCode, ScheduleTreeBar } from "slot-definitions"
import { isInRegime, not, notInSelector } from "routes/facilities/lib/when-parent-item"
import { Details } from "routes/schedule/plugins/derived-schedules/details"
import { and } from "lib/logic"
import { DerivedSchedules } from "routes/schedule/plugins/derived-schedules/derived-schedules"
import { ScheduleEmbed } from "lib/@components/schedule-embed"
import { WithDemand } from "lib/authorization/secure"

ScheduleItemCode.plug(
    <WithDemand demand="scheduleEditor" if={and(notInSelector, not(isInRegime))} priority={50}>
        <DerivedSchedules $lazy />
    </WithDemand>
)
ScheduleTreeBar.plug(
    <WithDemand demand="scheduleEditor">
        <ScheduleEmbed>
            <DerivedSchedules $lazy />
        </ScheduleEmbed>
    </WithDemand>
)

ScheduleItemBeneath.plug(<Details $lazy if={and(not(isInRegime), notInSelector)} />)
