import { useState } from "react"
import { useRefresh } from "lib/@hooks/useRefresh"
import { Bound } from "lib/@components/binding/Bound"
import { DialogContent, DialogTitle, Stack, TextField } from "@mui/material"
import { setFromEvent } from "lib/setFromEvent"
import { RHFUploadSingleFile } from "minimals-template/components/hook-form"
import { OkCancel } from "lib/@components/okCancel"

export function RenameDialog({ currentName, currentImage, type = "Group", title = "Rename" }) {
    const [name, setName] = useState(currentName)
    const [data] = useState({ image: currentImage })
    const refresh = useRefresh()
    return (
        <Bound target={data} refresh={refresh}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Stack spacing={1}>
                    <TextField
                        autoFocus
                        fullWidth
                        sx={{ mt: 2 }}
                        placeholder={`${type} name...`}
                        variant="outlined"
                        value={name}
                        onChange={setFromEvent(setName)}
                        label={`${type} Name`}
                    />
                    <RHFUploadSingleFile refresh={refresh} name="image" />
                </Stack>
            </DialogContent>
            <OkCancel value={{ name, image: data?.image }} />
        </Bound>
    )
}
