import { useState } from "react"
import { Alert, AlertTitle, Snackbar } from "@mui/material"
import { ShowNotification } from "event-definitions"

/**
 * Shows a popup notification to the user in a snack bar
 * @param {string} message - the message to show
 * @param {object} alertProps - the properties of the alert shown (normally severity)
 * @returns {boolean}
 */
export function showNotification(message, alertProps = { severity: "info" }) {
    ShowNotification.raise(message, alertProps)
    return true
}

export function SnackBars() {
    const [open, setOpen] = useState(false)
    const [content, setContent] = useState({})
    ShowNotification.useEvent((message, alertProps = {}) => {
        setOpen(false)
        setContent({ message, alertProps })
        setOpen(true)
    })
    const { title, ...alertProps } = content.alertProps ?? {}
    return (
        !!content && (
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                open={open}
                autoHideDuration={3000}
                onClose={() => setOpen(false)}
            >
                <Alert onClose={() => setOpen(false)} {...alertProps}>
                    {title ? <AlertTitle>{title}</AlertTitle> : null}
                    {content.message}
                </Alert>
            </Snackbar>
        )
    )
}
