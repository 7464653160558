import { BoundStandardSwitch } from "lib/@components/binding/bound-components"
import { Feature } from "lib/@components/feature"
import { FEATURE_FLAG_DOWNLOAD_SCHEDULE_PDF } from "./download-schedule-pdf.runtime"
import { useBoundValue } from "lib/@components/binding/use-bound-value"
import { Alert } from "@mui/material"

export function DownloadSchedulePDFShareLinkSetting() {
    const [canDownloadSchedulePDF] = useBoundValue("canDownloadSchedulePDF")

    return (
        <Feature feature={FEATURE_FLAG_DOWNLOAD_SCHEDULE_PDF}>
            <BoundStandardSwitch sideEffects field="canDownloadSchedulePDF" label="Can Download Print" />
            {canDownloadSchedulePDF && (
                <Alert severity="warning">
                    You are allowing your company&apos;s private data to be printed by all people with access to this
                    sharelink
                </Alert>
            )}
        </Feature>
    )
}
