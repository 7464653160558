import { Box, Divider, Stack, Tooltip, useMediaQuery, useTheme } from "@mui/material"
import { HelpTagsAvailable, HelpTagsRemoved, RefreshNavBar } from "event-definitions"
import { Bound } from "lib/@components/binding/Bound"
import { DoneWithIcon } from "lib/@components/doneWithIcon"
import { useMobile } from "lib/@components/mobile"
import { getReference, setReference, useReferenceState } from "lib/@hooks/use-reference"
import { OkCancelContext, openDialog } from "lib/@hooks/useDialog"
import { useRefresh } from "lib/@hooks/useRefresh"
import { ensureArray } from "lib/ensure-array"
import { MapPlus } from "library/map-plus"
import { IconButtonAnimate } from "minimals-template/components/animate"
import Iconify from "minimals-template/components/Iconify"
import { useContext, useEffect, useRef } from "react"
import { AfterMainStyle, HeaderBarRight } from "slot-definitions"
import { HelpContainer } from "./helpContainer"
import { RightDrawer } from "lib/@components/drawer/right-drawer"
import { FlushAllCache } from "library/server-event-definitions"

AfterMainStyle.plug(<Help />)

HeaderBarRight.plug(<HelpButton />)

function HelpButton() {
    const [showHelp] = useReferenceState("showHelp", false, "help")

    return (
        <Tooltip title="Help Centre">
            <IconButtonAnimate data-cy="HelpButton" onClick={toggleHelp} color={showHelp ? "primary" : undefined}>
                <Iconify icon="ic:round-help" />
            </IconButtonAnimate>
        </Tooltip>
    )
}

export function toggleHelp() {
    const { showHelp } = getReference({ showHelp: false })
    if (window.innerWidth < window.currentTheme.breakpoints.values.lg) {
        openDialog(<HelpDialog />, { fullScreen: true }).catch(console.error)
    } else {
        setReference({ showHelp: !showHelp }, "help")
        RefreshNavBar.raiseOnce()
    }
    return false
}

const currentTags = new MapPlus(() => 0)
FlushAllCache.handle(() => currentTags.clear())

function Help() {
    const [showHelp, setShowHelp] = useReferenceState("showHelp", false, "help")

    const isMobile = useMobile()

    const keys = useRef()
    const refresh = useRefresh()

    HelpTagsAvailable.useEvent((tags) => {
        tags = tags.map(ensureArray).flat(Infinity)
        tags.forEach((tag) => currentTags.set(tag, currentTags.get(tag) + 1))
        updateHelpKeys()
    })

    HelpTagsRemoved.useEvent((tags) => {
        tags = tags.map(ensureArray).flat(Infinity)
        tags.forEach((tag) => currentTags.set(tag, currentTags.get(tag) - 1))
        updateHelpKeys()
    })

    function updateHelpKeys() {
        const newKeys = Array.from(currentTags.entries())
            .filter(([, value]) => value > 0)
            .map("0")

        if (newKeys !== keys.current) {
            keys.current = newKeys
            refresh()
        }
    }

    const theme = useTheme()
    const isLarge = useMediaQuery(theme.breakpoints.up("xl"))

    const helpContent = (
        <Box
            id="helpCentrePanel"
            className="styled-bullets"
            sx={{
                width: 1,
                overflowX: "hidden",
                overflowY: "scroll",
                px: isLarge ? 1 : 0,
                pl: isLarge ? 1 : 1.2,
                pb: isLarge ? 3 : 0,
            }}
        >
            <HelpContainer />
        </Box>
    )

    return (
        !isMobile &&
        (isLarge ? (
            showHelp && (
                <Bound helpTags={keys.current}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            overflow: "hidden",
                            height: 1,
                            maxHeight: `calc(100vh - 30px)`,
                            paddingTop: "92px",
                            width: "30vw",
                            paddingRight: "8px !important",
                            minWidth: 600,
                            maxWidth: 850,
                        }}
                    >
                        <Stack direction="row" sx={{ overflowY: "scroll" }}>
                            <Divider orientation="vertical" flexItem sx={{ borderStyle: "dashed", padding: 0 }} />
                            {helpContent}
                        </Stack>
                    </Box>
                </Bound>
            )
        ) : (
            <Bound helpTags={keys.current}>
                <RightDrawer
                    open={showHelp}
                    setOpen={setShowHelp}
                    sx={{ padding: 0, py: 1, borderRadius: 2 }}
                    keepMounted
                >
                    <Box sx={{ zoom: 0.75 }}>{helpContent}</Box>
                </RightDrawer>
            </Bound>
        ))
    )
}

function HelpDialog() {
    const isMobile = useMobile()
    const okCancel = useContext(OkCancelContext)
    const { ok } = okCancel

    useEffect(() => {
        if (!isMobile) {
            ok()
        }
    }, [isMobile, ok])

    return (
        <Box sx={{ zoom: 0.75, p: 0.5 }}>
            <HelpContainer />

            <DoneWithIcon ok={ok} okCaption="Exit" icon="ic:round-cancel" />
        </Box>
    )
}
