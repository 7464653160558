import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { extendSchedule } from "library/extend-schedule"
import { PayloadId } from "routes/schedule/controller/payloads"
import { Bound } from "lib/@components/binding/Bound"
import { clone } from "lib/clone"

export function ExtendSummarySchedule({ children }) {
    const { payload, target } = useBoundContext()
    const extendedTarget = clone(target)
    extendSchedule(extendedTarget, payload ?? {})
    if (!payload) return children

    extendedTarget[PayloadId] = payload?.[PayloadId]
    return <Bound target={extendedTarget}>{children}</Bound>
}
