import PropTypes from "prop-types"
import { useDropzone } from "react-dropzone"
// @mui
import { styled } from "@mui/material/styles"
import { Box, Button, Stack } from "@mui/material"
//
import BlockContent from "minimals-template/components/upload/BlockContent"
import RejectionFiles from "minimals-template/components/upload/RejectionFiles"
import MultiFilePreview from "minimals-template/components/upload/MultiFilePreview"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

// ----------------------------------------------------------------------

const DropZoneStyle = styled("div")(({ theme }) => ({
    outline: "none",
    padding: theme.spacing(5, 1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.neutral,
    border: `1px dashed ${theme.palette.grey[500_32]}`,
    "&:hover": { opacity: 0.72, cursor: "pointer" },
}))

// ----------------------------------------------------------------------

UploadMultiFile.propTypes = {
    files: PropTypes.array.isRequired,
    error: PropTypes.bool,
    showPreview: PropTypes.bool,
    onUpload: PropTypes.func,
    onRemove: PropTypes.func,
    onRemoveAll: PropTypes.func,
    helperText: PropTypes.node,
    sx: PropTypes.object,
}

export default function UploadMultiFile({
    error,
    showPreview = false,
    files,
    onUpload,
    onRemove,
    onRemoveAll,
    helperText,
    sx,
    ...other
}) {
    const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
        ...other,
    })
    const { readOnly } = useBoundContext()
    return (
        <Box sx={{ width: "100%", ...sx }}>
            {!readOnly && (
                <DropZoneStyle
                    {...getRootProps()}
                    sx={{
                        ...(isDragActive && { opacity: 0.72 }),
                        ...((isDragReject || error) && {
                            color: "error.main",
                            borderColor: "error.light",
                            bgcolor: "error.lighter",
                        }),
                    }}
                >
                    <input {...getInputProps()} />

                    <BlockContent />
                </DropZoneStyle>
            )}

            {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

            <MultiFilePreview files={files} showPreview={showPreview} onRemove={!readOnly ? onRemove : undefined} />

            {files.length > 0 && !readOnly && (
                <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
                    {!!onRemoveAll && (
                        <Button color="inherit" size="small" onClick={onRemoveAll}>
                            Remove all
                        </Button>
                    )}
                    {!!onUpload && (
                        <Button size="small" variant="contained" onClick={onUpload}>
                            Upload files
                        </Button>
                    )}
                </Stack>
            )}

            {helperText && helperText}
        </Box>
    )
}
