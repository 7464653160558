/**
 * Converts the case of a string, an array of strings, or the string properties of an object.
 * @param {*} v - The value to be converted. Can be a string, array of strings or objects, or an object.
 * @param {string} caseOption - The type of conversion. Can be "upper", "lower", or "kebab".
 * @returns {*} - The converted value.
 * @throws {Error} - Throws an error if the caseOption is not "upper", "lower", or "kebab".
 */
export function convertCase(v, caseOption = "upper") {
    let conversionFunction

    switch (caseOption) {
        case "upper":
            conversionFunction = (s) => (typeof s === "string" ? s.toUpperCase() : s)
            break
        case "lower":
            conversionFunction = (s) => (typeof s === "string" ? s.toLowerCase() : s)
            break
        case "email":
            conversionFunction = (s) => (typeof s === "string" ? s.replace(/\s+/g, "").toLowerCase() : s)
            break
        case "kebab":
            conversionFunction = (s) => (typeof s === "string" ? s.toLowerCase().replace(/\s+/g, "-") : s)
            break
        default:
            throw new Error(`Invalid caseOption: ${caseOption}. Valid options are 'upper', 'lower', 'kebab'.`)
    }

    if (typeof v === "string") {
        return conversionFunction(v)
    }

    if (Array.isArray(v)) {
        return v.map((item) => convertCase(item, caseOption))
    }

    if (typeof v === "object" && v !== null) {
        const result = {}

        for (const key in v) {
            if (Object.prototype.hasOwnProperty.call(v, key)) {
                result[key] = convertCase(v[key], caseOption)
            }
        }

        return result
    }

    return v
}

export const convertToLowercase = (v) => (v ? convertCase(v, "lower") : v)
export const convertToLowercaseEmail = (v) => (v ? convertCase(v, "email") : v)
export const convertToUppercase = (v) => (v ? convertCase(v, "upper") : v)
export const convertToKebabCase = (v) => (v ? convertCase(v, "kebab") : v)
