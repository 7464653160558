import { gql } from "@apollo/client"
import { addItemsToBasket } from "routes/schedule/plugins/baskets/controller/addItemsToBasket"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { mutate } from "lib/graphql/mutate"

export async function createBasket(name, items) {
    try {
        if (!name) return undefined
        const id = await mutate(
            gql`
                mutation createBasket($name: String!) {
                    createBasket(name: $name, type: "ScheduleBasket", visible: true) {
                        id
                    }
                }
            `,
            { name },
            { returns: "createBasket.id" }
        )
        if (items) {
            await addItemsToBasket(id, items)
        }
        return id
    } catch (e) {
        console.error(e)
        errorSnackbar(e.message)
        return undefined
    }
}
