import { RegisterSystemTask } from "event-definitions"
import { handle } from "library/local-events"
import { warningSnackbar } from "lib/snackbar/warning-snackbar"
import { TaskEditor } from "slot-definitions"
import { Frag } from "lib/@components/slot/frag"
import { replaceAllWith } from "lib/@components/slot/replace-all-with"
import { successSnackbar } from "lib/snackbar/success-snackbar"
import { mutate } from "lib/graphql/mutate"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { gql } from "@apollo/client"
import { getCurrentClientId } from "minimals-template/components/contexts/NognitoContext"

RegisterSystemTask.handleOnce(({ tasks }) => {
    const task = {
        name: " [Win-723] Inject Regime with $ in Index*",
        event: "inject-dollar-regime",
        params: {},
    }
    tasks.push(task)
})

handle("inject-dollar-regime", async () => {
    const client = getCurrentClientId()

    warningSnackbar("[WIN-723] - Inject Regime with $ in Index")
    if (
        await mutate(
            gql`
                mutation LoadRecords($records: [JSONObject], $originalClient: Boolean, $merge: Boolean) {
                    loadRecords(rows: $records, originalClient: $originalClient, merge: $merge)
                }
            `,
            {
                records: [
                    {
                        __: "OmluAkCa8gzFfi",
                        _id: "live|YnMHRSRHKzc$c1:sfg20/plans",
                        date: "2024-07-15T23:00:00.000Z",
                        _state: "default",
                        baseId: "YnMHRSRHKzc$c1",
                        client,
                        version: 0,
                        _created: "2024-07-16T12:29:13.000Z",
                        _deleted: 0,
                        lastDate: "2024-07-15T23:59:59.999Z",
                        _modified: "2024-07-16T12:34:45.000Z",
                        _behaviours: {
                            instances: {},
                        },
                        publishedId: "YnMHRSRHKzc$c1-ElKO$Zjt5zwLoZ:sfg20/plan-history",
                        $permissions: {
                            creator: "KZ7ItzK4_Y5zhw",
                            lastModifiedBy: "KZ7ItzK4_Y5zhw",
                        },
                    },
                    {
                        __: "f_F9suDhBip$mq",
                        _id: "YnMHRSRHKzc$c1:sfg20/plans",
                        date: "2024-07-15T23:00:00.000Z",
                        _state: "default",
                        baseId: "YnMHRSRHKzc$c1",
                        client,
                        version: 1,
                        _created: "2024-07-16T12:29:07.000Z",
                        _deleted: 0,
                        _modified: "2024-07-16T12:29:07.000Z",
                        _behaviours: {
                            instances: {},
                        },
                        $permissions: {
                            creator: "KZ7ItzK4_Y5zhw",
                            lastModifiedBy: "KZ7ItzK4_Y5zhw",
                        },
                    },
                    {
                        __: "joozD9n9$gax8l",
                        _id: "live|YnMHRSRHKzc$c1:sfg20/tree",
                        name: "Dollar Index Regime",
                        tree: {
                            id: "_root",
                            type: "group",
                            label: "Dollar Index Regime",
                            children: [
                                {
                                    id: "rjyrUvnlidCnM$",
                                    data: {
                                        name: "Facility 1",
                                        type: "facility",
                                        location: null,
                                        statuses: {},
                                    },
                                    type: "group",
                                    label: "Facility 1",
                                    visible: true,
                                    children: [
                                        {
                                            id: "rjyrUvnlidCnM$!79974",
                                            _id: "79974:sfg20/schedule",
                                            type: "schedule",
                                            version: "1",
                                        },
                                    ],
                                },
                            ],
                        },
                        _state: "default",
                        baseId: "YnMHRSRHKzc$c1",
                        client,
                        version: 0,
                        _created: "2024-07-16T12:29:13.000Z",
                        _deleted: 0,
                        payloads: {
                            "rjyrUvnlidCnM$!79974": {
                                units: {},
                                assetRegisters: [],
                            },
                        },
                        _modified: "2024-07-16T12:29:13.000Z",
                        _behaviours: {
                            instances: {
                                treeSchedules: [{}],
                            },
                        },
                        publishedId: "YnMHRSRHKzc$c1-ElKO$Zjt5zwLoZ:sfg20/plan-history",
                        $permissions: {
                            creator: "KZ7ItzK4_Y5zhw",
                            lastModifiedBy: "KZ7ItzK4_Y5zhw",
                        },
                        schedules_all: {
                            _79974: 1,
                        },
                        schedules_tailored: {
                            _79974: 1,
                        },
                    },
                    {
                        __: "j0T__i0iHBcl$4u",
                        _id: "YnMHRSRHKzc$c1:sfg20/tree",
                        name: "Dollar Index Regime",
                        tree: {
                            id: "_root",
                            type: "group",
                            label: "Dollar Index Regime",
                            children: [
                                {
                                    id: "rjyrUvnlidCnM$",
                                    data: {
                                        name: "Facility 1",
                                        type: "facility",
                                        location: null,
                                        statuses: {},
                                    },
                                    type: "group",
                                    label: "Facility 1",
                                    visible: true,
                                    children: [
                                        {
                                            id: "rjyrUvnlidCnM$!79974",
                                            _id: "79974:sfg20/schedule",
                                            type: "schedule",
                                            version: "1",
                                        },
                                    ],
                                },
                            ],
                        },
                        _state: "default",
                        baseId: "YnMHRSRHKzc$c1",
                        client,
                        version: 1,
                        _created: "2024-07-16T12:28:34.000Z",
                        _deleted: 0,
                        payloads: {
                            "rjyrUvnlidCnM$!79974": {
                                units: {},
                                assetRegisters: [],
                            },
                        },
                        _modified: "2024-07-16T12:29:07.000Z",
                        _behaviours: {
                            instances: {
                                treeSchedules: [{}],
                            },
                        },
                        $permissions: {
                            creator: "KZ7ItzK4_Y5zhw",
                            lastModifiedBy: "KZ7ItzK4_Y5zhw",
                        },
                        schedules_all: {
                            _79974: 1,
                        },
                        schedules_tailored: {
                            _79974: 1,
                        },
                    },
                ],
            },
            { returns: "loadRecords" }
        ).catch((e) => {
            console.error(e)
            errorSnackbar(e.message)
        })
    ) {
        successSnackbar("Completed successfully")
    } else {
        errorSnackbar("Could not inject")
    }
})

TaskEditor("inject-dollar-regime").plug(replaceAllWith(<Frag />))
