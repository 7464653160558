import { TreeRoot } from "library/tree"
import { parentTreeItem } from "routes/schedule/tree/schedules/makeTreeItemsFromGroup"
import { useTreeItem } from "routes/schedule/lib/useTreeItem"

export function findRootFrom(item) {
    if (!item) return null
    while (!item[TreeRoot] && !!item[parentTreeItem]) {
        item = item[parentTreeItem]
    }
    return item
}

export function useStack(item) {
    let scan = useTreeItem(item, true)
    const stack = []

    while (scan && !scan[TreeRoot]) {
        scan = scan[parentTreeItem]
        if (scan) {
            stack.push(scan.id)
        }
    }
    return stack
}
