import { TextRun } from "docx"
import { styles } from "../legacy-export-docx-consts"

export function formatTextAndAddToParagraph(paragraph, textSegments) {
    const tagsInString = /<\/?[^>]+(>|$)/g
    const breakTagVariants = /<BR\s*\/?>/gi
    const aPairOfTags = /(<\/?[^>]+(>|$))/
    const trailingOrLeadingCommasOrAngleBrackets = /(^,|,$|^[<>]+|[<>]+$)/g
    let textRun
    for (let i = 0; i < textSegments.length; i++) {
        let segment = textSegments[i]
        const parts = segment.split(aPairOfTags)
        let currentStyle = {
            font: styles.FONT,
            subScript: false,
            superScript: false,
        }
        parts.forEach((part) => {
            if (part.trim() === "") return
            if (part.match(/^<EM>/)) {
                currentStyle.font = styles.FONT_ITALIC
            } else if (part.match(/<\/EM>$/)) {
                currentStyle.font = styles.FONT
            } else if (part.match(/^<(STRONG|B)>/)) {
                currentStyle.font = styles.FONT_BOLD
            } else if (part.match(/<\/(STRONG|B)>$/)) {
                currentStyle.font = styles.FONT
            } else if (part.match(/^<SUB>/)) {
                currentStyle.subScript = true
            } else if (part.match(/<\/SUB>$/)) {
                currentStyle.subScript = false
            } else if (part.match(/^<SUP>/)) {
                currentStyle.superScript = true
            } else if (part.match(/<\/SUP>$/)) {
                currentStyle.superScript = false
            } else {
                let textRunOptions = {
                    text: part.replace(tagsInString, "").replace(trailingOrLeadingCommasOrAngleBrackets, ""),
                    ...currentStyle,
                }
                textRun = new TextRun(textRunOptions)
                paragraph.addChildElement(textRun)
            }
            const breakParts = part.split(breakTagVariants)
            breakParts.forEach((index) => {
                if (index < breakParts.length - 1) {
                    const emptyTextRun = new TextRun({ text: "", break: 1 })
                    paragraph.addChildElement(emptyTextRun)
                }
            })
        })
    }
}
