import React, { useContext } from "react"
import { ensureArray } from "lib/ensure-array"
import { noop } from "lib/noop"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Else } from "lib/@components/else"

const SwitchContext = React.createContext()

export function Switch({ value, children }) {
    return <SwitchContext.Provider value={{ value, cases: {} }}>{children}</SwitchContext.Provider>
}

export function If({ value, truthy, field, children, equals, not, ...props }) {
    props.then = props.then || children || []
    const { target, ...contextProps } = useBoundContext()
    const targetValue = Object.get(target, field) ?? Object.get(contextProps, field)
    value = value ?? truthy ?? targetValue
    props.then = React.Children.map(props.then, (v, i) => {
        if (!!v && typeof v === "object") {
            return <v.type {...v.props} key={v.key ?? i} />
        } else {
            return v
        }
    })
    props.else ??= [props.then.find((c) => c?.type === Else)].filter(Boolean)
    props.then = ensureArray(props.then).filter((c) => c?.type !== Else && c?.type?.name !== "Else")

    const condition = equals ? value === equals : !!value
    // eslint-disable-next-line no-nested-ternary
    return not
        ? !condition
            ? props.then || null
            : props.else || null
        : condition
          ? props.then || null
          : props.else || null
}

export function Case({ when, then, children, execute = noop }) {
    const toCheck = ensureArray(when)
    const { value, cases } = useContext(SwitchContext)
    const condition = toCheck.some((whenCondition) => {
        if (typeof whenCondition === "function") {
            return whenCondition(value)
        }
        return whenCondition === value
    })

    cases[`${when}`] = condition
    if (condition) {
        execute()
        return <>{then || children}</>
    }
    return null
}

export function CaseElse({ children, then }) {
    const { cases } = useContext(SwitchContext)
    if (!Object.values(cases).some((v) => !!v)) {
        return <>{then || children}</>
    }
    return null
}
