import { usePayloadTask } from "routes/facilities/lib/use-payload-task"
import { Bound } from "lib/@components/binding/Bound"
import { Payload } from "routes/schedule/controller/payload"

export function PayloadTask({ children }) {
    const task = usePayloadTask()
    return (
        <Payload>
            <Bound target={task}>{children}</Bound>
        </Payload>
    )
}
