import { Alert, Box } from "@mui/material"
import { getCurrentClientId } from "minimals-template/components/contexts/NognitoContext"
import { uniqueId } from "library/database/split-id"
import { getAdoptClientRestriction } from "../controller/get-adopt-client-restriction"
import { Frag } from "lib/@components/slot/frag"
import { NotInPdf } from "lib/@components/pdf/not-in-pdf"
import { Secure } from "lib/authorization/secure"
import { SuccessButton } from "lib/@components/colored-buttons/success-button"
import { WarningButton } from "lib/@components/colored-buttons/warning-button"
import { navigate } from "lib/routes/navigate"
import { setReference } from "lib/@hooks/use-reference"
import { useEffect, useState } from "react"
import { useInterval } from "lib/@hooks/useInterval"
import { isSessionActiveFunc } from "./is-session-active"

export function SessionAlertBanner() {
    const clientId = uniqueId(getCurrentClientId())
    const adoptClientRestriction = getAdoptClientRestriction.useResults(clientId)
    const [active, setActive] = useState(false)

    useEffect(() => {
        setActive(isSessionActiveFunc(adoptClientRestriction))
    }, [adoptClientRestriction, setActive])

    if (!active) return <Frag />
    return (
        <Secure demand="clientAdmin" fallback={<Frag />}>
            <NotInPdf>
                <Box m={2} mr={4} data-cy="session-alert-banner">
                    <Banner setActive={setActive} adoptClientRestriction={adoptClientRestriction} />
                </Box>
            </NotInPdf>
        </Secure>
    )
}

function Banner({ setActive, adoptClientRestriction }) {
    useInterval(() => setActive(isSessionActiveFunc(adoptClientRestriction)), 30000)

    return (
        <>
            {!adoptClientRestriction.beingAdopted && (
                <Alert
                    severity="success"
                    action={<SuccessButton size="small" sx={{ marginRight: 1 }} text="Manage" onClick={handleClick} />}
                >
                    Your account currently has a remote access session - SFG20 is able to access your account
                </Alert>
            )}
            {adoptClientRestriction.beingAdopted && (
                <Alert
                    severity="warning"
                    action={<WarningButton size="small" sx={{ marginRight: 1 }} text="Manage" onClick={handleClick} />}
                >
                    SFG20 is currently remotely logged into your account, you may notice some changes taking place.
                    Access can be managed in the admin tab
                </Alert>
            )}
        </>
    )

    function handleClick() {
        setReference({ clientAdminTab: "adoptClientRestriction" })
        navigate("/app/admin")
    }
}
