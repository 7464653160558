import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"

export const deleteRegime = api(function deleteTree(tree) {
    return mutate(
        gql`
            mutation deleteTree($tree: String!) {
                deleteTree(tree: $tree)
            }
        `,
        { tree },
        { returns: "deleteTree" }
    )
})
