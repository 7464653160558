import { useSelectedItems } from "routes/schedule/lib/useSelectedItems"
import { Box, Button } from "@mui/material"
import { confirm } from "lib/@dialogs/confirm"
import { selectItems } from "routes/schedule/lib/useSelection"

import { ScheduleSelectorToolbar } from "slot-definitions"
import { useTreeItem } from "routes/schedule/lib/useTreeItem"
import { retireSchedules } from "routes/schedule/my-schedules/my-custom-schedules/controller/retire-schedules"
import { MY_LIST, MY_PUBLISHED } from "routes/schedule/my-schedules/my-custom-schedules/lib/constants"
import { busyWhile } from "lib/@components/busy/busy-while"

ScheduleSelectorToolbar("*").plug(
    <Retire if={(c) => c.parentItem.id === MY_PUBLISHED || c.parentItem.id === MY_LIST} />
)

export function Retire() {
    const items = useSelectedItems()
    const parentItem = useTreeItem("allPublished")
    if (!parentItem) return null
    const toRetire = parentItem.children.filter((child) => items.find((i) => i._id === child._id))

    return (
        <Box display="flex" flexDirection="row" alignItems="center" mr={2}>
            {!!toRetire.length && (
                <Button size="small" variant="contained" color="secondary" onClick={retire}>
                    Retire ({toRetire.length})
                </Button>
            )}
        </Box>
    )

    async function retire() {
        if (
            !(await confirm(
                `Are you sure that you want to retire ${toRetire.length} ${"schedule".pluralize(toRetire.length)}?`,
                "Retire"
            ))
        ) {
            return
        }
        await busyWhile(() => retireSchedules(true, toRetire.map("_id")), "Retiring")
        selectItems(() => ({}))
    }
}
