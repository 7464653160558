import { Checkbox, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import noop from "lib/noop"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { BlockKeyboard } from "lib/@components/block-clicks"

export function SelectAllCheckbox({ data, onSelect = noop }) {
    const { selectedItems } = useBoundContext()
    const rowCount = data?.length
    const numSelected = selectedItems?.length
    const allChecked = rowCount > 0 && numSelected === rowCount

    return (
        <BlockKeyboard>
            <ListItem disablePadding>
                <ListItemButton onClick={handleSelectAll} dense>
                    <ListItemIcon>
                        <Checkbox
                            edge="start"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={allChecked}
                            tabIndex={-1}
                            disableRipple
                        />
                    </ListItemIcon>
                    <ListItemText primary="Select All" />
                </ListItemButton>
            </ListItem>
        </BlockKeyboard>
    )

    function handleSelectAll() {
        onSelect(allChecked)
    }
}
