import { parentTreeItem } from "library/tree"
import { EDIT_TREE, REGIME_IDS } from "library/constants"

export function isFacilityLike(i) {
    return (
        REGIME_IDS.includes(i.id) ||
        i.data?.type === "facility" ||
        i[parentTreeItem]?.[parentTreeItem]?.id === EDIT_TREE
    )
}
