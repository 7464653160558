import { DialogContent, DialogTitle, TextField } from "@mui/material"
import { useState } from "react"
import { setFromEvent } from "lib/setFromEvent"
import { useDialog } from "lib/@hooks/useDialog"
import { gql } from "@apollo/client"
import { waitForScheduleRefresh } from "routes/schedule/lib/wait-for-schedule-refresh"
import { ContextMenuItem } from "lib/@components/context-menu-bar/context-menu-item"
import { TagContextMenu } from "routes/schedule/plugins/baskets/tags/tagbar"
import { BasketContextMenu } from "routes/schedule/plugins/baskets/tree/basket-bar"
import { OkCancel } from "lib/@components/okCancel"
import { mutate } from "lib/graphql/mutate"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { busyWhile } from "lib/@components/busy/busy-while"

BasketContextMenu.plug(<Rename />)
TagContextMenu.plug(<Rename />)

function Rename() {
    const { parentItem } = useBoundContext()
    const rename = useDialog(<RenameDialog currentName={parentItem?.label} />)
    return <ContextMenuItem icon="eva:edit-outline" label="Rename" onClick={go} />

    async function go() {
        const newName = await rename()
        if (newName) {
            await busyWhile(
                async () =>
                    mutate(
                        gql`
                            mutation changeName($id: String!, $name: String!) {
                                updateBasket(id: $id, name: $name)
                            }
                        `,
                        {
                            id: parentItem.id,
                            name: newName,
                        }
                    ),
                "Renaming",
                waitForScheduleRefresh
            )
        }
    }
}

export function RenameDialog({ currentName }) {
    const [name, setName] = useState(currentName)
    return (
        <>
            <DialogTitle sx={{ mb: 2 }}>Rename</DialogTitle>
            <DialogContent>
                <TextField fullWidth autoFocus value={name} onChange={setFromEvent(setName)} />
            </DialogContent>
            <OkCancel value={name} okDisabled={(v) => v === currentName} />
        </>
    )
}
