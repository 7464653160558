import { Document, Paragraph } from "docx"
import { DATE_FORMATS } from "lib/@constants/date-format"
import { addSkillsToTask } from "./add-skills-to-task"
import { processLegislationData } from "./process-legislation-data"
import { header } from "./template/header"
import { footer } from "./template/footer"
import { simpleParagraphs } from "./template/simple-paragraphs"
import { formatSchedule } from "./template/format-schedule"

export async function createDocX(content) {
    const { title, code, intro, legislation, unitOfMeasure, version, id, serviceTimings, tasks, metadata } = content
    const amendedTasks = await addSkillsToTask(tasks)
    const amendedLegislation = await processLegislationData(legislation)
    const dateCreated = new Date(metadata.createdOn).format(DATE_FORMATS.medium)

    const doc = new Document({
        styles: {
            default: {
                document: {
                    run: {
                        size: "9pt",
                        font: "Arial",
                    },
                    paragraph: {
                        spacing: {
                            before: "8pt",
                            after: "8pt",
                        },
                    },
                },
            },
            paragraphStyles: [
                {
                    id: "title",
                    name: "title",
                    basedOn: "normal",
                    next: "Normal",
                    run: {
                        size: "14pt",
                        bold: true,
                    },
                    paragraph: {
                        before: "12pt",
                        after: "3pt",
                    },
                },
                {
                    id: "bold",
                    name: "bold",
                    basedOn: "normal",
                    next: "Normal",
                    run: {
                        bold: true,
                    },
                },
                {
                    id: "defaultParagraph",
                    name: "defaultParagraph",
                    basedOn: "normal",
                    next: "Normal",
                    paragraph: {
                        spacing: {
                            before: 0,
                            after: 0,
                            line: 216,
                        },
                    },
                },
            ],
        },
        sections: [
            {
                headers: {
                    default: header(),
                },
                footers: {
                    default: footer(),
                },
                children: [
                    ...simpleParagraphs("Title", title),
                    ...simpleParagraphs("Code", code),
                    ...simpleParagraphs("Version", version),
                    ...simpleParagraphs("Date", dateCreated),
                    ...simpleParagraphs("Unit of Measure", unitOfMeasure),
                    new Paragraph({ text: "Intro", style: "title" }),
                    ...formatSchedule(intro),
                    new Paragraph({ text: "Tasks", style: "title" }),
                    ...formatSchedule(amendedTasks),
                    new Paragraph({ text: "Service Timings", style: "title" }),
                    ...formatSchedule(serviceTimings),
                    new Paragraph({ text: "Legislation", style: "title" }),
                    ...formatSchedule(amendedLegislation),
                    ...simpleParagraphs("Schedule ID", id),
                ],
            },
        ],
    })

    return doc
}
