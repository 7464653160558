import { inlineElements } from "../legacy-export-docx-consts"

export function wrapTextInSpan(htmlString) {
    const container = document.createElement("div")
    container.innerHTML = htmlString

    function wrapTextNodes(node) {
        let textBuffer = ""
        let elementsToWrap = []

        node.childNodes.forEach((child) => {
            if (child.nodeType === Node.TEXT_NODE && child.textContent.trim() !== "") {
                textBuffer += child.textContent
                elementsToWrap.push(child)
            } else if (child.nodeType === Node.ELEMENT_NODE) {
                const inlineTags = Object.values(inlineElements)
                if (inlineTags.includes(child.tagName)) {
                    textBuffer += child.outerHTML
                    elementsToWrap.push(child)
                } else {
                    if (textBuffer.trim() !== "") {
                        const span = document.createElement("span")
                        span.innerHTML = textBuffer
                        textBuffer = ""
                        elementsToWrap.forEach((el) => el.replaceWith(span))
                        elementsToWrap = []
                    }
                    if (child.tagName.toLowerCase() !== "ol" && child.tagName.toLowerCase() !== "table") {
                        wrapTextNodes(child)
                    }
                }
            }
        })

        if (textBuffer.trim() !== "") {
            const span = document.createElement("span")
            span.innerHTML = textBuffer
            elementsToWrap.forEach((el) => el.replaceWith(span))
        }
    }

    wrapTextNodes(container)

    return container.innerHTML
}
