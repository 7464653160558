import { Paragraph, TextRun } from "docx"
import { textFromHtmlNode } from "../text-from-html-node"
import decamelize from "decamelize"
import { formatTextAndAddToParagraph } from "../format-text-and-add-to-paragraph"
import { formatTable } from "./format-table"
import { formatList } from "./format-list"
import { formatObject } from "./format-object"

export function formatScheduleItem(value, valueAsHtml, key) {
    const valueIsOL = value.toString().startsWith("<ol>")
    const content = []
    switch (true) {
        case typeof value === "object":
            {
                const objectKey = new Paragraph({
                    children: [new TextRun(decamelize(key, " ").capitalize())],
                    style: "bold",
                })
                content.push(objectKey, formatObject(value))
            }
            break

        case valueIsOL:
            {
                const listParagraphs = formatList(valueAsHtml.body, key)
                const listKey = new Paragraph({
                    children: [new TextRun(key.capitalize())],
                    style: "bold",
                })
                content.push(listKey, ...listParagraphs)
            }
            break

        default:
            {
                const textKey = new Paragraph({
                    children: [new TextRun(decamelize(key, " ").capitalize())],
                    style: "bold",
                })
                content.push(textKey)
                const childNodes = Array.from(valueAsHtml.body.childNodes)

                childNodes.forEach((childNode) => {
                    switch (childNode.nodeName) {
                        case "TABLE":
                            content.push(formatTable(childNode, key))
                            break

                        case "OL":
                        case "UL":
                            {
                                const listParagraphs = formatList(childNode, key)
                                content.push(...listParagraphs)
                            }
                            break

                        default:
                            {
                                const textSegments = textFromHtmlNode(childNode)
                                const textParagraph = new Paragraph({
                                    text: "",
                                    style: "defaultParagraph",
                                })
                                formatTextAndAddToParagraph(textParagraph, textSegments, key)
                                content.push(textParagraph)
                            }
                            break
                    }
                })
            }
            break
    }

    return content
}
