import AuthGuard from "minimals-template/components/guards/AuthGuard"
import Iconify from "minimals-template/components/Iconify"
import AppLayout from "routes/@layouts/app"
import { register } from "lib/routes/register"
import { HomePage } from "routes/app/home-page"
import { registerConditionalPageNavigation } from "lib/routes/register-conditional-page-navigation"
import { hasDemand } from "lib/authorization/has-demand"

export const appRoute = (window.AppRoute = register(
    "/app",
    <AuthGuard>
        <AppLayout />
    </AuthGuard>
))
appRoute.register("", <HomePage $lazy index />)

registerConditionalPageNavigation(
    () => hasDemand("!sharing"),
    "/app",
    "Home",
    <Iconify icon="material-symbols:home-rounded" />
)
