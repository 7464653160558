import { isEnabled } from "lib/@components/feature"
import { registerTab } from "lib/@components/tabs"
import { Lazy } from "lib/make-lazy"
import { FEATURE_FLAG_ADOPT_CLENT } from "routes/client-admin/plugins/adopt-client-restriction/adopt-client-restriction.runtime"

registerTab({
    tab: "sfg20AdminTabs",
    id: "clients",
    title: "Adopt Client",
    demands: "admin-adoptclient",
    content: <Lazy importer={() => import("./components/clients-table")} extract="ClientTable" />,
    predicate: () => isEnabled(FEATURE_FLAG_ADOPT_CLENT),
})
