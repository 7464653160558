import { Bound } from "lib/@components/binding/Bound"
import { useParams, useSearchParams } from "react-router-dom"
import { useTreeItem } from "routes/schedule/lib/useTreeItem"
import { createSlot } from "lib/@components/slot/create-slot"
import { getGroupId, getScheduleId } from "library/get-unique-id-from-tree"
import { EditScheduleTailoring } from "routes/schedule/edit-schedule-tailoring"

import { getTreeId } from "library/id-helpers"
import { usePayload } from "routes/schedule/controller/use-payload"

export const ScheduleExtension = createSlot("ScheduleExtension")
export const ScheduleExtensionDisplay = createSlot("ScheduleExtensionDisplay")

export function ExtendedSchedule({ isEdit, isShare, scheduleId, children, ...props }) {
    const { id: paramId } = useParams()
    const id = scheduleId ?? paramId
    const [searchParams] = useSearchParams()
    isEdit ??= !!searchParams.get("editEx")
    isShare ??= !!searchParams.get("share")
    const item = useTreeItem(getGroupId(id))
    const payload = usePayload(getGroupId(id), getScheduleId(id), getTreeId(id))

    if (isEdit) {
        return (
            <EditScheduleTailoring {...props} payload={payload} id={id} item={item}>
                {children}
            </EditScheduleTailoring>
        )
    }
    return (
        <Bound isExtended currentId={id} currentGroup={item} isShare={isShare} payload={payload}>
            <ScheduleExtensionDisplay.Slot>{children}</ScheduleExtensionDisplay.Slot>
        </Bound>
    )
}
