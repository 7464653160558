import { ScheduleItemBeneath, ScheduleItemCode } from "slot-definitions"
import { isInRegime, not, notInSelector } from "routes/facilities/lib/when-parent-item"
import { and } from "lib/logic"
import { RelatedTreeDetails } from "routes/schedule/plugins/used-schedules/related-tree-details"
import { RelatedTrees } from "routes/schedule/plugins/used-schedules/related-trees"
import { WithDemand } from "lib/authorization/secure"

ScheduleItemCode.plug(
    <WithDemand demand="manageRegime" if={and(notInSelector, not(isInRegime))} priority={50}>
        <RelatedTrees $lazy />
    </WithDemand>
)

ScheduleItemBeneath.plug(<RelatedTreeDetails $lazy if={and(not(isInRegime), notInSelector)} />)
