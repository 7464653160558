import { useDialog } from "lib/@hooks/useDialog"
import { PreviewDialog } from "lib/@components/document-viewer/preview-dialog"
import { Box, ImageList, ImageListItem, ImageListItemBar } from "@mui/material"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function Pictures() {
    const preview = useDialog(<PreviewDialog />, { maxWidth: "lg", fullWidth: true })
    const { payload } = useBoundContext()
    return (
        !!payload?.pictures?.documents?.length && (
            <Box width={1} p={4}>
                <ImageList variant="masonry" cols={3} gap={2}>
                    {payload.pictures?.documents?.map((picture, index) => (
                        <ImageListItem onClick={() => preview({ name: picture.name, url: picture.href })} key={index}>
                            <img src={picture.href} alt={picture.name} />
                            <ImageListItemBar title={picture.name} />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>
        )
    )
}
