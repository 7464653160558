import { isEnabled } from "lib/@components/feature"
import { registerTab } from "lib/@components/tabs"
import { registerFeature } from "lib/features"
import { Lazy } from "lib/make-lazy"

export const FEATURE_FLAG_CLIENT_BACKUP = registerFeature({
    numericalId: 1,
    name: "Client Backup",
    id: "clientBackup",
    description: `Enables backing up a database for the client, it's for internal use and never production.`,
    readyForBeta: false,
    readyForProduction: false,
    internalUseOnly: true,
})

registerTab({
    tab: "clientAdmin",
    id: "backups",
    title: "Client Backup",
    predicate() {
        return isEnabled(FEATURE_FLAG_CLIENT_BACKUP)
    },
    content: <Lazy priority={10000} importer={() => import("./DatabaseBackup")} extract="DatabaseBackup" />,
})
