import { registerTab } from "lib/@components/tabs"
import { REGIME_ASSETS_TAB } from "routes/asset-register/plugins/regime-display/regime-display.runtime"
import { Divider } from "@mui/material"
import { TailoredSchedulesRegimeTab } from "routes/asset-register/plugins/general-tailoring/tailored-schedules-regime-tab"
import { and } from "lib/logic"
import { isInEditableRegime } from "routes/facilities/lib/when-parent-item"

registerTab({
    tab: REGIME_ASSETS_TAB,
    id: "general-tailoring",
    title: "Tailored Schedules",
    predicate: and(isInEditableRegime),
    content: <TailoredSchedulesRegimeTab $lazy priority={160} />,
})

//Register a vertical bar spacer
registerTab({
    tab: REGIME_ASSETS_TAB,
    id: "general-tailoring_spacer",
    tabProps: { disabled: true },
    title: <Divider orientation="vertical" />,
    predicate: and(isInEditableRegime),
    content: <TailoredSchedulesRegimeTab $lazy priority={150} />,
})
