import { ScheduleItemBelow, ScheduleSelectorBeforeList } from "slot-definitions"
import { Grid } from "@mui/material"
import { SpaceTile } from "routes/schedule/my-schedules/my-group-types/space/space-tile"
import { FacilityGroup } from "routes/facilities/space-and-group-page"
import { parentFind } from "library/tree"
import { isParentItemType } from "routes/schedule/my-schedules/my-group-types/isParentItemType"
import { AdaptItemForFacilities, ScheduleFolderIcon } from "event-definitions"
import { SpaceCompleteCard } from "routes/schedule/my-schedules/my-group-types/space/spaceCompleteCard"
import { Below } from "routes/schedule/my-schedules/my-group-types/space/below"
import { SpaceDisplay } from "routes/schedule/my-schedules/my-group-types/space/spaceDisplay"
import Iconify from "minimals-template/components/Iconify"
import { SimpleDisplay } from "routes/schedule/my-schedules/my-group-types/space/simpleDisplay"
import { navigate } from "lib/routes/navigate"
import { isFacilityLike } from "routes/schedule/my-schedules/my-group-types/space/is-facility-like"
import { FacilityTile } from "routes/schedule/my-schedules/my-group-types/facility/facility-tile"
import { FolderTile } from "routes/schedule/my-schedules/my-group-types/space/folder-tile"
import { FacilityCompleteCard } from "routes/schedule/my-schedules/my-group-types/facility/facilityCompleteCard"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Bound } from "lib/@components/binding/Bound"

ScheduleSelectorBeforeList("*").plug(<SpaceDisplay if={isParentItemType("space")} priority={400} />)
ScheduleSelectorBeforeList("*").plug(<SimpleDisplay if={isParentItemType("simple")} priority={400} />)
// ScheduleSelectorBeforeList("*").plug(<SimpleDisplay if={(c) => c.parentItem[PlanRoot]} priority={400} />)

AdaptItemForFacilities("*").handleOnce((props) => {
    if (props.item.data?.type === "space") {
        props.output = (
            <Grid item xs={6} md={3} alignItems="stretch">
                <SpaceTile onClick={openSpace} space={props.item.data} />
            </Grid>
        )
    } else if (props.item.data?.type === "facility") {
        props.output = (
            <Grid item xs={6} md={3} alignItems="stretch">
                <FacilityTile onClick={openFacility} facility={props.item.data} />
            </Grid>
        )
    } else if (props.item.type === "group") {
        props.output = (
            <Grid item xs={6} md={3} alignItems="stretch">
                <FolderTile onClick={openSpace} item={props.item} />
            </Grid>
        )
    }

    function openFacility() {
        navigate(`/app/facilities/facility/${props.item.id}`)
    }

    function openSpace() {
        navigate(`/app/facilities/facility/${parentFind(props.item, isFacilityLike)?.id}/${props.item.id}`)
    }
})

ScheduleFolderIcon.handleOnce(({ data, icon }) => {
    if (data?.data?.type !== "space") return null
    if (icon) return null
    return {
        icon: <Iconify sx={{ width: 32, height: 32, color: "myStuff.main" }} icon="fluent:conference-room-24-filled" />,
    }
})
ScheduleFolderIcon.handleOnce(({ data }) => {
    if (data?.data?.type !== "facility") return null
    return { icon: <Iconify sx={{ width: 32, height: 32, color: "myStuff.main" }} icon="bxs:building" /> }
})
ScheduleFolderIcon.handleOnce(({ data }) => {
    if (data?.data?.type !== "simple") return null
    return {
        icon: (
            <Iconify
                sx={{ width: 32, height: 32, color: "myStuff.main" }}
                icon={data?.data?.icon ?? "material-symbols:folder-rounded"}
            />
        ),
    }
})

export function ExtractContext({ children }) {
    const context = useBoundContext()
    return children(context)
}

FacilityGroup("space").plug(<SpaceCompleteCard hideMenu={true} />)
const groupContent = (
    <ExtractContext>
        {({ parentItem }) => (
            <Bound facility={parentItem}>
                <FacilityCompleteCard facility={parentItem?.data} hideMenu={true} />
            </Bound>
        )}
    </ExtractContext>
)
FacilityGroup("simple").plug(groupContent)
FacilityGroup.plug(groupContent)

ScheduleItemBelow.plug(<Below if={(c) => c.parentItem.id === "search"} />)
