import { registerConformityStep } from "routes/conformity/lib/register-conformity-step"

import { Lazy } from "lib/make-lazy"

registerConformityStep({
    title: "Sign Off",
    content: <Lazy importer={() => import("routes/conformity/plugins/sign-off/sign-off")} extract="SignOff" />,
    icon: "mdi:draw-pen",
    priority: 30,
})
