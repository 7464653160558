import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { parentFind } from "library/tree"
import { PlanRoot } from "library/tree/constants"
import { getBasePlanId } from "routes/plan/controller/use-plan"
import { getPublishedPlan } from "routes/plan/controller/publish-plan"

export function available(type) {
    return function usePlanAvailable() {
        const { parentItem } = useBoundContext()
        const parent = parentFind(parentItem, (c) => c[PlanRoot])
        const id = getBasePlanId(parent)
        const result = getPublishedPlan.useResults(id, type)
        return result?.tree?.version + 1 ?? false
    }
}
