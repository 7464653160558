import { Box, IconButton, List, Stack } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { ListItemStyle } from "minimals-template/components/nav-section/vertical/style"
import { ExpandTreeItem, TreeGoto } from "event-definitions"
import { Bound } from "lib/@components/binding/Bound"
import { prevent } from "lib/prevent"
import { useLayoutEffect, useMemo, useState } from "react"
import { useTreeItem } from "routes/schedule/lib/useTreeItem"
import { parentTreeItem } from "routes/schedule/tree/schedules/makeTreeItemsFromGroup"
import { useReference } from "lib/@hooks/use-reference"
import { useRefresh } from "lib/@hooks/useRefresh"
import { findRootFrom } from "routes/schedule/lib/useStack"
import { resolveAsFunction } from "lib/resolve-as-function"
import { findInChildren, TreeRoot } from "library/tree"
import { navigate } from "lib/routes/navigate"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { TreeToolbarLeft } from "slot-definitions"
import { CollapseTreeButton } from "routes/schedule/plugins/collapse-tree-button/collapse-tree-button"
import { useSearchParam } from "lib/@hooks/use-search-param"
import { showUnlicensed } from "routes/schedule/components/schedule-filter"

function TreeHolder({ children }) {
    return children
}

function isSelected(current, item) {
    if (item.id === current.id) return 1
    if (item.isLoaded === false) return 0
    if (item.children?.some((c) => isSelected(current, c))) return 2
    return 0
}

function TreeRow({ active, depth, expand, expanded, hasSubGroups, onClick, target, width }) {
    ExpandTreeItem.useEvent(
        (expanding) => {
            if (expanded && target[TreeRoot] && expanding[TreeRoot] && expanding !== target) {
                expand()
            }
        },
        [expanded, target]
    )

    return (
        <Bound target={target}>
            <Box width={width - 8} overflow="hidden">
                <ListItemStyle
                    highlight="highlight"
                    onClick={onClick}
                    sx={{
                        lineHeight: 1.43,
                        m: 0,
                        py: 0.1,
                        width: "100%",
                    }}
                    active={active}
                    depth={depth * 4 + 2}
                    open={!!expanded[target.id]}
                >
                    {/*{active === 1 && <ScrollIntoView always />}*/}
                    {<target.content.type {...target.content.props} last Component={TreeHolder} />}
                    {hasSubGroups && (
                        <IconButton onClick={prevent(expand)} sx={{ ml: 1, flex: 0 }} color="primary">
                            <Iconify
                                icon={
                                    expanded[target.id] ? "eva:arrow-ios-downward-fill" : "eva:arrow-ios-forward-fill"
                                }
                            />
                        </IconButton>
                    )}
                </ListItemStyle>
            </Box>
        </Bound>
    )
}

let treeScrollPosition = 0

export function ScheduleTree({ parentId, select }) {
    select ??= go
    const { inSelector } = useBoundContext()
    const context = useBoundContext()
    const [id] = useSearchParam("id", "")
    const current = useTreeItem(parentId)
    const rootId = findRootFrom(current)?.id
    const root = useTreeItem(rootId)

    // const [{ width }, attach] = useMeasurement()
    const refresh = useRefresh()
    const [{ treeExpanded, treeVisible }, setReference] = useReference(
        { treeExpanded: {}, treeVisible: false },
        "expanded"
    )
    const [expanded, setExpanded] = useState(!inSelector ? treeExpanded : {})
    useLayoutEffect(() => {
        setReference({ treeExpanded: expanded })
    }, [expanded, setReference])
    /* eslint-disable react-hooks/exhaustive-deps */
    useMemo(() => treeVisible && ensureExpanded(current), [parentId, refresh.id])

    if (!root) {
        return null
    }

    if (id === "search") {
        return null
    }

    if (!treeVisible) {
        return (
            <TreeToolbarLeft.Plug type={id}>
                <CollapseTreeButton />
            </TreeToolbarLeft.Plug>
        )
    }

    const output = []
    recurseAdd(root)

    if (!output[0]) {
        return null
    }

    return (
        <Bound inTree={true} treeWidth={360}>
            <TreeToolbarLeft.Plug type={id}>
                <CollapseTreeButton />
            </TreeToolbarLeft.Plug>

            <Stack
                // ref={attach}
                alignItems="stretch"
                sx={{ width: treeVisible ? 360 : undefined, overflowX: "auto" }}
                data-cy="schedule-tree"
            >
                <Box
                    sx={{
                        position: "relative",
                        flexBasis: treeVisible ? "35%" : undefined,
                        fontSize: "0.85rem",
                        display: "flex",
                        flexDirection: "column",
                        flexShrink: 0,
                        flex: 1,
                        width: treeVisible ? 1 : undefined,
                        overflow: "auto",
                    }}
                >
                    {!!treeVisible && (
                        <List
                            sx={{ overflow: "auto", flex: 1, width: 1 }}
                            ref={formerScrollPosition}
                            onScroll={storeScrollPosition}
                            className="schedule-tree-items"
                        >
                            {output}
                        </List>
                    )}
                </Box>
            </Stack>
        </Bound>
    )

    function storeScrollPosition({ target }) {
        treeScrollPosition = target.scrollTop
    }

    function formerScrollPosition(target) {
        if (target) {
            target.scrollTop = treeScrollPosition
        }
    }

    function ensureExpanded(item) {
        const toExpand = []
        recurseCheck(item?.[parentTreeItem])
        if (toExpand.length) {
            setExpanded((existing) => {
                toExpand.forEach((id) => {
                    existing[id] = true
                    expanded[id] = true
                })
                return { ...existing }
            })
        }

        function recurseCheck(item) {
            if (!item) return
            if (!expanded[item.id]) {
                toExpand.push(item.id)
            }
            recurseCheck(item[parentTreeItem])
        }
    }

    function recurseAdd(parent, depth = 0, visible = true) {
        const items = parent.children.sortBy("label")
        for (const child of items) {
            const hasSubGroups =
                child.hasSubGroups || !!(child.children ?? []).filter((f) => f.children?.length || f.visible).length
            const hasChildren = child.hasChildren || (child.children?.length ?? 0)
            let isVisible = resolveAsFunction(child.visible)(context)
            if (
                !showUnlicensed() &&
                !findInChildren(child, (c) => !!c._id && (!c.notLicensed || c.showUnlicensed))?.length
            )
                isVisible = false

            if (hasChildren || isVisible === true) {
                const active = isSelected(current, child)
                if (visible && isVisible !== false) {
                    output.push(
                        <TreeRow
                            key={child.id}
                            target={child}
                            width={360}
                            onClick={select(child)}
                            active={active}
                            depth={depth}
                            expanded={expanded}
                            hasSubGroups={hasSubGroups}
                            expand={expand(child)}
                        />
                    )
                }
                if (expanded[child.id]) {
                    recurseAdd(child, depth + 1, visible && !!expanded[child.id])
                }
            }
        }
    }

    function expand(item) {
        return function expandItem() {
            ExpandTreeItem.raise(item)
            setExpanded((expanded) => ({ ...expanded, [item.id]: !expanded[item.id] }))
        }
    }

    function go(item) {
        return function goItem() {
            TreeGoto.raise(item.id, item)
            navigate(`/app/schedules?id=${item.id}`)
        }
    }
}
