import { assetRegister } from "routes/asset-register/controller/asset-register"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { MenuItem } from "@mui/material"

export function RegisterEntry({ item }) {
    const register = assetRegister.useResults(item)
    const { createAsset } = useBoundContext()
    return (
        <MenuItem onClick={create} data-cy="register-entry">
            {register?.name}
        </MenuItem>
    )

    function create() {
        createAsset?.(item)
    }
}
