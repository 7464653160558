const _api = {}
const dummy = {}
const parents = new WeakMap()
const associated = new WeakMap()
const children = new WeakMap()
const cachedInstances = new WeakMap()

const DEFAULT_STATE = "default"

const wasDerived = Symbol("wasDerived")
const dontCall = Symbol("dontCall")
const documentRef = Symbol("documentRef")
const ready = Symbol("ready")
const existingRelationship = Symbol("existing")
const local = Symbol("local")

// Initialisation callbacks
const initializers = []

// Resettable
let registeredBehaviours = {}
let allMethods = {}
let bases = {}

function isObject(a) {
    return a !== null && typeof a === "object"
}

function isString(a) {
    return typeof a === "string"
}

function inPriorityOrder(a, b) {
    return -(b._priority || 100) + (a._priority || 100)
}

class Cancel extends Error {}

function ensureArray(param) {
    return Array.isArray(param) ? param : [param]
}

function noMapping(state) {
    return state
}

/**
 * Registers a function called when initialising an empty object.
 * This can be used to add additional behaviours etc
 * @param {Function} initialisationFunction
 */
function registerInitializer(initialisationFunction) {
    if (typeof initialisationFunction !== "function") throw new Error("initializers must be a function")
    initializers.push(initialisationFunction)
}

function registerMethod(...methodName) {
    methodName = methodName.flat(Infinity)
    methodName.forEach((name) => {
        _api[name] =
            _api[name] ||
            function doRegister(...params) {
                return this[documentRef].sendMessage(name, ...params)
            }
    })
}

function isParent(child, parent) {
    let former
    do {
        if (child === parent) return true
        former = child
        child = parents.get(child) || child
    } while (former !== child)
    return false
}

function isChild(child, parent) {
    let former
    do {
        if (child === parent) return true
        former = child
        child = children.get(child) || child
    } while (former !== child)
    return false
}

function isRelated(parent, child) {
    if (parents.get(child) === parent) return existingRelationship
    return isParent(parent, child) || isChild(parent, child) || isParent(child, parent) || isChild(child, parent)
}

function inherit(base, derived) {
    initialize(base)
    initialize(derived)
    const relationship = isRelated(base, derived)
    if (relationship && relationship !== existingRelationship) {
        throw new Error("Cyclic inheritance")
    }
    cachedInstances.delete(base)
    cachedInstances.delete(derived)
    associated.delete(derived)
    associated.delete(base)
    parents.set(derived, base)
    children.set(base, derived)
}

function getDocumentFor(target) {
    let source
    do {
        source = target
        target = parents.get(target) || target
    } while (target !== source)
    return target
}

function getInstancesFor(target) {
    if (!target) return []
    let source = (target = getDocumentFor(target))
    if (cachedInstances.has(target)) return cachedInstances.get(target)
    const list = []
    do {
        list.push(target)
        source = target
        target = children.get(target) || target
    } while (target !== source)
    const items = list.filter((item) => item._behaviours).map((c) => c._behaviours[local])
    const output = items.reduce((behaviours, instances, index) => {
        for (const [nameOfBehaviour, listOfInstances] of Object.entries(instances)) {
            behaviours[nameOfBehaviour] = behaviours[nameOfBehaviour] || []
            behaviours[nameOfBehaviour].push(
                ...listOfInstances.map((instance) => {
                    instance[wasDerived] = index > 0
                    return instance
                })
            )
        }
        return behaviours
    }, {})
    cachedInstances.set(target, output)
    return output
}

function reset() {
    allMethods = {}
    bases = {}
    registeredBehaviours = {}
}

function register(name, definition) {
    if (!isString(name)) {
        throw new Error("The name must be a string")
    }
    if (!isObject(definition)) {
        throw new Error("The definition must be specified")
    }

    const { initialState } = definition
    const baseMethods = (allMethods[name] = allMethods[name] || new Map())
    const base = (bases[name] = bases[name] || { _name: name })
    resolveMethods(definition, baseMethods)
    const states = (definition.states = definition.states || dummy)
    for (const [stateName, state] of Object.entries(states)) {
        Object.entries(state.methods || dummy).forEach(function doMap([key, fn]) {
            callStateMethod._name = dontCall
            baseMethods.set(key, [...(baseMethods.get(key) || []), callStateMethod])

            function callStateMethod(...params) {
                if ((definition.mapState || noMapping).call(this, this.document.behaviours.state) === stateName) {
                    return fn.apply(this, params)
                }
                return dontCall
            }
        })
    }
    for (const key of definition.calls || []) {
        registerMethod(key)
    }
    Object.assign(base, initialState)
    for (const [name, allMethods] of baseMethods.entries()) {
        registerMethod(name)
        const functions = allMethods.sort((a) => (a._name === dontCall ? -1 : 0))
        if (initialState && initialState[name]) {
            throw new Error(`Member "${name}" already declared`)
        }
        base[name] = function execute(...params) {
            let called = false
            let i = 0
            let result
            return callNext.call(this)

            function callNext() {
                if (i >= functions.length) return result
                const fn = functions[i++]
                if (fn._name !== dontCall) {
                    if (called) {
                        return result
                    }
                }
                result = fn.apply(this, params)
                called = called || (fn._name === dontCall && result !== dontCall)
                {
                    const self = this
                    if (result && result.then) {
                        return result.then(() => callNext.call(self))
                    }
                    return callNext.call(this)
                }
            }
        }
    }
    const list = (registeredBehaviours[name] = registeredBehaviours[name] || [])
    list.push(definition)
}

function resolveMethods(definition, allMethods) {
    const methods = definition.methods || {}
    for (const [key, fn] of Object.entries(methods)) {
        allMethods.set(key, [...(allMethods.get(key) || []), fn])
    }
    return allMethods
}

function callMethod(behaviourName, instance, method, ...params) {
    const toCallBehaviours = ensureArray(registeredBehaviours[behaviourName])
    let called = false
    for (const behaviour of toCallBehaviours) {
        if (behaviour) {
            const fn = behaviour[method]
            if (fn && (!fn.once || !called)) {
                called = true
                return fn.apply(instance, params)
            }
        }
    }
    return undefined
}

function defineStateProperties(states) {
    return (stateful) => {
        const state = stateful?.behaviours?.state ?? stateful?._behaviours?.state ?? stateful
        return states[state] ?? states.default
    }
}

function initialize(target, addBehaviours = {}) {
    if (!target) return target
    if (target.behaviours) {
        target.sendMessage("initialized")
        // eslint-disable-next-line prefer-const
        for (let [behaviour, instances] of Object.entries(addBehaviours)) {
            instances = ensureArray(instances)
            for (const instance of instances) {
                target.behaviours.add(behaviour, instance)
            }
        }
        return target
    }

    for (const initializer of initializers) {
        initializer(target)
    }
    const compiled = new Map()
    target._behaviours = { ...target._behaviours }
    target._behaviours.instances = { ...target._behaviours.instances }
    const behaviourApi = {
        instances: {},
        sendMessage: sendMessageOnDocument,
        destroy,
        add,
        remove,
        setState,
        ...target._behaviours,
    }
    target._state = target._state || "default"
    const behaviours = (target._behaviours = Object.defineProperties(behaviourApi, {
        behaviours: {
            get() {
                return target._behaviours
            },
        },
        [local]: {
            value: behaviourApi.instances,
        },
        state: {
            get() {
                return target._state || DEFAULT_STATE
            },
            set(endState) {
                endState = endState || DEFAULT_STATE
                const startState = behaviours.state
                if (startState === endState) return
                const data = { canChange: true, startState, endState }
                iterateInstances((instance, definition) => {
                    const mappingFunction = (definition.mapState || noMapping).bind(instance)
                    const behaviourStart = mappingFunction(startState)
                    const behaviourEnd = mappingFunction(endState)
                    const oldState = (definition.states || dummy)[behaviourStart]
                    const newState = (definition.states || dummy)[behaviourEnd]
                    if (oldState && oldState.canExit) {
                        oldState.canExit.call(instance, data)
                    }
                    if (newState && data.canChange && newState.canEnter) {
                        newState.canEnter.call(instance, data)
                    }
                })

                if (!data.canChange) return
                delete data.canChange
                iterateInstances((instance, definition) => {
                    const mappingFunction = (definition.mapState || noMapping).bind(instance)
                    const behaviourStart = mappingFunction(startState)
                    const oldState = (definition.states || dummy)[behaviourStart]
                    if (oldState && oldState.exit) {
                        const result = oldState.exit.call(instance, data)
                        instance[ready] = result && result.then ? result : null
                    } else {
                        instance[ready] = null
                    }
                })
                target._state = endState
                behaviours.sendMessage("stateChanged", data)
                iterateInstances((instance, definition) => {
                    const mappingFunction = (definition.mapState || noMapping).bind(instance)
                    const behaviourEnd = mappingFunction(endState)
                    const newState = (definition.states || dummy)[behaviourEnd]
                    if (newState && newState.enter) {
                        if (instance[ready]) {
                            instance[ready] = instance[ready].then(() =>
                                Promise.resolve(newState.enter.call(instance, data))
                            )
                        } else {
                            instance[ready] = Promise.resolve(newState.enter.call(instance, data))
                        }
                    }
                })
                behaviours.ready = Promise.all(
                    getAllInstances()
                        .map((pair) => pair.instance[ready])
                        .concat([behaviours.sendMessage("stateChangeComplete", data)])
                )
            },
        },
    }))

    const instanceBase = {
        sendMessage: sendMessageOnDocument,
        destroy() {
            behaviours.remove(this._name, this).catch(console.error)
        },
    }

    if (target._behaviours) {
        // eslint-disable-next-line prefer-const
        for (let [type, list] of Object.entries({ ...target._behaviours[local], ...addBehaviours })) {
            list = Array.isArray(list) ? list : [list]
            for (let i = 0, l = list.length; i < l; i++) {
                list[i] = behaviours.add(type, list[i])
            }
        }
    }

    const api = Object.create(_api)
    api[documentRef] = target
    target.setState = behaviours.setState
    target.sendMessage = sendMessageOnDocument
    behaviours.sendMessage = sendMessageOnDocument
    Object.defineProperties(target, {
        behaviours: {
            get() {
                return behaviours
            },
        },
        api: {
            get() {
                return api
            },
        },
    })

    target.sendMessage("initialized")
    return target

    function setState(endState) {
        behaviours.state = endState
        return behaviours.ready
    }

    function sendMessageOnDocument(...params) {
        return sendMessage?.apply(target, params)
    }

    async function remove(behaviourType, instance = null) {
        compiled.clear()
        cachedInstances.delete(target)
        if (!instance) {
            const current = behaviours[local][behaviourType] || []
            current.forEach((instance) => {
                callMethod(behaviourType, instance, "destroy")
            })
            delete behaviours[local][behaviourType]
        } else {
            const list = (behaviours[local][behaviourType] = behaviours[local][behaviourType] || [])
            const instanceIndex = list.indexOf(instance)
            if (instanceIndex !== -1) {
                callMethod(behaviourType, instance, "destroy")
            }
            list.splice(instanceIndex, 1)
            if (!list.length) delete behaviours[local][behaviourType]
        }
    }

    function destroy() {
        compiled.clear()
        cachedInstances.delete(target)
        const instances = behaviours[local]
        for (const type in instances) {
            if (Object.hasOwn(instances, type)) {
                behaviours.remove(type).catch(console.error)
            }
        }
    }

    function getAllInstances() {
        if (associated.has(target)) {
            return associated.get(target)
        }
        let instances = Object.entries(getInstancesFor(target))
            .map(([type, list]) => list.map((instance) => ({ type, instance })))
            .flat(Infinity)
        instances = instances.filter((t) => registeredBehaviours[t.type]).sort(inPriorityOrder)
        associated.set(target, instances)
        return instances
    }

    function iterateInstances(cb) {
        Object.entries(getInstancesFor(target)).forEach(function doEach([type, list]) {
            const toCallBehaviours = ensureArray(registeredBehaviours[type])
            for (const availableBehaviour of toCallBehaviours) {
                if (availableBehaviour) {
                    for (const instance of list) {
                        cb(instance, availableBehaviour)
                    }
                }
            }
        })
    }

    function add(behaviourName, instance) {
        const original = instance
        associated.delete(target)
        compiled.clear()
        cachedInstances.delete(target)
        instance = { ...instance } || {}
        let existing = registeredBehaviours[behaviourName]
        if (!existing) {
            register(behaviourName, {}, true)
            existing = registeredBehaviours[behaviourName]
        }

        const prototype = Object.create(instanceBase)
        Object.assign(prototype, bases[behaviourName])
        Object.setPrototypeOf(instance, prototype)
        const addBehaviours = existing
        for (const availableBehaviour of addBehaviours) {
            if (availableBehaviour.mandatory === false) {
                instance._mandatory = false
            }

            const newState = (availableBehaviour.states || dummy)[behaviours.state] || dummy
            if (newState.enter) {
                const data = { startState: null, endState: behaviours.state }
                newState.enter.call(instance, data)
            }
        }

        const list = (behaviours[local][behaviourName] = behaviours[local][behaviourName] || [])
        if (!list.includes(original)) {
            notify()
            list.push(instance)
        }

        return instance

        function notify() {
            callMethod(behaviourName, instance, "initialize")
            setTimeout(function finishInitialization() {
                callMethod(behaviourName, instance, "postInitialize")
                callMethod(behaviourName, instance, "initialized")
            }, 0)
        }
    }

    function sendMessage(message, ...params) {
        let fn = compiled.get(message)
        if (fn) {
            return fn.call(this, ...params)
        }
        const calls = []
        getAllInstances().forEach(({ instance }) => {
            const document = target
            const fn = instance[message]
            if (instance.onMessage) {
                calls.push((result, params) =>
                    instance.onMessage.apply({ ...instance, document }, [message, ...params, result])
                )
            }
            if (typeof fn === "function") {
                calls.push((result, params) => fn.apply({ ...instance, document }, params, [...params, result]))
            }
        })
        fn = function perform(...params) {
            if (params.length === 0) params.push([])
            const promises = []
            let count = 0
            let result
            for (const step of calls) {
                try {
                    count++
                    result = step.call(this, result, params)
                    if (result && result.then) {
                        promises.push(result)
                    }
                } catch (e) {
                    if (!(e instanceof Cancel)) throw e
                    break
                }
            }
            params.called = count
            params.result = result
            if (promises.length) {
                const result = Promise.all(promises).then(() =>
                    params.length >= 1
                        ? Array.isArray(params[0])
                            ? Object.assign(params[0], {
                                  count,
                                  result,
                              })
                            : params
                        : params
                )
                return result
            }
            return params.length >= 1
                ? Array.isArray(params[0])
                    ? Object.assign(params[0], {
                          count,
                          result,
                      })
                    : params
                : params
        }
        compiled.set(message, fn)
        return fn.call(this, ...params)
    }
}

function uncache(item) {
    associated.delete(item)
    cachedInstances.delete(item)
}

module.exports = {
    register,
    registerInitializer,
    defineStateProperties,
    initialize,
    reset,
    Cancel,
    inherit,
    uncache,
}
