import { registerTab } from "lib/@components/tabs"
import { ASSET_TAB_ID } from "routes/asset-register/asset-register"
import { createEvent } from "library/local-events"
import { Mapping } from "routes/asset-register/plugins/asset-mapping/mapping"

registerTab({
    tab: ASSET_TAB_ID,
    id: "types",
    title: "Map Types To Library",
    content: <Mapping $lazy />,
})

export const MappingUpdated = createEvent("MappingUpdated")
