const { createEvent } = require("library/local-events")

function getSymbolKey(symbol) {
    return `@@${symbol.toString()}`
}

const DefinedSymbol = createEvent("DefinedSymbol", { extract: (v) => v[0].listOfSymbols })

function registerSymbol(symbol) {
    if (typeof symbol === "string") {
        symbol = Symbol(symbol)
    }
    DefinedSymbol.handleOnce(({ listOfSymbols }) => {
        listOfSymbols.push(symbol)
    })
    return symbol
}

function deserializeCircular(jsonString, listOfSymbols = []) {
    listOfSymbols = DefinedSymbol.call({ listOfSymbols })
    const symbolLookup = Object.fromEntries(listOfSymbols.map((s) => [getSymbolKey(s), s]))
    const objects = {}
    const fixups = []

    let result = jsonString
    try {
        result = JSON.parse(jsonString, revive)
        fixups.forEach((f) => f())
    } catch (e) {
        console.error(e)
        throw e
    }

    return result

    function revive(key, value) {
        if (typeof value === "object" && value?.$$_id !== undefined) {
            objects[value.$$_id] = value
            delete value.$$_id
        }
        if (key.startsWith("@@")) {
            const target = this
            fixups.push(() => {
                value = value?.startsWith?.("$$") ? objects[value.slice(2)] : value
                if (symbolLookup[key]) {
                    target[symbolLookup[key]] = value
                }
            })

            return undefined
        }
        if (!value) return value
        if (value.startsWith?.("$$")) {
            const target = this
            fixups.push(() => {
                target[key] = objects[value.slice(2)]
            })
            return null
        }

        return value
    }
}

function serializeCircular(object) {
    const fixup = []
    const seen = new WeakSet()
    try {
        let counter = 0
        recurseConvertSymbols(object)
        const circular = new WeakMap()
        return JSON.stringify(object, replace)

        // eslint-disable-next-line no-inner-declarations
        function replace(key, value) {
            if (!Array.isArray(value) && typeof value === "object" && value) {
                if (!circular.has(value)) {
                    circular.set(value, counter)
                    value = { ...value, $$_id: counter++ }

                    fixup.push(() => delete value.$$_id)
                } else {
                    value = `$$${circular.get(value)}`
                }
            } else if (Array.isArray(value)) {
                return [...value]
            }
            return value
        }
    } finally {
        fixup.forEach((f) => f())
    }

    function recurseConvertSymbols(object) {
        if (!object) return
        if (typeof object !== "object") return
        if (seen.has(object)) return
        seen.add(object)
        if (Array.isArray(object)) {
            object.forEach(recurseConvertSymbols)
        } else {
            Object.getOwnPropertySymbols(object).forEach((key) => {
                const value = object[key]
                const symbolKey = getSymbolKey(key)
                object[symbolKey] = value
                delete object[key]
                fixup.push(() => {
                    delete object[symbolKey]
                    object[key] = value
                })
                recurseConvertSymbols(value)
            })
            Object.keys(object).forEach((key) => recurseConvertSymbols(object[key]))
        }
    }
}

exports.serializeCircular = serializeCircular
exports.deserializeCircular = deserializeCircular
exports.registerSymbol = registerSymbol
