import { registerTab } from "lib/@components/tabs"
import { tasksWithTimings } from "routes/schedule/plugins/task-summary/annual-timing"
import { ToBeSaved } from "lib/@components/save-indicator/save-indicator"
import { Summary } from "routes/schedule/plugins/summary-tab/summary"

registerTab({
    tab: "ScheduleRightBar",
    id: "Summary",
    content: <Summary $lazy priority={20} />,
    predicate: (context) => !!context?.target?.tasks && tasksWithTimings(context.target.tasks).length,
    refresher: <TaskBasedRefresher />,
})

function TaskBasedRefresher({ refresh }) {
    ToBeSaved("**").useEvent(refresh)
    return null
}
