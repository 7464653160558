import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"

export async function updateSystemClientFubspot({
    address,
    city,
    code,
    email,
    id,
    name,
    numberOfUsers,
    phone,
    photoURL,
    postcode,
    website,
}) {
    return mutate(
        gql`
            mutation UpdateSystemClientFubspot(
                $address: String
                $city: String
                $code: String
                $email: String
                $id: String!
                $name: String!
                $phone: String
                $photoURL: String
                $postcode: String
                $website: String
            ) {
                updateSystemClientFubspot(
                    address: $address
                    city: $city
                    code: $code
                    email: $email
                    id: $id
                    name: $name
                    phone: $phone
                    photoURL: $photoURL
                    postcode: $postcode
                    website: $website
                )
            }
        `,

        {
            address,
            city,
            code,
            email,
            id,
            name,
            numberOfUsers,
            phone,
            photoURL,
            postcode,
            website,
        },
        { returns: "updateSystemClientFubspot" }
    )
}
