import { useState } from "react"
import { useDialog } from "lib/@hooks/useDialog"
import { SpaceConfiguration } from "routes/schedule/my-schedules/my-group-types/space/spaceConfiguration"
import { ContextMenuItem } from "lib/@components/context-menu-bar/context-menu-item"
import { storeGroupData } from "routes/schedule/controller/store-group-data"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { getGroup } from "routes/schedule/controller/get-group"

export function CreateSpace() {
    const { parentItem } = useBoundContext()
    const [newSpace] = useState({ image: parentItem.data?.image })
    const facility = useDialog(<SpaceConfiguration parentItem={parentItem} existingSpace={newSpace} />, {
        maxWidth: "lg",
        fullScreen: true,
        className: "space-dialog",
    })
    return (
        <ContextMenuItem
            divider={true}
            icon="fluent:conference-room-24-filled"
            label="Convert to space"
            onClick={create}
        />
    )

    async function create() {
        const result = await facility()
        if (result) {
            const existing = (await getGroup(parentItem.id)) ?? {}
            existing.data ??= {}
            if (existing.data.icon) {
                existing.data.icon = undefined
            }
            await storeGroupData(parentItem.id, { ...existing.data, ...result }, result.name)
        }
    }
}
