import { useParams } from "react-router"
import { BulletinEditor } from "../components/bulletin-editor"
import { getTechnicalUpdateBulletin } from "../controller/get-technical-update-bulletin"
import { Loader } from "lib/@components/Loader"
import { updateBulletin } from "../controller/editor/update-bulletin"
import { successSnackbar } from "lib/snackbar/success-snackbar"
import { errorSnackbar } from "lib/snackbar/error-snackbar"

export function EditTechnicalUpdate() {
    const { type } = useParams("type")
    const { id } = useParams("id")
    const { loading, data: existingUpdate } = getTechnicalUpdateBulletin.useResults.status(id, type)

    const handleUpdate = async (bulletin) => {
        const success = await updateBulletin(bulletin.id, bulletin)
        if (success) {
            successSnackbar("bulletin successfully updated")
        } else {
            errorSnackbar("something went wrong")
        }
    }

    if (loading) return <Loader />

    return <BulletinEditor id={id} bulletin={existingUpdate} onClickPublish={handleUpdate} />
}
