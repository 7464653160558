import { htmlToText } from "lib/html-to-text"

export function findFirstDifference(str1, str2) {
    const minLength = Math.min(str1.length, str2.length)

    for (let i = 0; i < minLength; i++) {
        if (str1[i] !== str2[i]) {
            console.log(`First difference found at index ${i}`)
            console.log(`String 1: ${str1.substring(0, i + 20)} (difference: ${str1[i]})`)
            console.log(`String 2: ${str2.substring(0, i + 20)} (difference: ${str2[i]})`)
            return
        }
    }

    if (str1.length !== str2.length) {
        console.log(`Strings have different lengths.`)
        console.log(`String 1 length: ${str1.length}`)
        console.log(`String 2 length: ${str2.length}`)
        return
    }

    console.log("No differences found.")
}

export function ifNotSameText(fn) {
    return function check(props) {
        const result = sameText(props) ? null : fn()
        if (!result) {
            findFirstDifference(cleanHTMLForComparison(props.v1), cleanHTMLForComparison(props.v2))
        }
        return result
    }
}

export function cleanHTMLForComparison(html = "") {
    if (html === "undefined") html = ""
    return htmlToText(html.replace(/\n/gi, "").replace(/\r/gi, ""))
        .trim()
        .replace(/([.,!?;:])\s+/g, "$1")
        .replace(/&\w+;/gi, "")
        .replace(/\s+/g, " ")
        .toLowerCase()
}

export function sameText({ v1, v2 }) {
    return cleanHTMLForComparison(v1) === cleanHTMLForComparison(v2)
}
