import { Button } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { RegimeAccessTableDialog } from "./regime-access-dialog"
import { useDialog } from "lib/@hooks/useDialog"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { warningSnackbar } from "lib/snackbar/warning-snackbar"
import { successSnackbar } from "lib/snackbar/success-snackbar"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Secure } from "lib/authorization/secure"
import { Frag } from "lib/@components/slot/frag"
import { grantRegimeAccess } from "../controller/grant-regime-access"
import { removeRegimeAccess } from "../controller/remove-regime-access"
import { If } from "lib/@components/switch"
import { useIsPrimaryUser } from "lib/user/use-primary-user"

export function RegimeAccessButton() {
    const { parentItem, closeNow } = useBoundContext()
    const accessManagementDialog = useDialog(<RegimeAccessTableDialog regime={parentItem.id} />, { maxWidth: "lg" })
    const { isPrimaryUser } = useIsPrimaryUser()

    return (
        <If
            truthy={isPrimaryUser}
            then={<AccessButton openDialog={openDialog} />}
            else={
                <Secure hide demand="manageAccess" fallback={<Frag />}>
                    <AccessButton openDialog={openDialog} />
                </Secure>
            }
        />
    )

    async function openDialog() {
        closeNow()
        const users = await accessManagementDialog()
        if (!users) {
            warningSnackbar("No changes have been saved")
            return null
        }
        try {
            await updateRegimeAccess(users)
            successSnackbar("Permissions successfully updated!")
        } catch (e) {
            errorSnackbar(e)
        }
        return null
    }

    async function updateRegimeAccess(changedUsers) {
        const { toGrant, toRemove } = sortIds()
        await grantRegimeAccess(toGrant, parentItem.id)
        await removeRegimeAccess(toRemove, parentItem.id)

        function sortIds() {
            return changedUsers.reduce(
                (acc, { userId, hasAccess }) => {
                    hasAccess ? acc.toGrant.push(userId) : acc.toRemove.push(userId)
                    return acc
                },
                { toGrant: [], toRemove: [] }
            )
        }
    }
}

function AccessButton({ openDialog }) {
    return (
        <Button
            onClick={openDialog}
            sx={{ color: "primary.main" }}
            startIcon={<Iconify icon="ic:round-person" />}
            data-cy="regime-access-button"
        >
            Access
        </Button>
    )
}
