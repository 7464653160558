import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"

export const downloadSchedulePDF = api(function downloadSchedulePDF(scheduleURL, scheduleId, regimeId, version) {
    return query(
        gql`
            query DownloadSchedulePDF(
                $scheduleURL: String!
                $scheduleId: String!
                $regimeId: String!
                $version: String
            ) {
                downloadSchedulePDF(
                    scheduleURL: $scheduleURL
                    scheduleId: $scheduleId
                    regimeId: $regimeId
                    version: $version
                )
            }
        `,
        { scheduleURL, scheduleId, regimeId, version },
        { returns: "downloadSchedulePDF", fetchPolicy: "network-only" }
    )
})
