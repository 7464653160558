import { Box } from "@mui/material"
import { Tabs } from "lib/@components/tabs"
import { HelpTag } from "routes/help/helpTag"
import { PageBreadcrumbContainer } from "lib/page-breadcrumb-container"

export function TabbedPage({ tabId, pageTitle, helpTags = [] }) {
    return (
        <PageBreadcrumbContainer title={pageTitle} sx={{ height: 1 }}>
            <HelpTag tags={helpTags} />
            <Box flex={1}>
                <Tabs id={`${tabId}`} panelHolder={<Box sx={{ marginTop: "3px" }} />} />
            </Box>
        </PageBreadcrumbContainer>
    )
}
