import { RegimeButtons } from "slot-definitions"
import { useSelectSchedule } from "routes/schedule/my-schedules/my-custom-schedules/select-schedule"
import { Button } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { useInventoryDialog } from "routes/regime/assets/inventory-dialog"
import { addSchedulesToFolder } from "routes/schedule/folder-manager/controller/add-schedules-to-folder"
import { alert } from "lib/@dialogs/alert"
import { setGroupPayload } from "routes/schedule/controller/set-group-payload"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { PlanRoot } from "library/tree/constants"

import { checkPermission } from "library/authorization"
import { getScheduleRecordId } from "library/get-unique-id-from-tree"

RegimeButtons.plug(<AddSchedule if={(c) => c.parentItem.folderHandling && !c.parentItem[PlanRoot]} priority={1} />)

function AddSchedule() {
    const { perspective, parentItem } = useBoundContext()
    const schedule = useSelectSchedule({ message: "Choose a schedule" })
    const inventory = useInventoryDialog(parentItem.id)
    return (
        perspective !== "assets" && (
            <Button
                sx={{ mr: 0.5 }}
                variant="contained"
                startIcon={<Iconify icon="mdi:file-document-outline" />}
                onClick={add}
            >
                Add Schedule
            </Button>
        )
    )

    async function add() {
        const toAdd = await schedule({ title: "Choose a schedule to add" })
        if (toAdd) {
            if (parentItem.children.some((c) => c._id === toAdd._id)) {
                await alert("Schedule already present in folder", "Cannot Add Schedule", "error")
                return
            }

            if (checkPermission("advancedTailoring")) {
                const payload = await inventory({ scheduleId: toAdd._id })
                if (payload) {
                    await setGroupPayload(parentItem, getScheduleRecordId(toAdd._id), payload)
                    await addSchedulesToFolder(parentItem, [toAdd])
                }
            } else {
                await addSchedulesToFolder(parentItem, [toAdd])
            }
        }
    }
}
