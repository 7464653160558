import { useSkills } from "routes/@lib/skills"
import { Box, Typography } from "@mui/material"
import { useCurrentUser } from "lib/store/use-current-user"

export function CurrentSkills() {
    const user = useCurrentUser()
    const [skillIndex] = useSkills()

    const skills = (user?.metadata?.skills ?? [])
        .map((s) => skillIndex[s]?.Skilling)
        .filter(Boolean)
        .map((s, i, ar) => (
            <Typography sx={{ display: "inline" }} key={s}>
                {i && i === ar.length - 1 ? "and " : ""}
                <strong>{s}</strong>
                {i < ar.length - 2 ? ", " : " "}
            </Typography>
        ))
    return skills.length ? (
        <Box display="inline">
            {" "}
            skill set{skills.length !== 1 ? "s" : ""}: {skills}
        </Box>
    ) : (
        <Box display="inline">skill sets: Not Specified</Box>
    )
}
