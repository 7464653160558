import { Box, ListItemButton, ListItemText, Typography } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function AssetItem({ item }) {
    const { setAsset, current } = useBoundContext()
    return (
        <ListItemButton onClick={selectAsset} selected={current === item.id} data-cy="asset-list-item">
            <ListItemBox>
                <ListItemText primary={item.description ?? item.tag} secondary={item.description ? item.tag : ""} />
                <Box flex={1} />
                <Typography variant="caption">{item.system}</Typography>
            </ListItemBox>
        </ListItemButton>
    )

    function selectAsset() {
        setAsset(item.id)
    }
}
