import { gql } from "@apollo/client"
import { getClient } from "minimals-template/components/contexts/NognitoContext"
import { getTreeId } from "routes/schedule/lib/getTreeId"
import { getTreeIdFromItem } from "lib/get-tree-id-from-item"
import { mutate } from "lib/graphql/mutate"
import { busyWhile } from "lib/@components/busy/busy-while"

export async function storeGroupData(id, data, name, tree) {
    await busyWhile(async () => saveGroupData(id, data, name, tree ?? getTreeId(id)), "Updating...")
}

export async function saveGroupData(id, data, name, tree) {
    return mutate(
        gql`
            mutation setFacilityData($tree: String!, $group: String!, $data: JSONObject, $name: String) {
                setGroup(tree: $tree, group: $group, data: $data, label: $name)
            }
        `,
        {
            tree: tree ?? getTreeId(id) ?? getTreeIdFromItem(data) ?? getClient(),
            group: id,
            data,
            name,
        }
    )
}

export async function saveGroupDataByIds(tree, group, data, name) {
    return mutate(
        gql`
            mutation setFacilityData($tree: String!, $group: String!, $data: JSONObject, $name: String) {
                setGroup(tree: $tree, group: $group, data: $data, label: $name)
            }
        `,
        {
            tree: tree,
            group,
            data,
            name,
        }
    )
}
