import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const getCustomSchedulesUsingCode = api(async function getCustomSchedulesUsingCode(id, client) {
    return query(
        gql`
            query getCustomSchedulesUsingCode($id: String!, $client: String) {
                getCustomSchedulesUsingCode(id: $id, client: $client)
            }
        `,
        { id, client },
        { returns: "getCustomSchedulesUsingCode" }
    )
})
