import { ActionItem } from "slot-definitions"
import { Avatar, Button, ListItemAvatar } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { uniqueId } from "library/database/split-id"
import { navigate } from "lib/routes/navigate"
import { TruncatedListItemText } from "lib/@components/truncatedListItemText"

ActionItem("schedule-draft-review").plug(<ReviewSchedule />)

function ReviewSchedule({ action }) {
    return (
        <>
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: "primary.main", color: "primary.contrastText" }}>
                    <Iconify icon="mdi:file-document-edit-outline" />
                </Avatar>
            </ListItemAvatar>
            <TruncatedListItemText primary={action.title} secondary="A draft schedule is ready to be published" />
            <Button onClick={go} color="primary" variant="outlined" size="small">
                Review
            </Button>
        </>
    )

    function go() {
        navigate(`/app/schedules/allDrafts!${uniqueId(action.referenceId)}/edit`)
    }
}
