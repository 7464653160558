import { XmlTaskContentTypes } from "event-definitions"

export function getXmlTaskContentTypes() {
    const { contentTypes } = XmlTaskContentTypes.call({ contentTypes: [] })
    const sortedTypes = contentTypes.sortBy((c) => c.priority)
    const output = {}
    sortedTypes.forEach((item) => {
        output[item.type] = item
    })
    return output
}
