import { Box } from "@mui/material"
import { TagsList } from "routes/schedule/plugins/baskets/tags/tagsList"
import { navigate } from "lib/routes/navigate"

export function TagList({ schedule }) {
    return (
        <Box sx={{ transform: "scale(0.8)", transformOrigin: "0 0", pl: 7 }}>
            <TagsList onClick={go} referenceId={schedule._id} schedule={schedule} />
        </Box>
    )

    function go(tag) {
        navigate(`/app/schedules?id=${tag.id}`)
    }
}
