import { Checkbox, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import noop from "lib/noop"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { BlockKeyboard } from "lib/@components/block-clicks"

export function ClientDataMigrationItem({ item, onSelect = noop }) {
    const { selectedItems } = useBoundContext()

    return (
        <BlockKeyboard>
            <ListItem disablePadding>
                <ListItemButton onClick={handleClick} dense>
                    <ListItemIcon>
                        <Checkbox edge="start" checked={selectedItems.includes(item)} tabIndex={-1} disableRipple />
                    </ListItemIcon>
                    <ListItemText primary={item.label} />
                </ListItemButton>
            </ListItem>
        </BlockKeyboard>
    )

    function handleClick() {
        onSelect(item)
    }
}
