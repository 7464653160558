import { CompareTasks } from "event-definitions"
import { ensureArray } from "lib/ensure-array"
import { SubsequentWrapper } from "routes/schedule/compare/subsequent-wrapper"
import { TaskHeaderBar } from "routes/schedule/compare/task-header-bar"
import { normalizedStringify } from "library/normalized-stringify"
import { Bound } from "lib/@components/binding/Bound"
import { cleanHTMLForComparison } from "routes/schedule/compare/ifNotSameText"

function wrapWithTask({ compare, to, target = compare, comparedWith }) {
    const otherTask = target !== compare ? compare : comparedWith
    return function mapIt(item) {
        return (
            <TaskHeaderBar
                label={target?.title ?? compare.title}
                task={target}
                other={otherTask}
                compare={() => otherTask}
                comparedWith={comparedWith}
            >
                <SubsequentWrapper task={target} compare={compare} to={to} target={target} comparedWith={comparedWith}>
                    <Bound to={otherTask}>{item}</Bound>
                </SubsequentWrapper>
            </TaskHeaderBar>
        )
    }
}

export const attributeMap = new WeakMap()

export function getAttributesFor(task) {
    if (!task) return undefined
    return attributeMap.get(task)
}

export function onlyStandardProperties(item) {
    return normalizedStringify(item, (k, v) => (k.startsWith("_") && k !== "_behaviours" ? undefined : v))
}

export function registerTaskComparitor(name, compareFn, onlyIfNotHeader) {
    CompareTasks.handleOnce(({ differences, compare, to, target, comparedWith, ...params }) => {
        if (compare?.isHeader || to?.isHeader || target?.isHeader) {
            if (onlyIfNotHeader) {
                return
            }
        }
        if (!to || !target) return
        let v1 = Object.get(compare, name, true)
        let v2 = Object.get(to, name, true)
        if (typeof v1 === "string") {
            v1 = cleanHTMLForComparison(v1)
        }
        if (typeof v2 === "string") {
            v2 = cleanHTMLForComparison(v2)
        }
        let result = null
        if (v1 !== undefined || v2 !== undefined) {
            // eslint-disable-next-line eqeqeq
            if (onlyStandardProperties(v1) !== onlyStandardProperties(v2)) {
                result = compareFn({ ...params, compare, to, target, differences, comparedWith, v1, v2 })
            }
        }
        if (result && (result.type || Array.isArray(result))) {
            const subTasks = ensureArray(result).map(wrapWithTask({ ...params, compare, to, target, comparedWith }))
            subTasks.forEach((task) => attributeMap.set(task, { ...params, name, compare, to, target, comparedWith }))

            differences.push(...subTasks)
        }
    })
}

export function setAttributeFor(item, props) {
    attributeMap.set(item, props)
    return item
}
