import { ScheduleSelectorToolbarStart, TreeToolbarLeft } from "slot-definitions"
import { Box, Button, Typography } from "@mui/material"
import { parentTreeItem } from "routes/schedule/tree/schedules/makeTreeItemsFromGroup"
import { OpenSchedule } from "event-definitions"
import { Hide, TreeRoot } from "library/tree"
import { navigate } from "lib/routes/navigate"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import Iconify from "minimals-template/components/Iconify"

TreeToolbarLeft("*").plug(<UpButton />)
ScheduleSelectorToolbarStart("*").plug(<UpButton if={(c) => c.inSelector} />)

function UpButton() {
    const { parentItem, inSelector } = useBoundContext()

    if (!parentItem?.[parentTreeItem]) return null
    const parentItemElement = parentItem[parentTreeItem]
    if (parentItem[TreeRoot] && !inSelector) return null
    if (parentItem[Hide]) return null

    return (
        <>
            <Box flexShrink={2}>
                <Button
                    size="small"
                    onClick={go}
                    sx={{
                        "&:hover": { color: "text.primary" },
                        mx: 1,

                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: "text.secondary",
                    }}
                    startIcon={<Iconify icon="mdi:arrow-upward" />}
                >
                    {ellipsis(parentItem[parentTreeItem].label)}
                </Button>
            </Box>
            {inSelector && (
                <Box flexGrow={2} flexShrink={10} sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                    <Typography variant="subtitle2">{parentItem?.label ?? ""}</Typography>
                </Box>
            )}
        </>
    )

    function go() {
        if (inSelector) {
            OpenSchedule(parentItemElement.id).raiseAsync(parentItemElement)
        } else {
            navigate(`/app/schedules?id=${parentItem[parentTreeItem].id}`)
        }
    }
}

function ellipsis(text = "", length = 16) {
    if (text.length > length) {
        return text.slice(0, length) + "..."
    }
    return text
}
