import { FrameAnchorType, Header, HorizontalPositionAlign, Paragraph, TextRun, VerticalPositionAlign } from "docx"
import { darkFont, lightFont } from "../../legacy-export-docx-consts"
export function header() {
    const textRunsLeft = [
        new TextRun({
            text: "SFG",
            ...darkFont,
        }),
        new TextRun({
            text: "20",
            ...lightFont,
        }),
    ]
    const textRunsRight = [
        new TextRun({
            text: "Amends",
            ...darkFont,
        }),
        new TextRun({
            text: " Document",
            ...lightFont,
        }),
    ]

    const header = new Header({
        children: [
            new Paragraph({
                frame: {
                    position: {
                        x: 0,
                        y: -1000,
                    },
                    width: 2000,
                    height: 300,
                    anchor: {
                        horizontal: FrameAnchorType.MARGIN,
                        vertical: FrameAnchorType.MARGIN,
                    },
                    alignment: {
                        x: HorizontalPositionAlign.LEFT,
                        y: VerticalPositionAlign.TOP,
                    },
                },
                children: textRunsLeft,
            }),
            new Paragraph({
                frame: {
                    position: {
                        x: 4500,
                        y: -1000,
                    },
                    width: 10000,
                    height: 300,
                    anchor: {
                        horizontal: FrameAnchorType.MARGIN,
                        vertical: FrameAnchorType.MARGIN,
                    },
                    alignment: {
                        x: HorizontalPositionAlign.RIGHT,
                        y: VerticalPositionAlign.TOP,
                    },
                },
                children: textRunsRight,
            }),
        ],
    })

    return header
}
