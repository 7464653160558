import { AsyncScheduleAndGroupFilter } from "event-definitions"
import { CriticalityProfileWrapper, PeriodProfileWrapper, SkillProfileWrapper } from "slot-definitions"
import { SelectablePeriod } from "routes/regime/plugins/regime-filtering/selectable-period"
import { SelectableSkill } from "routes/regime/plugins/regime-filtering/selectable-skill"
import { SelectableCriticality } from "routes/regime/plugins/regime-filtering/selectable-criticality"
import { filterRegime } from "routes/regime/plugins/regime-filtering/filter-regime"

AsyncScheduleAndGroupFilter.handle(filterRegime)

PeriodProfileWrapper.plug(<SelectablePeriod />)
SkillProfileWrapper.plug(<SelectableSkill />)
CriticalityProfileWrapper.plug(<SelectableCriticality />)
