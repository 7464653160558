import { ListItemBox } from "lib/@components/ListItemBox"
import { Repeat } from "lib/@components/repeat"
import { Code } from "routes/schedule/my-schedules/my-group-types/space/code"
import { pick } from "lib/pick"

export function Below({ schedule }) {
    return (
        <ListItemBox pl={5} pt={1} flexWrap="wrap" justifyContent="flex-start">
            <Repeat list={schedule?.allCodes?.sortBy("code")} item={<Code />} keyFn={pick("code")} />
        </ListItemBox>
    )
}
