import { AvailableFeatures } from "event-definitions"

export function getAvailableFeatures() {
    return AvailableFeatures.call({ features: [] })
        .filter(({ hideInList }) => !hideInList)
        .unique("id")
}

const features = {}
export const FEATURE_STATUS_INTERNAL_USE_ONLY = "Internal use only"
export const FEATURE_STATUS_READY_FOR_PROD = "Ready for production"
export const FEATURE_STATUS_READY_FOR_BETA = "Ready for BETA"
export const FEATURE_STATUS_NOT_READY = "Not ready"

/**
 * Registers a new feature with the given name and id.
 *
 * @param props - the properties of the feature
 * @param {int} props.numericalId - A numerical id for product/ops to associate with the feature (must be unique) - if
 *     hidden in list, should be 0
 * @param {string} props.name - The name of the feature.
 * @param {string} props.id - The id of the feature.
 * @param {string} props.description - The description of the feature, in HTML.
 * @param {boolean} props.readyForBeta - If the feature is ready for beta testing.
 * @param {boolean} props.readyForProduction - If the feature is ready for release on production.
 * @param {boolean} props.internalUseOnly - If the feature is meant only for internal use.
 * @param {boolean} props.hideInList - If the feature is to be hidden from selection.
 * @return {string} The id of the registered feature.
 */
export function registerFeature({
    numericalId,
    name,
    id,
    description,
    readyForBeta = false,
    readyForProduction = false,
    internalUseOnly = false,
    hideInList = false,
}) {
    if (!id) throw new Error("All features must have an id")
    if (features[id]) return id
    features[id] = true

    AvailableFeatures.handleOnce(({ features }) => {
        features.push({
            numericalId,
            name,
            demand: `feature-${id}`,
            id,
            description,
            status: getStatus(internalUseOnly, readyForProduction, readyForBeta),
            hideInList,
        })
    })
    return id
}

export function getDemandForFeature(feature) {
    return `feature-${feature}`
}

function getStatus(internalUseOnly, readyForProduction, readyForBeta) {
    let status = ""
    if (internalUseOnly) {
        status = FEATURE_STATUS_INTERNAL_USE_ONLY
    } else if (readyForProduction) {
        status = FEATURE_STATUS_READY_FOR_PROD
    } else if (readyForBeta) {
        status = FEATURE_STATUS_READY_FOR_BETA
    } else {
        status = FEATURE_STATUS_NOT_READY
    }
    return status
}
