import { useReference } from "lib/@hooks/use-reference"
import { useState } from "react"
import { htmlToText } from "lib/html-to-text"
import { Box, Typography } from "@mui/material"
import { BoundEditableRichText } from "lib/@components/editable/editableRichText"
import { If } from "lib/@components/switch"
import { Accordion } from "minimals-template/components/accordion"
import { CollapseAllTasks } from "event-definitions"
import { useScheduleSettings } from "routes/schedule/lib/useScheduleSettings"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { normalizeActions } from "library/normalizeActions"
import { useDarkMode } from "lib/use-dark-mode"
import { createSlot } from "lib/@components/slot/create-slot"
import { ScheduleIntroductionContentAfter } from "slot-definitions"

export const ScheduleIntroductionContents = createSlot("ScheduleIntroduction")
export const ScheduleTopmost = createSlot("ScheduleTopmost")
export const ScheduleBottom = createSlot("ScheduleBottom")

export function ScheduleIntroduction({ schedule }) {
    const [reference, save] = useReference({}, "showInfo")
    const { showAll, editMode } = useBoundContext()
    const [{ showDetail }] = useScheduleSettings()
    const [openIntro, setOpenIntro] = useState(reference.showIntro ?? true)
    const hasContent = htmlToText((schedule?.intro?.content ?? "") + (schedule?.intro?.note ?? "")).trim().length > 0
    reference.showIntro = openIntro
    CollapseAllTasks.useEvent(() => setOpenIntro(false))
    save({ showIntro: openIntro })
    const isDark = useDarkMode()
    return (
        <>
            {(!!hasContent || editMode || showAll) && (
                <Accordion
                    alwaysOpen={showDetail || !schedule.tasks.length || showAll}
                    noCollapse
                    divider
                    title={
                        <Box role="button" sx={{ cursor: "pointer", py: 0.5 }}>
                            <strong>Introduction</strong>
                        </Box>
                    }
                    open={openIntro}
                    onChange={(v) => {
                        setOpenIntro(v)
                        save({ showIntro: v })
                    }}
                >
                    <ScheduleIntroductionContents.Slot>
                        <Box mt={2} mb={3}>
                            <BoundEditableRichText field="intro.content" transformIn={normalizeActions} />
                            <ScheduleIntroductionContentAfter.Slot />
                            <If truthy={htmlToText(schedule.intro?.note).trim().length || editMode}>
                                <Box sx={{ mt: 2, pl: 2, pr: { lg: 16, md: 4, xs: 0, fontSize: 14 } }}>
                                    <Typography variant="overline">Notes</Typography>
                                    <Box sx={{ color: isDark ? "#aaa" : "#333" }}>
                                        <BoundEditableRichText label="Notes" field="intro.note" />
                                    </Box>
                                </Box>
                            </If>
                        </Box>
                    </ScheduleIntroductionContents.Slot>
                </Accordion>
            )}
        </>
    )
}
