import { BlockClicks } from "lib/@components/block-clicks"
import { useStoreState } from "lib/store"
import Iconify from "minimals-template/components/Iconify"
import { RotatingDropDownMenu } from "lib/@components/rotating-dropdown-menu"

export function RelatedSchedules({ custom, tailored, skillId }) {
    const [openCustom, setOpenCustom] = useStoreState(`related_custom_${skillId}`, false)
    const [openTailored, setOpenTailored] = useStoreState(`related_tailored_${skillId}`, false)
    return (
        <>
            {custom > 0 ? (
                <BlockClicks>
                    <RotatingDropDownMenu
                        tooltipMessage={"Custom schedules that use this skill"}
                        state={openCustom}
                        onClick={() => {
                            toggle("custom")
                        }}
                        icon={<Iconify icon="mdi:file-document-outline" />}
                    />
                </BlockClicks>
            ) : null}
            {tailored > 0 ? (
                <BlockClicks>
                    <RotatingDropDownMenu
                        tooltipMessage={"Tailored schedules that use this skill"}
                        state={openTailored}
                        onClick={() => {
                            toggle("tailored")
                        }}
                        icon={<Iconify icon="pajamas:issue-type-maintenance" />}
                    />
                </BlockClicks>
            ) : null}
        </>
    )

    function toggle(type) {
        if (type === "custom") {
            if (openCustom === false && tailored) {
                setOpenTailored(false)
            }
            setOpenCustom((open) => !open)
        } else {
            if (openTailored === false && custom) {
                setOpenCustom(false)
            }
            setOpenTailored((open) => !open)
        }
    }
}
