import { ContextMenuItem } from "lib/@components/context-menu-bar/context-menu-item"
import { RegimeContextMenu } from "routes/regime/regime-context-menu"
import { notInSelector } from "routes/facilities/lib/when-parent-item"
import { deleteRegime } from "routes/schedule/my-schedules/my-custom-schedules/controller/delete-regime"
import { and } from "lib/logic"
import { hasDemand } from "lib/authorization/has-demand"

RegimeContextMenu.plug(<DeleteRegime if={and(notInSelector, () => hasDemand("manageRegime"))} />)

export function DeleteRegime({ regime }) {
    return <ContextMenuItem onClick={remove} icon="material-symbols:delete" label="Delete Regime" />

    async function remove() {
        await deleteRegime(regime._id)
    }
}
