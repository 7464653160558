import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const treeSecurity = api(async (id) =>
    query(
        gql`
            query getTreeSecurity($id: String!) {
                treeSecurity(id: $id) {
                    rules
                }
            }
        `,
        { id },
        { returns: "treeSecurity" }
    )
)
