import { Box, Card, Grid, Typography, Stack, Alert, Skeleton } from "@mui/material"
import { PercentageBar } from "lib/@components/percentageBar"
import { getRegimeAccessCount } from "./controller/get-regime-access-count.controller"

export function RegimeCountIndicator() {
    const regimeAccessCount = getRegimeAccessCount.useResults()

    if (!regimeAccessCount)
        return (
            <Grid item xs={12} lg={4} xl={3}>
                <Skeleton variant="rectangular" animation="wave" width={250} height={265} />
            </Grid>
        )

    return (
        <Grid item xs={12} lg={4} xl={3}>
            <Card
                sx={{ display: "flex", height: 1, flexDirection: "column" }}
                data-cy="regimesInUseCard"
                className="regimeItem"
            >
                <Stack p={1.5} height={1}>
                    <Typography variant="subtitle1">Regimes in use</Typography>
                    <Box flex={1} />
                    <PercentageBar
                        text={"In use"}
                        partialValue={regimeAccessCount.inUse}
                        fullValue={regimeAccessCount.total}
                        color={"success"}
                        dataCyPrepend={"inUse"}
                    />
                    <PercentageBar
                        text={"Available"}
                        partialValue={regimeAccessCount.available}
                        fullValue={regimeAccessCount.total}
                        color={"info"}
                        dataCyPrepend={"available"}
                    />
                    <PercentageBar
                        text={"You can access"}
                        partialValue={regimeAccessCount.canAccess}
                        fullValue={regimeAccessCount.total}
                        color={"warning"}
                        dataCyPrepend={"youCanAccess"}
                    />
                    <Box flex={1} />
                    <Alert sx={{ alignItems: "center", fontSize: "12px" }} severity="info">
                        Contact SFG20 to get more Maintenance Regimes
                    </Alert>
                </Stack>
            </Card>
        </Grid>
    )
}
