import { TableCell, Typography } from "@mui/material"
import { plug } from "lib/@components/slot/plug"

plug("ScheduleItem", <Title priority={100} />)

function Title({ schedule }) {
    return (
        <TableCell>
            <Typography sx={{ lineHeight: 1 }} variant="caption">
                {schedule.title}
            </Typography>
        </TableCell>
    )
}
