import { useState } from "react"
import { useRefresh } from "lib/@hooks/useRefresh"
import { Bound } from "lib/@components/binding/Bound"
import { DialogBox } from "lib/@components/dialogBox"
import { Button, DialogContent, List, Stack, Typography } from "@mui/material"
import { Repeat } from "lib/@components/repeat"
import { Rule } from "routes/regime/plugins/regime-task-security/rule"
import { NoResults } from "lib/@components/no-results/no-results"

export function SecuritySettings({ rules }) {
    const [state, setState] = useState([...rules])
    const refresh = useRefresh()
    return (
        <Bound refresh={refresh} target={state}>
            <DialogBox
                actions={[
                    <Button disabled={state.includes("*")} onClick={all} color="secondary" key="all">
                        Allow all users
                    </Button>,
                    <Button onClick={add} variant="outlined" key="add">
                        Add Rule
                    </Button>,
                ]}
                title="App Security"
                value={state}
                okDisabled={Object.isEqual(rules, state)}
            >
                <DialogContent>
                    <Stack spacing={2}>
                        <Typography>
                            On this screen you can enter a series of email address patterns. Only users who have an
                            email address that ends with one of the patterns may access the schedules through the SFG20
                            application.
                        </Typography>
                        <List>
                            <Repeat
                                keyFn={(_, u) => u}
                                list={state}
                                item={<Rule />}
                                fallback={<NoResults>No users can access the tasks</NoResults>}
                            />
                        </List>
                    </Stack>
                </DialogContent>
            </DialogBox>
        </Bound>
    )

    function add() {
        setState((s) => [...s.filter((v) => !!v.trim() && v !== "*").unique(), ""])
    }

    function all() {
        if (state.includes("*")) return
        setState((s) => [...s.filter((v) => !!v.trim()).unique(), "*"])
    }
}
