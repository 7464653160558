import decamelize from "decamelize"
import { Paragraph } from "docx"

export function formatObject(value) {
    const entries = Object.entries(value)
    if (!entries.length) return null
    const nestedString = entries
        .map((entry) => `${decamelize(entry[0].toString(), " ").capitalize()}: ${entry[1].toString()}`)
        .join(", ")

    return new Paragraph({
        text: nestedString,
        style: "defaultParagraph",
    })
}
