import { Box, Stack, Typography } from "@mui/material"
import { useSkills } from "routes/@lib/skills"
import { UserCard } from "routes/conformity/plugins/your-details/user-card"
import { ListItemBox } from "lib/@components/ListItemBox"
import Iconify from "minimals-template/components/Iconify"
import { PageBreadCrumbs } from "slot-definitions"

export function UserDetailsCard({ user, avatar, breadcrumb }) {
    return (
        <UserCard user={user} avatar={avatar}>
            <UserInformation user={user} breadcrumb={breadcrumb} />
        </UserCard>
    )
}

function UserInformation({ user, breadcrumb }) {
    return (
        <>
            <PageBreadCrumbs.Plug>
                <>{breadcrumb}</>
            </PageBreadCrumbs.Plug>

            <Typography variant="body1">{user?.metadata?.displayName ?? null}</Typography>
            <Typography variant="body1" sx={{ color: "text.secondary" }} component="div">
                <Skills user={user} />
            </Typography>
            <Typography variant="body2" component="div">
                <ListItemBox alignItems="flex-start" mt={2} pr={{ xs: 2, lg: 6 }}>
                    <Box width={"fit-content"} pr={{ xs: 2, lg: 6 }}>
                        <Stack spacing={2} width="fit-content">
                            {!!user?.phone && (
                                <ListItemBox spacing={1} alignItems="flex-start">
                                    <Iconify
                                        sx={{ lineHeight: 0, fontSize: 24, width: 32 }}
                                        icon="material-symbols:phone-enabled"
                                    />
                                    <Box flex={1}>{user?.phone}</Box>
                                </ListItemBox>
                            )}
                            {!!user?.email && (
                                <ListItemBox spacing={1} alignItems="flex-start">
                                    <Iconify
                                        sx={{ lineHeight: 0, fontSize: 24, width: 32, minWidth: 32 }}
                                        icon="mdi:email"
                                    />
                                    <Box flex={1}>{user?.email}</Box>
                                </ListItemBox>
                            )}
                        </Stack>
                    </Box>
                    <Box width={"fit-content"}>
                        <Stack spacing={2}>
                            {!!user?.company && (
                                <ListItemBox spacing={1} alignItems="flex-start">
                                    <Iconify
                                        sx={{ lineHeight: 0, fontSize: 24, width: 32 }}
                                        icon="ri:building-2-line"
                                    />
                                    <Box flex={1}>{user?.company}</Box>
                                </ListItemBox>
                            )}
                            {!!user?.metadata?.jobTitle && (
                                <ListItemBox spacing={1} alignItems="flex-start">
                                    <Iconify sx={{ lineHeight: 0, fontSize: 24, width: 32 }} icon="mdi:toolbox" />
                                    <Box flex={1}>{user?.metadata?.jobTitle}</Box>
                                </ListItemBox>
                            )}
                        </Stack>
                    </Box>
                </ListItemBox>
            </Typography>
        </>
    )
}

export function Skills({ user }) {
    const [skillIndex] = useSkills()

    const skills = (user?.metadata?.skills ?? [])
        .map((s) => skillIndex[s]?.Skilling)
        .filter(Boolean)
        .map((s, i, ar) => (
            <Typography sx={{ display: "inline" }} key={s}>
                {i && i === ar.length - 1 ? "and " : ""}
                {s}
                {i < ar.length - 2 ? ", " : " "}
            </Typography>
        ))
    return !!skills.length && <Box display="inline">{skills}</Box>
}
