import { Box, ListItemButton } from "@mui/material"
import noop from "lib/noop"
import { ListItemBox } from "lib/@components/ListItemBox"
import { FolderItemPart } from "routes/schedule/tree/schedules/folder-item-part"
import { SelectedChildren } from "routes/schedule/components/selected-children"
import { getIconForType } from "ioc"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { TruncatedTypography } from "lib/@components/truncated-typography"

export function BasketItem({ name, last, Component = ListItemButton, type }) {
    const icon = getIconForType(type)
    const { onClick = noop } = useBoundContext()
    return (
        <Component divider={!last} onClick={onClick}>
            <ListItemBox spacing={1} className="basket-item">
                <FolderItemPart>
                    <SelectedChildren />
                </FolderItemPart>
                <Box>{icon}</Box>
                <Box sx={{ lineHeight: 1.1 }} flex={1}>
                    <TruncatedTypography>{name}</TruncatedTypography>
                </Box>
            </ListItemBox>
        </Component>
    )
}
