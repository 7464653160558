import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const downloadTasksAsExcelSpreadsheet = api((shareLinkId) =>
    query(
        gql`
            query DownloadTasks($shareLinkId: String!) {
                downloadTasksAsExcelSpreadsheet(shareLinkId: $shareLinkId)
            }
        `,
        { shareLinkId },
        { fetchPolicy: "network-only", returns: "downloadTasksAsExcelSpreadsheet" }
    )
)
