import noop from "lib/noop"
import { useEffect, useRef, useState } from "react"
import { Box, Pagination } from "@mui/material"
import { NotOnMobile } from "lib/@components/mobile"
import { ListItemBox } from "lib/@components/ListItemBox"
import { setFromValueParam } from "lib/setFromEvent"
import { Repeat } from "lib/@components/repeat"
import { NoResults } from "lib/@components/no-results/no-results"
import { Frag } from "lib/@components/slot/frag"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { createPortal } from "react-dom"
import { createEvent } from "library/local-events"
import { PaginationPortalLoaded } from "lib/@components/pagination-portal"
import { noChange } from "lib/@hooks/useRefresh"

export const PortalUpdated = createEvent("PortalUpdated")

/**
 * RequestPageRepeat component handles pagination and rendering of a collection of items which can be paged from a
 * server.
 *
 * @param {Object} params - The parameters for the RequestPageRepeat component.
 * @param {Array} params.collection - The collection of items to paginate.
 * @param {number} [params.total=0] - The total number of items.
 * @param {number} [params.page=1] - The initial page number.
 * @param {Function} [params.setSkip=noop] - Function to set the skip value.
 * @param {Function} [params.setTake=noop] - Function to set the take value.
 * @param {ReactNode} params.children - Child components to be rendered.
 * @param {HTMLElement} [params.portal] - If set then the paginator for the repeat will be in a portal (necessary for
 *     tables)
 * @param {Function} [params.setSettings=noop] - Function to set pagination settings.
 * @param {boolean} [params.showBottom=true] - Whether to show paginator at the bottom.
 * @param {boolean} [params.showTop=false] - Whether to show paginator at the top.
 * @param {Component} [params.ContentWrapper=Frag] - Component to wrap the content.
 * @param {Component} [params.Component=Box] - Component to wrap the entire structure.
 * @param {Function} [params.useRetrieve=(v) => v] - Function to retrieve paginated data.
 * @param {string} [params.refreshKey=""] - Key to trigger refresh effect.
 * @param {ReactNode} [params.fallback=<NoResults />] - Fallback component when no results are found.
 * @param {Object} [params.sx] - Style properties for the root component.
 * @param {string} [params.color="primary"] - Color variant for the paginator.
 * @param {string} [params.variant] - Variant of the paginator.
 * @param {Array} [params.list=params.collection] - List of items to render.
 * @param {number} [params.pageSize=10] - Number of items per page.
 * @param {Object} [params.props] - Additional props for the Repeat component.
 *
 * @return {ReactNode} The RequestPageRepeat component.
 */
export function RequestPageRepeat({
    collection,
    total = 0,
    page: initialPage = 1,
    setSkip = noop,
    setTake = noop,
    children,
    portal,
    setSettings = noop,
    showBottom = true,
    showTop = false,
    ContentWrapper = Frag,
    Component = Box,
    useRetrieve = (v) => v,
    refreshKey = "",
    fallback = <NoResults />,
    sx,
    color = "primary",
    variant,
    list = collection,
    pageSize = 10,
    ...props
}) {
    PortalUpdated.useRefresh(noChange)
    PaginationPortalLoaded.useRefresh(noChange)
    const { paginationPortal = portal ? false : undefined } = useBoundContext()
    const nop = useRef(Math.ceil(total / pageSize))
    // eslint-disable-next-line prefer-const
    let [page, setPage] = useState(initialPage)
    ;({ total, list } = useRetrieve({ skip: (page - 1) * pageSize, take: pageSize, total, list }))
    const numberOfPages = (nop.current = Math.ceil(total / pageSize))

    useEffect(() => {
        setSkip((page - 1) * pageSize)
        setSettings({ skip: (page - 1) * pageSize, take: pageSize })
        setTake(pageSize)
    }, [page, setSkip, setSettings, setTake, initialPage, pageSize])

    useEffect(() => {
        setPage(1)
    }, [refreshKey, numberOfPages])

    const paginator = (
        <ListItemBox mb={1}>
            {children}
            <Box flex={1} />
            <Pagination
                size="small"
                variant={variant}
                onChange={setFromValueParam((v) => {
                    setPage(v)
                })}
                color={color}
                count={numberOfPages}
                page={page}
                data-cy="request-page-repeat-paginator"
            />
        </ListItemBox>
    )

    if (!list) return null

    return (
        <Component sx={sx}>
            {numberOfPages > 1 && showTop && (
                <>
                    {paginationPortal
                        ? createPortal(paginator, paginationPortal)
                        : paginationPortal === false
                          ? null
                          : paginator}
                </>
            )}

            <ContentWrapper>
                {list.length ? <Repeat {...props} list={list.slice(0, pageSize)} /> : fallback}
            </ContentWrapper>
            <NotOnMobile>
                {numberOfPages > 1 && showBottom && (
                    <>
                        {paginationPortal
                            ? createPortal(paginator, paginationPortal)
                            : paginationPortal === false
                              ? null
                              : paginator}
                    </>
                )}
            </NotOnMobile>
        </Component>
    )
}
