const MULT = {
    D: 1,
    H: 1 / 24,
    W: 7,
    M: 30,
    Y: 365,
    U: 0,
}

export function sortDateLabel(label) {
    const value = +label.slice(0, -1)
    const mult = MULT[label.slice(-1)[0]]
    return value * mult
}
