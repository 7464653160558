import { RefreshSchedule } from "event-definitions"
import { noChange } from "lib/@hooks/useRefresh"
import { SelectorTitleText } from "slot-definitions"
import { VersionInfo } from "routes/schedule/lib/version-info"
import { getSchedule } from "routes/schedule/lib/get-schedule"

SelectorTitleText.plug(<VersionInfo priority={120} />)

const dummySchedule = {}

export function useSchedule(code, version = "no_v") {
    RefreshSchedule.useRefresh(noChange)
    const schedule = getSchedule.useResults(code, version) ?? dummySchedule

    return {
        loading: schedule === dummySchedule,
        schedule,
    }
}
