import { combineSearchParams } from "lib/combine-search-params"

/**
 * Fetch from the application server
 * @param {string} url - the URL to fetch
 * @param {object} options - the fetch options to use
 * @returns {Promise<Response>}
 */
export function server(url, options) {
    options ??= {}
    options.credentials = "include"
    if (!url.startsWith("http")) {
        if (url.startsWith("/")) url = url.slice(1)
        url = `${window.appProtocol || process.env.REACT_APP_PROTOCOL}://${
            window.appServerLocation || process.env.REACT_APP_SERVER
        }/${url}`
    }
    return fetch(combineSearchParams(url), options)
}
