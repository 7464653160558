import { TreeToolbar } from "slot-definitions"
import { hasDemand } from "lib/authorization/has-demand"
import { useLocation, useParams } from "react-router-dom"
import { Button } from "@mui/material"
import { navigate } from "lib/routes/navigate"
import { useMemo } from "react"
import { useSearchParam } from "lib/@hooks/use-search-param"
import { useTreeItem } from "routes/schedule/lib/useTreeItem"
import { PlanRoot } from "library/tree/constants"
import { parentTreeItem } from "library/tree"
import Iconify from "minimals-template/components/Iconify"
import { isInRegime } from "routes/facilities/lib/when-parent-item"

TreeToolbar("**").plug(
    <RegimeToggleButton
        priority={-1000}
        if={(...props) =>
            hasDemand("manageRegime") &&
            !window.location.pathname.includes("review") &&
            (isInRegime(...props) || window.location.pathname.includes("facilities") || props[0]?.parentId === "myTree")
        }
    />
)

function RegimeToggleButton() {
    const { pathname } = useLocation()
    const [id] = useSearchParam("id")
    const { planId } = useParams()
    const [itemId, isViewer, review] = useMemo(() => {
        if (pathname) {
            if (pathname.includes("facilities")) {
                return [pathname.split("/").at(-1), true]
            }
            if (pathname.includes("review")) {
                return [`${planId.split("|").at(-1)}`, false, true]
            }
            return [id, false]
        }
        return [undefined, undefined]
    }, [pathname, id, planId])
    const treeItem = useTreeItem(itemId)

    if (pathname.includes("facilities")) {
        return (
            !pathname.includes("schedule") && (
                <Button onClick={go} sx={{ color: "primary.main" }} startIcon={<Iconify icon="tabler:edit" />}>
                    Edit
                </Button>
            )
        )
    }

    return (
        <Button onClick={go} sx={{ color: "primary.main" }} startIcon={<Iconify icon="carbon:view-filled" />}>
            Preview
        </Button>
    )

    function go() {
        if (id === "myTree") {
            navigate("/app/facilities")
        } else if (isViewer) {
            const destination = pathname.split("/").at(-1)

            if (destination === "facilities") {
                navigate("/app/schedules?id=myTree", { replace: true })
            } else {
                navigate(`/app/schedules?id=${destination}`, { replace: true })
            }
        } else {
            const path = []
            let scan = treeItem
            while (scan && !scan[PlanRoot]) {
                if (review) {
                    path.push(`publish|${scan.id}`)
                }
                scan = scan[parentTreeItem]
            }

            if (path.length) {
                navigate(`/app/facilities/facility/${path.reverse().join("/")}`, { replace: true })
            } else {
                navigate(`/app/facilities/${review ? `publish|${scan.id}` : scan.id}`, { replace: true })
            }
        }
    }
}
