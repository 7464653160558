import { Checkbox, Switch, TableCell, TableHead, TableRow } from "@mui/material"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function RegimeAccessTableHeader({ rows = [], selected, handleSelectAllClick, showSelectAllToggle = false }) {
    const { setChangedUsers, changedUsers } = useBoundContext()
    const allToggled = selected.every((row) => row.hasAccess)

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    {showSelectAllToggle && (
                        <Checkbox
                            data-cy="regime-access-select-all"
                            indeterminate={selected.length > 0 && selected.length < rows.length}
                            checked={rows.length > 0 && selected.length === rows.length}
                            onChange={handleSelectAllClick}
                        />
                    )}
                </TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Job Title</TableCell>
                <TableCell align="left">User Roles</TableCell>
                {selected.length === 0 && <TableCell align="left">Access</TableCell>}
                {selected.length > 0 && (
                    <TableCell align="left">
                        <Switch
                            checked={allToggled}
                            onChange={handleToggleSelected}
                            data-cy="regime-access-toggle-all"
                        />
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    )

    function handleToggleSelected(event) {
        const updatedUsers = []
        selected.forEach((row) => {
            if (row.disabled) return
            if (row.hasAccess !== event.target.checked) {
                row.hasAccess = event.target.checked
                updatedUsers.push(row)
            }
        })

        const newChangedUsers = [...changedUsers]
        updatedUsers.forEach((updatedUser) => {
            const index = newChangedUsers.findIndex((user) => user.userId === updatedUser.userId)
            if (index !== -1) {
                newChangedUsers.splice(index, 1)
            } else {
                newChangedUsers.push(updatedUser)
            }
        })

        setChangedUsers(newChangedUsers)
    }
}
