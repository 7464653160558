import { Bound } from "lib/@components/binding/Bound"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { createEvent } from "library/local-events"
import { noChange } from "lib/@hooks/useRefresh"
import { useEffect } from "react"

let context = {}

const RefreshContext = createEvent("RefreshContext")
const ResetContext = createEvent("ResetContext")

export function CurrentContext({ children }) {
    RefreshContext.useRefresh(noChange)
    return <Bound {...context}>{children}</Bound>
}

export function TakeContext({ children }) {
    ResetContext.useRefresh(noChange)
    useEffect(() => () => ResetContext.raiseOnce(), [])

    const currentContext = useBoundContext()
    if (currentContext !== context) {
        context = currentContext
        RefreshContext.raiseOnce()
    }
    return children
}
