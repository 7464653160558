import { Box, Paper, Skeleton, Typography } from "@mui/material"
import { Bound } from "lib/@components/binding/Bound"
import { ListItemBox } from "lib/@components/ListItemBox"
import { HeaderBar } from "routes/schedule/compare/header-bar"
import { Task } from "routes/schedule/tasks/task"
import { sameText } from "./ifNotSameText"
import { useState } from "react"
import { clone } from "lib/clone"
import { useMeasurement } from "lib/@hooks/useMeasurement"
import { RefreshTaskHeader } from "event-definitions"
import { Approved } from "routes/schedule/compare/approved"

export function TaskHeaderBar({ label, task, other, children, compareFn, ...props }) {
    // const { comparisons, accepted } = useBoundContext()
    // const allForThisTask = comparisons.filter((c) => c.props.task?.id === task?.id)
    // const isDone = allForThisTask.every((c) => accepted.has(c))

    const [localTask] = useState(clone(task))
    const [{ width }, attach] = useMeasurement()
    compareFn ??= compareTwoObjects
    RefreshTaskHeader.useRefresh()
    return (
        <HeaderBar
            {...props}
            label={label}
            extra={
                !!other && (
                    <Bound editMode={false} alwaysOpenTask>
                        <Box mb={3} />
                        <Box sx={{ mx: -3, bgcolor: "action.hover" }}>
                            <Typography sx={{ mt: 2, pl: 3 }} variant="overline">
                                Overview
                            </Typography>
                        </Box>

                        <Box mx={-3} pt={1} pb={1} bgcolor="background.paper">
                            <ListItemBox bgcolor="background.paper" alignItems="flex-start">
                                <Box flex={1}>
                                    <Bound comparisonFunction={compareFn(localTask, other, task)}>
                                        <Paper>
                                            <Task item={task} index={0} />
                                        </Paper>
                                    </Bound>
                                </Box>
                                <Approved width={"1px"}>
                                    {<Box width={"2px"} sx={{ my: -1 }} alignSelf="stretch" bgcolor="action.hover" />}
                                    {other ? (
                                        <Box flex={1} opacity={!other ? 0 : 1}>
                                            <Bound comparisonFunction={compareFn(localTask, other, task)}>
                                                <Paper>
                                                    <Task item={other} index={0} />
                                                </Paper>
                                            </Bound>
                                        </Box>
                                    ) : (
                                        <Box flex={1} ref={attach} position="relative">
                                            <Skeleton
                                                variant="rectangular"
                                                width={width}
                                                animation={false}
                                                height={56}
                                            />
                                        </Box>
                                    )}
                                </Approved>
                            </ListItemBox>
                        </Box>

                        <Box sx={{ mx: -3, bgcolor: "action.hover" }}>
                            <Typography sx={{ pl: 3 }} variant="overline">
                                Individual Changes
                            </Typography>
                        </Box>
                    </Bound>
                )
            }
        >
            {children}
        </HeaderBar>
    )
}

export function compareTwoObjects(original, other, target) {
    return function makeComparison({ path }) {
        const v1 = Object.get(target, path, true)
        const v2 = Object.get(other, path, true)
        const v3 = Object.get(original, path, true)
        if (typeof v1 === "string") {
            return [!sameText({ v1, v2 }), !sameText({ v1: v3, v2 })]
        }
        return [!Object.isEqual(v1, v2), !Object.isEqual(v3, v2)]
    }
}
