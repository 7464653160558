import { XmlGroupTypes } from "event-definitions"

XmlGroupTypes.handle(({ groupTypes }) => {
    groupTypes.push({
        type: "regime",
        label: "Regime",
        description: "This outputs the location within the regime where the schedule exists.",
        priority: 200,
    })
})
