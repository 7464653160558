export const FEATURE_FLAG_REGIME_TASK_MANAGEMENT = "regime-task-management"

export const FEATURE_FLAG_REGIME_TASK_MANAGEMENT_DESCRIPTION = `This feature flag hides the de-scoped features on the maintenance regime for the MVP of asset-mapping:
<ol>
 <li>- Tasks tab</li>
 <li>- Overview tab</li>
 <li>- Scheduled Library Updates tab</li>
 <li>- Variations tab</li>
 </ol>
 `
