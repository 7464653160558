import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { MenuItem } from "@mui/material"
import { navigate } from "lib/routes/navigate"
import { useReference } from "lib/@hooks/use-reference"
import { REGIME_ASSETS_TAB } from "routes/asset-register/plugins/regime-display/regime-display.runtime"

export function WhereItem({ item: { id, where } }) {
    const { regime } = useBoundContext()

    const tabKey = `${REGIME_ASSETS_TAB}Tab`
    const [, save] = useReference({ [tabKey]: null }, `tab-${REGIME_ASSETS_TAB}`)
    return (
        !!regime && (
            <MenuItem onClick={go} dense>
                {where}
            </MenuItem>
        )
    )

    function go() {
        navigate(`/app/schedules?id=~${regime.id}!${id.split("!").at(0)}`)
        save({ [tabKey]: "original" })
    }
}
