import { gql } from "@apollo/client"

export const GET_SCHEDULES = gql`
    query Schedules($query: String!, $type: SearchType) {
        scheduleSearch(query: $query, limit: 250, type: $type) {
            count
            schedules {
                id
                _id
                version
                code
                notLicensed
                coreTagDescriptions
                title
                allCodes {
                    code
                    description
                }
                taskTypes {
                    Red
                    Pink
                    Amber
                    Green
                    NotSpecified
                }
            }
        }
    }
`
