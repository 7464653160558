import ReactApexChart from "lib/@components/chart"
// @mui
import { useTheme } from "@mui/material/styles"
import { Box, Typography } from "@mui/material"
// utils
import { fNumber } from "minimals-template/components/utils/formatNumber"
// components
import { BaseOptionChart } from "minimals-template/components/chart"
import WidgetCard from "lib/widget-card"

export default function AppWidget({ title, total, color = "primary", chartData, ...other }) {
    const theme = useTheme()

    const chartOptions = Object.merge(BaseOptionChart(), {
        colors: [color.startsWith("#") ? color : theme.palette[color].main],
        chart: { sparkline: { enabled: true } },
        legend: { show: false },
        plotOptions: {
            radialBar: {
                hollow: { size: "78%" },
                track: { margin: 0 },
                dataLabels: {
                    name: { show: false },
                    value: {
                        offsetY: 6,
                        color: theme.palette.text.primary,
                        fontSize: theme.typography.subtitle2.fontSize,
                    },
                },
            },
        },
    })
    return (
        <WidgetCard {...other} bannerColor={color.startsWith("#") ? color : `${color}.main`}>
            {chartData !== undefined && (
                <ReactApexChart type="radialBar" {...chartData} options={chartOptions} width={86} height={86} />
            )}

            <Box sx={{ ml: 3, color: "text.primary" }}>
                <Typography variant="h4" className="number" data-cy="in-use-heading">
                    {" "}
                    {typeof total === "number" ? fNumber(total) : total}
                </Typography>
                <Typography className="title" variant="body2" sx={{ opacity: 0.72 }}>
                    {title}
                </Typography>
            </Box>
        </WidgetCard>
    )
}
