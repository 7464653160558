// @mui
import { ListItemButton, ListItemIcon, ListItemText, ListSubheader } from "@mui/material"
import { alpha, styled } from "@mui/material/styles"
// utils
import cssStyles from "minimals-template/components/utils/cssStyles"
// config
import { ICON, NAVBAR } from "config"

// ----------------------------------------------------------------------

export const ListItemStyle = styled(ListItemButton, {
    shouldForwardProp: (prop) => prop !== "active",
})(({ active, depth, highlight, theme }) => ({
    position: "relative",
    textTransform: "capitalize",
    width: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1.5),
    marginBottom: `2px !important`,
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
    minHeight: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
    // Active item
    ...(active && {
        "&:hover": {
            backgroundColor: alpha(
                theme.palette.primary.main,
                0.1 + theme.palette.action.selectedOpacity / (active / 2)
            ),
        },
        color: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity / (active / 2)),
    }),
    // Active item
    ...(active &&
        depth !== 1 &&
        !highlight && {
            color: theme.palette.text.primary,
            backgroundColor: "transparent",
        }),
    // Sub item
    ...(depth && {
        ...(depth > 1 && {
            height: "auto",
        }),
        ...(depth > 2 && {
            paddingLeft: theme.spacing(depth),
        }),
    }),
}))

// ----------------------------------------------------------------------

export const ListItemTextStyle = styled(ListItemText, {
    shouldForwardProp: (prop) => prop !== "isCollapse",
})(({ isCollapse, theme }) => ({
    transition: theme.transitions.create(["width", "opacity"], {
        duration: theme.transitions.duration.shorter,
    }),
    ...(isCollapse && {
        width: 0,
        opacity: 0,
    }),
}))

// ----------------------------------------------------------------------

export const ListItemIconStyle = styled(ListItemIcon)({
    width: ICON.NAVBAR_ITEM,
    height: ICON.NAVBAR_ITEM,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": { width: "100%", height: "100%" },
})

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled(ListSubheader)(({ theme }) => ({
    ...theme.typography.overline,
    borderRadius: theme.shape.borderRadius,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create("opacity", {
        duration: theme.transitions.duration.shorter,
    }),
    ...cssStyles(theme).bgBlur(),
}))
