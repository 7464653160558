import { appRoute } from "routes/app/home.runtime"
import { RootPlugs } from "slot-definitions"
import { getSharingLinks } from "routes/sharing/controller/get-sharing-links"
import { useEffect } from "react"
import { RefreshNavBar } from "event-definitions"
import { registerConditionalPageNavigation } from "lib/routes/register-conditional-page-navigation"
import { hasDemand } from "lib/authorization/has-demand"
import { Lazy } from "lib/make-lazy"
import { registerFeature } from "lib/features"

appRoute.register("sharing", <Lazy importer={() => import("routes/sharing/sharing")} extract="Sharing" />)

export const FEATURE_FLAG_COMPANION_TASKS = registerFeature({
    numericalId: 9,
    name: "Companion Tasks",
    id: "companionTasks",
    description: `Enables the system to create sharing links that have user checks in them, currently not to be used in production.`,
})

RootPlugs.plug(<HasSharing />)

let hasLinks = false

function HasSharing() {
    const links = getSharingLinks.useResults({ take: 0 }) ?? null

    hasLinks = !!links?.total
    useEffect(() => {
        RefreshNavBar.raiseOnce()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasLinks])
    return null
}

registerConditionalPageNavigation(
    () => hasLinks && hasDemand("canMakeShare"),
    "/app/sharing",
    "Sharing Links",
    "material-symbols:share",
    {
        group: "Planning",
        priority: 155,
    }
)
