import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const assetRegisterExcelExport = api((registerId) =>
    query(
        gql`
            query AssetRegisterExcelExport($registerId: String!) {
                assetRegisterExcelExport(registerId: $registerId)
            }
        `,
        { registerId },
        { returns: "assetRegisterExcelExport", fetchPolicy: "network-only" }
    )
)
