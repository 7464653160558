import { Badge, Box, Button, Stack, Tooltip } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"

export function RotatingDropDownMenu({ tooltipMessage, state, onClick, icon, count }) {
    return (
        <Tooltip title={tooltipMessage} arrow>
            <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                role="button"
                component={Button}
                data-cy="rotating-drop-down"
                sx={{ cursor: "pointer", color: "text.primary" }}
                onClick={onClick}
            >
                <Badge badgeContent={count} />
                {icon}
                <Box
                    sx={{
                        fontSize: 24,
                        lineHeight: 0,
                        transition: "transform linear 0.3s",
                        transform: `rotate(${state ? -180 : 0}deg)`,
                    }}
                >
                    <Iconify icon="mdi:arrow-down-drop" />
                </Box>
            </Stack>
        </Tooltip>
    )
}
