import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const getAssetRegistriesForRegime = api(async function getAssetRegistriesForRegime(regime) {
    if (!regime) return []
    return query(
        gql`
            query GetAssetRegisters($regime: String!) {
                getAssetRegistriesForRegime(regimeTree: $regime)
            }
        `,
        {
            regime,
        },
        {
            returns: "getAssetRegistriesForRegime",
        }
    )
})
