import { uniqueId } from "library/database/split-id"
import { useTreeItem } from "routes/schedule/lib/useTreeItem"
import { useSchedule } from "routes/schedule/lib/useSchedule"
import { Bound } from "lib/@components/binding/Bound"
import { DialogContent, DialogTitle } from "@mui/material"
import { useDialog } from "lib/@hooks/useDialog"
import { Inventory } from "routes/regime/assets/inventory"
import { OkCancel } from "lib/@components/okCancel"
import { Pictures } from "routes/regime/extension-edit/pictures"
import { TruncatedTypography } from "lib/@components/truncated-typography"
import { usePayload } from "routes/schedule/controller/use-payload"
import { Payload } from "routes/schedule/controller/payload"

export function InventoryDialog({ groupId, scheduleId }) {
    const payload = usePayload(groupId, uniqueId(scheduleId))
    const tree = useTreeItem(groupId)
    const { schedule } = useSchedule(uniqueId(scheduleId))
    return (
        !!payload &&
        !!schedule && (
            <Bound payload={payload ?? {}} schedule={schedule} target={schedule} isExtended={true}>
                <DialogTitle>{tree?.label}</DialogTitle>
                <DialogContent>
                    <TruncatedTypography variant="subtitle1" gutterBottom data-cy="inventory-dialog-schedule-title">
                        {schedule.title}
                    </TruncatedTypography>
                    <Inventory />
                    <Payload>
                        <Pictures />
                    </Payload>
                </DialogContent>
                <OkCancel value={payload} />
            </Bound>
        )
    )
}

export function useInventoryDialog(groupId, id) {
    return useDialog(<InventoryDialog groupId={groupId} scheduleId={id} />, { fullScreen: false })
}
