import { useEffect, useRef } from "react"
import { generate } from "library/guid"
import { BusyAdd, BusyRemoved } from "event-definitions"

export function useBusy(isBusy, description = "", delay = 0) {
    const timer = useRef()
    isBusy = !!isBusy
    useEffect(() => {
        const id = generate()
        clearTimeout(timer.current)
        if (isBusy) {
            timer.current = setTimeout(() => {
                BusyAdd.raise({ id, description })
            }, delay)
        }
        return () => {
            clearTimeout(timer.current)
            return BusyRemoved.raise({ id })
        }
    }, [isBusy, description, delay])
}
