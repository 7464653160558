import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"

export async function removeItemsFromBasket(id, items) {
    await mutate(
        gql`
            mutation removeItems($id: String!, $items: [String]!) {
                removeItemsFromBasket(id: $id, ids: $items)
            }
        `,
        {
            id,
            items,
        }
    )
}
