import { registerTab } from "lib/@components/tabs"
import { REGIME_ASSETS_TAB } from "routes/asset-register/plugins/regime-display/regime-display.runtime"
import { and } from "lib/logic"
import { isInEditableRegime } from "routes/facilities/lib/when-parent-item"
import { ZeroUWizardTab } from "routes/regime/plugins/zero-u-wizard/zero-u-wizard-tab"
//import { Wizard } from "routes/regime/plugins/zero-u-wizard/wizard"

registerTab({
    tab: REGIME_ASSETS_TAB,
    id: "zero-u-wizard",
    title: "Risk Assessments",
    predicate: and(isInEditableRegime),
    // content: (
    //     <Wizard
    //         $lazy
    //         summariseBy={[
    //             {
    //                 summaryField: "skill",
    //                 label: "skill",
    //             },
    //             {
    //                 summaryField: "classification",
    //                 label: "classification",
    //             },
    //             { summaryField: "task.title", label: "task", field: "title" },
    //         ]}
    //     />
    // ),
    content: <ZeroUWizardTab priority={151} $lazy />,
})
