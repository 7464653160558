import { Box, LinearProgress, TableCell, TableRow, Typography } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { formatTime } from "lib/format-time"
import { SelectedRadio } from "routes/asset-register/plugins/regime-display/plugins/grouped-tasks/selected-radio"

export function SummaryEntry({ item: { key, value, minutes, count }, total, icon }) {
    return (
        <>
            <TableRow sx={{ height: 8 }} />
            <TableRow>
                <TableCell>
                    <SelectedRadio selection={{ [key[0]]: value[0] }} />
                </TableCell>
                <TableCell>
                    <Box color="#444" sx={{ lineHeight: 0, fontSize: 18, color: "primary.main" }}>
                        <Iconify icon={icon} />
                    </Box>
                </TableCell>
                <TableCell>
                    <Typography
                        variant="caption"
                        component="div"
                        sx={{ width: 130, overflow: "hidden", whiteSpace: "normal" }}
                    >
                        {value[0]}
                    </Typography>
                </TableCell>
                <TableCell align="right">
                    <Typography variant="caption">{count}</Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={3} />
                <TableCell align="right">
                    <Typography variant="caption" color="text.secondary">
                        {formatTime(minutes)}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={2} />
                <TableCell colSpan={2} align="right">
                    <LinearProgress
                        variant="determinate"
                        value={(minutes / total) * 100}
                        sx={{ width: 1 }}
                        color="primary"
                    />
                </TableCell>
            </TableRow>
            <TableRow sx={{ height: 8 }} />
        </>
    )
}
