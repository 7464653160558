// @mui
import { Box, Stack, Typography } from "@mui/material"
// assets
import { UploadIllustration } from "minimals-template/assets"
import { useMobile } from "lib/@components/mobile"

// ----------------------------------------------------------------------

export default function BlockContent({ header = "Select an image", message = "Choose a picture" }) {
    const isMobile = useMobile(() => true)
    return (
        <Stack
            spacing={2}
            alignItems="center"
            justifyContent="center"
            direction={{ xs: "column", md: "row" }}
            sx={{ width: 1, textAlign: { xs: "center", md: "left" } }}
        >
            <UploadIllustration sx={{ width: 220 }} />

            <Box sx={{ p: 3 }}>
                <Typography gutterBottom variant="h5">
                    {header}
                </Typography>

                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    {isMobile ? (
                        <Typography
                            variant="body2"
                            component="span"
                            sx={{ color: "primary.main", textDecoration: "underline" }}
                        >
                            {message}
                        </Typography>
                    ) : (
                        <>
                            Drop files here or&nbsp;
                            <Typography
                                variant="body2"
                                component="span"
                                sx={{ color: "primary.main", textDecoration: "underline" }}
                            >
                                choose one
                            </Typography>
                        </>
                    )}
                </Typography>
            </Box>
        </Stack>
    )
}
