import { ScheduleSelectorToolbar } from "slot-definitions"
import { ContextMenu } from "lib/@components/context-menu-bar"
import { makeLazy } from "lib/make-lazy"
import { conditionalComponent } from "lib/@components/slot/conditional-component"

const TagSelectorTopBar = makeLazy(
    () => import("routes/schedule/plugins/baskets/tags/tagSelectorTopBar"),
    "TagSelectorTopBar"
)

ScheduleSelectorToolbar("*").plug(conditionalComponent(isATag, <TagSelectorTopBar />))

export const TagContextMenu = ContextMenu("tag")

export function isATag({ context: { parentId } }) {
    return parentId.includes("-Tag-")
}
