import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"

export async function closeTailoredReview(id) {
    return mutate(
        gql`
            mutation CloseTailoredReview($id: String!) {
                closeTailoredReview(id: $id)
            }
        `,
        { id },
        { returns: "closeTailoredReview" }
    )
}
