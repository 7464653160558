import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function IsEditMode({ children }) {
    const { editMode } = useBoundContext()
    return !!editMode && children
}

export function NotEditMode({ children }) {
    const { editMode } = useBoundContext()
    return !editMode && children
}
