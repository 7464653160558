import { parentSome } from "library/tree"
import { PlanRoot } from "library/tree/constants"
import { hasDemand } from "lib/authorization/has-demand"
import { resolveAsFunction } from "lib/resolve-as-function"

export function whenParentItem(predicate, output) {
    return ({ context }) => predicate(context.parentItem) && output
}

export function notSharing() {
    return hasDemand("!sharing") && hasDemand("user") && hasDemand("schedules")
}

export function isInRegime(context) {
    return !context?.parentId?.startsWith("smart_") && parentSome(context?.parentItem ?? context, (c) => c[PlanRoot])
}

export function not(result) {
    const fn = resolveAsFunction(result)
    return (...params) => !fn(...params)
}

export function isInSelector(item) {
    return item.inSelector
}

export function notInSelector(item) {
    return !item.inSelector && !item.inTree
}

export function isInEditableRegime(item) {
    return parentSome(
        item?.parentItem ?? item,
        (c) => c[PlanRoot] && c.folderHandling && !c?._id.includes("|") && !window.location.pathname.includes("review")
    )
}

export function isInPublishedRegime(item) {
    return parentSome(item?.parentItem ?? item, (c) => c[PlanRoot] && !c.folderHandling)
}

export function isPublishedRegime(item) {
    return item.parentItem?.[PlanRoot] && !item.parentItem?.folderHandling
}

export function isInRegimeFolder(item) {
    return isInRegime(item) && !item[PlanRoot]
}

export function whenInMaintenanceRegime(output) {
    return whenParentItem((item) => isInRegime(item), output)
}
