import { useState } from "react"
import { TextField } from "@mui/material"
import { setFromEvent } from "lib/setFromEvent"
import { bind } from "lib/@components/binding/bind"

export const BoundBlurChangeTextField = bind(<BlurChangeTextField fullWidth variant="outlined" />)

export function BlurChangeTextField({ value, onChange, ...props }) {
    const [current, setCurrent] = useState(value)
    return (
        <TextField
            {...props}
            onChange={setFromEvent(setCurrent)}
            value={current}
            onBlur={change}
            onKeyUp={handleEnter}
        />
    )

    function change() {
        onChange(current)
    }

    function handleEnter(event) {
        if (event.key === "Enter") change()
    }
}
