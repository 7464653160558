import { RefreshPayload } from "event-definitions"
import { noChange } from "lib/@hooks/useRefresh"
import { Bound } from "lib/@components/binding/Bound"
import { BoundCheckBox } from "lib/@components/standard-checkbox"
import { BlockClicks } from "lib/@components/block-clicks"
import { Box, Stack, Tooltip, Typography } from "@mui/material"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function ZeroU() {
    RefreshPayload.useRefresh(noChange)
    const { payloadTarget, target } = useBoundContext()
    if (!target.tasks) {
        return null
    }
    const task = (target.tasks[payloadTarget.id] ??= {})

    const period = task?.frequency?.period ?? payloadTarget.frequency?.period
    const interval = task?.frequency?.interval ?? payloadTarget.frequency?.interval
    return (
        (period === "Unknown" || interval === 0) && (
            <Bound target={task}>
                <Box data-cy="confirm-ou-checkbox">
                    <KeepZeroU />
                </Box>
            </Bound>
        )
    )
}

function KeepZeroU() {
    return (
        <Tooltip
            arrow
            title={
                <Stack spacing={2} sx={{ p: 1 }}>
                    <Typography component="span">
                        Check this box to suppress the 0U warning, if you believe that the task does not need a
                        frequency and timing. Otherwise you can update the frequency to fit your needs.
                    </Typography>
                    <Typography color="text.secondary" component="span">
                        0U (Zero U) which means the frequency is to be defined by the user based on a site-specific
                        assessment.
                    </Typography>
                </Stack>
            }
        >
            <BlockClicks>
                <BoundCheckBox field="suppressZeroU" label="Confirm 0U" />
            </BlockClicks>
        </Tooltip>
    )
}
