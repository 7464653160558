import { DecorateIndex } from "event-definitions"
import { idIndex } from "routes/schedule/lib/getTreeItemIndex"
import { uniqueId } from "library/database/split-id"
import { parentTreeItem } from "library/tree"
import { GROUP } from "library/constants"

DecorateIndex.handleOnce(add)

function add({ index }) {
    const licensed = Object.values(index[idIndex])
        .map("0")
        .filter((s) => !s.notLicensed && !s.custom && !s?.data?.type && s._id.includes("schedule"))

    const notLicensed = Object.values(index[idIndex])
        .map("0")
        .filter((s) => s.notLicensed)

    const custom = Object.values(index[idIndex])
        .map("0")
        .filter((s) => s.custom && !s.isDraft && !s.isRetired)

    index.licensed = {
        id: "licensed",
        label: "Licensed",
        type: GROUP,
        [parentTreeItem]: index.schedules,
        children: licensed.map((l) => ({ ...l, id: `licensed!${uniqueId(l.id)}`, isAlias: true })),
    }
    index.notLicensed = {
        id: "notLicensed",
        label: "Not Licensed",
        type: GROUP,
        showUnlicensed: true,
        [parentTreeItem]: index.schedules,
        children: notLicensed.map((l) => ({ ...l, id: `notLicensed!${uniqueId(l.id)}`, isAlias: true })),
    }
    index.custom = {
        id: "custom",
        label: "Custom Schedules",
        type: GROUP,
        [parentTreeItem]: index.schedules,
        children: custom.map((l) => ({ ...l, id: `${uniqueId(l.id)}`, isAlias: true })),
    }
}
