import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"

import { makeBusyFunction } from "lib/@components/busy/make-busy-function"

export const uploadPack = makeBusyFunction(
    api((pack, name) =>
        mutate(
            gql`
                mutation UploadPack($pack: String!, $name: String) {
                    uploadPack(pack: $pack, createClient: $name)
                }
            `,
            { pack, name },
            { returns: "uploadPack" }
        )
    ),
    "Uploading Pack"
)

export const removeAllUsers = api((client) =>
    mutate(
        gql`
            mutation RemoveAllUsers($client: String) {
                removeAllUsers(client: $client)
            }
        `,
        { client },
        { returns: "removeAllUsers" }
    )
)
