import { useReference } from "lib/@hooks/use-reference"
import { useEffect, useRef, useState } from "react"
import { Box, Button, Menu, MenuItem, Portal, Stack } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { Bound } from "lib/@components/binding/Bound"
import { SortModes } from "slot-definitions"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

const NAMES = {
    name: "Alphabetical",
    code: "Schedule Code",
    natural: "Relevance",
}

const NATURAL_SORT = ["search"]

export function ScheduleSort() {
    const { parentId } = useBoundContext()
    const [{ sortMode: originalSort }, save] = useReference({ sortMode: "name" }, "sortMode")
    const [anchor, setAnchor] = useState(null)
    const ref = useRef()
    const sortMode = !NATURAL_SORT.includes(parentId) && originalSort === "natural" ? "name" : originalSort
    useEffect(() => {
        if (sortMode !== originalSort) {
            save({ sortMode })
        }
    }, [originalSort, sortMode, save])
    return (
        <>
            <Stack
                component={Button}
                className="schedule-sort"
                onClick={open}
                sx={{
                    cursor: "pointer",
                    fontSize: "85%",
                    color: "text.primary",
                    overflow: "hidden",
                    width: "max-content",
                    flexWrap: "nowrap",
                }}
                role="menu"
                direction="row"
                alignItems="center"
                spacing={1}
            >
                <Box sx={{ fontWeight: "bold" }}>Sort:</Box>
                <Box sx={{ fontWeight: "normal" }} ref={ref}>
                    {NAMES[sortMode]}
                </Box>
                <Box sx={{ fontSize: 24, lineHeight: 0 }}>
                    <Iconify icon="mdi:arrow-down-drop" />
                </Box>
            </Stack>
            <Portal>
                <Menu sx={{ zIndex: 3000 }} onClose={close} open={Boolean(anchor)} anchorEl={anchor}>
                    <Bound set={set}>
                        <SortModes.Slot>
                            <MenuItem selected={sortMode === "name"} onClick={set("name")}>
                                Alphabetical
                            </MenuItem>
                            <MenuItem selected={sortMode === "code"} onClick={set("code")}>
                                Schedule Code
                            </MenuItem>
                            {!!NATURAL_SORT.includes(parentId) && (
                                <MenuItem selected={sortMode === "natural"} onClick={set("natural")}>
                                    Relevance
                                </MenuItem>
                            )}
                        </SortModes.Slot>
                    </Bound>
                </Menu>
            </Portal>
        </>
    )

    function open() {
        setAnchor(ref.current)
    }

    function close() {
        setAnchor(null)
    }

    function set(value) {
        return function execute() {
            save({ sortMode: value })
            close()
        }
    }
}
