import { useDialog } from "lib/@hooks/useDialog"
import { ScheduleById } from "routes/schedule/scheduleById"
import { Bound } from "lib/@components/binding/Bound"
import { Container, Divider } from "@mui/material"
import { useMobile } from "lib/@components/mobile"
import { Done } from "lib/@components/done"

export function usePreviewSchedule(id, { fullScreen = true, maxWidth = "lg", sx } = {}) {
    return useDialog(<PreviewSchedule id={id} />, { fullScreen, maxWidth, sx })
}

function PreviewSchedule({ id }) {
    const isMobile = useMobile()
    return (
        <Bound hideUpBar={true} isPreview={true}>
            <Container
                maxWidth="lg"
                className="styled-bullets"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    overflow: "hidden",
                    height: 1,
                    maxHeight: isMobile ? `calc(100vh - 64px)` : `calc(100vh - 92px)`,
                }}
            >
                <ScheduleById id={id} />
            </Container>
            <Divider sx={{ my: 0.5 }} />
            <Container maxWidth="lg">
                <Done />
            </Container>
        </Bound>
    )
}
