import { FolderContextMenu } from "routes/schedule/folder-manager/lib/folder-context-menu"
import { parentSome } from "library/tree"
import { RegimeButtons } from "slot-definitions"
import { CreateFacility } from "routes/schedule/my-schedules/my-group-types/facility/createFacility"
import { EditFacility } from "routes/schedule/my-schedules/my-group-types/facility/editFacility"
import { AddFacility } from "routes/schedule/my-schedules/my-group-types/facility/addFacility"
import { RegimeWelcome } from "routes/schedule/folder-manager/folder-management"
import AppWelcome from "minimals-template/pages/sections/@dashboard/general/app/AppWelcome"
import { Box, Button, Stack } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { CanContainGroup, ScheduleFolderIcon } from "event-definitions"
import { PlanRoot } from "library/tree/constants"
import { isInEditableRegime, isInPublishedRegime, isInRegimeFolder } from "routes/facilities/lib/when-parent-item"
import { and } from "lib/logic"
import { replaceAllWith } from "lib/@components/slot/replace-all-with"
import { FACILITY } from "library/constants"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Secure } from "lib/authorization/secure"
import { Frag } from "lib/@components/slot/frag"

FolderContextMenu.plug(convertToFacility)

CanContainGroup.handleOnce((props) => {
    props.canContain &&=
        props.source.data?.type !== "facility" || !parentSome(props.target, (i) => i.data?.type === "facility")
})

function convertToFacility({ context }) {
    return (
        context.parentItem &&
        !context.parentItem[PlanRoot] &&
        !isInPublishedRegime(context) &&
        (!context.parentItem.data?.type ||
            context.parentItem.data?.type === "simple" ||
            context.parentItem.data?.type === "group") &&
        !parentSome(context.parentItem, (i) => i.data?.type === "facility" || i[PlanRoot]) && (
            <CreateFacility priority={1000} />
        )
    )
}

FolderContextMenu.plug(<EditFacility if={and(isInEditableRegime, isInRegimeFolder)} />)

RegimeButtons.plug(
    <Secure demand="manageRegime" fallback={<Frag />} if={(c) => c.parentItem.folderHandling}>
        <AddFacility />
    </Secure>
)

RegimeWelcome(FACILITY).plug(replaceAllWith(<Welcome />))

function Welcome({ toAdd, addSchedules }) {
    const { parentItem } = useBoundContext()
    const folderIcon = ScheduleFolderIcon.call({ icon: undefined, data: parentItem })
    const { icon } = folderIcon.icon.props
    return (
        <Box mb={2}>
            <AppWelcome
                title={"Facility"}
                img={
                    <Box position="relative" overflow="hidden" flexGrow={1} width={150} height={160}>
                        <Box position="absolute" right={20} zIndex={200} opacity={0.5}>
                            <Iconify
                                sx={{
                                    width: 90,
                                    height: 90,
                                    opacity: 0.3,
                                    mr: 3,
                                    transform: "rotate(15deg) scale(1.7) translateY(25px)",
                                    color: "primary.dark",
                                }}
                                icon={icon}
                            />
                        </Box>
                    </Box>
                }
                action={
                    <Stack direction="row" spacing={1}>
                        {!!toAdd.length && (
                            <Button variant="contained" onClick={addSchedules}>
                                Add {toAdd.length} Selected Schedule{toAdd.length !== 1 ? "s" : ""}
                            </Button>
                        )}
                    </Stack>
                }
                description={
                    <>
                        <Box>
                            A {parentItem.data.type} is used as a way to structure your maintenance regime, making it
                            easier to identify where items live and to represent real-life locations. Subfolders can be
                            inserted below this one, allowing for a tree structure with parent-child relationships. When
                            editing this {parentItem.data.type} extra information such as location, contacts and
                            documents can be entered.
                        </Box>
                        <Box mt={1}>
                            Add some schedules to this {parentItem.data.type} by selecting them and clicking on the Add
                            button on the toolbar.
                        </Box>
                    </>
                }
            />
        </Box>
    )
}
