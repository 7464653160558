export const updateTypeEnum = {
    SCHEDULE: "schedule",
    TECHNICAL: "technical",
}

export function selectLocalStorageType(type) {
    switch (type) {
        case updateTypeEnum.SCHEDULE:
            return "latestOpenedScheduleBulletin"
        case updateTypeEnum.TECHNICAL:
            return "latestOpenedTechnicalBulletin"
        default:
            throw new Error("not a valid type in local storage selection")
    }
}
