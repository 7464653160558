import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"

export const getClientGroups = api(function getClientGroups() {
    return query(
        gql`
            query GetClientTrees {
                getClientGroups {
                    _id
                    name
                    modified
                    data
                    hasLive
                    hasReady
                    facilities
                    images
                }
            }
        `,
        {},
        { returns: "getClientGroups", fetchPolicy: "network-only" }
    )
})
