import { useState } from "react"
import { FilterSchedule, OpenDraft, OpenSchedule } from "event-definitions"
import { ScheduleSelectorAfterList, ScheduleSelectorBeforeList, ScheduleSelectorToolbar } from "slot-definitions"
import { Bound } from "lib/@components/binding/Bound"
import { Box, Button, DialogContent, DialogTitle } from "@mui/material"
import Schedules from "routes/schedule/schedules"
import { NotOnMobile, OnMobile } from "lib/@components/mobile"
import { ListItemBox } from "lib/@components/ListItemBox"
import { useDialog } from "lib/@hooks/useDialog"
import { SearchSchedules } from "routes/schedule/my-schedules/my-custom-schedules/search-schedules"
import { ScrollIntoView } from "lib/@components/scrollIntoView"
import { usePreviewSchedule } from "routes/schedule/preview-schedule"
import { ScheduleSelectorComponent } from "routes/schedule/components/schedule-selector-component"
import { SCHEDULE } from "library/constants"
import { OkCancel } from "lib/@components/okCancel"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { replaceAllWithPredicate } from "lib/@components/slot/replace-all-with-predicate"
import { Frag } from "lib/@components/slot/frag"
import { itemsInDialog } from "routes/schedule/lib/items-in-dialog"
import Iconify from "minimals-template/components/Iconify"

export function NotInSelector({ children }) {
    const { inSelector } = useBoundContext()
    return !inSelector && children
}

export function useSelectSchedule({ message = undefined } = {}) {
    return useDialog(
        <Bound pageSizeCalculator={itemsInDialog(40, 0.7, 300)}>
            <SelectSchedule title={message} />
        </Bound>,
        {
            dialogProps: { maxWidth: "lg", sx: { height: "100vh", width: "60vw", minWidth: 500 } },
        }
    )
}

ScheduleSelectorToolbar("*").after.plug(replaceAllWithPredicate((_, context) => context.inSelector, <Frag />, Infinity))
ScheduleSelectorBeforeList("*").after.plug(
    replaceAllWithPredicate((_, context) => context.inSelector, <Frag />, Infinity)
)
ScheduleSelectorAfterList("*").after.plug(
    replaceAllWithPredicate((_, context) => context.inSelector, <Frag />, Infinity)
)

export function SelectSchedule({ title = "Choose a base schedule to derive from" }) {
    const [scrollButtons, setScrollButtons] = useState()
    const [value, setValue] = useState()
    const [previous, setPrevious] = useState(["_root"])
    const [parentId, setParentId] = useState("_root")
    const preview = usePreviewSchedule(value?.id)
    OpenSchedule("*").before.useEvent((schedule) => {
        if (schedule?.$?.itemType === "retired") {
            errorSnackbar("You can't use a retired schedule here")
            return false
        }
        if (schedule?.code === "00-01") {
            errorSnackbar("You can't use the Overarching Introduction")
            return false
        }
        if (schedule.type === SCHEDULE || schedule.$?.type === SCHEDULE || schedule._id?.includes("schedule")) {
            if (!schedule.retired) {
                setValue(schedule)
            }
        } else {
            setParentId(schedule.id)
        }
        return false
    })
    OpenDraft("*").before.useEvent(() => {
        errorSnackbar("You can't use a draft schedule here")
        return false
    })

    FilterSchedule.after.useEvent(filterRetired)

    return (
        <Bound
            paginationPortal={scrollButtons}
            disableCreation={true}
            inSelector={true}
            transformScheduleItem={wrapInSelectable}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Schedules>
                    <Box overflow="hidden" data-cy="dialog-schedule-selector">
                        <Box display="flex" flexDirection="column" height={1} flex={1} py={2}>
                            <SearchSchedules />
                            <OnMobile>
                                <ListItemBox>
                                    <NotOnMobile>
                                        <Box width="30%" />
                                    </NotOnMobile>
                                    {previous.length > 1 && (
                                        <Box>
                                            <Button
                                                onClick={pop}
                                                startIcon={<Iconify icon="mdi:arrow-left" />}
                                                variant="contained"
                                            >
                                                Back
                                            </Button>
                                        </Box>
                                    )}
                                </ListItemBox>
                            </OnMobile>
                            <ScheduleSelectorComponent key={parentId} id={parentId} onSelect={push} />
                        </Box>
                    </Box>
                </Schedules>
            </DialogContent>
            <ListItemBox pr={4}>
                <Box flex={1} />
                <Box ref={setScrollButtons} />
            </ListItemBox>

            <OkCancel value={value} okCaption={!value ? "Ok" : `Use ${value.code}: ${value.label}`.truncate(40)}>
                {value && (
                    <Button onClick={preview} color="primary">
                        Preview
                    </Button>
                )}
            </OkCancel>
        </Bound>
    )

    function filterRetired({ items }) {
        return { items: items.filter((s) => !s.retired) }
    }

    function wrapInSelectable(children, item) {
        return value?.id && (item.id === value?.id || item._id === value?._id) ? (
            <Box sx={{ bgcolor: "action.selected" }}>
                {children}
                <ScrollIntoView />
            </Box>
        ) : (
            children
        )
    }

    function pop() {
        const last = previous.at(-2)
        setPrevious((previous) => previous.slice(0, -1))
        setParentId(last)
    }

    function push(item) {
        setPrevious((previous) => [...previous, item.id])
        setParentId(item.id)
    }
}
