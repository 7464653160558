import { OverviewWidgets, StatsPanels, StatsTabs } from "slot-definitions"
import { Grid, Tab } from "@mui/material"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useSearchParam } from "lib/@hooks/use-search-param"
import { useCallback, useEffect, useState } from "react"
import { generate } from "library/guid"
import { createEvent } from "library/local-events"
import useAsync from "lib/@hooks/useAsync"
import { Bound } from "lib/@components/binding/Bound"
import { getComplianceReport } from "routes/facilities/plugins/overview/controller/get-compliance-report"
import { getTreeId } from "routes/schedule/lib/getTreeId"
import { noChange, useRefresh } from "lib/@hooks/useRefresh"
import { HasInvalidated } from "event-definitions"
import noop from "lib/noop"
import { usePlanFor } from "routes/plan/controller/use-plan"
import { Feature, isEnabled } from "lib/@components/feature"
import { TabPanel } from "@mui/lab"
import { FEATURE_FLAG_PLANNING } from "routes/plan/plan.runtime"

StatsTabs.plug(<Tab value="overview" label="Overview" if={() => isEnabled("plan")} />)
StatsPanels.plug(
    <Feature feature={FEATURE_FLAG_PLANNING}>
        <TabPanel value="overview">
            <Overview />
        </TabPanel>
    </Feature>
)

export const ComplianceResults = createEvent("ComplianceResults")

export function useComplianceResults(query) {
    const [results, setResults] = useState({})
    const [id] = useState(() => generate())
    const { registerReportRequest = noop } = useBoundContext()
    useEffect(() => {
        registerReportRequest(id, query)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registerReportRequest, id, JSON.stringify(query)])
    ComplianceResults(id).useEvent(setResults)
    return results
}

function ReportRequestor({ parentItem }) {
    const refresh = useRefresh(noChange)
    const context = useBoundContext()

    const [reports, setReports] = useState({})
    HasInvalidated("getChartData").useEvent(refresh)
    context.registerReportRequest = useCallback(
        (id, query) => {
            setReports((r) => ({ ...r, [id]: query }))
        },
        [setReports]
    )
    useAsync(runReports, null, [Object.keys(reports), refresh.id])
    return null

    function prepId() {
        let id = parentItem._id
        if (!id) {
            id = getTreeId(parentItem.id)
        }
        return id
    }

    async function runReports() {
        const result = await getComplianceReport({
            plan: prepId(),
            group: parentItem.id,
            windowStart: new Date().addYears(-1).beginningOfDay(),
            effectiveDate: new Date().endOfDay(),
            queries: Object.values(reports),
        })
        if (!result) return
        Object.keys(reports).forEach((id, i) => {
            ComplianceResults(id).raise(result[i])
        })
    }
}

export function Overview() {
    const [date] = useSearchParam("date", new Date().beginningOfDay().toISOString())
    const context = useBoundContext()
    const { parentItem } = context
    const [plan] = usePlanFor(parentItem)

    return (
        plan && (
            <Bound effectiveDate={Date.create(date).beginningOfDay()} parentItem={parentItem}>
                <ReportRequestor parentItem={parentItem} />
                <Grid container spacing={2} sx={{ mt: 2, px: 2 }}>
                    <OverviewWidgets.Slot />
                </Grid>
            </Bound>
        )
    )
}
