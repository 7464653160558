import { Frag } from "lib/@components/slot/frag"
import { makeLazy } from "lib/make-lazy"

export const DocIllustration = makeLazy(() => import("./illustration_doc"), "default", <Frag />)
export const SeoIllustration = makeLazy(() => import("./illustration_seo"), "default", <Frag />)
export const UploadIllustration = makeLazy(() => import("./illustration_upload"), "default", <Frag />)
export const ForbiddenIllustration = makeLazy(() => import("./illustration_403"), "default", <Frag />)
export const SeverErrorIllustration = makeLazy(() => import("./illustration_500"), "default", <Frag />)
export const BookingIllustration = makeLazy(() => import("./illustration_booking"), "default", <Frag />)
export const CheckInIllustration = makeLazy(() => import("./illustration_checkin"), "default", <Frag />)
export const PageNotFoundIllustration = makeLazy(() => import("./illustration_404"), "default", <Frag />)
export const CheckOutIllustration = makeLazy(() => import("./illustration_checkout"), "default", <Frag />)
export const MotivationIllustration = makeLazy(() => import("./illustration_motivation"), "default", <Frag />)
export const ComingSoonIllustration = makeLazy(() => import("./illustration_coming_soon"), "default", <Frag />)
export const MaintenanceIllustration = makeLazy(() => import("./illustration_maintenance"), "default", <Frag />)
export const OrderCompleteIllustration = makeLazy(() => import("./illustration_order_complete"), "default", <Frag />)
export const EmptyContentIllustration = makeLazy(() => import("./illustration_empty_content"), "default", <Frag />)
export const SentIcon = makeLazy(() => import("./icon_sent"), "default", <Frag />)
export const PlanFreeIcon = makeLazy(() => import("./icon_plan_free"), "default", <Frag />)
export const PlanStarterIcon = makeLazy(() => import("./icon_plan_starter"), "default", <Frag />)
export const PlanPremiumIcon = makeLazy(() => import("./icon_plan_premium"), "default", <Frag />)
