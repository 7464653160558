import { Alert, Stack, Typography } from "@mui/material"
import { DialogBox } from "lib/@components/dialogBox"
import LoadingScreen from "minimals-template/components/LoadingScreen"
import { PagedRepeat } from "lib/@components/paged-repeat"
import { useState } from "react"
import { Bound } from "lib/@components/binding/Bound"
import { ClientDataMigrationItem } from "./client-data-migration-item"
import { SelectAllCheckbox } from "./select-all-checkbox"

export function ClientDataMigrationDialog({ type, subscriptionId, query, warning }) {
    const { data, loading } = query.useResults.status(subscriptionId)
    const [selectedItems, setSelectedItems] = useState([])

    if (loading) return <LoadingScreen />

    return (
        <Bound selectedItems={selectedItems}>
            <DialogBox title={`Migrate ${type.titleize()}`} value={selectedItems} okDisabled={selectedItems.length < 1}>
                {data && data.length > 0 ? (
                    <Stack spacing={2} mt={2}>
                        <Typography>The following {type} can be migrated:</Typography>
                        {data.length > 1 && <SelectAllCheckbox data={data} onSelect={handleSelectAll} />}
                        <PagedRepeat list={data} item={<ClientDataMigrationItem onSelect={handleToggle} />} />
                        {!!warning && (
                            <Alert severity="warning">
                                <Typography variant="body2">{warning}</Typography>
                            </Alert>
                        )}
                    </Stack>
                ) : (
                    <Typography mt={2}>No {type} available</Typography>
                )}
            </DialogBox>
        </Bound>
    )

    function handleToggle(item) {
        if (selectedItems.includes(item)) {
            setSelectedItems((c) => c.filter((c) => c !== item))
        } else {
            setSelectedItems((c) => [...c, item])
        }
    }

    function handleSelectAll(allChecked) {
        if (allChecked) {
            setSelectedItems([])
        } else {
            setSelectedItems(data)
        }
    }
}
