import { handle } from "library/local-events"

handle("settings.update.server_demands", (setting) => {
    setting.value = setting.value
        ?.split(",")
        ?.filter(Boolean)
        ?.map((d) => d.trim())
        ?.unique()
        ?.join(",")
})
