import { OverviewWidgets } from "slot-definitions"
import { StatsWidget } from "routes/facilities/plugins/overview/plugins/summaries/stats-widget"
import { COLOR_FROM_CLASSIFICATION } from "library/SFG20/constants"

OverviewWidgets.plug(
    <StatsWidget title="Completed" color="primary.main" query={{ completed: true, minInterval: 24 }} lg={6} md={6} />
)

OverviewWidgets.plug(
    <StatsWidget title="Overdue" color="error.dark" query={{ completed: false, minInterval: 24 }} lg={6} md={6} />
)

OverviewWidgets.plug(
    <StatsWidget
        title="Overdue Red"
        color="error"
        query={{ completed: false, classification: "Red", minInterval: 24 }}
    />
)

OverviewWidgets.plug(
    <StatsWidget
        title="Overdue Pink"
        color={COLOR_FROM_CLASSIFICATION.Pink}
        query={{ completed: false, classification: "Pink", minInterval: 24 }}
    />
)

OverviewWidgets.plug(
    <StatsWidget
        title="Overdue Amber"
        color={COLOR_FROM_CLASSIFICATION.Amber}
        query={{ completed: false, classification: "Amber", minInterval: 24 }}
    />
)

OverviewWidgets.plug(
    <StatsWidget
        title="Overdue Green"
        color={COLOR_FROM_CLASSIFICATION.Green}
        query={{ completed: false, classification: "Green", minInterval: 24 }}
    />
)

OverviewWidgets.plug(
    <StatsWidget
        title="Completed (Daily)"
        color="primary.main"
        query={{ completed: true, minInterval: 0, maxInterval: 25 }}
        lg={6}
        md={6}
    />
)
OverviewWidgets.plug(
    <StatsWidget
        title="Overdue (Daily)"
        color="error.dark"
        lg={6}
        md={6}
        query={{ completed: false, minInterval: 0, maxInterval: 25 }}
    />
)

OverviewWidgets.plug(
    <StatsWidget
        title="Overdue Red (Daily)"
        color={COLOR_FROM_CLASSIFICATION.Red}
        query={{ completed: false, classification: "Red", minInterval: 0, maxInterval: 25 }}
    />
)

OverviewWidgets.plug(
    <StatsWidget
        title="Overdue Pink (Daily)"
        color={COLOR_FROM_CLASSIFICATION.Pink}
        query={{ completed: false, classification: "Pink", minInterval: 0, maxInterval: 25 }}
    />
)

OverviewWidgets.plug(
    <StatsWidget
        title="Overdue Amber (Daily)"
        color={COLOR_FROM_CLASSIFICATION.Amber}
        query={{ completed: false, classification: "Amber", minInterval: 0, maxInterval: 25 }}
    />
)

OverviewWidgets.plug(
    <StatsWidget
        title="Overdue Green (Daily)"
        color={COLOR_FROM_CLASSIFICATION.Green}
        query={{ completed: false, classification: "Green", minInterval: 0, maxInterval: 25 }}
    />
)
