import { ContextMenuItem } from "lib/@components/context-menu-bar/context-menu-item"
import { gql } from "@apollo/client"
import { getTreeIdFromItem } from "lib/get-tree-id-from-item"
import { convertFolderName } from "routes/schedule/folder-manager/folder-management"
import { RemoveFromGroup } from "event-definitions"
import { selectItems } from "routes/schedule/lib/useSelection"
import { uniqueId } from "library/database/split-id"
import { mutate } from "lib/graphql/mutate"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

import { busyWhile } from "lib/@components/busy/busy-while"

export function RemoveSchedule() {
    const { schedule, parentItem } = useBoundContext()
    return <ContextMenuItem icon="material-symbols:delete" label="Remove Schedule" onClick={removeSchedule} />

    async function removeSchedule() {
        await busyWhile(async () => {
            const toRemove = [{ ...schedule, id: `${parentItem.id}!${uniqueId(schedule._id)}` }]
            await mutate(
                gql`
                    mutation removeSchedules($tree: String!, $group: String!, $ids: [String]!) {
                        removeReferencesFromGroup(tree: $tree, group: $group, ids: $ids)
                    }
                `,
                {
                    tree: getTreeIdFromItem(parentItem),
                    group: convertFolderName(parentItem.id),
                    ids: toRemove.map("_id"),
                }
            )
            await RemoveFromGroup.callAsync({ toRemove, fromGroup: parentItem })
            selectItems(() => Object.fromEntries(toRemove.map((r) => [r._id, r.id])))
        }, "Removing Schedule")
    }
}
