import { ProcessVariable } from "event-definitions"
import { REMOVE_VARIABLE } from "lib/graphql/api"

export class ComparisonFunction {
    constructor(comparisonFn) {
        this.comparison = comparisonFn
    }
}

export function withComparison(comparisonFn) {
    return new ComparisonFunction(comparisonFn)
}

ProcessVariable.handleOnce(({ variable }) => {
    if (variable instanceof ComparisonFunction) {
        return { comparison: variable.comparison, variable: REMOVE_VARIABLE }
    }
    return undefined
})
