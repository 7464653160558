import { defaultNumberOfRecords } from "lib/@components/pagination/default-number-of-records"

export function itemsInDialog(heightOfItem, percentageOfScreenHeight = 0.5, minimumHeightOfSurroundings = 0) {
    if (!heightOfItem) throw new Error("Must specify a height of the rows")
    return function calculatePageSize() {
        const pageSizes = defaultNumberOfRecords.sort(function (a, b) {
            return a - b
        })

        const fitOnScreenNum = Math.max(
            pageSizes[0],
            Math.floor(((window.innerHeight - minimumHeightOfSurroundings) * percentageOfScreenHeight) / heightOfItem)
        )

        let retVal = pageSizes[0]
        if (fitOnScreenNum >= pageSizes[pageSizes.length - 1]) {
            retVal = pageSizes[pageSizes.length - 1]
        } else {
            for (let i = 0; i < pageSizes.length; i++) {
                if (fitOnScreenNum >= pageSizes[i] && fitOnScreenNum < pageSizes[i + 1]) {
                    retVal = pageSizes[i]
                    break
                }
            }
        }

        return retVal
    }
}
