import PropTypes from "prop-types"
// @mui
import { TableCell, TableRow } from "@mui/material"
//
import EmptyContent from "minimals-template/components/EmptyContent"

// ----------------------------------------------------------------------

TableNoData.propTypes = {
    isNotFound: PropTypes.bool,
}

export default function TableNoData({ isNotFound, title = "No Data" }) {
    return (
        <TableRow>
            {isNotFound ? (
                <TableCell colSpan={12}>
                    <EmptyContent
                        title={title}
                        sx={{
                            "& span.MuiBox-root": { height: 160 },
                        }}
                    />
                </TableCell>
            ) : (
                <TableCell colSpan={12} sx={{ p: 0 }} />
            )}
        </TableRow>
    )
}
