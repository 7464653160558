import { CaptureNow } from "event-definitions"
import { busyWhileWithDelay } from "lib/@components/busy/busy-while-with-delay"

export async function busyWhile(fn, description, extra = () => true) {
    return busyWhileWithDelay(fn, 0, description, extra)
}

busyWhile.capture = function busyWhileCapture(...params) {
    CaptureNow.raise()
    return busyWhile(...params)
}
