import { NotOnMobile, OnMobile } from "lib/@components/mobile"
import { DesktopDatePicker, MobileDatePicker } from "@mui/x-date-pickers"
import { TextField } from "@mui/material"
import { mergeDeep } from "@apollo/client/utilities"
import { bind } from "lib/@components/binding/bind"

export const BoundDatePicker = bind(<DatePicker />, { extract: (v) => v })

export function DatePicker({ value, onChange, textFieldProps, popperProps, ...props }) {
    return (
        <>
            <OnMobile>
                <MobileDatePicker
                    inputFormat="DD/MM/YYYY"
                    onChange={onChange}
                    value={value}
                    renderInput={(p) => (
                        <TextField fullWidth {...mergeDeep(textFieldProps, p, { inputProps: { readOnly: true } })} />
                    )}
                    {...props}
                />
            </OnMobile>
            <NotOnMobile>
                <DesktopDatePicker
                    inputFormat="DD/MM/YYYY"
                    onChange={onChange}
                    value={value}
                    PopperProps={{ ...popperProps }}
                    renderInput={(p) => (
                        <TextField fullWidth {...mergeDeep(textFieldProps, p, { inputProps: { readOnly: true } })} />
                    )}
                    {...props}
                />
            </NotOnMobile>
        </>
    )
}
