import { ConformityStep, ConformityStepContent } from "slot-definitions"
import { Step, StepLabel } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { StepIcon } from "routes/conformity/step-icon"

export function registerConformityStep({ title, icon, content, priority = 100 }) {
    ConformityStep.plug(
        <Step priority={priority}>
            <StepLabel icon={!!icon && <Iconify icon={icon} />} StepIconComponent={StepIcon}>
                {title}
            </StepLabel>
        </Step>
    )
    ConformityStepContent.plug(<content.type {...content.props} priority={priority} />)
}
