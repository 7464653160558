import { waitForEvent } from "library/local-events"
import { waitForSystemStable } from "lib/wait-for-system-stable"

window.waitForEvent = async function wait(eventName, timeout) {
    try {
        console.log("Waiting For Event", eventName)
        return await waitForEvent(eventName, timeout)
    } finally {
        console.log("Released Due To Event", eventName)
    }
}

window.waitForStable = waitForSystemStable
