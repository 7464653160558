import { TabPanel } from "@mui/lab"
import { Tab } from "@mui/material"
import { registerTab } from "lib/@components/tabs"
import { hasDemand } from "lib/authorization/has-demand"

import { Lazy } from "lib/make-lazy"
import { ClientPanels, ClientTabs } from "slot-definitions"

registerTab({
    tab: "clientAdmin",
    id: "users",
    title: "Users & Licenses",
    content: (
        <Lazy
            importer={() => import("routes/client-admin/plugins/permissions/client-users/index")}
            extract="ClientUserConfiguration"
            label={"Users"}
            priority={30}
        />
    ),
    predicate: () => hasDemand("clientAdmin"),
})

registerTab({
    tab: "clientAdmin",
    id: "roles",
    title: "User Roles",
    content: (
        <Lazy
            importer={() => import("routes/client-admin/plugins/permissions/client-roles/index")}
            extract="ClientRoleConfiguration"
            label={"Roles"}
            priority={50}
        />
    ),
    predicate: () => hasDemand("clientAdmin"),
})

ClientTabs.plug(<Tab label="Users & Licenses" value="Users & Licenses" />)

ClientPanels.plug(
    <TabPanel value="Users & Licenses">
        <Lazy
            importer={() => import("routes/client-admin/plugins/permissions/client-users/index")}
            extract="ClientUserConfiguration"
        />
    </TabPanel>
)
ClientTabs.plug(<Tab label="Roles" value="Roles" />)

ClientPanels.plug(
    <TabPanel value="Roles">
        <Lazy
            importer={() => import("routes/client-admin/plugins/permissions/client-roles/index")}
            extract="ClientRoleConfiguration"
            label={"Roles"}
            priority={50}
        />
    </TabPanel>
)
