import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"

export const getAuditsByReferenceIdAndType = api(function getAuditsByReferenceIdAndType(referenceId, type) {
    return query(
        gql`
            query GetAuditsByReferenceIdAndType($referenceId: String, $type: String) {
                getAuditsByReferenceIdAndType(referenceId: $referenceId, type: $type) {
                    id
                    shortDescription
                    longDescription
                    referenceId
                    type
                    date
                    userId
                    userName
                }
            }
        `,
        { referenceId, type },
        { returns: "getAuditsByReferenceIdAndType" }
    )
})

export const getAuditPayload = api(function getAudit(id) {
    return query(
        gql`
            query GetAudit($id: String!) {
                getAudit(id: $id) {
                    payload
                }
            }
        `,
        { id },
        { returns: "getAudit.payload" }
    )
})
