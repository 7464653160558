import { Box, ListItemButton } from "@mui/material"
import { AddToCore, AddToLibrary, RefreshMainTree, SidebarTreeRoots } from "event-definitions"
import { cacheTreeRoot } from "lib/cached"
import { ListItemBox } from "lib/@components/ListItemBox"
import { TreeRoot } from "library/tree"
import { FolderIcon } from "routes/schedule/tree/schedules/folder-icon"
import { FolderItemPart } from "routes/schedule/tree/schedules/folder-item-part"
import { makeTreeItemsFromGroup } from "routes/schedule/tree/schedules/makeTreeItemsFromGroup"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function Core({ last, Component = ListItemButton }) {
    const { refresh, onClick } = useBoundContext()
    RefreshMainTree.useEvent(refresh)
    return (
        <Component divider={!last} onClick={onClick} data-cy="schedule-folder-SFG20">
            <ListItemBox spacing={1}>
                <FolderItemPart />
                <FolderItemPart>
                    <FolderIcon />
                </FolderItemPart>
                <Box flex={1}>SFG20</Box>
            </ListItemBox>
        </Component>
    )
}

export function Library({ last, Component = ListItemButton }) {
    const { refresh, onClick } = useBoundContext()
    RefreshMainTree.useEvent(refresh)
    return (
        <Component divider={!last} onClick={onClick}>
            <ListItemBox spacing={1}>
                <FolderItemPart />
                <FolderItemPart>
                    <FolderIcon />
                </FolderItemPart>
                <Box flex={1}>Schedule Library</Box>
            </ListItemBox>
        </Component>
    )
}

SidebarTreeRoots.handleOnce(
    cacheTreeRoot("schedules", async ({ add }) => {
        add({
            content: <Library />,
            id: "schedules",
            label: "Schedule Library",
            [TreeRoot]: true,
            priority: 100,
            children: await AddToLibrary.callAsync({
                library: [
                    {
                        content: <Core />,
                        id: "sfg20",
                        label: " SFG20",
                        priority: 100,
                        children: await AddToCore.callAsync({ core: await makeTreeItemsFromGroup({ tree: "sfg20" }) }),
                    },
                ],
            }),
        })
    })
)
