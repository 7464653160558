export function textFromHtmlNode(node) {
    let textSegments = []
    if ((!node.childNodes || !node.childNodes.length) && node.nodeValue) {
        textSegments.push(node.nodeValue)
    } else {
        for (let childNode of node.childNodes) {
            if (childNode.nodeName === "OL") {
                textSegments = textSegments.concat(textFromHtmlNode(childNode))
            } else if (childNode.nodeName === "LI" && childNode.nodeType !== Node.TEXT_NODE) {
                if (textSegments.length > 0 && textSegments[textSegments.length - 1].trim()) {
                    textSegments.push(" ")
                }
            } else if (childNode.nodeType === Node.TEXT_NODE) {
                if (!childNode.parentNode || childNode.parentNode.nodeName === "#document") {
                    textSegments.push(childNode.nodeValue)
                } else {
                    let wrappedText = `<${childNode.parentNode.nodeName}>${childNode.nodeValue}</${childNode.parentNode.nodeName}>`
                    textSegments.push(wrappedText)
                }
            } else if (childNode.nodeType === Node.ELEMENT_NODE) {
                let childTextSegments = textFromHtmlNode(childNode).join(" ")
                let wrappedText = `<${childNode.nodeName}>${childTextSegments}</${childNode.nodeName}>`
                textSegments.push(wrappedText)
            } else {
                textSegments.push("N/A")
            }
        }
    }

    return textSegments
}
