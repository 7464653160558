import { useDialog } from "lib/@hooks/useDialog"
import { PreviewDialog } from "lib/@components/document-viewer/preview-dialog"
import { OneCard } from "lib/@components/one-card"
import { Box, ImageList, ImageListItem, ImageListItemBar, Tab, Typography } from "@mui/material"
import { TabPanel } from "@mui/lab"
import { DocumentList } from "lib/@components/document-viewer/document-list"
import { ListItemBox } from "lib/@components/ListItemBox"
import { ContextMenuBar } from "lib/@components/context-menu-bar"
import { StatsPanels, StatsTabs } from "slot-definitions"
import { Settings } from "lib/@components/settings"

export function SpaceCompleteCard({ group, space = group, hideMenu }) {
    const preview = useDialog(<PreviewDialog />, { maxWidth: "lg", fullWidth: true })

    StatsTabs.usePlug(space?.description && <Tab label="Info" value="Description" />)
    StatsTabs.usePlug(!!space?.documents?.documents?.length && <Tab label="Documents" value="Documents" />)
    StatsTabs.usePlug(!!space?.pictures?.documents?.length && <Tab label="Pictures" value="Pictures" />)

    StatsPanels.usePlug(
        <TabPanel value="Description" sx={{ maxHeight: "25vh", overflow: "auto" }}>
            <Box dangerouslySetInnerHTML={{ __html: space.description }} />
        </TabPanel>
    )
    StatsPanels.usePlug(
        <TabPanel value="Documents">
            <DocumentList value={space.documents} />
        </TabPanel>
    )
    StatsPanels.usePlug(
        <TabPanel value="Pictures">
            {!!space.pictures?.documents?.length && (
                <ImageList variant="masonry" cols={2} gap={2}>
                    {space.pictures?.documents?.map((picture, index) => (
                        <ImageListItem onClick={() => preview({ name: picture.name, url: picture.href })} key={index}>
                            <img src={picture.href} alt={picture.name} />
                            <ImageListItemBar title={picture.name} />
                        </ImageListItem>
                    ))}
                </ImageList>
            )}
        </TabPanel>
    )

    return (
        !!space && (
            <OneCard sx={{ textAlign: "center", mb: 1, position: "relative" }}>
                {!!space.image && (
                    <Box sx={{ position: "relative" }}>
                        <Box
                            width={1}
                            component="img"
                            sx={{ objectFit: "cover" }}
                            src={space.image}
                            height={260}
                            color="#333"
                            overflow="hidden"
                        />
                    </Box>
                )}

                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                    {space.name ?? "Not named"}
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    {space.location}
                </Typography>

                {!hideMenu && (
                    <Settings>
                        <ListItemBox sx={{ position: "absolute", top: 5, right: 5, width: "100%" }}>
                            <Box flex={1} />
                            <Box>
                                <ContextMenuBar type="spaceCard" />
                            </Box>
                        </ListItemBox>
                    </Settings>
                )}
            </OneCard>
        )
    )
}
