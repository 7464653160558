import { getLastRenderTime, resetRenderTime } from "lib/render-trap"

export function waitForSystemStable(time = 100) {
    return new Promise((resolve) => {
        try {
            let start = Date.now()
            resetRenderTime()
            window.addEventListener("beforeunload", unload)

            check()

            // eslint-disable-next-line no-inner-declarations
            function unload() {
                resolve()
                window.removeEventListener("beforeunload", unload)
            }

            // eslint-disable-next-line no-inner-declarations
            function check() {
                try {
                    if (Date.now() - getLastRenderTime() < time && Date.now() - start < 8000) {
                        setTimeout(check, 200)
                    } else {
                        resolve()
                        window.removeEventListener("beforeunload", unload)
                    }
                } catch (e) {
                    resolve()
                    window.removeEventListener("beforeunload", unload)
                }
            }
        } catch (e) {
            resolve()
        }
    })
}
