import { useRefresh } from "lib/@hooks/useRefresh"
import { Bound } from "lib/@components/binding/Bound"
import LoadingScreen from "minimals-template/components/LoadingScreen"
import { Alert, Box } from "@mui/material"
import { Delayed } from "lib/@components/delayed"
import { ErrorPage } from "./error-page"

export function LoadingTemplate({ loading, error, target, children, ...props }) {
    const refresh = useRefresh()

    return (
        <Bound {...props} target={target} masterRefresh={refresh} refresh={refresh}>
            {loading ? (
                <LoadingScreen description="Loading Template" />
            ) : error ? (
                <Alert severity="error">{error?.toString()}</Alert>
            ) : target ? (
                children
            ) : (
                <Delayed>
                    <Box pr={2}>
                        <ErrorPage />
                    </Box>
                </Delayed>
            )}
        </Bound>
    )
}
