import { XmlTaskContentTypes } from "event-definitions"

XmlTaskContentTypes.handle(({ contentTypes }) => {
    contentTypes.push({
        type: "html",
        label: "HTML",
        description: "This matches the superseded SFG20 software XML format, useful for those familiar with it.",
        priority: 100,
    })
})
