import { appRoute } from "routes/app/home.runtime"
import { Bound } from "lib/@components/binding/Bound"
import { GetSortLabel } from "event-definitions"
import { SCHEDULE } from "library/constants"
import { makeLazy } from "lib/make-lazy"
import { pdfRoute } from "routes/pdf/pdf.runtime"
import { FacilityPDF } from "routes/facilities/facility"

const Facility = makeLazy(() => import("routes/facilities/facility"), "Facility")
const FacilityOverrides = makeLazy(() => import("routes/facilities/facility-overrides"), "FacilityOverrides")

appRoute.register(
    "facilities/facility/:id",
    <Bound>
        <FacilityOverrides $lazy>
            <Facility $lazy />
        </FacilityOverrides>
    </Bound>
)

pdfRoute.register(
    "facilities/facility/:id",
    <Bound>
        <FacilityOverrides $lazy>
            <FacilityPDF $lazy />
        </FacilityOverrides>
    </Bound>
)

export function sortSpecial(item) {
    if (item?.data?.type || item.type !== SCHEDULE) {
        return `${sortables[item?.data?.type] ?? "Y"}${item.label}`
    }
    return `z${item.label}`
}

GetSortLabel.handleOnce((props) => {
    const { item } = props
    if (item?.data?.type || item?.type !== SCHEDULE) {
        props.label = `${sortables[item?.data?.type] ?? "Y"}${props.label}`
    }
    props.label = `z${props.label}`
})

export const sortables = {
    group: "Y",
}
