export function predicate(predicateFunction, component) {
    const result = function processPredicate(props) {
        if (predicateFunction(props)) {
            return component
        }
        return null
    }
    result.$id = `${predicateFunction.toString()}${JSON.stringify(component)}`
    return result
}
