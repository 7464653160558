import { useContext } from "react"
import { Button, DialogActions } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { OkCancelContext } from "lib/@hooks/useDialog"

export function DoneWithIcon({ ok, okCaption = "Done", value, icon }) {
    const okCancel = useContext(OkCancelContext)
    ok = ok || okCancel.ok
    return (
        <DialogActions>
            <Button
                onClick={async () => ok(value)}
                color="primary"
                variant="contained"
                startIcon={<Iconify icon={icon} />}
            >
                {okCaption}
            </Button>
        </DialogActions>
    )
}
