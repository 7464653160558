export const fileTypes = {
    xls: "vscode-icons:file-type-excel",
    xlsx: "vscode-icons:file-type-excel",
    doc: "vscode-icons:file-type-word",
    docx: "vscode-icons:file-type-word",
    pdf: "vscode-icons:file-type-pdf2",
    ppt: "vscode-icons:file-type-powerpoint",
    pptx: "vscode-icons:file-type-powerpoint",
    jpg: "vscode-icons:file-type-image",
    jpeg: "vscode-icons:file-type-image",
    png: "vscode-icons:file-type-image",
    svg: "vscode-icons:file-type-image",
}

export function getIconForFile(filename) {
    return fileTypes[filename.split(".").at(-1)] ?? "vscode-icons:default-file"
}

export const supportedImagePreviews = ["jpg", "png", "jpeg", "svg", "tiff"]
export const supportedFilePreviews = ["pdf", "docx", "xlsx", "doc", "xls", "ppt", "pptx"]
export const allSupportedPreviews = [...supportedFilePreviews, ...supportedImagePreviews]
