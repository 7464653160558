import { Button } from "@mui/material"
import { TreeToolbar } from "slot-definitions"
import { useParams } from "react-router-dom"
import { navigate } from "lib/routes/navigate"

export function PreviewButton() {
    const { id } = useParams()
    return (
        <Button aria-label="Preview" onClick={preview} size="small" color="primary">
            Preview
        </Button>
    )

    function preview() {
        navigate(`/app/schedules/${id}/preview`)
    }
}

TreeToolbar.plug(<PreviewButton if={({ editMode }) => !!editMode} priority={1} />)
