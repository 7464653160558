import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { successSnackbar } from "lib/snackbar/success-snackbar"

import { handle } from "library/local-events"

handle("settings.update._auto_create_database_with_test_data", async (setting) => {
    const result = await mutate(
        gql`
            mutation createAndInitialiseDatabase($sourceDataFileName: String, $namespace: String) {
                createAndInitialiseDatabase(sourceDataFileName: $sourceDataFileName, namespace: $namespace)
            }
        `,
        { namespace: setting.value },
        { returns: "createAndInitialiseDatabase" }
    ).catch((e) => {
        console.error(e)
        errorSnackbar(e.message)
    })

    if (result) {
        console.log("Reset namespace and database")
        successSnackbar("Reset namespace and database")
    }
})

handle("settings.update._auto_switch_server_to_namespace", async (setting) => {
    const result = await mutate(
        gql`
            mutation SwitchServerToNamespace($namespace: String) {
                switchServerToNamespace(namespace: $namespace)
            }
        `,
        { namespace: setting.value },
        { returns: "switchServerToNamespace" }
    ).catch((e) => {
        console.error(e)
        errorSnackbar(e.message)
    })

    if (result) {
        console.log("Reset namespace")
        successSnackbar("Reset namespace ")
    }
})
