import { TaskRowEnd } from "slot-definitions"
import { IconButton, Menu, MenuItem } from "@mui/material"
import { useRef, useState } from "react"
import { prevent } from "lib/prevent"
import noop from "lib/noop"
import { EditField, TaskListRefresh } from "event-definitions"
import { generate } from "library/guid"
import { IsEditMode } from "lib/@components/edit-mode"
import { indexTasksInSchedule } from "routes/schedule/lib/index-tasks-in-schedule"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import Iconify from "minimals-template/components/Iconify"

TaskRowEnd.plug(<AddTaskButton />)

export function AddTaskButton() {
    const { schedule, task } = useBoundContext()
    const [anchor, setAnchor] = useState(null)
    const buttonRef = useRef()
    return (
        <IsEditMode>
            <IconButton
                className="add-task"
                data-cy="add-task"
                ref={buttonRef}
                color="primary"
                size="small"
                onClick={prevent(() => setAnchor(buttonRef.current))}
            >
                <Iconify icon="mdi:add" />
            </IconButton>

            <Menu onClick={prevent(noop)} open={!!anchor} anchorEl={anchor} onClose={() => setAnchor(null)}>
                <MenuItem onClick={addTask}>Add Task</MenuItem>
                <MenuItem onClick={addHeader}>Add Header</MenuItem>
            </Menu>
        </IsEditMode>
    )

    function addTask() {
        const newTask = {
            id: generate(),
            classification: "Amber",
            frequency: {
                period: "Unknown",
                interval: 0,
            },
            title: "New task",
            note: "",
            content: "",
            isHeader: false,
            unitOfMeasure: "Nr",
            skillSet: "1",
        }
        if (task) {
            schedule.tasks.splice(task.$sort + 1, 0, newTask)
        } else {
            schedule.tasks.push(newTask)
        }
        indexTasksInSchedule(schedule)
        TaskListRefresh.raise()
        setAnchor(null)
        EditField.raiseLater({ target: newTask, field: "title" })
    }

    function addHeader() {
        const newTask = {
            id: generate(),
            classification: "Not Specified",
            frequency: {
                period: "Unknown",
                interval: 0,
            },
            title: "New header",
            isHeader: true,
            unitOfMeasure: "Not Specified",
        }
        if (task) {
            schedule.tasks.splice(task.$sort + 1, 0, newTask)
        } else {
            schedule.tasks.push(newTask)
        }
        indexTasksInSchedule(schedule)
        TaskListRefresh.raise()
        setAnchor(null)
        EditField.raiseLater({ target: newTask, field: "title" })
    }
}
