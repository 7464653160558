import { Box, ListItemButton } from "@mui/material"
import { Bound } from "lib/@components/binding/Bound"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { ListItemBox } from "lib/@components/ListItemBox"
import { createPortalPair } from "lib/portals"
import { HelpTag } from "routes/help/helpTag"
import { ScheduleSort } from "routes/schedule/components/schedule-sort"
import {
    MY_DRAFTS,
    MY_LIST,
    MY_PUBLISHED,
    MY_RETIRED,
} from "routes/schedule/my-schedules/my-custom-schedules/lib/constants"
import { NotInSelector } from "routes/schedule/my-schedules/my-custom-schedules/select-schedule"
import { FolderIcon } from "routes/schedule/tree/schedules/folder-icon"
import { FolderItemPart } from "routes/schedule/tree/schedules/folder-item-part"
import {
    ScheduleSelectorList,
    ScheduleSelectorPanel,
    ScheduleSelectorToolbar,
    SelectedComponent,
} from "slot-definitions"
import { replaceAllWith } from "lib/@components/slot/replace-all-with"
import { replaceAllWithType } from "lib/@components/slot/replace-all-with-type"
import { Frag } from "lib/@components/slot/frag"
import { ListOfMySchedules } from "routes/schedule/my-schedules/my-custom-schedules/list-of-my-schedules"
import { HELPTAG_CUSTOM_SCHEDULES } from "routes/help/help-tag-constants"

export function MyList({ last, Component = ListItemButton }) {
    const { onClick } = useBoundContext()
    return (
        <Component divider={!last} onClick={onClick}>
            <ListItemBox spacing={1}>
                <FolderItemPart />
                <FolderItemPart>
                    <FolderIcon sx={{ color: "myStuff.main" }} icon="ion:documents-outline" />
                </FolderItemPart>
                <Box flex={1}>Schedules</Box>
            </ListItemBox>
        </Component>
    )
}

export const [SortPortal, SortComponent] = createPortalPair()
export const [FilterPortal] = createPortalPair()

ScheduleSelectorList(MY_LIST).plug(
    replaceAllWith(
        <NotInSelector>
            <ListOfMySchedules $lazy retired={false} />
        </NotInSelector>
    )
)
ScheduleSelectorPanel(MY_LIST).plug(<HelpTag tags={[HELPTAG_CUSTOM_SCHEDULES]} />)

ScheduleSelectorList(MY_DRAFTS).plug(
    replaceAllWith(
        <NotInSelector>
            <ListOfMySchedules $lazy drafts={true} published={false} retired={false} />
        </NotInSelector>
    )
)
SelectedComponent(MY_RETIRED).plug(replaceAllWith(<Frag />, 1000))
ScheduleSelectorList(MY_RETIRED).plug(
    replaceAllWith(
        <NotInSelector>
            <Bound disableCreation={true}>
                <ListOfMySchedules $lazy drafts={false} published={false} retired={true} />
            </Bound>
        </NotInSelector>
    )
)
// ScheduleSelectorList(MY_LIST).plug(replaceAllWith(<Frag />))
ScheduleSelectorList(MY_PUBLISHED).plug(
    replaceAllWith(
        <NotInSelector>
            <ListOfMySchedules $lazy drafts={false} published={true} retired={false} />
        </NotInSelector>
    )
)

// Comment the below elements to allow filtering on custom schedules
// ScheduleSelectorToolbar(MY_LIST).plug(replaceAllWithType(ScheduleFilter, <FilterPortal />, 10000))
// ScheduleSelectorToolbar(MY_RETIRED).plug(replaceAllWithType(ScheduleFilter, <FilterPortal />, 10000))
// ScheduleSelectorToolbar(MY_DRAFTS).plug(replaceAllWithType(ScheduleFilter, <FilterPortal />, 10000))
// ScheduleSelectorToolbar(MY_PUBLISHED).plug(replaceAllWithType(ScheduleFilter, <FilterPortal />, 10000))
ScheduleSelectorToolbar(MY_LIST).plug(replaceAllWithType(ScheduleSort, <SortPortal />, 10000))
ScheduleSelectorToolbar(MY_DRAFTS).plug(replaceAllWithType(ScheduleSort, <SortPortal />, 10000))
ScheduleSelectorToolbar(MY_PUBLISHED).plug(replaceAllWithType(ScheduleSort, <SortPortal />, 10000))
ScheduleSelectorToolbar(MY_RETIRED).plug(replaceAllWithType(ScheduleSort, <SortPortal />, 10000))
