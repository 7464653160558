import { useBoundValue } from "lib/@components/binding/use-bound-value"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { BoundBlurChangeTextField } from "lib/@components/debounced-text-field/blur-change-text-field"
import { UOM_TO_NAME } from "routes/schedule/lib/uom-to-input"
import { PagedRepeat } from "lib/@components/paged-repeat"
import { ReadonlyAsset } from "routes/regime/assets/readonly-asset"
import { Bound } from "lib/@components/binding/Bound"
import { isEnabled } from "lib/@components/feature"

export function ReadonlyUOM({ uom }) {
    const [number] = useBoundValue(uom, 1)
    const { target } = useBoundContext()
    const items = Array.from({ length: number }, (_, i) => i).filter((i) => target[`${uom}_${i + 1}`])

    return (
        <Bound readOnly={true}>
            <BoundBlurChangeTextField
                InputProps={{ readOnly: true }}
                key={uom}
                sx={{ mb: 1 }}
                field={uom}
                label={UOM_TO_NAME[uom]}
                default={1}
                data-cy="read-only-no-of-assets"
            />
            {isEnabled("assets") && ["Nr", "System"].includes(uom) && (
                <PagedRepeat sx={{ mt: 1 }} pageSize={5} list={items} item={<ReadonlyAsset uom={uom} />} />
            )}
        </Bound>
    )
}
