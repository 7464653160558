import { Bound } from "lib/@components/binding/Bound"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { NoResults } from "lib/@components/no-results/no-results"
import { MemorizedPagedRepeat } from "lib/@components/memorized-paged-repeat"
import { SelectedChildren } from "routes/schedule/components/selected-children"
import { Box } from "@mui/material"
import { ItemTypeLabel } from "event-definitions"
import { useMemo } from "react"

export function ScheduleItemList({ items = [], select }) {
    const context = useBoundContext()
    const { inDialog, parentItem, transformScheduleItem = (v) => v, pageSizeCalculator = (v) => v } = context
    const { label } = ItemTypeLabel.call({ label: "Schedules", parentItem: parentItem })

    const display = useMemo(
        () =>
            items
                .filter((i) => !!i.content)
                .map((item, index, array) => (
                    <Bound key={item._id ?? item.id} target={item} onClick={select(item)}>
                        {transformScheduleItem(
                            <item.content.type {...item.content.props} last={array.length === index + 1} />,
                            item
                        )}
                    </Bound>
                )),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [items]
    )

    if (!display.length) return <NoResults>No {label}</NoResults>

    return (
        <>
            <Box ml={0.6}>
                <SelectedChildren id={parentItem.id} depth={1} />
            </Box>

            <MemorizedPagedRepeat
                showTop
                showBottom={!inDialog}
                id={`schedule-item-list-${parentItem.id}`}
                sx={{ mt: 0, width: 1 }}
                pageSize={pageSizeCalculator(10, context)}
                list={display}
                pass="children"
                fallback={<NoResults />}
                item={
                    /* https://sfg20.atlassian.net/browse/WIN-1506 */
                    <Box
                        sx={{
                            willChange: "transform",
                            transform: "translateZ(0)",
                        }}
                    />
                }
            />
        </>
    )
}
