import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const areSchedulesUsingCode = api(async function areSchedulesUsingCode(id, client) {
    return query(
        gql`
            query AreSchedulesUsingCode($id: String!, $client: String) {
                areSchedulesUsingCode(id: $id, client: $client)
            }
        `,
        { id, client },
        { returns: "areSchedulesUsingCode", fetchPolicy: "network-only" }
    )
})
