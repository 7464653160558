import { PublishingStep } from "slot-definitions"
import { Button, Stack, Typography } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { StepIcon } from "routes/conformity/step-icon"
import { Bound } from "lib/@components/binding/Bound"
import { createEvent } from "library/local-events"
import noop from "lib/noop"
import { forwardRef } from "react"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { PropertyBox } from "lib/@components/property-box"

export const CheckActive = createEvent("CheckActive", { extract: (v) => v[0].active })

export function registerPublishingStep({
    title,
    icon,
    isSelected = () => false,
    onClick = noop,
    available = () => true,
    content = null,
    priority = 100,
}) {
    const step = (
        <AvailableStep id={title} priority={priority} available={available} onClick={onClick} content={content}>
            <Bound active={false}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <StepIcon icon={!!icon && <Iconify icon={icon} />} />
                    <Stack sx={{ pl: 1 }} alignItems="flex-start">
                        <Typography variant="body2">{title}</Typography>
                        <VersionNumber />
                    </Stack>
                </Stack>
            </Bound>
        </AvailableStep>
    )
    CheckActive.handleOnce((props) => {
        if (isSelected(props)) {
            return { active: step }
        }
        return undefined
    })
    PublishingStep.plug(step)
}

const AvailableStep = forwardRef(function AvailableStep(
    { available = () => true, content, index, onClick, children, ...props },
    ref
) {
    const isAvailable = available()

    RefreshAvailable.useRefresh()
    const context = useBoundContext()

    return (
        <Stack spacing={1} sx={{ flex: 1 }}>
            <Button
                variant={context.activeIndex === index ? "contained" : "default"}
                disabled={!isAvailable}
                sx={{ flex: 1, width: 1, display: "flex" }}
                onClick={(...params) => isAvailable && onClick.call(context, context, ...params)}
                className={context.activeIndex === index ? "step-active" : undefined}
            >
                <PropertyBox {...props} ref={ref} flex={1} spacing={1}>
                    <Bound isAvailable={isAvailable}>{children}</Bound>
                </PropertyBox>
                {context.activeIndex === index && context.allSteps.length !== index + 1 ? (
                    <Iconify icon="mdi:arrow-right" />
                ) : null}
            </Button>
            {content}
        </Stack>
    )
})

export function VersionNumber() {
    const { isAvailable } = useBoundContext()
    return typeof isAvailable === "number" && !Number.isNaN(isAvailable) ? (
        <Typography component="div" variant="caption">
            Version {isAvailable}
        </Typography>
    ) : null
}

export const RefreshAvailable = createEvent("RefreshAvailable")
