import { MenuItem, TextField } from "@mui/material"
import { bind } from "lib/@components/binding/bind"

export const BoundStandardCombo = bind(<StandardCombo />, { extract: (v) => v.target.value })

export function StandardCombo({ options = [], getOptionLabel = (v) => v, ...props }) {
    return (
        <TextField variant="outlined" fullWidth {...props} select>
            {options.filter(Boolean).map(createOption)}
        </TextField>
    )

    function createOption(option) {
        if (typeof option === "string") {
            return (
                <MenuItem value={option} key={option}>
                    {getOptionLabel(option)}
                </MenuItem>
            )
        }
        if (option.type) {
            return option
        }
        if (Array.isArray(option)) {
            return (
                <MenuItem value={option[0]} key={option[0]}>
                    {option[1] ?? getOptionLabel(option[0])}
                </MenuItem>
            )
        }
        return (
            <MenuItem value={option.value} key={option.value}>
                {option.label}
            </MenuItem>
        )
    }
}
