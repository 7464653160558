import "./extension-edit"
import { Box } from "@mui/material"
import { Activity } from "routes/@lib/activity/runtime-main"
import { Secure } from "lib/authorization/secure"
import { ContextMenuBar } from "lib/@components/context-menu-bar"
import { RegimeToolbar, ScheduleSelectorToolbar } from "slot-definitions"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { PlanRoot } from "library/tree/constants"
import { replaceAllWith } from "lib/@components/slot/replace-all-with"
import { Frag } from "lib/@components/slot/frag"

ScheduleSelectorToolbar("myTree").plug(replaceAllWith(<Frag />, 1000))
RegimeToolbar.plug(<RegimeOptions priority={2500} />)

function RegimeOptions() {
    const { parentItem } = useBoundContext()
    return (
        <>
            {parentItem[PlanRoot] && (
                <Box ml={1}>
                    <Activity type={"maintenanceRegime"} passedId={parentItem.id} />
                </Box>
            )}
            <Secure hide demand="manageRegime" fallback={<Frag />}>
                <ContextMenuBar type="folder" />
            </Secure>
        </>
    )
}
