import { XmlTaskContentTypes } from "event-definitions"

XmlTaskContentTypes.handle(({ contentTypes }) => {
    contentTypes.push({
        type: "aiSummary",
        label: "AI Summary",
        description:
            "This outputs steps keeping within 250 characters, useful for 3rd party software with input limits to prevent data loss.",
        priority: 300,
    })
})
