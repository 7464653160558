import { NotOnMobile } from "lib/@components/mobile"
import { AuditLog } from "./audit-log"
import { AuditTopBar } from "./top-bar"
import { getAuditsByReferenceIdAndType } from "routes/audit/controller/get-audits-by-reference-id"
import { Bound } from "lib/@components/binding/Bound"
import { getAuditsForTypeAllClients } from "../controller/get-all-audits-for-type"

export function AuditContainer({ setOpen, type, id, forTypeAllClients }) {
    const data = forTypeAllClients
        ? getAuditsForTypeAllClients.useResults(type) ?? []
        : getAuditsByReferenceIdAndType.useResults(id, type) ?? []

    return (
        <Bound data={data} auditId={id} type={type}>
            <NotOnMobile>
                <AuditTopBar setOpen={setOpen} type={type} />
            </NotOnMobile>
            <AuditLog />
        </Bound>
    )
}
