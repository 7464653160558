import { DialogActions, DialogContent, Drawer } from "@mui/material"
import React, { useContext, useEffect } from "react"
import { NotOnMobile, useMobile } from "lib/@components/mobile"
import { OkCancelContext, openDialog } from "lib/@hooks/useDialog"
import { Bound } from "lib/@components/binding/Bound"

export function RightDrawer({ open, setOpen, keepMounted, sx, dialogActions, children }) {
    const isMobile = useMobile()
    useEffect(() => {
        if (open && isMobile) {
            openDialog(<RightDrawerDialog setOpen={setOpen} children={children} dialogActions={dialogActions} />, {
                fullScreen: true,
            }).catch(console.error)
        }
    }, [open, children, dialogActions, isMobile, setOpen])

    return (
        <Bound close={() => setOpen(false)}>
            <NotOnMobile>
                <Drawer
                    keepMounted={keepMounted}
                    sx={{
                        "& .MuiDrawer-paper": {
                            width: 450,
                            padding: 2,
                            margin: 3,
                            height: "calc(100% - 48px)",
                            ...sx,
                        },
                    }}
                    anchor="right"
                    open={open}
                    onClose={() => {
                        setOpen(false)
                    }}
                    PaperProps={{ square: false }}
                    data-cy="rightDrawer"
                >
                    {children}
                </Drawer>
            </NotOnMobile>
        </Bound>
    )
}

function RightDrawerDialog({ children, dialogActions, setOpen }) {
    const isMobile = useMobile()
    const okCancel = useContext(OkCancelContext)
    const { ok } = okCancel

    useEffect(() => {
        if (!isMobile) {
            ok()
        }
    }, [isMobile, ok])

    const dialogActionsWithProps = React.cloneElement(dialogActions, { isMobile, ok, setOpen })

    return (
        <>
            <DialogContent>{children}</DialogContent>
            <DialogActions sx={{ display: "unset" }}>{dialogActionsWithProps}</DialogActions>
        </>
    )
}
