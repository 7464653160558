import { registerTab } from "lib/@components/tabs"
import { Lazy } from "lib/make-lazy"
import { appRoute } from "routes/app/home.runtime"
import DemandGuard from "minimals-template/components/guards/DemandGuard"

registerTab({
    tab: "sfg20AdminTabs",
    id: "helpCentre",
    title: "Help Centre",
    demands: "admin-helpcentre",
    content: (
        <Lazy
            priority={4}
            importer={() => import("routes/sfg20-admin/plugins/help/adminHelpContainer.js")}
            extract={"AdminHelpContainer"}
        />
    ),
})

appRoute.register(
    "sfg20admin/helpCategory/:id",
    <DemandGuard demands={"admin-helpcentre"}>
        <Lazy
            demands={"admin-helpcentre"}
            importer={() => import("routes/sfg20-admin/plugins/help/categoryPage/adminHelpCategoryContainer")}
            extract={"AdminHelpCategoryContainer"}
        />
    </DemandGuard>
)
