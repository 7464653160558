import { BlockClicks } from "lib/@components/block-clicks"
import { Box, Stack } from "@mui/material"
import { NotAuthorised } from "lib/authorization/not-authorised"
import { useDemand } from "lib/authorization/use-demand"

import { Frag } from "lib/@components/slot/frag"

export function Secure({ demand, hide, not, and = true, children, fallback = <NotAuthorised />, sx }) {
    const approved = useDemand(demand, and)
    //if (hide && ((!not && approved) || (not && approved))) return null
    return (not && !approved) || (!not && approved) ? (
        children
    ) : fallback && !hide ? (
        fallback
    ) : (
        <BlockClicks all>
            <Stack spacing={1} sx={{ ...sx, pointerEvents: "none" }}>
                {hide ? null : <Box sx={{ opacity: 0.4 }}>{children}</Box>}
            </Stack>
        </BlockClicks>
    )
}

export function WithDemand({ demand, children, ...props }) {
    return (
        <Secure hide fallback={<Frag />} demand={demand}>
            <children.type {...children.props} {...props} />
        </Secure>
    )
}

export function withSecure(Component) {
    return function WrappedComponent({ demand, hide, not, fallback, ...props }) {
        return (
            <Secure demand={demand} hide={hide} not={not} fallback={fallback}>
                <Component {...props} />
            </Secure>
        )
    }
}
