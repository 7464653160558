import { AlignmentType, Footer, PageNumber, Paragraph, TextRun } from "docx"
import { styles } from "../../legacy-export-docx-consts"

export function footer() {
    const footer = new Footer({
        children: [
            new Paragraph({
                children: [
                    new TextRun({
                        children: ["Page ", PageNumber.CURRENT, " of ", PageNumber.TOTAL_PAGES],
                        font: styles.FONT,
                    }),
                ],
                alignment: AlignmentType.RIGHT,
            }),
        ],
    })

    return footer
}
