import { Button } from "@mui/material"

export function DivStyleButton(props) {
    return (
        <Button
            {...props}
            sx={{ m: 0, p: 0, display: "block", border: "none", textAlign: "left", width: "100%", ...props.sx }}
        >
            {props.children}
        </Button>
    )
}
