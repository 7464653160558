import { Grid, useTheme } from "@mui/material"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { WidgetSummary } from "routes/facilities/plugins/overview/plugins/summaries/widget-summary"
import { useComplianceResults } from "routes/facilities/plugins/overview/overview.runtime"

export function StatsWidget({ title, color, query, xs = 12, md = 6, lg = 3 }) {
    const { effectiveDate } = useBoundContext()
    const originalColor = color
    const theme = useTheme()
    if (typeof color === "string") {
        if (!color.includes(".")) {
            color += ".main"
        }
        color = Object.get(theme.palette, color) ?? originalColor
    }

    const { counts: series, total } = useComplianceResults({
        ...query,
        from: new Date(effectiveDate).addDays(-30).beginningOfDay(),
        to: effectiveDate,
    })
    const { rangeTotal: thisWeek } = useComplianceResults({
        ...query,
        from: new Date(effectiveDate).addDays(-7).beginningOfDay(),
        to: effectiveDate,
    })
    const { rangeTotal: prevWeek } = useComplianceResults({
        ...query,
        from: new Date(effectiveDate).addDays(-14).beginningOfDay(),
        to: new Date(effectiveDate).addDays(-7).beginningOfDay(),
    })

    return (
        total !== undefined && (
            <Grid item xs={xs} md={md} lg={lg}>
                <WidgetSummary
                    title={title}
                    percent={+((thisWeek / (prevWeek || Infinity)) * 100 - 100).toFixed(1)}
                    total={total}
                    chart={{
                        colors: [color],
                        series,
                    }}
                />
            </Grid>
        )
    )
}
