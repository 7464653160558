import { Box, FormControlLabel, Switch } from "@mui/material"

export function SortButton({ isSorted, handleSort }) {
    return (
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"} sx={{ pl: 2 }}>
            <FormControlLabel
                id="sortByVersionSwitchLabel"
                control={
                    <Switch
                        id="sortByVersionSwitch"
                        color="primary"
                        checked={isSorted}
                        onChange={handleSort}
                        data-cy="sortByVersionSwitch"
                    />
                }
                label="Sort by version (desc)"
                labelPlacement="start"
            />
        </Box>
    )
}
