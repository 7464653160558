import { XmlGroupTypes } from "event-definitions"

XmlGroupTypes.handle(({ groupTypes }) => {
    groupTypes.push({
        type: "library",
        label: "Schedule Library",
        description: "This outputs where in the schedule library (SFG20/NRM) each schedule exists.",
        priority: 100,
    })
})
