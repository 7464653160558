import { Alert } from "@mui/material"
import { BoundStandardSwitch } from "lib/@components/binding/bound-components"
import { useBoundValue } from "lib/@components/binding/use-bound-value"
import { Feature } from "lib/@components/feature"
import { FEATURE_FLAG_DOWNLOAD_TASKS } from "routes/asset-register/feature_flag_download_tasks"

export function DownloadExcelShareLinkSetting() {
    const [canDownloadExcel] = useBoundValue("canDownloadExcel")

    return (
        <Feature feature={FEATURE_FLAG_DOWNLOAD_TASKS}>
            <BoundStandardSwitch sideEffects field="canDownloadExcel" label="Can Download Excel" />
            {canDownloadExcel && (
                <Alert severity="warning">
                    You are sharing your company&apos;s private data via Excel to all people with access to this
                    sharelink
                </Alert>
            )}
        </Feature>
    )
}
