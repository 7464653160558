import { OpenDialog } from "event-definitions"
import { nanoid } from "nanoid"
import { ConfirmDialog } from "lib/@dialogs/confirm-dialog"

/**
 * Renders a confirmation message and waits for a user response
 * @param {string} message - the message to confirm
 * @param {string} title - the title of the confirmation box
 * @returns {Promise<boolean>} the selection the user made
 */
export function confirm(message, title = "", buttons = undefined) {
    return new Promise((resolve) => {
        OpenDialog.raise({
            dialogProps: {
                maxWidth: "sm",
                fullWidth: true,
            },
            dialogId: nanoid(),
            content: <ConfirmDialog buttons={buttons} message={message} title={title} />,
            resolve,
        })
    })
}
