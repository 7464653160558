import { useParams } from "react-router"
import { successSnackbar } from "lib/snackbar/success-snackbar"
import { BulletinEditor } from "../components/bulletin-editor"
import { createBulletin } from "../controller/editor/create-bulletin"
import { errorSnackbar } from "lib/snackbar/error-snackbar"

export function CreateTechnicalUpdate() {
    const { type } = useParams()

    const newBulletin = {
        title: "",
        type,
        date: new Date().toISOString(),
        content: "",
    }

    const handleCreate = async (bulletin) => {
        const success = await createBulletin(bulletin)
        if (success) {
            successSnackbar("bulletin successfully created")
        } else {
            errorSnackbar("something went wrong")
        }
    }

    return <BulletinEditor bulletin={newBulletin} onClickPublish={handleCreate} />
}
