import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
    root: {
        display: "inline-flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        border: "1px solid #ccc",
        padding: "1em",
        margin: "1em 0",
        flexWrap: "wrap",
        width: "100%",
    },
    table: {
        width: "100%",
        margin: "0.5em",
    },
    pre: {
        backgroundColor: "#f2f2f2",
        border: "1px solid #ccc",
        padding: "0.5em",
        width: "45%",
        overflow: "auto",
        whiteSpace: "pre-wrap",
        wordBreak: "break-all",
        margin: "0.5em",
    },
})

function DummyComponent(props) {
    const classes = useStyles()

    const tableData = Object.entries(props)

    return (
        <Paper className={classes.root}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map(([name, value]) => (
                        <TableRow key={name}>
                            <TableCell sx={{ verticalAlign: "top" }}>
                                <strong>{name}</strong>
                            </TableCell>
                            <TableCell>
                                <pre>{JSON.stringify(value, null, 2)}</pre>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    )
}

export default DummyComponent
