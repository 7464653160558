import { RegisterSystemTask } from "event-definitions"
import { handle } from "library/local-events"
import { warningSnackbar } from "lib/snackbar/warning-snackbar"
import { TaskEditor } from "slot-definitions"
import { Frag } from "lib/@components/slot/frag"
import { replaceAllWith } from "lib/@components/slot/replace-all-with"
import { successSnackbar } from "lib/snackbar/success-snackbar"
import { mutate } from "lib/graphql/mutate"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { gql } from "@apollo/client"

RegisterSystemTask.handleOnce(({ tasks }) => {
    const task = {
        name: "25-03 Bottled Water Cooler Update",
        event: "update-bottled-water-cooler",
        params: {},
    }
    tasks.push(task)
})

handle("update-bottled-water-cooler", async () => {
    warningSnackbar("Running Update 25-03 Bottled Water Cooler")
    if (
        await mutate(
            gql`
                mutation Mutation {
                    updateBottledWaterCooler
                }
            `,
            {},
            { returns: "updateBottledWaterCooler" }
        ).catch((e) => {
            console.error(e)
            errorSnackbar(e.message)
        })
    ) {
        successSnackbar("25-03 Bottled Water Cooler Updated")
    } else {
        errorSnackbar("25-03 Bottled Water Cooler Not Updated")
    }
})

TaskEditor("update-bottled-water-cooler").plug(replaceAllWith(<Frag />))
