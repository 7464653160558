import { Box, Stack, Typography } from "@mui/material"
import { useTreeItemIndex } from "routes/schedule/lib/useTreeItemIndex"
import { isGroup, isNotGroup } from "library/constants"
import { generate } from "library/guid"
import { useTreeItem } from "routes/schedule/lib/useTreeItem"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useIdScopedByTreeId } from "routes/conformity/lib/use-tree-Item-for-conformity"
import { navigate } from "lib/routes/navigate"
import { getTreeItemIndex } from "routes/schedule/lib/getTreeItemIndex"
import { Bound } from "lib/@components/binding/Bound"
import { extractGlobalId } from "library/get-unique-id-from-tree"

export function PlanDetails({ plan, id = plan?.plan?.baseId }) {
    const treeIndex = useTreeItemIndex()
    const {
        tree: { payloads = {} },
    } = plan
    const localIndex = getTreeItemIndex(plan?.tree?.tree)
    const parentItem = useTreeItem(id)

    Object.entries(payloads)
        .filter(([key]) => key.startsWith(plan?.plan?.baseId))
        .forEach(([key, value]) => {
            payloads[key.replace(plan?.plan?.baseId, "_root")] = value
        })

    const items = recurseAdd(plan?.tree?.tree)
    return (
        <Bound parentItem={parentItem} localIndex={localIndex} payloads={payloads}>
            {items.slice(1)}
        </Bound>
    )

    function recurseAdd(root, output = [], depth = 0) {
        if (root.children.length) output.push(<Group key={root.id} label={root.label} depth={depth} />)
        for (const item of root.children.filter(isGroup).sortBy("label")) {
            recurseAdd(item, output, depth + 1)
        }
        output.push(<Spacer key={generate()} />)
        for (const item of root.children
            .filter(isNotGroup)
            .sortBy((t) => treeIndex.retrieve(t._id)?.label)
            .filter(Boolean)) {
            output.push(<Schedule key={item.id} item={item} depth={depth + 1} />)
        }
        return output
    }
}

function Spacer() {
    return <Box mt={1} />
}

function Group({ label, depth }) {
    return (
        <Typography
            sx={{ textTransform: "capitalize", ml: depth * 2, mt: Math.max(0, 4 - depth), color: "text.secondary" }}
            variant="subtitle2"
        >
            {label}
        </Typography>
    )
}

function formatUnits(units) {
    if (!units) return "1"
    if (units.System || units.Nr) {
        return `${units.System ?? units.Nr}`
    }
    if (units.Metre) {
        return `${units.Metre}m`
    }
    if (units["m²"]) {
        return `${units["m²"]}m²`
    }
    return "1"
}

function Schedule({ item, depth }) {
    const [group, baseId] = item.id.split("!")
    const schedule = useTreeItem(`${group}!${extractGlobalId(baseId)}`)
    const { payloads } = useBoundContext()
    const modified = payloads[item.id]
    const id = useIdScopedByTreeId(item.id)
    if (!schedule) return null
    // eslint-disable-next-line no-unused-expressions
    return modified ? (
        <Stack spacing={0}>
            <Stack
                direction="row"
                display="flex"
                spacing={1}
                alignItems="center"
                onClick={go}
                sx={{ cursor: "pointer" }}
            >
                <Typography variant="body2" sx={{ ml: depth * 2 + 1, minWidth: 24, textAlign: "right" }}>
                    {formatUnits(modified.units)}
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    ×
                </Typography>
                <Typography variant="body2" sx={{ ml: depth * 2 + 1 }}>
                    {schedule?.label ?? ""}
                </Typography>
                {modified.uniqueCode ? (
                    <Typography variant="caption" sx={{ color: "text.secondary" }}>
                        (#{schedule?.code}-{modified.uniqueCode.padLeft(4, "0")})
                    </Typography>
                ) : (
                    <Typography variant="caption" sx={{ color: "text.secondary" }}>
                        (#{schedule?.code})
                    </Typography>
                )}
            </Stack>
            {/* <Typography */}
            {/*     component="div" */}
            {/*     variant="caption" */}
            {/*     sx={{ color: "text.secondary", pl: depth * 2 + 5, pb: modified.status?.reasons?.length ? 1 : 0 }} */}
            {/* > */}
            {/*     <Iconify icon={statusIcons[modified.status?.summary]} sx={{ color: modified.status?.summary }} />{" "} */}
            {/*     {modified.status?.reasons?.join(", ").titleize() ?? ""} */}
            {/* </Typography> */}
        </Stack>
    ) : (
        <Stack sx={{ cursor: "pointer" }} onClick={go} direction="row" display="flex" spacing={1} alignItems="center">
            <Typography variant="body2" sx={{ ml: depth * 2 + 1, minWidth: 24, textAlign: "right" }}>
                1
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
                ×
            </Typography>
            <Typography variant="body2">{schedule?.label ?? ""}</Typography>
            <Typography variant="caption" sx={{ color: "text.secondary" }}>
                (#{schedule?.code})
            </Typography>
        </Stack>
    )

    function go() {
        navigate(`/app/schedules/${id}`)
    }
}
