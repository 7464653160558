import { PublishingStep } from "slot-definitions"
import { useCurrentStep } from "routes/regime/plugins/publishing/use-current-step"
import { Bound } from "lib/@components/binding/Bound"
import { Box } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"

export function PublishingSteps() {
    const steps = PublishingStep.useSlot().map((step, index) => (
        <step.type index={index} key={step.key} {...step.props} />
    ))
    const [activeStep, active] = useCurrentStep(steps)

    return (
        <Bound activeStep={active} activeIndex={activeStep} allSteps={steps}>
            <Box py={2}>
                <ListItemBox alignItems="flex-start" spacing={1}>
                    {steps}
                </ListItemBox>
            </Box>
        </Bound>
    )
}
