import { AddToGroup, RemoveFromGroup } from "event-definitions"
import { ContextMenu, ContextMenuBar } from "lib/@components/context-menu-bar"
import { EDIT_TREE } from "library/constants"
import { uniqueId } from "library/database/split-id"
import { DisplayUpdates } from "routes/regime/extension-edit/display-updates"
import { isInPublishedRegime, isInRegime, isInRegimeFolder } from "routes/facilities/lib/when-parent-item"
import { saveGroupData } from "routes/schedule/controller/store-group-data"
import { ScheduleExtension, ScheduleExtensionDisplay } from "routes/schedule/extendedSchedule"

import { parentTreeItem } from "routes/schedule/tree/schedules/makeTreeItemsFromGroup"
import { ScheduleItemRight, SelectorTitleRight } from "slot-definitions"
import { EditScheduleMenuItem } from "routes/regime/extension-edit/edit-schedule-menu-item"
import { EditDeltaWhileInSchedule } from "routes/regime/extension-edit/edit-delta-while-in-schedule"
import { RemoveSchedule } from "routes/regime/extension-edit/remove-schedule"
import { getTreeIdFromItem } from "lib/get-tree-id-from-item"
import { setGroupPayload } from "routes/schedule/controller/set-group-payload"
import { getGroupPayload } from "routes/schedule/controller/get-group-payload"
import { ExtensionEdit } from "routes/regime/extension-edit/extension-edit"
import { getTreeId } from "library/id-helpers"
import { Secure } from "lib/authorization/secure"
import { Frag } from "lib/@components/slot/frag"
import { getGroup } from "routes/schedule/controller/get-group"
import { getGroupId, getScheduleRecordId } from "library/get-unique-id-from-tree"

SelectorTitleRight.plug(
    <Secure demand="manageRegime" fallback={<Frag />}>
        <EditDeltaWhileInSchedule priority={200} />
    </Secure>
)
ScheduleExtensionDisplay.plug(<DisplayUpdates />)

ScheduleExtension.plug(function extensionEditPlug({ context: { editExtension } }) {
    return editExtension && <ExtensionEdit />
})

ScheduleItemRight.plug(
    <ContextMenuBar if={(c) => !c.facility && !isInPublishedRegime(c)} type="scheduleItem" priority={1000} />
)
ContextMenu("scheduleItem").plug(
    <Secure demand="manageRegime" fallback={<Frag />} if={isInRegimeFolder}>
        <EditScheduleMenuItem />
    </Secure>
)
ContextMenu("scheduleItem").plug(
    <Secure demand="manageRegime" fallback={<Frag />} if={isInRegimeFolder}>
        <RemoveSchedule />
    </Secure>
)

AddToGroup.handleOnce(async ({ toAdd, toGroup }) => {
    if (!isInRegime(toGroup)) return
    const existing = await getGroup(toGroup.id)
    existing.data ??= {}
    existing.data.statuses ??= {}
    await Promise.all(
        toAdd.map(async (add) => {
            const parent = add[parentTreeItem]
            if (isInRegimeFolder(add)) {
                const status = parent.data?.statuses?.[uniqueId(add._id)]
                const payload = await getGroupPayload(getGroupId(add.id), getScheduleRecordId(add.id))
                if (payload) {
                    await setGroupPayload(toGroup, getScheduleRecordId(add.id), payload)
                }
                if (status) {
                    existing.data.statuses[uniqueId(add._id)] = status
                }
            }
        })
    )
    await saveGroupData(toGroup.id, existing.data, undefined, getTreeId(toGroup.id))
})

RemoveFromGroup.handleOnce(async ({ toRemove, fromGroup }) => {
    if (fromGroup.id === EDIT_TREE) return
    const existing = await getGroup(fromGroup.id)
    existing.data ??= {}
    existing.data.statuses ??= {}
    await Promise.all(
        toRemove.map(async (remove) => {
            delete existing.data.statuses[uniqueId(remove._id)]
            const prior = await getGroupPayload(getScheduleRecordId(fromGroup.id), getScheduleRecordId(remove.id))
            await setGroupPayload(fromGroup, getScheduleRecordId(remove.id), { prior })
        })
    )
    await saveGroupData(fromGroup.id, existing.data, undefined, getTreeIdFromItem(fromGroup))
})
