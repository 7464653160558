import noop from "lib/noop"
import { Frag } from "lib/@components/slot/frag"
import { RegisterSystemTask } from "event-definitions"
import { handle } from "library/local-events"
import { TaskEditor } from "slot-definitions"
import { replaceAllWith } from "lib/@components/slot/replace-all-with"

export function registerSystemTask(name, handler = noop, editor = <Frag />, params = {}) {
    RegisterSystemTask.handleOnce(({ tasks }) => {
        const task = {
            name,
            event: "ST_" + name,
            params,
        }
        tasks.push(task)
    })
    handle("ST_" + name, handler)
    TaskEditor("ST_" + name).plug(replaceAllWith(editor))
}
