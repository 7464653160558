import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { parentFind } from "library/tree"
import DemandGuard from "minimals-template/components/guards/DemandGuard"
import { getDemandForFeature } from "lib/features"
import { FEATURE_FLAG_REGIME_PERMISSIONS } from "../regime-access/regime-access.runtime"
import { EDIT_TREE } from "library/constants"
import { ScheduleSelectorWrapper } from "slot-definitions"
import { PlanRoot } from "library/tree/constants"

ScheduleSelectorWrapper.plug(<RegimeGuard />)

function RegimeGuard({ children }) {
    const { parentItem } = useBoundContext()
    if (parentItem?.id === EDIT_TREE || parentFind(parentItem, (p) => p[PlanRoot])) {
        return (
            <DemandGuard
                demands={[getDemandForFeature(FEATURE_FLAG_REGIME_PERMISSIONS), "manageRegime", "publishRegime"]}
            >
                {children}
            </DemandGuard>
        )
    }
    return children
}
