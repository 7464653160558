import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { PlanFor } from "routes/plan/plan"
import { Box } from "@mui/material"
import { registerTab } from "lib/@components/tabs"
import { isEnabled } from "lib/@components/feature"
import { FEATURE_FLAG_PLANNING } from "routes/plan/plan.runtime"

import { REVIEW_TABS } from "routes/plan/review-tabs"

export function PlanTasksWrapperRuntime() {
    const { planId } = useBoundContext()
    return <PlanFor PlanContainer={Box} id={planId} published={true} />
}

registerTab({
    tab: REVIEW_TABS,
    id: "review-tasks",
    title: "Review Tasks",
    content: <PlanTasksWrapperRuntime $lazy />,
    predicate: () => isEnabled(FEATURE_FLAG_PLANNING),
})
