import { EnsureTree, RefreshScheduleItem, TreeReloaded, TreeUpdated } from "event-definitions"
import { GROUP } from "library/constants"
import { uniqueId } from "library/database/split-id"
import { Hide, parentFind, parentTreeItem, TreeRoot } from "library/tree"
import { PlanRoot } from "library/tree/constants"
import { getTreeIndex } from "routes/schedule/lib/getTreeIndex"
import { DontIndexChildren } from "routes/schedule/lib/getTreeItemIndex"
import { Regime } from "routes/regime/regime"
import { retrieveClientTree } from "routes/schedule/my-schedules/my-custom-schedules/retrieve-client-tree"
import { hasDemand } from "lib/authorization/has-demand"
import { debounce } from "lib/debounce"
import { addDebugItem } from "debug-window"
import { refreshTree } from "ioc"
import { checkPermission } from "library/authorization"

import { MULTIPLE_REGIMES_DEMAND } from "routes/schedule/folder-manager/multiple-regimes-demand"
import { invalidate } from "lib/graphql/cache"
import { ClientChanged } from "library/server-event-definitions"

const treeHandler = {}
TreeUpdated.before.handleOnce((id) => {
    if (treeHandler[id]) {
        invalidate("groupToSchedules")
        treeHandler[id]()
    }
})

let treeRetrievers = {}
ClientChanged.handleOnce(() => (treeRetrievers = {}))

export function createDynamicTreeRetriever({
    definition,
    tree,
    type = "",
    map = (v) => v,
    folderHandling = true,
    topLevelFolderHandling = folderHandling,
}) {
    let children
    let lastChildren = []
    const { _id: id } = definition
    let loaded = false
    let reading = false
    const treeId = `${type ? `${type}|` : ""}${uniqueId(id.split("|").at(-1))}`
    if (treeRetrievers[treeId]) {
        const cachedRetriever = treeRetrievers[treeId]
        cachedRetriever[parentTreeItem] = tree
        cachedRetriever.users = definition.users
        cachedRetriever.data = definition.data
        cachedRetriever.content = <Regime regime={definition} />
        cachedRetriever.$ = definition
        cachedRetriever.label = (type ? `${type.titleize()}: ` : "") + definition?.name ?? "Regime"
        return cachedRetriever
    }

    treeHandler[type ? `${type}|${treeId}` : treeId] = debounce(reload, 350)
    folderHandling &&= hasDemand("manageRegime")
    const childNode = {
        content: <Regime regime={definition} />,
        id: treeId,
        data: definition.data,
        _id: treeId,
        label: (type ? `${type.titleize()}: ` : "") + definition?.name ?? "Regime",
        [PlanRoot]: true,
        [TreeRoot]: false,
        [parentTreeItem]: tree,
        [DontIndexChildren]: true,
        folderHandling: topLevelFolderHandling,
        visible: true,
        isLoaded: false,
        hasChildren: true,
        type: GROUP,
        hasSubGroups: true,
        users: definition.users,
        [Hide]: !checkPermission(MULTIPLE_REGIMES_DEMAND),
        $: definition,
    }
    EnsureTree.handle((tree) => {
        if (loaded) return undefined
        if (uniqueId(tree) === uniqueId(treeId)) {
            loaded = true
            return childNode.children
        }
        return undefined
    })
    const retriever = Object.defineProperty(childNode, "children", {
        get() {
            if (children) return children
            if (reading) return lastChildren

            reading = true
            retrieveClientTree(
                childNode,
                type,
                treeId,
                map,
                (g) => (childNode.label = (type ? `${type.titleize()}: ` : "") + g?.label ?? "Regime")
            )
                .then((result) => {
                    recurseSetFolderHandling(result, folderHandling)
                    children = result
                    lastChildren = result
                    childNode[DontIndexChildren] = false
                    childNode.isLoaded = true
                    getTreeIndex().addToIndex?.(childNode, children)
                    addDebugItem(`${childNode.label} was updated`)
                    const treeRootParent = parentFind(childNode, (c) => c[TreeRoot])
                    RefreshScheduleItem(treeRootParent?.id).raiseOnce()
                })
                .finally(() => {
                    reading = false
                    RefreshScheduleItem(id).raiseOnce()
                    refreshTree()
                    TreeReloaded.raiseOnce()
                })
            return lastChildren
        },
    })

    treeRetrievers[treeId] = retriever
    return retriever

    function reload() {
        if (children) {
            children = null // This MUST happen or it won't reload
            ;({ children } = childNode.children) // This causes it to re-initialize
        }
    }
}

function recurseSetFolderHandling(result, folderHandling) {
    if (!Array.isArray(result)) {
        return
    }
    for (const child of result) {
        if (child.type === "group") {
            child.folderHandling = folderHandling
            recurseSetFolderHandling(child.children, folderHandling)
        } else {
            child.isAlias = true
        }
    }
}
