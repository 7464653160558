import { myConformityDocuments } from "routes/conformity/controller/my-conformity-documents"
import NavItem from "minimals-template/components/nav-section/vertical/NavItem"
import { registerPageNavigationWithContent } from "lib/routes/register-page-navigation-with-content"
import { Feature, isEnabled } from "lib/@components/feature"
import { hasDemand } from "lib/authorization/has-demand"
import { FEATURE_FLAG_PLANNING } from "routes/plan/plan.runtime"
import { OutlinedBadge } from "lib/@components/outlined-badge"

registerPageNavigationWithContent(
    <NavigateConformity />,
    "/app/conformity",
    "Conformity Documents",
    "carbon:document-tasks",
    {
        group: "Planning",
        predicate: () => hasDemand("$!sharing") && isEnabled("conformity"),
    }
)

function NavigateConformity({ data, depth, active, isCollapse, onClick }) {
    const documents = myConformityDocuments.useResults() ?? []
    if (documents.length) {
        data.info = <OutlinedBadge color="primary.main" content={documents.length} />

        return (
            <Feature feature={FEATURE_FLAG_PLANNING}>
                <NavItem
                    className="nav-item conformity"
                    item={data}
                    depth={depth}
                    active={active}
                    isCollapse={isCollapse}
                    onClick={onClick}
                />
            </Feature>
        )
    }
    return null
}
