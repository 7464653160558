import { Button } from "@mui/material"
import { useDialog } from "lib/@hooks/useDialog"
import Iconify from "minimals-template/components/Iconify"
import { ClientMigrations } from "slot-definitions"
import { ClientDataMigrationDialog } from "../../client-data-migration-dialog"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { getLegacyServiceModels } from "./controller/get-legacy-service-models"
import { successSnackbar } from "lib/snackbar/success-snackbar"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { importLegacyServiceModels } from "./controller/import-legacy-service-models"

const CUSTOM_DATA_MIGRATION_SERVICE_MODELS = "service models"

ClientMigrations.plug(<LegacyServiceModels priority={400} />)

function LegacyServiceModels() {
    const { subscriptionId } = useBoundContext()
    const dialog = useDialog(
        <ClientDataMigrationDialog
            type={CUSTOM_DATA_MIGRATION_SERVICE_MODELS}
            subscriptionId={subscriptionId}
            query={getLegacyServiceModels}
            warning={"Service models will be migrated to a new regime."}
        />
    )

    return (
        <Button variant="outlined" size="large" onClick={handleMigrate} startIcon={<Iconify icon={"mdi:folders"} />}>
            Service Models
        </Button>
    )

    async function handleMigrate() {
        const data = await dialog()
        if (data && data.length > 0) {
            try {
                const selectedIds = data.map((item) => [item.subscriptionLibraryId, ...item.childIds]).flat()
                await importLegacyServiceModels(subscriptionId, selectedIds)
                successSnackbar("Service models successfully migrated")
            } catch (error) {
                errorSnackbar("Error migrating service models")
            }
        }
    }
}
