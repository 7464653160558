import { Box, Stack, Typography } from "@mui/material"
import { PageBreadcrumbContainer } from "lib/page-breadcrumb-container"
import Iconify from "../Iconify"
import { UserDetailsCard } from "routes/conformity/plugins/your-details/user-details-card"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { UserAvatar } from "../user-avatar"

export function LockErrorPage({ breadcrumb, message }) {
    const { lockedBy } = useBoundContext()

    return (
        <PageBreadcrumbContainer title={breadcrumb}>
            <Stack alignItems="center" flex={1} spacing={5} mt={7}>
                <Box display="flex" justifyContent="center" sx={{ width: 275 }}>
                    <Iconify
                        sx={{ height: 150, width: 150, color: "primary.main" }}
                        icon="streamline:padlock-square-1-solid"
                    />
                </Box>
                <Typography variant="body2" color="text.secondary">
                    {message}
                </Typography>
                <Box>
                    <UserDetailsCard
                        user={lockedBy}
                        avatar={<UserAvatar user={lockedBy} sx={{ width: 64, height: 64 }} />}
                        breadcrumb={"Item locked"}
                    />
                </Box>
            </Stack>
        </PageBreadcrumbContainer>
    )
}
