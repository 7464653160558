import { Box } from "@mui/material"
import { BadgeLabel } from "routes/regime/plugins/regime-title/badge-label"

export function CustomScheduleStatusTag({ isDraft = true }) {
    return (
        <Box flex={1} sx={{ zoom: 0.8, ml: 1.5 }}>
            <BadgeLabel color={isDraft ? "info" : "success"} badgeContent={isDraft ? "Draft" : "Published"} />
        </Box>
    )
}
