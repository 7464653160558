import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material"

export function TabledRepeatHeaderRow({
    tableHeaders,
    orderBy,
    order,
    sortedItems,
    setSortedItems,
    setOrderBy,
    setOrder,
    initialFieldKey,
}) {
    return (
        <TableHead>
            <TableRow>
                {tableHeaders.map((tableHeader, index) => (
                    <TableCell key={index} sx={{ width: "auto", whiteSpace: "noWrap" }}>
                        {tableHeader.title}
                        {!!tableHeader.sortable && (
                            <TableSortLabel
                                onClick={() => handleSort(tableHeader)}
                                active={tableHeader.fieldKey === orderBy}
                                direction={orderBy === tableHeader.fieldKey ? (order === "" ? "asc" : "desc") : "desc"}
                                data-cy={`tabled-repeat-sort-label-${tableHeader?.fieldKey}`}
                            ></TableSortLabel>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )

    function handleSort(header) {
        if (header.fieldKey !== orderBy) {
            setOrderBy(header.fieldKey)
            setOrder("")
            const sortedItemsAsc = sortedItems.sortBy(header.fieldKey)
            setSortedItems([...sortedItemsAsc])
        } else {
            if (order === "") {
                setOrder("desc")
                const sortedItemsDesc = sortedItems.sortBy(header.fieldKey, "desc")
                setSortedItems([...sortedItemsDesc])
            } else if (order === "desc") {
                setOrder("")
                setOrderBy(initialFieldKey)
                const sortedItemsDefault = sortedItems.sortBy(initialFieldKey)
                setSortedItems([...sortedItemsDefault])
            }
        }
    }
}
