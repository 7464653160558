import noop from "lib/noop"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useRef } from "react"
import { normalizedStringify } from "library/normalized-stringify"
import { noChange, useRefresh } from "lib/@hooks/useRefresh"
import { Bound } from "lib/@components/binding/Bound"
import { RefreshPayload } from "event-definitions"
import { Box } from "@mui/material"
import { assessStatus } from "routes/schedule/assess-status"

export function Payload({ children, log }) {
    const context = useBoundContext()
    const { storePayload = noop, payload, schedule, target } = context

    const currentPayload = useRef()
    currentPayload.current = payload
    const stored = useRef(normalizedStringify(payload))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const refresh = useRefresh(noChange)

    if (log) {
        if (payload) {
            console.log("Will render", log)
        }
    }

    return (
        payload && (
            <Bound onChange={checkAndSave} target={payload} refresh={refresh} payloadTarget={target} payload={payload}>
                {!!log && <Box>{log}</Box>}
                {children}
            </Bound>
        )
    )

    async function checkAndSave() {
        const payload = currentPayload.current
        const newPayload = normalizedStringify(payload)
        if (stored.current !== newPayload) {
            assessStatus(payload, schedule)
            RefreshPayload.raise()
            stored.current = newPayload
            storePayload()
        }
    }
}
