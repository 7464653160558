import { appRoute } from "routes/app/home.runtime"
import { ActionsPage } from "routes/actions/actions-page"
import { HomePageTile } from "slot-definitions"
import { ActionsTile } from "routes/actions/actions-tile"
import NavItem from "minimals-template/components/nav-section/vertical/NavItem"
import { registerPageNavigationWithContent } from "lib/routes/register-page-navigation-with-content"
import { getDisplayActions } from "routes/actions/controller/get-display-actions"
import { hasPermission } from "library/authorization"
import { OutlinedBadge } from "lib/@components/outlined-badge"

appRoute.register("actions", <ActionsPage $lazy />)

registerPageNavigationWithContent(<NavigateActions />, "/app/actions", "Actions", "mdi:lightning-bolt-circle", {
    subPaths: true,
    predicate: hasPermission("schedules"),
})

function NavigateActions({ data, depth, active, isCollapse, onClick }) {
    const actions = getDisplayActions.useResults({ type: "all", take: 1 })
    if (!actions) return null
    if (actions.overdue) {
        data.info = <OutlinedBadge color="error.main" content={actions.overdue} />
    } else {
        data.info = <OutlinedBadge content={actions.all} />
    }

    return (
        <NavItem
            className="nav-item actions"
            item={data}
            depth={depth}
            active={active}
            isCollapse={isCollapse}
            onClick={onClick}
        />
    )
}

HomePageTile.plug(<ActionsTile $lazy priority={105} />)
