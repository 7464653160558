import { AssetAvailableSchedule, ScheduleItemMainExtra } from "slot-definitions"
import { isEnabled } from "lib/@components/feature"

import { FEATURE_FLAG_ASSET_MAPPING } from "routes/asset-register/feature-flag-asset-mapping"
import { Bound } from "lib/@components/binding/Bound"
import { Summary } from "routes/schedule/plugins/ai-summary/summary"

ScheduleItemMainExtra.plug(<Summary $lazy if={isEnabled(FEATURE_FLAG_ASSET_MAPPING)} priority={500} />)
AssetAvailableSchedule.plug(<Summary $lazy if={isEnabled(FEATURE_FLAG_ASSET_MAPPING)} priority={190} />)

export function ScheduleSummary({ id, ...props }) {
    return (
        <Bound schedule={{ _id: id }}>
            <Summary $lazy {...props} />
        </Bound>
    )
}
