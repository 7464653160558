import { TaskNotes } from "slot-definitions"
import { isEnabled } from "lib/@components/feature"
import { CHECKLIST_FEATURE } from "routes/schedule/plugins/ai-steps/ai-steps.runtime"
import { ThreeWords } from "routes/schedule/plugins/three-words/three-words"

TaskNotes.plug(
    <ThreeWords
        $lazy
        if={({ currentId, individualTask }) =>
            !individualTask && currentId.includes("~") && !isEnabled(CHECKLIST_FEATURE)
        }
        priority={120}
    />
)
