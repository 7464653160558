import { Box, Collapse, List, ListItemButton, Typography } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import Iconify from "minimals-template/components/Iconify"
import { Repeat } from "lib/@components/repeat"
import { useStoreState } from "lib/store"
import { ScheduleGroupInstance } from "routes/schedule/plugins/used-schedules/schedule-group-instance"

export function RelatedTree({ item, last, groupComponent = <ScheduleGroupInstance /> }) {
    const [open, setOpen] = useStoreState(`_open${item.id}`, false)
    return (
        <>
            <ListItemButton divider={!last} onClick={() => setOpen((o) => !o)}>
                <ListItemBox spacing={1}>
                    <Iconify icon="pajamas:issue-type-maintenance" />
                    <Box flex={1}>
                        <Typography variant="body2">{item.name}</Typography>
                    </Box>
                    <Typography variant="caption" color="text.secondary">
                        {item.count}
                    </Typography>
                    <Iconify
                        icon="gridicons:dropdown"
                        sx={{ transition: "transform linear 0.3s", transform: `rotate(${open ? -180 : 0}deg)` }}
                    />
                </ListItemBox>
            </ListItemButton>
            <Collapse in={open} mountOnEnter>
                <List>
                    <Repeat list={item.groups} item={groupComponent} />
                </List>
            </Collapse>
        </>
    )
}
