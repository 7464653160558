import { Box, Button, Stack } from "@mui/material"
import { format } from "date-fns"
import { singleWorksheetDownload } from "lib/excel-download"
import Iconify from "minimals-template/components/Iconify"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { AuditTopBarDownloadButton } from "slot-definitions"

export function AuditTopBar({ setOpen, isMobile, ok, type }) {
    const { data } = useBoundContext()

    const handleCloseClick = () => {
        if (!isMobile) {
            setOpen(false)
        } else {
            setOpen(false)
            ok()
        }
    }

    return (
        <Stack
            sx={{
                flexDirection: isMobile ? "row-reverse" : "row",
            }}
            mb={isMobile ? 0 : 3}
        >
            <Box>
                <Button
                    onClick={handleCloseClick}
                    startIcon={<Iconify icon="ic:round-cancel" />}
                    variant={isMobile ? "contained" : "text"}
                    id="closeAuditBtn"
                >
                    Exit
                </Button>
            </Box>
            <Box flex={1} />
            <AuditTopBarDownloadButton.Slot type={type}>
                <Box>
                    <Button
                        onClick={handleDownloadClick}
                        startIcon={<Iconify icon="ic:round-download" />}
                        variant="contained"
                        id="downloadAuditBtn"
                    >
                        Download
                    </Button>
                </Box>
            </AuditTopBarDownloadButton.Slot>
        </Stack>
    )

    function handleDownloadClick() {
        const groupedData = Object.values(data.groupBy("referenceId"))
        const filteredData = groupedData.filter(filterGroup).flatMap((entry) => entry)

        const formattedData = filteredData.map((d) => ({
            Version: d.referenceId.split("-version:")[1],
            Name: d.userName,
            Date: format(new Date(d.date), "dd/MM/yyyy"),
            Time: format(new Date(d.date), "p"),
            Comment: d.shortDescription,
        }))

        let row = 1
        const merge = []
        Object.values(filteredData.groupBy("referenceId")).forEach((group) => {
            const endRow = row + (group.length - 1)
            merge.push({ s: { r: row, c: 0 }, e: { r: endRow, c: 0 } })
            row = endRow + 1
        })

        singleWorksheetDownload({
            fileName: "ActivityLog.xlsx",
            sheetName: "Activity-Log",
            data: formattedData,
            mergedCells: merge,
        })
    }
}

function filterGroup(group) {
    return group?.some((c) => c.shortDescription.toLowerCase().includes("published"))
}
