import React from "react"

export function Frag({ children, clean, ...props }) {
    if (clean) {
        props = {}
    }
    if (Array.isArray(children))
        return children.filter(Boolean).map((child, index) => (
            <Frag key={child.key ?? index} {...props}>
                {child}
            </Frag>
        ))
    if (!children) return null
    if (!children.type) return children
    if (children.type === React.Fragment) {
        return <children.type>{children}</children.type>
    }
    return <children.type {...children.props} {...props} />
}

export const frag = <Frag />
