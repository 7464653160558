/**
 * @fileoverview CLS for the browser
 *
 * Summary
 *
 * This code manages the context in asynchronous JavaScript operations like Promises,
 * setTimeout, and event listeners. It ensures that a global context is preserved
 * across different async calls. This is achieved by creating a custom Promise class
 * that saves and restores the context, and by monkey-patching setTimeout and
 * addEventListener to ensure the context is kept during timer callbacks and event handling.
 *
 * Key Functions and Classes
 *
 * `createContext(parentContext)`: Creates a new context object, optionally inheriting from a parent context.
 *
 * `enableBrowserContinuationStorage()`: Enables continuation storage by replacing the global Promise with
 * a custom implementation and monkey-patching setTimeout and addEventListener.
 *
 * Global functions
 *
 * `runInContext(fn)` enables a function to be executed in a new context, inheriting from any current context
 * this can be used to start a context chain or create a separation.
 *
 * `enableBrowserContinuationStorage()` called to turn the system on, otherwise Promise etc. remain native
 *
 */
import { getStackFrameLocations } from "lib/@debug/browser-cls/get-stack-frame-locations"

globalThis.currentContext = null

/**
 * Creates a new context object, optionally inheriting from a parent context.
 *
 * @param {Object|null} parentContext - The parent context to inherit from, or null if none.
 * @return {Object} - The new context object with a potentially new unique ID.
 */
function createContext(parentContext = null) {
    // Create a new empty context object
    return Object.create(
        parentContext && Object.keys(parentContext).length === 0 ? parentContext.prototype ?? null : parentContext
    )
}

let enabled = false
globalThis.runInContext = runInContext

/**
 * Enables browser continuation storage by replacing the global Promise with a custom implementation
 * that captures and maintains the context across asynchronous operations.
 *
 * The method also monkey-patches `setTimeout` and `addEventListener` to ensure the context is preserved
 * during timer callbacks and event handling.
 *
 * @return {void}
 */
function enableBrowserContinuationStorage() {
    if (enabled) return
    enabled = true

    // Custom Promise subclass
    class CustomPromise extends Promise {
        constructor(executor) {
            const context = createContext(globalThis.currentContext)
            super((resolve, reject) => {
                const previousContext = globalThis.currentContext
                globalThis.currentContext = context

                try {
                    executor(resolve, reject)
                } finally {
                    globalThis.currentContext = previousContext
                }
            })
            this._context = context
        }

        then(onFulfilled, onRejected) {
            const capturedContext = createContext(this._context)

            const nextPromise = super.then(
                (value) => {
                    const previousContext = globalThis.currentContext
                    try {
                        globalThis.currentContext = capturedContext
                        return onFulfilled ? onFulfilled(value) : value
                    } finally {
                        globalThis.currentContext = previousContext
                    }
                },
                (reason) => {
                    const previousContext = globalThis.currentContext
                    try {
                        globalThis.currentContext = capturedContext
                        return onRejected ? onRejected(reason) : Promise.reject(reason)
                    } finally {
                        globalThis.currentContext = previousContext
                    }
                }
            )

            nextPromise._context = capturedContext
            return nextPromise
        }

        finally(onFinally) {
            const capturedContext = createContext(this._context)

            const nextPromise = super.finally(() => {
                const previousContext = globalThis.currentContext
                try {
                    globalThis.currentContext = capturedContext
                    if (onFinally) {
                        onFinally()
                    }
                } finally {
                    globalThis.currentContext = previousContext
                }
            })

            nextPromise._context = capturedContext
            return nextPromise
        }
    }

    // Replace the global Promise with the custom one
    window.Promise = CustomPromise

    // Monkey-patch setTimeout to maintain context
    const originalSetTimeout = window.setTimeout
    window.setTimeout = function (callback, delay, ...args) {
        const capturedContext = globalThis.currentContext
        return originalSetTimeout(function () {
            // Restore the captured context before running the callback
            const previousContext = globalThis.currentContext
            globalThis.currentContext = capturedContext

            try {
                callback(...args)
            } finally {
                globalThis.currentContext = previousContext
            }
        }, delay)
    }
}

/**
 * Executes a given function within a new continuation context, which will inherit from any existing context.
 *
 * @param {Function} fn - The function to execute within the context. If no function is provided, nothing happens
 * @return {any} - The return value of the executed function.
 */
export function runInContext(fn) {
    if (typeof fn !== "function") return undefined
    if (!enabled) {
        return fn()
    }
    const context = createContext(globalThis.currentContext)

    const previousContext = globalThis.currentContext
    globalThis.currentContext = context

    try {
        return fn()
    } finally {
        globalThis.currentContext = previousContext
    }
}

globalThis.createContextFunction = createContextFunction
globalThis.logPromise = Promise.resolve(true)

globalThis.getCurrentStack = function getCurrentStack() {
    return getStackFrameLocations(3)
}

/**
 * Creates a new HoF that executes the given function within a new context.
 *
 * @param {Function} fn - The function to be executed within the context.
 * @param {String} name - the name of the context function
 * @return {Function} A new function that, when called, executes the provided function `fn` within a context.
 */
export function createContextFunction(fn, name) {
    if (typeof fn !== "function") throw new Error("You must specify a function")
    if (!process.env.REACT_APP_DEBUG_STACK) return fn
    return (...params) =>
        runInContext(() => {
            currentContext.contextFunction = name
            currentContext.stack = new Error()
            return fn(...params)
        })
}

globalThis.enableBrowserContinuationStorage = enableBrowserContinuationStorage

if (process.env.REACT_APP_DEBUG_STACK) {
    enableBrowserContinuationStorage()
}

globalThis.compactContext = function compactContext() {
    const output = {}
    for (const key in globalThis.currentContext) {
        output[key] = globalThis.currentContext[key]
    }
    return output
}
