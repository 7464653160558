import { Box, Tooltip, Typography } from "@mui/material"
import { imageForClass } from "routes/schedule/plugins/codes-tab/image-for-class"

export function Code({ item }) {
    return (
        <Tooltip arrow title={item.description}>
            <Box display="flex" alignItems="center" lineHeight={0} mr={1.5} mb={0.5}>
                <Box mr={0.5}>
                    <img
                        src={imageForClass[!item.code.includes("_") ? "rics" : "uniclass"]}
                        width={16}
                        alt="Logo"
                        height={16}
                    />
                </Box>
                <Typography variant="caption" sx={{ color: "text.secondary" }}>
                    {item.code}
                </Typography>
            </Box>
        </Tooltip>
    )
}
