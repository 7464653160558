/**
 * Makes a data copy of an object, does not maintain any added functions
 * @param {any} item - the item to clone
 * @returns {any}
 */
import { deserializeCircular, serializeCircular } from "library/serialize-circular"
import { noCircular } from "lib/noCircular"

export function clone(item) {
    if (!item) return item
    return JSON.parse(JSON.stringify(item, noCircular()))
}

export function cloneDeep(item) {
    if (!item) return item
    return deserializeCircular(serializeCircular(item))
}
