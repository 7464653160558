import { RegisterSystemTask } from "event-definitions"
import { handle } from "library/local-events"
import { mutate } from "lib/graphql/mutate"
import { gql } from "@apollo/client"
import { TaskEditor } from "slot-definitions"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { warningSnackbar } from "lib/snackbar/warning-snackbar"
import { replaceAllWith } from "lib/@components/slot/replace-all-with"
import { Frag } from "lib/@components/slot/frag"

RegisterSystemTask.handleOnce(({ tasks }) => {
    const task = {
        name: "Reimport Core Tags",
        event: "reimport-core-tags",
        params: {},
    }
    tasks.push(task)
})

handle("reimport-core-tags", async () => {
    warningSnackbar("Requesting  Reimport Core Tags", { autoHideDuration: 10000 })
    await mutate(
        gql`
            mutation Mutation {
                reimportCoreTags
            }
        `,
        {},
        { returns: "reimportCoreTags" }
    ).catch((e) => {
        console.error(e)
        errorSnackbar(e.message, { autoHideDuration: null })
    })
})

TaskEditor("reimport-core-tags").plug(replaceAllWith(<Frag />))

RegisterSystemTask.handleOnce(({ tasks }) => {
    const task = {
        name: "Process Legacy Data",
        event: "process-legacy-data",
        params: {},
    }
    tasks.push(task)
})

handle("process-legacy-data", async () => {
    warningSnackbar("Requesting Process Legacy Data", { autoHideDuration: 10000 })
    await mutate(
        gql`
            mutation Mutation {
                processLegacyData
            }
        `,
        {},
        { returns: "processLegacyData" }
    ).catch((e) => {
        console.error(e)
        errorSnackbar(e.message, { autoHideDuration: null }, { autoHideDuration: null })
    })
})

TaskEditor("process-legacy-data").plug(replaceAllWith(<Frag />))

RegisterSystemTask.handleOnce(({ tasks }) => {
    const task = {
        name: "Process Excel",
        event: "process-excel",
        params: {},
    }
    tasks.push(task)
})

handle("process-excel", async () => {
    warningSnackbar("Requesting  Process Excel", { autoHideDuration: 10000 })
    await mutate(
        gql`
            mutation Mutation {
                processExcel
            }
        `,
        {},
        { returns: "processExcel" }
    ).catch((e) => {
        console.error(e)
        errorSnackbar(e.message, { autoHideDuration: null })
    })
})

TaskEditor("process-excel").plug(replaceAllWith(<Frag />))
