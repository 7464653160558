import { useStoreState } from "lib/store"
import { getCustomSchedulesUsingCode } from "../controller/get-custom-schedules-using-code"
import { Box, Collapse, Paper, Typography } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import { ScheduleItem } from "routes/schedule/tree/schedules/scheduleItem"
import { Repeat } from "lib/@components/repeat"
import { Bound } from "lib/@components/binding/Bound"

export function RelatedCustomSchedules({ skillId, client }) {
    const [open] = useStoreState(`related_custom_${skillId}`, false)
    return (
        <Collapse in={open} mountOnEnter>
            <CustomScheduleList skillId={skillId} client={client} />
        </Collapse>
    )
}

function CustomScheduleList({ skillId, client }) {
    const custom = getCustomSchedulesUsingCode.useResults(skillId, client)
    if (!custom) return null
    return (
        <Paper elevation={1} sx={{ overflow: "hidden", mb: 2, p: 0 }}>
            <Typography variant="subtitle2" sx={{ p: 2 }}>
                Custom schedules in use
            </Typography>
            <ListItemBox sx={{ width: 1, py: 0.3, bgcolor: "grey.50016", color: "text.secondary" }}>
                <Typography sx={{ ml: 6 }} variant="caption">
                    Name
                </Typography>
                <Box flex={1} />
            </ListItemBox>
            <Repeat list={custom} item={<RelatedCustomSchedule />} />
        </Paper>
    )
}

function RelatedCustomSchedule({ item }) {
    if (item._id.includes("drafts") && !item.id.includes("allDrafts!")) {
        item.id = `allDrafts!${item.id}/edit`
    }
    return (
        <Bound target={item}>
            <ScheduleItem schedule={item} />
        </Bound>
    )
}
