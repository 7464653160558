import { styled } from "@mui/material/styles"
import { StepConnector, stepConnectorClasses } from "@mui/material"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    fontSize: 36,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.available && {
        backgroundColor: theme.palette.primary.main,
    }),
    ...(ownerState.active && {
        backgroundImage: "linear-gradient( 136deg, #8DBBBF 0%, #38939B 50%, #005A62 100%)",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
        backgroundImage: "linear-gradient( 136deg, #8DBBBF  0%,  #38939B 50%, #005A62 100%)",
    }),
    ...(!ownerState.available && {
        backgroundColor: theme.palette.grey["400"],
        backgroundImage: null,
    }),
}))

export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: "linear-gradient( 95deg, #005A62  0%,#38939B  50%,#8DBBBF   100%)",
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: "linear-gradient( 95deg, #005A62  0%,#38939B  50%,#8DBBBF   100%)",
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
        borderRadius: 1,
    },
}))

export function StepIcon(props) {
    const { active, completed, className } = props
    const { isAvailable, active: nowActive } = useBoundContext()

    return (
        <ColorlibStepIconRoot
            ownerState={{ available: isAvailable ?? true, completed, active: active || nowActive }}
            className={className}
        >
            {props.icon}
        </ColorlibStepIconRoot>
    )
}
