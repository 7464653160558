import { useDebouncedValue } from "lib/@hooks/useDebouncedValue"
import { Box, IconButton, InputAdornment, TextField } from "@mui/material"
import { setFromEvent } from "lib/setFromEvent"
import { FilterSchedule, RefreshScheduleSelector } from "event-definitions"
import { useRef, useState } from "react"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useEffectOnSubsequentChange } from "lib/@hooks/use-effect-on-subsequent-change"
import Iconify from "minimals-template/components/Iconify"

export function SearchFilter() {
    const { parentItem } = useBoundContext()
    const [labelSearch, setLabelSearch] = useState("")
    const searchString = useRef()
    searchString.current = labelSearch.toLowerCase()
    const [search, setSearch] = useDebouncedValue(labelSearch, 500, setLabelSearch)
    FilterSchedule.useEvent(filter)
    useEffectOnSubsequentChange(() => {
        RefreshScheduleSelector.raiseOnce()
    }, [labelSearch])
    return (
        !!parentItem?.children?.length && (
            <Box py={2} m="auto" width={1}>
                <TextField
                    fullWidth
                    autoFocus
                    className="model-filter"
                    value={search}
                    onChange={setFromEvent(setSearch)}
                    variant="standard"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton className="clear-button" size="small" onClick={() => setSearch("")}>
                                    <Iconify icon="ic:round-clear" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    placeholder="Filter by name or code..."
                />
            </Box>
        )
    )

    function filter({ items }) {
        return {
            items: items
                .filter(Boolean)
                .filter(
                    (item) =>
                        item.label?.toLowerCase().includes(searchString.current) ||
                        item.code?.toLowerCase().startsWith(searchString.current)
                ),
        }
    }
}
