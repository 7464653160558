import { appRoute } from "routes/app/home.runtime"
import { registerConditionalPageNavigation } from "lib/routes/register-conditional-page-navigation"
import { isEnabled } from "lib/@components/feature"
import { Lazy } from "lib/make-lazy"
import { registerFeature } from "lib/features"

registerConditionalPageNavigation(() => isEnabled("ai-schedules"), "/app/ai", "AI Search", "eos-icons:ai", {
    group: "Schedules",
})

appRoute.register("ai", <Lazy importer={() => import("./ai-route")} extract="AiRoute" />)

export const FEATURE_FLAG_AI_SCHEDULES = registerFeature({
    numericalId: 6,
    name: "AI Search",
    id: "ai-schedules",
    description: `Turn on the AI search feature, searches the database using AI to work out what might be useful, e.g. how to maintain a florist etc.`,
})
