import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"
import { query } from "lib/graphql/query"

export const publishPlan = api(function publishPlan(planId, type = "publish") {
    return mutate(
        gql`
            mutation PublishPlan($planId: String!, $type: String!) {
                publishPlan(id: $planId, type: $type)
            }
        `,
        { planId, type },
        { returns: "publishPlan" }
    )
})

export const getPublishedPlan = api(function getPublishedPlan(id, type = "publish") {
    return query(
        gql`
            query GetPublishedPlan($id: String!, $type: String!) {
                getPublishedPlan(id: $id, type: $type)
            }
        `,
        { id, type },
        { returns: "getPublishedPlan" }
    )
})

export const getHistoricalPlan = api(function getHistoricalPlan(historyId) {
    return query(
        gql`
            query GetHistoricalPlan($historyId: String!) {
                getHistoricalPlan(historyId: $historyId)
            }
        `,
        {
            historyId,
        },
        { returns: "getHistoricalPlan" }
    )
})

export const removePlan = api(function removePlan(id, type = "publish") {
    return mutate(
        gql`
            mutation RemovePlan($id: String!, $type: String!) {
                removePlan(id: $id, type: $type)
            }
        `,
        { id, type },
        { returns: "removePlan" }
    )
})
