import noop from "lib/noop"
import { Box } from "@mui/material"
import { EDITABLE_STYLE } from "lib/@components/editable/style"
import { useEffect, useRef } from "react"
import { prevent } from "lib/prevent"
import { useEditing } from "lib/@components/editable/useEditing"
import { Editing } from "lib/@components/editable/editing"
import { EditField } from "event-definitions"
import { getCurrentCursorPosition } from "lib/@components/editable/getCurrentCursorPosition"
import { createRange } from "lib/@components/editable/createRange"
import { setCurrentCursorPosition } from "lib/@components/editable/setCurrentCursorPosition"
import { EditableBox } from "lib/@components/editable/editableBox"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { bind } from "lib/@components/binding/bind"

export const BoundEditableText = bind(<EditableText />, { extract: (v) => v, omit: ["helperText", "error"] })
export const BoundEditableBox = bind(<EditableBox />, { extract: (v) => v, omit: ["helperText", "error"] })

export function EditableText({
    field,
    value,
    readOnly,
    onChange = noop,
    pt = 0.8,
    pb = 0.8,
    pl = 1.5,
    pr = 1.5,
    sx,
    outerSx,
    ...props
}) {
    const { target } = useBoundContext()
    const cursor = useRef(0)
    const ref = useRef()
    EditField.useEvent(({ target: desiredTarget, field: desiredField }) => {
        if (desiredField === field && desiredTarget === target) {
            ref.current.focus()
            const range = createRange(ref.current, { count: value.length })
            const selection = window.getSelection()
            selection.removeAllRanges()
            selection.addRange(range)
        }
    })
    useEffect(() => {
        try {
            if (document.activeElement === ref.current) {
                if (cursor.current) {
                    setCurrentCursorPosition(ref.current, cursor.current)
                }
            }
        } catch (e) {
            //
        }
    }, [value])
    const [, edit] = useEditing()
    return (
        <Editing
            field={field}
            editor={
                <Box
                    display="inline-block"
                    onKeyDown={handleSpaceKey}
                    onClick={prevent(edit)}
                    onFocus={edit}
                    sx={outerSx}
                >
                    <Box
                        {...props}
                        ref={ref}
                        tabIndex={0}
                        contentEditable
                        onInput={handleInput}
                        minHeight={10}
                        sx={{
                            ...EDITABLE_STYLE,
                            pt,
                            pb,
                            pl,
                            pr,
                            wordBreak: "break-word",
                            ...sx,
                        }}
                        dangerouslySetInnerHTML={{ __html: value ?? "" }}
                    />
                </Box>
            }
            display={
                <Box
                    display="inline-block"
                    sx={{ wordBreak: "break-word", ...sx }}
                    minHeight={10}
                    dangerouslySetInnerHTML={{ __html: value ?? "" }}
                />
            }
        />
    )

    function handleSpaceKey(event) {
        if (readOnly) {
            event.preventDefault()
            return
        }
        if (event.code !== "Space") {
            return
        }
        event.preventDefault()
        document.execCommand("insertText", false, " ")
    }

    function handleInput(event) {
        cursor.current = getCurrentCursorPosition(ref.current.id)
        if (readOnly) {
            onChange(value)
        } else {
            onChange(event.target.innerText)
        }
    }
}
