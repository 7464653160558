import { Breadcrumbs } from "slot-definitions"
import { replaceAllWith } from "lib/@components/slot/replace-all-with"
import { Frag } from "lib/@components/slot/frag"
import { checkPermission } from "library/authorization"

Breadcrumbs.plug(replaceAllWith(<Frag if={onlyIfSharingAndOverarchingIntro} />, 2000))

function onlyIfSharingAndOverarchingIntro(context) {
    return !!(context.schedule?.code === "00-01" && checkPermission("$sharing"))
}
