import { Box, Button, Portal, Stack, Typography, useTheme } from "@mui/material"
import { openContactUs } from "routes/help/helpBanner"
import { HeaderBarRight } from "slot-definitions"

export function BetaBarRuntime() {
    const theme = useTheme()

    return (
        <Portal>
            <Stack
                direction="row"
                alignItems="center"
                sx={{
                    position: "fixed",
                    top: -2,
                    left: 8,
                    // transform: "translateX(-50%)",
                    p: 0,
                    zIndex: 4000,
                    borderBottomLeftRadius: 8,
                    borderBottomRightRadius: 8,

                    bgcolor: `${theme.palette.warning.main}F0`,
                    color: "warning.contrastText",
                }}
            >
                <Box sx={{ textAlign: "center", mx: 1 }}>
                    <Typography variant="body2" sx={{ whiteSpace: "nowrap" }} component="div">
                        You are using a BETA version of the product which may contain bugs and incomplete features
                    </Typography>
                </Box>

                <Button
                    variant="contained"
                    onClick={openContactUs}
                    size="small"
                    sx={{
                        whiteSpace: "nowrap",
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderTopRightRadius: 0,
                    }}
                >
                    Contact Us
                </Button>
            </Stack>
        </Portal>
    )
}

if (process.env.REACT_APP_BETA_VERSION) {
    HeaderBarRight.plug(<BetaBarRuntime priority={-1} />)
}
