import { TableCell, TableHead, TableRow } from "@mui/material"

export function Head() {
    return (
        <TableHead>
            <TableRow>
                <TableCell align="left">Id</TableCell>
                <TableCell align="left">Title</TableCell>
                <TableCell align="left">Code</TableCell>
                <TableCell align="left">Download</TableCell>
            </TableRow>
        </TableHead>
    )
}
