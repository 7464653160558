import { uniqueId } from "library/database/split-id"
import { MyScheduleListItem } from "slot-definitions"
import { OpenDraft } from "event-definitions"
import { PublishedItem } from "routes/schedule/my-schedules/my-custom-schedules/schedule-item-types/draft-schedule-item/publishedItem"
import { DraftItem } from "routes/schedule/my-schedules/my-custom-schedules/schedule-item-types/draft-schedule-item/draftItem"
import { RetiredItem } from "routes/schedule/my-schedules/my-custom-schedules/schedule-item-types/draft-schedule-item/retiredItem"
import { DRAFT, RETIRED, SCHEDULE } from "library/constants"
import { navigate } from "lib/routes/navigate"

MyScheduleListItem(DRAFT).plug(<DraftItem />)
MyScheduleListItem(SCHEDULE).plug(<PublishedItem />)
MyScheduleListItem(RETIRED).plug(<RetiredItem />)

OpenDraft("*").handle((draft) => {
    navigate(`/app/schedules/${uniqueId(draft._id ?? draft)}/edit`)
})
