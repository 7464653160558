import { Box, ListItemButton } from "@mui/material"
import { RefreshMainTree } from "event-definitions"
import { ListItemBox } from "lib/@components/ListItemBox"
import { FolderItemPart } from "routes/schedule/tree/schedules/folder-item-part"
import { FolderIcon } from "routes/schedule/tree/schedules/folder-icon"
import { getClientInfo } from "minimals-template/components/contexts/NognitoContext"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function MyPublished({ last, Component = ListItemButton }) {
    const { refresh, onClick, selected, noDivider } = useBoundContext()
    last = last || noDivider
    RefreshMainTree.useEvent(refresh)
    return (
        <Component divider={!last} onClick={onClick} selected={selected}>
            <ListItemBox spacing={1}>
                <FolderItemPart />
                <FolderItemPart>
                    <FolderIcon icon="fluent:building-multiple-24-filled" sx={{ color: "orange" }} />
                </FolderItemPart>
                <Box flex={1}>{getClientInfo()?.name ?? "My Company"} Published Schedules</Box>
            </ListItemBox>
        </Component>
    )
}
