import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material"

export function ConfirmDialog({ message, title, ok, cancel, buttons }) {
    return (
        <>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent sx={{ mt: 2 }}>{message}</DialogContent>
            <DialogActions>
                <Button onClick={cancel} color="secondary">
                    Cancel
                </Button>
                {buttons ? (
                    buttons.map((b) => (
                        <Button key={b} onClick={() => ok(b)} color="primary">
                            {b}
                        </Button>
                    ))
                ) : (
                    <Button onClick={() => ok(true)} color="primary" variant="contained" id="confirmOkButton">
                        Ok
                    </Button>
                )}
            </DialogActions>
        </>
    )
}
