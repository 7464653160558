const { registerSymbol } = require("library/serialize-circular")
const { PlanRoot } = require("library/tree/constants")

const parentTreeItem = (exports.parentTreeItem = registerSymbol(Symbol("parent")))
exports.Hide = registerSymbol(Symbol("Hide"))
exports.TreeRoot = registerSymbol(Symbol("TreeRoot"))
exports.parentSome = (item, predicate = () => false) => {
    if (!item) return false
    if (predicate(item)) {
        return true
    }
    if (!item[exports.TreeRoot]) {
        return exports.parentSome(item?.[exports.parentTreeItem], predicate)
    }
    return false
}

exports.parentFind = (item, predicate = () => false, seen = new Set(), depth = 0) => {
    if (!item) return null
    if (seen.has(item)) return null
    if (depth > 30) return null
    seen.add(item)
    return predicate(item) ? item : exports.parentFind(item?.[exports.parentTreeItem], predicate, seen, depth + 1)
}

exports.allParents = (item, check = (item) => !!item[exports.parentTreeItem], list = [], seen = new Set()) => {
    if (!item) return list
    if (seen.has(item)) return list
    seen.add(item)
    list.push(item)
    if (!check(item)) return list
    return exports.allParents(item[exports.parentTreeItem], check, list, seen)
}

exports.findInChildren = (item, predicate = () => false, depth = Infinity) => {
    if (!item) return []
    return find(item)

    function find(item, output = [], current = 0) {
        if (predicate(item)) {
            output.push(item)
        }
        if (item.children?.length && current < depth) {
            for (const child of item.children) {
                find(child, output, current + 1)
            }
        }
        return output
    }
}

function getWhere(currentGroup) {
    return getParents(currentGroup).reverse().map("label").join(" › ")
}

function getParents(currentGroup) {
    const parents = []
    while (currentGroup && currentGroup.data?.type !== "facility" && !currentGroup[PlanRoot]) {
        parents.push({ label: currentGroup.label, id: currentGroup.id })
        currentGroup = currentGroup[parentTreeItem]
    }
    if (currentGroup && !currentGroup[PlanRoot]) {
        parents.push({ label: currentGroup.label, id: currentGroup.id })
    }
    return parents
}

function getAllParents(currentGroup) {
    const parents = []
    while (currentGroup) {
        parents.push({ label: currentGroup.label, id: currentGroup.id })
        currentGroup = currentGroup[parentTreeItem]
    }
    return parents
}

exports.getWhere = getWhere
exports.getParents = getParents
exports.getAllParents = getAllParents
