import { Box, Typography } from "@mui/material"
import { OneCard } from "lib/@components/one-card"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function SimpleDisplay() {
    const { parentItem } = useBoundContext()
    const group = parentItem.data

    return <SimpleCompleteCard group={group} label={trimType(parentItem.label)} />
}

function trimType(name) {
    return name.replace("Live: ", "").replace("Ready: ", "")
}

function SimpleCompleteCard({ group, label }) {
    return (
        !!group && (
            <OneCard sx={{ textAlign: "center", mb: 2, position: "relative" }}>
                {!!group.image && (
                    <Box sx={{ position: "relative" }}>
                        <Box
                            width={1}
                            component="img"
                            sx={{ objectFit: "cover" }}
                            src={group.image}
                            height={260}
                            color="#333"
                            overflow="hidden"
                        />
                    </Box>
                )}

                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                    {group.name ?? label ?? "Not named"}
                </Typography>
            </OneCard>
        )
    )
}
