import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"

export async function updateScheduleInTree(tree, scheduleId, tailored) {
    return mutate(
        gql`
            mutation UpdateScheduleInTree($tree: String!, $scheduleId: String!, $tailored: Boolean) {
                updateScheduleInTree(tree: $tree, scheduleId: $scheduleId, createTailoredPrompt: $tailored)
            }
        `,
        { tree, scheduleId, tailored },
        { returns: "updateScheduleInTree" }
    )
}
