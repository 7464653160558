import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const planConfig = api(async function planConfiguration(id, published = false) {
    return query(
        gql`
            query PlanConfig($id: String!) {
                planConfiguration(id: $id)
            }
        `,
        { id: published ? `publish|${id}` : id },
        { returns: "planConfiguration" }
    )
})
