import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { parentFind } from "library/tree"
import { PlanRoot } from "library/tree/constants"
import { getBasePlanId } from "routes/plan/controller/use-plan"
import { If } from "lib/@components/switch"
import { Secure } from "lib/authorization/secure"
import { Frag } from "lib/@components/slot/frag"
import { Button } from "@mui/material"
import { confirm } from "lib/@dialogs/confirm"
import { publishPlan, removePlan } from "routes/plan/controller/publish-plan"
import { navigate } from "lib/routes/navigate"
import { PublishClient } from "routes/regime/plugins/publishing/publishing.runtime"
import { busyWhile } from "lib/@components/busy/busy-while"

export function PublishButton() {
    const { parentItem } = useBoundContext()
    const parent = parentFind(parentItem, (c) => c[PlanRoot])
    const id = getBasePlanId(parent)

    return (
        <If field="activeStep.props.id" equals="Review">
            <PublishClient>
                <Secure demand="publishRegime" hide fallback={<Frag />}>
                    <Button
                        onClick={publish}
                        size="small"
                        variant="outlined"
                        color="primary"
                        sx={{ mx: "auto", display: "block", mt: 1 }}
                    >
                        Approve Review
                    </Button>
                </Secure>
            </PublishClient>
        </If>
    )

    async function publish() {
        if (await confirm("Are you sure you want to approve the current review version?")) {
            await busyWhile(async () => {
                await publishPlan(`publish|${id}`, "ready")
                await removePlan(id, "publish")
                navigate(`/app/schedules?id=ready|${id}`, { replace: true })
            }, "Publishing")
        }
    }
}
