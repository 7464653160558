import { TailoredScheduleRight } from "slot-definitions"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Button, Menu, Portal, Typography } from "@mui/material"
import { useRef, useState } from "react"
import { Repeat } from "lib/@components/repeat"
import { WhereItem } from "routes/asset-register/plugins/global-tailoring/plugins/schedule-usage/where-item"
import Iconify from "minimals-template/components/Iconify"

TailoredScheduleRight.plug(<WhereUsed priority={50} />)

function WhereUsed() {
    const {
        tailoredSchedule: { where },
    } = useBoundContext()
    const [show, setShow] = useState()
    const button = useRef()
    return (
        !!where.length && (
            <>
                <Button
                    ref={button}
                    onClick={toggle}
                    endIcon={
                        show ? (
                            <Iconify icon="mdi:arrow-up-drop-circle-outline" />
                        ) : (
                            <Iconify icon="mdi:arrow-down-drop-circle-outline" />
                        )
                    }
                >
                    <Typography color="text.secondary" variant="body2">
                        Used {where.length} {"time".pluralize(where.length)}
                    </Typography>
                </Button>
                <Portal>
                    <Menu open={!!show} anchorEl={show} onClose={() => setShow(null)}>
                        <Repeat list={where} item={<WhereItem />} />
                    </Menu>
                </Portal>
            </>
        )
    )

    function toggle() {
        setShow(button.current)
    }
}
