export function truncateAtNthString(source, n, search) {
    if (!source) return source
    const regex = new RegExp(search, "gi")
    let match
    let count = 0
    while (count <= n) {
        match = regex.exec(source)
        count++
        if (count === n) {
            return match ? source.substring(0, match.index + match[0].length) : source
        }
    }
    return source
}
