import { useBoundContext } from "lib/@components/binding/use-bound-context"
import {
    Button,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
} from "@mui/material"
import { Head } from "./table-head"
import { NoResults } from "lib/@components/no-results/no-results"
import { useEffect, useMemo, useState } from "react"
import { downloadDocX } from "../../helpers/download-docx"
import { SortButton } from "../sort-btn"
import { setFromEvent } from "lib/setFromEvent"
import Iconify from "minimals-template/components/Iconify"

export function SchedulesTable() {
    const { target } = useBoundContext()
    const [schedules, setSchedules] = useState()

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [isSorted, setIsSorted] = useState(true)

    const copyOfSchedules = useMemo(() => structuredClone(target), [target])
    const isSchedules = schedules !== undefined && schedules !== null && schedules?.edges?.length

    useEffect(() => {
        setSchedules(target)
        setIsSorted(true)
    }, [target])

    if (!isSchedules) {
        return <NoResults />
    }

    return (
        <Stack>
            <SortButton isSortDesc={isSorted} handleSort={handleSort} />
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <Head />
                    <TableBody>
                        {schedules !== undefined &&
                            schedules.edges.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((s) => {
                                const { approved, id } = s?.node ?? {}
                                const schedulesToUse = approved
                                schedulesToUse["id"] = id

                                return (
                                    <TableRow
                                        key={schedulesToUse?.id}
                                        data-cy={`scheduleTableRow-scheduleId-${schedulesToUse?.id}`}
                                    >
                                        <TableCell data-cy={"scheduleIdCell"}>{schedulesToUse?.id}</TableCell>
                                        <TableCell data-cy={"scheduleTitleCell"}>{schedulesToUse?.title}</TableCell>
                                        <TableCell data-cy={"scheduleCodeCell"}>
                                            {schedulesToUse?.code} (version: {schedulesToUse?.version})
                                        </TableCell>
                                        <TableCell data-cy={"scheduleDownloadCell"}>
                                            <Button
                                                startIcon={<Iconify icon="mdi:file-download" />}
                                                onClick={() => doDownload(schedulesToUse)}
                                            >
                                                Download
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    count={schedules?.edges?.length || 0}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 25]}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={setFromEvent(handleChangeRowsPerPage)}
                />
            </TableContainer>
        </Stack>
    )

    //JK: event is needed
    function handleChangePage(event, newPage) {
        setPage(newPage)
    }

    function handleChangeRowsPerPage(value) {
        setRowsPerPage(value)
        setPage(0)
    }

    async function doDownload(data) {
        downloadDocX(data)
    }

    function handleSort() {
        const schedulesToSort = [...schedules.edges]
        let sortByVersion
        if (isSorted) {
            sortByVersion = schedulesToSort.sort((a, b) => b.node.approved.version - a.node.approved.version)
            setSchedules({ ...schedules, edges: sortByVersion })
            setIsSorted((p) => !p)
        } else {
            setSchedules(copyOfSchedules)
            setIsSorted((p) => !p)
        }
    }
}
