import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"

export async function deleteBasket(id) {
    await mutate(
        gql`
            mutation removeBasket($id: String!) {
                deleteBasket(id: $id)
            }
        `,
        {
            id,
        }
    )
}
