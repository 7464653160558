import { mutate } from "lib/graphql/mutate"
import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"

export const removeWatch = api(
    (_id, path) =>
        _id &&
        mutate(
            gql`
                mutation RemoveWatch($_id: String!, $path: String!) {
                    removeWatch(_id: $_id, path: $path)
                }
            `,
            { _id, path },
            { returns: "addWatch" }
        )
)
