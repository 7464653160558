import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"

export const getAllowedRegimeUsers = api(async (id) =>
    query(
        gql`
            query GetAllowedRegimeUsers($id: String!) {
                getAllowedRegimeUsers(id: $id) {
                    id
                    metadata
                }
            }
        `,
        { id },
        { returns: "getAllowedRegimeUsers" }
    )
)
