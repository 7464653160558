import { useState } from "react"
import { DialogContent, DialogTitle, TextField } from "@mui/material"
import { setFromEvent } from "lib/setFromEvent"
import { OkCancel } from "lib/@components/okCancel"

export function CreateBasketDialog() {
    const [name, setName] = useState("")
    return (
        <>
            <DialogTitle>Create a favourites list of schedules</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    sx={{ mt: 2 }}
                    variant="outlined"
                    label="List name"
                    fullWidth
                    value={name}
                    onChange={setFromEvent(setName)}
                />
            </DialogContent>
            <OkCancel value={name} />
        </>
    )
}
