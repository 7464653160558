import { StatsOverrides, StatsPanels } from "slot-definitions"
import { TabPanel } from "@mui/lab"
import { makeLazy } from "lib/make-lazy"
import { CheckForCompletions } from "routes/regime/completions/check-for-completions"

const CompletionsTab = makeLazy(() => import("./completions-tab"), "CompletionsTab")

StatsOverrides.plug(<CheckForCompletions />)
StatsPanels.plug(
    <TabPanel value="Completions">
        <CompletionsTab />
    </TabPanel>
)
