import { getReference } from "lib/@hooks/use-reference"
import memoize from "memoizee"
import { GetSortLabel } from "event-definitions"

export const getSortMode = memoize(
    function getSortMode() {
        return getReference({ sortMode: "name" }).sortMode
    },
    { maxAge: 100 }
)

export function mappingSort(item) {
    const { code, $order, hasChildren } = item
    let label = hasChildren || !!item.children ? `@${item.label}` : `ZZZzz${item.label}`

    if (hasChildren || item.children?.length) {
        const startsWithNumber = /^\d/.test(item.label)
        if (startsWithNumber) {
            label = tokenizeLabel(label)
        }
        return label
    }

    if (getSortMode() === "natural") {
        return $order || code || label
    }

    if (getSortMode() === "name") {
        if (label.toLowerCase().startsWith("smart ")) {
            label = `Z@0${label}`
        }
        if (["introductory"].some((word) => label.toLowerCase().includes(word))) {
            label = `Z@1${label}`
        }
        if (["procedures"].some((word) => label.toLowerCase().includes(word))) {
            label = `Z@2${label}`
        }
        return GetSortLabel.call({ label, item })
    }

    return `ZCCC${code}` ?? `@${label}`
}

function tokenizeLabel(label) {
    const parts = label.split(".")
    if (parts.length === 1) return label
    let tokenizedPart = ""
    parts.forEach((part, index) => {
        if (index === 0) {
            tokenizedPart += `#${part}#`
        } else {
            const decimalPart = part.replace(/(\d+)/g, (decimalMatch) => `${decimalMatch.length}_${decimalMatch}`)
            tokenizedPart += `.${decimalPart}`
        }
    })
    return tokenizedPart
}
