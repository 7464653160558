import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { UpdatedParam } from "event-definitions"
import { useLocation } from "react-router-dom"
import { resolveAsFunction } from "lib/resolve-as-function"

/**
 * Retrieves and updates a search parameter from the URL.
 *
 * @param {string} key - The key of the search parameter.
 * @param {string} [defaultValue=""] - The default value to use if the search parameter is not found.
 * @param {function} [transform=(v) => v] - The transformation function to apply to the search parameter value.
 * @returns {array} - An array containing the transformed search parameter value and a setter function.
 */
export function useSearchParam(key, defaultValue = "", transform = (v) => v) {
    const mounted = useRef(true)
    const [value, setValue] = useState(
        () => Object.fromEntries(new URLSearchParams(window.location.search).entries())[key] ?? defaultValue
    )
    const location = useLocation()
    useEffect(() => {
        setValue(Object.fromEntries(new URLSearchParams(window.location.search).entries())[key] ?? defaultValue)
    }, [defaultValue, key, location])
    UpdatedParam(key).useEvent(setValue)
    // eslint-disable-next-line
    const doSet = useCallback(set, [key])
    useLayoutEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
    }, [])
    return [transform(value), doSet]

    function set(v) {
        if (!mounted.current) return
        const lastParams = Object.fromEntries(new URLSearchParams(window.location.search).entries())
        if (typeof v === "function") {
            v = v(transform(lastParams[key] ?? defaultValue))
        }
        if (v !== lastParams[key]) {
            lastParams[key] = v
            store(lastParams)
            UpdatedParam(key).raise(v)
        }
    }

    function store(lastParams) {
        window.history.replaceState(
            {},
            null,
            `${window.location.pathname}?${Object.entries(lastParams)
                .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                .join("&")}`
        )
    }
}

export function useNumericSearchParam(key, defaultValue) {
    return useSearchParam(key, defaultValue, (v) => +v)
}

export function useObjectSearchParam(key, defaultValue) {
    defaultValue = encodeURIComponent(JSON.stringify(defaultValue))
    const [value, setValue] = useSearchParam(key, defaultValue)

    const objectValue = JSON.parse(decodeURIComponent(value))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setObjectValue = useCallback(_setObjectValue, [setValue, value])
    return [objectValue, setObjectValue]

    function _setObjectValue(newValue) {
        newValue = resolveAsFunction(newValue)
        setValue(JSON.stringify(newValue(objectValue)))
    }
}
