import { Box, Button, DialogContent, IconButton, List, ListItemButton, Stack, Typography } from "@mui/material"
import { useDialog } from "lib/@hooks/useDialog"
import Iconify from "minimals-template/components/Iconify"
import { BoundAutocomplete, BoundTextField } from "lib/@components/binding/bound-components"
import { DataInputDialog } from "lib/@dialogs/data-input-dialog"
import { createBundle, deleteBundle, getBundles, updateBundle } from "./controller/auth-bundles.controller"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { getSystemDemands } from "lib/permissions/demands"
import { PagedRepeat } from "lib/@components/paged-repeat"
import { ListItemBox } from "lib/@components/ListItemBox"
import { BlockClicks } from "lib/@components/block-clicks"
import { successSnackbar } from "lib/snackbar/success-snackbar"
import { confirm } from "lib/@dialogs/confirm"
import { getScheduleTypes } from "routes/schedule/plugins/schedule-types/controller/schedule-types.api"

import { getAvailableFeatures } from "lib/features"

export function AuthBundleDialog({ bundle }) {
    const demands = getSystemDemands.useResults()
    const scheduleTypes = getScheduleTypes.useResults()
    const features = getAvailableFeatures() || []

    if (!demands) return null
    if (!scheduleTypes) return null
    if (!features) return null

    return (
        <DataInputDialog initialValue={bundle} okLabel="Create">
            <Box pt={3} pl={3} mb={1}>
                <Typography variant="h5">Auth Bundles</Typography>
                <Typography variant="subtitle2">Configure an auth bundle to assign to users.</Typography>
            </Box>
            <DialogContent>
                <Stack sx={{ mt: 2 }} spacing={2}>
                    <BoundTextField field="name" label="Name" yup="string.trim.required" />
                    <BoundAutocomplete
                        default={[]}
                        field="demands"
                        label="Demands"
                        filterSelectedOptions
                        options={demands}
                        getOptionLabel={(option) => option?.name}
                        transformIn={(demandsToFilter) =>
                            demands?.filter((demand) => demandsToFilter?.includes(demand?.name))
                        }
                        multiple
                        transformOut={(v) => v.map((d) => d?.name)}
                    />
                    <BoundAutocomplete
                        default={[]}
                        field="scheduleTypes"
                        label="Schedule Types Available"
                        filterSelectedOptions
                        options={scheduleTypes}
                        getOptionLabel={(option) => `${option?.name} [${option?.id}]`}
                        transformIn={(types) =>
                            scheduleTypes?.filter((option) => types?.includes(`${option?.name} [${option?.id}]`))
                        }
                        multiple
                        transformOut={(v) => v.map((option) => `${option?.name} [${option?.id}]`)}
                    />
                    <BoundAutocomplete
                        default={[]}
                        field="features"
                        label="Features Available"
                        filterSelectedOptions
                        options={features}
                        getOptionLabel={(option) => `${option?.name} [${option?.id}]`}
                        transformIn={(types) =>
                            features?.filter((option) => types?.includes(`${option?.name} [${option?.id}]`))
                        }
                        multiple
                        transformOut={(v) => v.map((option) => `${option?.name} [${option?.id}]`)}
                    />
                </Stack>
            </DialogContent>
        </DataInputDialog>
    )
}

export function AuthBundles() {
    const create = useDialog(<AuthBundleDialog />)
    const bundles = getBundles.useResults()
    if (!bundles) return null

    return (
        <>
            <Stack mx={1} mb={2}>
                <Typography variant="body2" color="text.secondary">
                    Auth Bundles are predefined sets of permissions packaged together for internal use. These bundles
                    allow users to quickly and efficiently gain a specific set of permissions tailored for different
                    functionalities, scenarios, or features.
                </Typography>
                <Button
                    sx={{ mt: 2 }}
                    data-cy="admin-add-bundle-button"
                    startIcon={<Iconify icon="mdi:account-lock" />}
                    variant="contained"
                    color="primary"
                    onClick={onConfirm}
                >
                    Add Bundle
                </Button>
            </Stack>
            <List className="custom-skills">
                <PagedRepeat showTop list={bundles.sortBy("name")} item={<AuthBundle />} pageSize={5} />
            </List>
        </>
    )

    async function onConfirm() {
        const newBundle = await create()
        if (!newBundle) return
        try {
            await createBundle(newBundle)
        } catch (error) {
            console.error(error)
            errorSnackbar(error?.toString())
        }
    }
}

function AuthBundle({ item: bundle }) {
    const edit = useDialog(<AuthBundleDialog bundle={bundle} />)

    return (
        <ListItemButton onClick={modify}>
            <ListItemBox data-cy={`auth-bundle-item-${bundle.name.replaceAll(" ", "")}`}>
                {bundle.name}
                <Box flex={1} />
                <BlockClicks>
                    <IconButton color="secondary" onClick={remove} data-cy="auth-bundle-delete-button">
                        <Iconify icon="mdi:delete" />
                    </IconButton>
                </BlockClicks>
            </ListItemBox>
        </ListItemButton>
    )

    async function modify() {
        const update = await edit(bundle)
        if (update) {
            try {
                update.id = bundle._id
                await updateBundle(update)
                successSnackbar("Bundle updated")
            } catch (error) {
                console.error(error)
                errorSnackbar(error?.toString())
            }
        }
    }

    async function remove() {
        if (await confirm("Are you sure you want to remove the bundle?", "Remove Bundle")) {
            try {
                await deleteBundle({ id: bundle._id })
                successSnackbar("Bundle removed")
            } catch (error) {
                console.error(error)
                errorSnackbar(error?.toString())
            }
        }
    }
}
