import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getLegacyCustomSkills = api(async function getLegacyCustomSkills(subscriptionId) {
    return query(
        gql`
            query GetLegacyCustomSkills($subscriptionId: Int!) {
                getLegacyCustomSkills(subscriptionId: $subscriptionId) {
                    customSkillingId
                    skillingCode
                    skilling
                    rate
                    label
                }
            }
        `,
        { subscriptionId },
        { returns: "getLegacyCustomSkills" }
    )
})
