import { htmlToText } from "lib/html-to-text"

export function assessStatus(payload, target) {
    payload.status = { summary: "", reasons: [] }
    const { reasons } = payload.status
    if (payload.pictures?.documents?.length) {
        payload.status.summary = "cyan"
        reasons.push("Added pictures")
    }
    if (
        htmlToText(payload.introduction).length ||
        htmlToText(payload.notes).length ||
        Object.values(payload.tasks).some((t) => htmlToText(t.introduction).length || htmlToText(t.notes).length)
    ) {
        payload.status.summary = "cyan"
        reasons.push("Added text/notes")
    }
    if (Object.values(payload.tasks).some((t) => t.skillSet || t.unitOfMeasure)) {
        reasons.push("Updated skillsets or unit of measurement for 1+ tasks")
        payload.status.summary = "skyblue"
    }
    if (target.tasks.some((t) => payload.tasks?.[t.id]?.minutes !== t.minutes)) {
        reasons.push("Updated the duration of 1+ tasks")
        payload.status.summary = "skyblue"
    }
    if (
        target.tasks.some((t) => {
            const task = payload.tasks?.[t.id]
            return (
                task?.frequency &&
                t?.frequency &&
                (task.frequency?.period !== t.frequency?.period || task.frequency?.interval !== t.frequency?.interval)
            )
        })
    ) {
        payload.status.summary = "orange"
        reasons.push("Updated the frequency of 1+ tasks")
    }
    if (
        target.tasks.some(
            (t) =>
                t.classification !== "Red" &&
                !!payload.tasks?.[t.id]?.classification &&
                t.classification !== payload.tasks?.[t.id]?.classification
        )
    ) {
        payload.status.summary = "orange"
        reasons.push("Changed the criticality of 1+ tasks")
    }
    if (target.tasks.some((t) => payload.tasks?.[t.id]?.included === false)) {
        payload.status.summary = "orange"
        reasons.push("Removed 1+ tasks")
    }
    if (
        target.tasks.some(
            (t) =>
                t.classification === "Red" &&
                !!payload.tasks?.[t.id]?.classification &&
                t.classification !== payload.tasks?.[t.id]?.classification
        )
    ) {
        payload.status.summary = "red"
        reasons.push("Changed the criticality of 1+ Red tasks")
    }
    if (target.tasks.some((t) => t.classification === "Red" && payload.tasks?.[t.id]?.included === false)) {
        payload.status.summary = "red"
        reasons.push("Removed 1+ Red criticality tasks")
    }
    return payload.status
}
