import { Badge } from "@mui/material"

export function OutlinedBadge({ color, content }) {
    return (
        <Badge
            sx={{
                ...(color && {
                    "& .MuiBadge-badge": {
                        color,
                        borderStyle: "solid",
                        borderWidth: "1px",
                    },
                }),
            }}
            size="small"
            badgeContent={content}
        />
    )
}
