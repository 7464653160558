import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { parentFind } from "library/tree"
import { PlanRoot } from "library/tree/constants"
import { getPlanId } from "routes/plan/controller/use-plan"
import { Secure } from "lib/authorization/secure"
import { Button } from "@mui/material"
import { publishPlan } from "routes/plan/controller/publish-plan"
import { navigate } from "lib/routes/navigate"
import { uniqueId } from "library/database/split-id"
import { waitForScheduleRefresh } from "routes/schedule/lib/wait-for-schedule-refresh"
import { Frag } from "lib/@components/slot/frag"
import { busyWhile } from "lib/@components/busy/busy-while"

export function CreateDraft() {
    const { parentItem } = useBoundContext()
    const parent = parentFind(parentItem, (c) => c[PlanRoot])
    const id = getPlanId(parent)
    return (
        <Secure
            hide
            demand="manageRegime"
            fallback={<Frag />}
            justifyContent="center"
            sx={{ flex: 1, display: "flex" }}
        >
            <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={publish}
                sx={{ mt: 1, mx: "auto", display: "block" }}
            >
                Submit for Review
            </Button>
        </Secure>
    )

    async function publish() {
        return busyWhile(
            async () => {
                await publishPlan(id)
                navigate(`/app/review/${uniqueId(id)}`)
            },
            "Publishing To Draft",
            waitForScheduleRefresh
        )
    }
}
