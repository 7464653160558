import { SelectorTitle } from "slot-definitions"
import { isInRegime } from "routes/facilities/lib/when-parent-item"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Box, Stack } from "@mui/material"
import { BadgeLabel } from "routes/regime/plugins/regime-title/badge-label"
import { replaceAllWithPredicate } from "lib/@components/slot/replace-all-with-predicate"

SelectorTitle.plug(replaceAllWithPredicate(isInRegime, <RegimeTitle />))

function RegimeTitle() {
    const { parentItem } = useBoundContext()
    const isLive = parentItem.id.includes("live|")
    const isReady = parentItem.id.includes("ready|")
    if (isLive) {
        return (
            <Stack direction="row" spacing={1}>
                <Box>{parentItem.label.replace("Live:", "")}</Box> <BadgeLabel color="myStuff" badgeContent="Live" />
            </Stack>
        )
    }
    if (isReady) {
        return (
            <Stack direction="row" spacing={1}>
                <Box>{parentItem.label.replace("Ready:", "")}</Box>{" "}
                <BadgeLabel color="secondary" badgeContent="Candidate" />
            </Stack>
        )
    }
    return (
        <Stack direction="row" spacing={1}>
            <Box>{parentItem.label}</Box> <BadgeLabel color="primary" badgeContent="Draft" />
        </Stack>
    )
}
