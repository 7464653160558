function htmlToText(htmlString) {
    if (typeof document !== "undefined") {
        // Running in the browser
        const tempEl = document.createElement("div")
        tempEl.innerHTML = htmlString
        // Replace <br> tags with new line characters
        tempEl.innerHTML = tempEl.innerHTML.replace(/<br\s*[/]?>/gi, "\n")
        // Replace block-level elements with new line characters
        tempEl.innerHTML = tempEl.innerHTML.replace(
            /<\/?(?:address|article|aside|blockquote|canvas|dd|div|dl|fieldset|figcaption|figure|footer|form|h1|h2|h3|h4|h5|h6|header|hr|main|nav|noscript|ol|output|p|pre|section|table|tfoot|ul|video)[^>]*?>/gi,
            "\n"
        )
        // Get the text content of the temporary element
        return tempEl.textContent || tempEl.innerText || ""
    }
    // if (typeof require === "function") {
    //     // Running in Node.js
    //     const jsdom = require("jsdom")
    //     const { JSDOM } = jsdom
    //     const dom = new JSDOM(htmlString)
    //     const { document } = dom.window
    //     // Replace <br> tags with new line characters
    //     document.querySelectorAll("br").forEach((br) => {
    //         br.replaceWith("\n")
    //     })
    //     // Replace block-level elements with new line characters
    //     document
    //         .querySelectorAll(
    //             "address, article, aside, blockquote, canvas, dd, div, dl, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hr, li, main, nav, noscript, ol, output, p, pre, section, table, tfoot, ul, video"
    //         )
    //         .forEach((el) => {
    //             el.replaceWith(`\n${el.textContent}\n`)
    //         })
    //     // Get the plain text from the document
    //     return document.body.textContent
    // }
    throw new Error("Unable to convert HTML to plain text: no DOM API available")
}

exports.htmlToText = htmlToText

exports.startsWithNumber = function startsWithNumber(str) {
    const regex = /^\s*(\d+)(\.\d+)*(\))?\s/
    const match = regex.exec(str)
    if (match) {
        const subNumbers = match[0].match(/\.\d+/g)
        const subNumberValues = subNumbers ? subNumbers.map((subNumber) => subNumber.substr(1)) : []
        return {
            number: match[1],
            subNumbers: subNumberValues,
        }
    }
    return null
}

function normalizeActions(text) {
    return text
        .replace(/<!--[\s\S]*?-->/g, "")
        .replace(/<br\s*\/?>(\s|&nbsp;)*/gi, "<br>")
        .replace(/(<br>)+/gi, "<br>")
    // if (text.toLowerCase().includes("<table") || text.toLowerCase().includes("<li")) {
    //     return text.replace(/(<br\s*[\/]?>\s*)+/gi, "<br/>")
    // }
    // const strings = htmlToText(text)
    //     .split("\n")
    //     .map((s) => s.trim())
    //     .filter(Boolean)
    //     .map((f, i, ar) => {
    //         const n = startsWithNumber(f)
    //         if (n?.subNumbers?.length) {
    //             return `<div style="margin-left: ${n.subNumbers.length * 16}px; padding-bottom: 12px">${f}</div>`
    //         }
    //         return `<div style="padding-bottom: 12px; ${
    //             ar[i - 1]?.endsWith(":") || ar[i - 1]?.endsWith(";") || f.charAt(0) !== f.charAt(0).toUpperCase()
    //                 ? "padding-left: 16px"
    //                 : ""
    //         }">${f}</div>`
    //     })
    // const someAreNumbered = strings.some(startsWithNumber)
    // // const shouldMakeActions = string.length >= 3 || someAreNumbered
    // return strings.join("")
    // return text
}

function getActions(text) {
    // const strings = htmlToText(text)
    //     .split("\n")
    //     .map((s) => s.trim())
    //     .filter(Boolean)
    //     .map((f) => {
    //         const n = startsWithNumber(f)
    //         if (n.subNumbers.length) {
    //             return `${"&nbsp;".repeat(n.subNumbers)}${f}`
    //         }
    //     })
    // const someAreNumbered = strings.some(startsWithNumber)
    // const shouldMakeActions = string.length >= 3 || someAreNumbered
    // return !shouldMakeActions ? [strings.join("<br/><br/>")] : strings
    return text
}

exports.normalizeActions = normalizeActions
exports.getActions = getActions
