import { MapPlus } from "library/map-plus"
import { Uncache } from "library/server-event-definitions"
import { FlushGraphQLCache, RefreshTreeRoots, UserLoggedOut } from "event-definitions"
import { createEvent } from "library/local-events"
import { defer } from "lib/defer"

let results = {}
const links = new MapPlus(() => new Set())

Uncache("**").handleOnce(removeCache)
FlushGraphQLCache.handle(() => links.clear())

const RefreshSidebar = createEvent("RefreshSideBar")

RefreshSidebar.handleOnce(() => RefreshTreeRoots.raiseOnce())

export function removeCache(name) {
    name ??= this.event?.split(".").last()
    if (!name) return
    delete results[name]
    for (const other of links.get(name).values()) {
        delete results[other]
    }
    RefreshSidebar.raiseOnceDelay(100)
}

UserLoggedOut.handleOnce(() => {
    results = {}
})

export function linkUncache(from, to) {
    links.get(from).add(to)
}

let promises = {}
FlushGraphQLCache.handle(() => (promises = {}))

export function cacheTreeRoot(name, fn) {
    return async ({ add }) => {
        await (promises[name] || Promise.resolve(true))
        const { resolve } = (promises[name] = defer())
        try {
            if (results[name]) {
                results[name].forEach((item) => add({ ...item }))
            } else {
                try {
                    await fn({ add: addAndCache })
                } catch (e) {
                    console.warn(e)
                }
            }
        } finally {
            resolve()
        }

        function addAndCache(item) {
            results[name] ??= []
            results[name].push({ ...item })
            add(item)
        }
    }
}
