import { useEffect, useState } from "react"
import { useDialog } from "lib/@hooks/useDialog"
import { FacilityConfiguration } from "routes/schedule/my-schedules/my-group-types/facility/facility-configuration"
import { SpaceConfiguration } from "routes/schedule/my-schedules/my-group-types/space/spaceConfiguration"

export function ChooseFacilityOrSpace({ ok, type = "facility", label }) {
    const [existingFacility] = useState({})
    const facility = useDialog(
        type === "space" ? (
            <SpaceConfiguration
                title={label ? `Create ${label}` : undefined}
                existingSpace={existingFacility}
                parentItem={{ label: "" }}
            />
        ) : (
            <FacilityConfiguration existingFacility={existingFacility} parentItem={{ label: "" }} />
        ),
        {
            fullScreen: false,
            maxWidth: "xl",
            className: type === "space" ? "space-dialog" : "facility-dialog",
        }
    )

    useEffect(() => {
        create().catch(console.error)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /*

        This code is left here in case we want to go back to having the ability to select from a
        library of already created facilities

     */

    return null

    async function create() {
        ok(facility())
    }
}
