import {
    Backdrop,
    Box,
    Button,
    ButtonGroup,
    IconButton,
    InputAdornment,
    ListItemText,
    Menu,
    MenuItem,
    Portal,
    Stack,
    Typography,
    useTheme,
} from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import { useSelectedItemIds } from "routes/schedule/lib/useSelectedItems"
import { SELECTION_REFERENCE_TYPE, selectItems } from "routes/schedule/lib/useSelection"
import { useEffect, useRef, useState } from "react"
import { isEqual } from "lib/isEqual"
import Iconify from "minimals-template/components/Iconify"
import { ContextMenu } from "lib/@components/context-menu-bar"
import { Bound } from "lib/@components/binding/Bound"
import noop from "lib/noop"
import { findBaskets } from "routes/schedule/components/find-basket"
import { NotOnMobile, OnMobile, useMobile } from "lib/@components/mobile"
import { prevent } from "lib/prevent"
import { ReferenceUpdated } from "event-definitions"
import { navigate } from "lib/routes/navigate"
import { useTreeItem } from "routes/schedule/lib/useTreeItem"
import { useMemoryReference } from "lib/@hooks/use-memory-reference"
import { BlockClicks } from "lib/@components/block-clicks"
import { setFromEvent } from "lib/setFromEvent"
import { DebouncedTextField } from "lib/@components/debounced-text-field"
import { ActionMenu } from "slot-definitions"
import { CurrentContext } from "lib/@components/current-context"

export const SelectionsMenu = ContextMenu("selections-menu")
const HISTORY_LENGTH = 25

export function Selections() {
    const search = useRef()
    const [filter, setFilter] = useState("")
    const skip = useRef(false)
    const ref = useRef()
    const additional = SelectionsMenu.useSlot()
    const [anchor, setAnchor] = useState(null)
    const [mobileMenuAnchor, setMobileMenuAnchor] = useState(null)
    const mobileMenuRef = useRef()
    const [mobileAnchor, setMobileAnchor] = useState(null)
    const mobileRef = useRef()
    const [actionAnchor, setActionAnchor] = useState(null)
    const actionRef = useRef()
    const [mobileActionAnchor, setMobileActionAnchor] = useState(null)
    const mobileActionRef = useRef()
    const items = useSelectedItemIds()
    const [{ selectedItems, historyPos, selectionHistory }] = useMemoryReference(
        { selectedItems: {}, historyPos: 0, selectionHistory: [] },
        SELECTION_REFERENCE_TYPE
    )

    const isMobile = useMobile()
    const [, , save] = useMemoryReference({}, SELECTION_REFERENCE_TYPE)
    const theme = useTheme()
    useEffect(() => {
        if (skip.current) return
        if (!isEqual(selectedItems, selectionHistory[historyPos])) {
            const history = selectionHistory.slice(0)
            history.unshift(selectedItems)
            history.length = Math.min(history.length, HISTORY_LENGTH)
            save((ref) => ({ ...ref, selectionHistory: history, historyPos: 0 }))
            ReferenceUpdated(SELECTION_REFERENCE_TYPE).raiseOnce()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(items), JSON.stringify(selectionHistory)])

    useEffect(() => {
        if (anchor) {
            setTimeout(() => {
                search.current?.focus()
            }, 0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Boolean(anchor), filter])

    const boxes = (useTreeItem("basket-ScheduleBasket", true)?.children ?? [])
        .filter((c) => c.children.filter((c) => !c.children).length > 0)
        .sortBy("label")
    const tags = (useTreeItem("basket-Tag", true)?.children ?? [])
        .filter((c) => c.children.filter((c) => !c.children).length > 0)
        .sortBy("label")

    let name = findBaskets(boxes, items).map("label").join(", ")
    name = findBaskets(tags, items).map("label").join(", ") || name

    const borderColor = !name && !items?.length ? theme.palette.grey[500_32] : "primary.light"

    useEffect(() => {
        if (!isMobile) {
            setMobileAnchor(null)
            setMobileMenuAnchor(null)
            setMobileActionAnchor(null)
        } else {
            setAnchor(null)
            setActionAnchor(null)
        }
    }, [isMobile, mobileAnchor, mobileMenuAnchor, anchor, actionAnchor, mobileActionAnchor])

    const component = (
        <Bound close={close}>
            <Box className="selections">
                <ButtonGroup
                    ref={isMobile ? mobileActionRef : actionRef}
                    sx={{
                        mx: 1,
                    }}
                >
                    <Button
                        className="undo-selection"
                        aria-label="Undo selection"
                        variant="outlined"
                        disabled={selectionHistory.length <= historyPos + 1}
                        onClick={back}
                        sx={{
                            "&.Mui-disabled": { borderColor },
                            borderColor,
                            borderRightColor: "transparent !important",
                        }}
                    >
                        <Iconify icon="icon-park-outline:undo" />
                    </Button>
                    <Button
                        className="selection-name"
                        aria-label="Change selection"
                        ref={isMobile ? mobileMenuRef : ref}
                        color="primary"
                        sx={{
                            px: 0,
                            "&.Mui-disabled": { borderColor },
                            borderColor,
                            borderLeftColor: "transparent !important",
                            borderRightColor: "transparent !important",
                        }}
                        onClick={open}
                    >
                        <ListItemBox minWidth={150} maxWidth={250} pr={isMobile ? 0 : 2}>
                            <Typography
                                component="div"
                                variant="body2"
                                pl={1}
                                sx={{
                                    flexShrink: 1,
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {name || (items?.length ? "Selected" : "Nothing Selected")}
                            </Typography>
                            {!!items?.length && (
                                <Box ml={0.5}>
                                    <Typography variant="caption">({items.length})</Typography>
                                </Box>
                            )}
                        </ListItemBox>
                    </Button>
                    <Button
                        className="clear-selection"
                        aria-label="Clear selection"
                        onClick={deselect}
                        variant="outlined"
                        disabled={!items?.length}
                        sx={{
                            "&.Mui-disabled": { borderColor },
                            borderColor,
                            borderLeftColor: "transparent !important",
                            borderRightColor: "transparent !important",
                        }}
                    >
                        <Iconify icon="ic:round-clear" />
                    </Button>
                    <Portal>
                        <Backdrop
                            invisible
                            onClick={prevent(close)}
                            onMouseDown={prevent(noop)}
                            onTouchStart={prevent(noop)}
                            sx={{ zIndex: 2000 }}
                            open={isMobile ? Boolean(mobileMenuAnchor) : Boolean(anchor)}
                        >
                            <Menu
                                className="selections-menu"
                                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                transformOrigin={{ vertical: "top", horizontal: "left" }}
                                sx={{
                                    zIndex: 3000,
                                }}
                                onClose={close}
                                open={isMobile ? Boolean(mobileMenuAnchor) : Boolean(anchor)}
                                anchorEl={isMobile ? mobileMenuAnchor : anchor}
                            >
                                <Box mx={1}>
                                    <Typography
                                        sx={{ color: "text.secondary", mt: -0.5, mb: 0.5 }}
                                        component="div"
                                        variant="caption"
                                    >
                                        Change Selection
                                    </Typography>
                                </Box>
                                <MenuItem>
                                    <BlockClicks>
                                        <DebouncedTextField
                                            variant="standard"
                                            inputRef={search}
                                            autoFocus
                                            value={filter}
                                            onChange={setFromEvent(setFilter)}
                                            size="small"
                                            label="Filter..."
                                            InputProps={{
                                                disableUnderline: true,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Iconify
                                                            icon="eva:search-fill"
                                                            sx={{ color: "text.disabled", width: 20, height: 20 }}
                                                        />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton size="small" onClick={() => setFilter("")}>
                                                            <Iconify icon="ic:round-clear" />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </BlockClicks>
                                </MenuItem>

                                {makeMenu("mdi:suit-hearts", boxes, filter)}
                                {makeMenu("bi:tag-fill", tags, filter)}
                            </Menu>
                        </Backdrop>
                    </Portal>
                    <Portal>
                        <Backdrop
                            invisible
                            onClick={prevent(() => (isMobile ? setMobileActionAnchor(null) : setActionAnchor(null)))}
                            onMouseDown={prevent(noop)}
                            onTouchStart={prevent(noop)}
                            sx={{ zIndex: 2000 }}
                            open={isMobile ? Boolean(mobileActionAnchor) : Boolean(actionAnchor)}
                        >
                            <Menu
                                className="selections-menu"
                                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                transformOrigin={{ vertical: "top", horizontal: "left" }}
                                sx={{
                                    zIndex: 3000,
                                }}
                                onClose={() => (isMobile ? setMobileActionAnchor(null) : setActionAnchor(null))}
                                open={isMobile ? Boolean(mobileActionAnchor) : Boolean(actionAnchor)}
                                anchorEl={isMobile ? mobileActionAnchor : actionAnchor}
                            >
                                {!!items.length && (
                                    <MenuItem onClick={go}>
                                        <Stack direction="row" alignItems="center">
                                            <Box sx={{ lineHeight: 0 }} mr={1}>
                                                <Iconify icon="mdi:arrow-down-right" />
                                            </Box>
                                            <ListItemText>View Selection</ListItemText>
                                        </Stack>
                                    </MenuItem>
                                )}
                                <CurrentContext>
                                    <ActionMenu.Slot />
                                    {additional}
                                </CurrentContext>
                            </Menu>
                        </Backdrop>
                    </Portal>
                    <Button
                        className="redo-selection"
                        aria-label="Redo selection"
                        onClick={forward}
                        sx={{
                            "&.Mui-disabled": { borderColor },
                            borderColor,
                            borderLeftColor: "transparent !important",
                        }}
                        // variant="outlined"
                        disabled={historyPos < 1}
                    >
                        <Iconify icon="icon-park-outline:redo" />
                    </Button>
                    <Button
                        className="selections-action"
                        disabled={!items?.length}
                        onClick={() => {
                            if (items?.length) {
                                isMobile
                                    ? setMobileActionAnchor(mobileActionRef.current)
                                    : setActionAnchor(actionRef.current)
                            }
                        }}
                        sx={{
                            "&.Mui-disabled": { borderColor },
                            borderColor,
                        }}
                        variant="contained"
                        size="small"
                    >
                        <Iconify sx={{ fontSize: "125%" }} icon="pajamas:ellipsis-v" />
                    </Button>
                </ButtonGroup>
            </Box>
        </Bound>
    )

    return (
        <>
            <OnMobile>
                <Button
                    ref={mobileRef}
                    onClick={() => setMobileAnchor(mobileRef.current)}
                    sx={{ maxWidth: 120, opacity: mobileAnchor ? 0.3 : 1, display: "flex", alignItems: "flex-end" }}
                    variant="outlined"
                >
                    <Typography
                        component="div"
                        variant="body2"
                        sx={{ maxWidth: 1 - 0, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
                    >
                        {name || (items?.length ? "Selected" : "No Selection")}
                    </Typography>
                    {!!items?.length && (
                        <Typography component="div" variant="caption" sx={{ ml: 0.5, pb: 0.15 }}>
                            ({items.length})
                        </Typography>
                    )}
                </Button>
                <Portal>
                    <Menu
                        sx={{
                            "& .MuiPaper-root": {
                                background: `${theme.palette.background.paper}b0`,
                                p: 0,
                                backdropFilter: "blur(4px)",
                            },
                        }}
                        open={!!mobileAnchor}
                        anchorEl={mobileAnchor}
                        onClose={() => setMobileAnchor(null)}
                    >
                        {component}
                    </Menu>
                </Portal>
            </OnMobile>
            <NotOnMobile>{component}</NotOnMobile>
        </>
    )

    function makeMenu(icon, list, filter) {
        return list
            .filter((i) => i.label.toLowerCase().includes(filter.toLowerCase()))
            .map((item) => {
                return (
                    <MenuItem selected={false} onClick={choose} key={item.id} sx={{ pr: 2 }}>
                        <Stack direction="row" alignItems="center">
                            <Box sx={{ lineHeight: 0 }} mr={1}>
                                {typeof icon === "string" ? <Iconify icon={icon} /> : icon}
                            </Box>
                            <ListItemText>{item.label}</ListItemText>
                        </Stack>
                    </MenuItem>
                )

                function choose() {
                    selectItems(() => Object.fromEntries(item.children.map((child) => [child._id, child._id])))
                    close()
                }
            })
    }

    function open() {
        isMobile ? setMobileMenuAnchor(mobileMenuRef.current) : setAnchor(ref.current)
    }

    function close(fn) {
        if (typeof fn === "function") {
            return () => {
                fn()
                close()
            }
        }
        isMobile ? setMobileMenuAnchor(null) : setAnchor(null)
        return noop
    }

    function back() {
        if (skip.current) return
        skipIt()
        save((ref) => ({ ...ref, historyPos: historyPos + 1 }))
        selectItems(() => selectionHistory[historyPos + 1])
    }

    function forward() {
        if (skip.current) return
        skipIt()
        save((ref) => ({ ...ref, historyPos: historyPos - 1 }))
        selectItems(() => selectionHistory[historyPos - 1])
    }

    function skipIt() {
        clearTimeout(skip.current)
        skip.current = setTimeout(() => {
            skip.current = 0
        }, 100)
    }

    function deselect() {
        save((reference) => {
            reference.selectedItems = {}
            return reference
        })
    }

    function go() {
        if (items.length === 0) return
        if (name && !name.includes(", ")) {
            const folder = [...findBaskets(boxes, items), ...findBaskets(tags, items)]
            navigate(`/app/schedules?id=${folder[0].id}`, { replace: false })
        } else {
            navigate("/app/schedules?id=selected", { replace: false })
        }
    }
}
