import { Box, Collapse } from "@mui/material"
import { useStoreState } from "lib/store"
import { SkillTreeList } from "./skill-tree-list"

export function RelatedTailoredSchedules({ skillId, client }) {
    const [open] = useStoreState(`related_tailored_${skillId}`, false)
    return (
        <Collapse in={open} mountOnEnter sx={{ width: 1 }}>
            <Box>
                <SkillTreeList skill={skillId} client={client} />
            </Box>
        </Collapse>
    )
}
