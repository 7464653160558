/* eslint-disable react-hooks/exhaustive-deps */
import { appRoute } from "routes/app/home.runtime"
import ScheduleDisplay from "routes/schedule/scheduleDisplay"
import Schedules from "routes/schedule/schedules"
import "./add-task"
import "./remove-task"
import "./time-line"
import "./timeline-button"
import "./delete-button"
import "./publish-button"
import "./preview-button"
import { defineStateProperties } from "library/behaviours"
import { ScheduleEdit } from "routes/schedule/edit/schedule-edit"
import { Review } from "routes/schedule/edit/review"
import { Bound } from "lib/@components/binding/Bound"
import { Rejection } from "routes/schedule/edit/rejection"
import { AuditEntryBelow } from "slot-definitions"
import { DisplayReason } from "routes/schedule/edit/display-reason"

appRoute.register(
    "schedules/:id/edit",
    <Schedules>
        <ScheduleEdit $lazy />
    </Schedules>
)

appRoute.register(
    "schedules/:id/preview",
    <Schedules>
        <ScheduleEdit $lazy preview={true} />
    </Schedules>
)

AuditEntryBelow("**").plug(<DisplayReason />)

export const states = defineStateProperties({
    default: (
        <>
            <Rejection />
            <ScheduleDisplay />
        </>
    ),
    review: (
        <Bound canUndo={false} canRedo={false}>
            <Review />
        </Bound>
    ),
})
