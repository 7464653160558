import { useParams } from "react-router"
import { DemandGuard } from "./DemandGuard"
import { Bound } from "lib/@components/binding/Bound"

export default function AdministerClientsGuard({ hasContent = true, children }) {
    const { id, cid } = useParams()
    return (
        <Bound clientId={cid} userId={id} clientManagement={true}>
            <DemandGuard hasContent={hasContent} demands={["admin", "admin-users", "admin-companies"]}>
                {children}
            </DemandGuard>
        </Bound>
    )
}
