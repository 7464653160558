import { Grid, IconButton, Stack, Typography } from "@mui/material"
import { PreviewDialog } from "lib/@components/document-viewer/preview-dialog"
import Iconify from "minimals-template/components/Iconify"
import { allSupportedPreviews, getIconForFile } from "lib/@components/document-viewer/file-types"
import { useDialog } from "lib/@hooks/useDialog"
import { scopeUrl } from "lib/scope-url"
import { infoSnackbar } from "lib/snackbar/info-snackbar"

export function DocumentList({ value }) {
    const preview = useDialog(<PreviewDialog />, { fullScreen: true })

    return (
        !!value?.documents && (
            <Grid container sx={{ textAlign: "center" }} spacing={1} justifyContent="space-evenly">
                {value.documents?.map((document) => (
                    <Grid item key={document.id} xs={12} sm={6} md={4} lg={3}>
                        <Stack sx={{ width: 1, alignItems: "center", p: 1 }}>
                            <Iconify sx={{ width: 48, height: 48 }} icon={getIconForFile(document.filename)} />
                            <Typography variant="caption">{document.name}</Typography>
                            <Stack direction="row">
                                <IconButton onClick={download(document)}>
                                    <Iconify icon="mdi:download" />
                                </IconButton>
                                <IconButton onClick={view(document)}>
                                    <Iconify icon="mdi:eye" />
                                </IconButton>
                            </Stack>
                        </Stack>
                    </Grid>
                )) ?? []}
            </Grid>
        )
    )

    function download(document) {
        return () => {
            window.open(`${scopeUrl(document.href)}?filename=${encodeURIComponent(document.filename)}`, "_blank")
        }
    }

    function view(document) {
        const { name, href } = document
        const extension = href.split(".").at(-1)
        return allSupportedPreviews.includes(extension)
            ? () => {
                  preview({ name: name, url: href, extension: extension }).catch(console.error)
              }
            : () => {
                  infoSnackbar("Please download file type to preview")
              }
    }
}
