import { useDialog } from "lib/@hooks/useDialog"
import { ContextMenuItem } from "lib/@components/context-menu-bar/context-menu-item"
import { waitForScheduleRefresh } from "routes/schedule/lib/wait-for-schedule-refresh"
import { gql } from "@apollo/client"
import { convertFolderName } from "routes/schedule/folder-manager/folder-management"
import { createSubFolder } from "routes/schedule/folder-manager/controller/create-sub-folder"
import { makeLazy } from "lib/make-lazy"
import { getTreeIdFromItem } from "lib/get-tree-id-from-item"
import { storeGroupData } from "routes/schedule/controller/store-group-data"
import { mutate } from "lib/graphql/mutate"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { busyWhile } from "lib/@components/busy/busy-while"

const ChooseCopyFolder = makeLazy(() => import("routes/schedule/folder-manager/choose-copy-folder"), "ChooseCopyFolder")

// FolderContextMenu.plug(
//     ({ context }) =>
//         context.parentItem &&
//         !context.parentItem[PlanRoot] &&
//         context.parentItem.children?.length !== 0 && <CopyFolder />
// )

export function CopyFolder() {
    const { parentItem } = useBoundContext()
    const chooseFolder = useDialog(<ChooseCopyFolder source={parentItem} />)
    return <ContextMenuItem icon="ic:round-folder-copy" label={`Copy "${parentItem.label}"`} onClick={copyFolder} />

    async function copyFolder() {
        const folder = await chooseFolder()
        if (folder) {
            const newFolder = await createSubFolder(folder, parentItem.label)
            await storeGroupData(newFolder, parentItem.data, parentItem.label)
            await busyWhile(
                async () => {
                    await mutate(
                        gql`
                            mutation move(
                                $tree: String!
                                $toTree: String!
                                $fromGroup: String!
                                $toGroup: String!
                                $ids: [String]!
                            ) {
                                copyReferencesToGroup(
                                    tree: $tree
                                    fromGroup: $fromGroup
                                    toGroup: $toGroup
                                    ids: $ids
                                    toTree: $toTree
                                )
                            }
                        `,
                        {
                            tree: getTreeIdFromItem(parentItem),
                            toTree: getTreeIdFromItem(folder),
                            fromGroup: convertFolderName(parentItem.id),
                            toGroup: convertFolderName(newFolder),
                            ids: parentItem.children.map("id"),
                        }
                    )
                },
                "Copying",
                waitForScheduleRefresh
            )
        }
    }
}
