import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { mutate } from "lib/graphql/mutate"

export const removeRegimeAccess = api(async (users, regimeId) =>
    mutate(
        gql`
            mutation removeRegimeAccess($users: [String], $regimeId: String) {
                removeRegimeAccess(users: $users, regimeId: $regimeId)
            }
        `,
        { users, regimeId },
        { returns: "removeRegimeAccess" }
    )
)
