import { Box, Grid } from "@mui/material"
import { BoundTextField } from "lib/@components/binding/bound-components"
import { BoundDatePicker } from "lib/@components/datePicker"
import { BoundTextEditor } from "minimals-template/components/editor"
import { BoundStandardCombo } from "lib/@components/combos/standardCombo"
import { updateTypeEnum } from "../technical-update-consts"
import { capitalCase } from "change-case"
import { useState } from "react"
import { Bound } from "lib/@components/binding/Bound"
import { OnBulletinEdited } from "event-definitions"

export function UpdateForm({ bulletin }) {
    const [localBulletin] = useState(bulletin)
    const disableDatePicker = bulletin.content.length ?? false

    const capitalizedUpdateTypes = Object.entries(updateTypeEnum).map(([key, value]) => ({
        label: capitalCase(key),
        value: value.toLowerCase(),
    }))

    return (
        <Bound
            target={localBulletin}
            onChange={() => {
                OnBulletinEdited.raiseOnce(localBulletin)
            }}
        >
            <Box sx={{ p: 1 }}>
                <BoundTextField
                    sx={{ marginBottom: 2 }}
                    field="title"
                    label="Title"
                    data-cy="update-title-input"
                    helperText="Title optional - will default to date if not provided"
                />
                <Grid container spacing={4} sx={{ mb: 3 }}>
                    <Grid item xs={12} sm={6} md={4}>
                        <BoundStandardCombo
                            field="type"
                            label="Type"
                            options={capitalizedUpdateTypes}
                            defaultValue={localBulletin.type}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                        <BoundDatePicker
                            required
                            field="date"
                            title="Date"
                            defaultValue={localBulletin.date}
                            disableFuture={true}
                            disabled={disableDatePicker}
                        />
                    </Grid>
                </Grid>
                <BoundTextEditor
                    className="force-urbanist"
                    field="content"
                    defaultValue={localBulletin.content}
                    yup="string.trim.required"
                    validateOnChange={true}
                    onChanged={() => {
                        OnBulletinEdited.raiseOnce(localBulletin)
                    }}
                    removeBtns={["font"]}
                    sx={{ mb: { sm: 3, lg: 4 }, "& .jodit-workplace": { height: "1000px !important" } }}
                    hideLinkNewTabButton
                />
            </Box>
        </Bound>
    )
}
