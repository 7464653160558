import { SelectionsMenu } from "routes/schedule/components/selections"
import { ScheduleSelectorBeforeList, ScheduleSelectorList, SelectedSchedulesTopBar } from "slot-definitions"

import { makeLazy } from "lib/make-lazy"
import { HelpTag } from "routes/help/helpTag"
import { getMemoryReference } from "lib/@hooks/use-memory-reference"
import { notInSelector } from "routes/facilities/lib/when-parent-item"
import { CreateBasketWithItems } from "./create-basket-with-items"
import { ItemTypeLabel } from "event-definitions"
import { HELPTAG_FAVOURITES } from "routes/help/help-tag-constants"

const CreateBasket = makeLazy(
    () => import("routes/schedule/plugins/baskets/create-basket/create-basket"),
    "CreateBasket"
)
const CreateBasketMenu = makeLazy(
    () => import("routes/schedule/plugins/baskets/create-basket/create-basket-menu"),
    "CreateBasketMenu"
)
const CreateNewBasket = makeLazy(
    () => import("routes/schedule/plugins/baskets/create-basket/create-new-basket"),
    "CreateNewBasket"
)

SelectedSchedulesTopBar.plug(<CreateBasketWithItems if={notInSelector} />)
ScheduleSelectorBeforeList("basket-ScheduleBasket").plug(<CreateNewBasket if={notInSelector} />)

SelectionsMenu.plug(() => !Object.isEmpty(getMemoryReference().selectedItems) && <CreateBasketMenu />)

ScheduleSelectorBeforeList("basket-ScheduleBasket").plug(<HelpTag tags={[HELPTAG_FAVOURITES]} />)

ScheduleSelectorList("basket-ScheduleBasket").plug(<CreateBasket priority={10} if={notInSelector} />)

ItemTypeLabel.handleOnce(({ label, parentItem }) => {
    if (parentItem?.id !== "basket-ScheduleBasket") return { label }
    return { label: "Favourites" }
})
