export const HELPTAG_HOME = "home"
export const HELPTAG_ACTIONS = "actions"
export const HELPTAG_ASSET_REGISTERS = "asset registers"
export const HELPTAG_ASSET_REGISTER = "asset register"
export const HELPTAG_ASSET = "asset"
export const HELPTAG_MAINTENANCE = "maintenance"
export const HELPTAG_SCHEDULE_LIBRARY = "schedule library"
export const HELPTAG_MAINTENANCE_REGIME = "maintenance regime"
export const HELPTAG_CUSTOM_SCHEDULES = "custom schedules"
export const HELPTAG_ADMINISTRATION = "administration"
export const HELPTAG_FAVOURITES = "favourites"
export const HELPTAG_TAGS = "tags"
export const HELPTAG_SFG20_ADMIN = "SFG20 admin"
export const HELPTAG_SEARCH_RESULTS = "search results"
export const HELPTAG_RECYCLE_BIN = "recycle bin"
export const HELPTAG_SHARED_WITH_ME = "shared with me"
export const HELPTAG_SHARING_LINKS = "sharing links"
export const HELPTAG_CORE_TAGS = "core tags"
export const HELPTAGS = [
    HELPTAG_HOME,
    HELPTAG_ACTIONS,
    HELPTAG_SHARED_WITH_ME,
    HELPTAG_SHARING_LINKS,
    HELPTAG_SCHEDULE_LIBRARY,
    HELPTAG_MAINTENANCE_REGIME,
    HELPTAG_CUSTOM_SCHEDULES,
    HELPTAG_ADMINISTRATION,
    HELPTAG_FAVOURITES,
    HELPTAG_TAGS,
    //HELPTAG_CORE_TAGS,
    HELPTAG_SFG20_ADMIN,
    HELPTAG_SEARCH_RESULTS,
    HELPTAG_RECYCLE_BIN,
]
