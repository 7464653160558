import { useDialog } from "lib/@hooks/useDialog"
import { parentSome } from "library/tree"
import { Button } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { createSubFolder } from "routes/schedule/folder-manager/controller/create-sub-folder"
import { ChooseFacilityOrSpace } from "routes/schedule/my-schedules/my-group-types/facility/chooseFacilityOrSpace"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useTreeItemIndex } from "routes/schedule/lib/useTreeItemIndex"
import { MULTIPLE_REGIMES_DEMAND } from "routes/schedule/folder-manager/multiple-regimes-demand"
import { useDemand } from "lib/authorization/use-demand"

export function AddFacility() {
    const { parentItem } = useBoundContext()
    const facility = useDialog(<ChooseFacilityOrSpace />, {
        maxWidth: "md",
    })
    useTreeItemIndex()
    const multipleRegimes = useDemand(MULTIPLE_REGIMES_DEMAND)

    if (parentSome(parentItem, (c) => c.data?.type === "facility")) {
        return null
    }

    return (
        !!multipleRegimes && (
            <Button
                data-cy="add-facility-btn"
                onClick={add}
                variant="outlined"
                color="primary"
                startIcon={<Iconify icon="bxs:building" />}
            >
                Add Facility
            </Button>
        )
    )

    async function add() {
        const result = await facility()
        if (result) {
            await createSubFolder(parentItem, result.name, result)
        }
    }
}
