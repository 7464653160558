import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { mutate } from "lib/graphql/mutate"

export const createLock = api(async function createLock(item) {
    if (!item) return null

    return mutate(
        gql`
            mutation CreateLock($item: String!) {
                createLock(item: $item) {
                    canLock
                    lockedBy
                }
            }
        `,
        { item },
        { returns: "createLock" }
    )
})
