/* eslint-disable react-hooks/rules-of-hooks */
import { cloneDeep } from "@apollo/client/utilities"
import useAsync, { CancelAsync, TerminatedError, useAsyncWithStatus } from "lib/@hooks/useAsync"
import { noChange } from "lib/@hooks/useRefresh"
import { useMemo, useRef, useState } from "react"
import noop from "lib/noop"
import { HasInvalidated, ProcessVariable } from "event-definitions"
import { resolveAsFunction } from "lib/resolve-as-function"
import localforage from "localforage"
import { invalidate } from "lib/graphql/cache"
import { withBusyMessage } from "lib/graphql/with-busy-message"

export const LOADING = "__loading"
export const ONCE = Symbol("Once")
export const FETCH = Symbol("Fetch")
export const REMOVE_VARIABLE = Symbol.for("RemoveVariable")

export function api(name, fn) {
    if (typeof name === "function") {
        fn = name
        // eslint-disable-next-line prefer-destructuring
        name = fn.name
    }
    let returnName = ""
    const fnStr = fn.toString()
    const returns = fnStr.indexOf("returns:")
    if (returns !== -1) {
        const start = fnStr.indexOf('"', returns)
        const end = fnStr.indexOf('"', start + 1)
        returnName = fnStr.slice(start + 1, end).split(".")[0]
    }

    const invalidateOn = fnStr.indexOf("invalidateOn:")
    if (invalidateOn !== -1) {
        const start = fnStr.indexOf('"', invalidateOn)
        const end = fnStr.indexOf('"', start + 1)
        name = fnStr.slice(start + 1, end).split(".")[0]
    } else {
        if (!name) {
            name = returnName
        } else {
            if (name !== returnName && !!returnName) {
                throw new Error(`function name mismatch, '${name}' is not the same as '${returnName}'`)
            }
        }
    }
    const result = createContextFunction(async (...params) => cloneDeep(await fn(...params)), name)
    result.useResultsOnce = createContextFunction(
        (...variables) => useRun(name, noop, (v) => v, ONCE, ...variables),
        `${name} useResultsOnce`
    )
    result.useResults = createContextFunction(
        (...variables) => useRun(name, noop, (v) => v, ...variables),
        `${name} useResults`
    )
    result.useResultsOnce.withRefetch = createContextFunction((...variables) => {
        const result = useRun(name, noop, (v) => v, ONCE, ...variables)
        return result ? [result, refresh] : undefined

        function refresh() {
            invalidate(name)
            invalidate(`unlikely.${name}`)
        }
    }, `${name} useResultsOnce.withRefetch`)

    result.useResults.withRefetch = createContextFunction((...variables) => {
        const result = useRun(name, noop, (v) => v, ...variables)
        return result ? [result, refresh] : undefined

        function refresh() {
            invalidate(name)
            invalidate(`unlikely.${name}`)
        }
    }, `${name} useResults.withRefetch`)

    result.useResults.debounce = createContextFunction(function useResultsDebounced(delay, ...variables) {
        const timer = useRef()
        const [value, setValue] = useState()
        const current = useRef()
        current.current = value
        const cloned = useMemo(() => cloneDeep(value), [value])
        const refresh = HasInvalidated(name).after.useRefresh(noChange)

        timer.current = useMemo(() => {
            clearTimeout(timer.current)
            return setTimeout(
                async () => {
                    setValue(await fn(...variables))
                },
                current.current === undefined ? 0 : delay
            )
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [delay, refresh.id, JSON.stringify(variables)])
        return cloned
    }, `${name} useResults.debounce`)

    result.useResults.offlineCache = createContextFunction(function offlineCache(...variables) {
        const key = `${name}:${JSON.stringify(variables)}`
        return useRunWithInitialValue(
            async () => await localforage.getItem(key),
            name,
            async (value) => {
                if (value instanceof Error) return true
                return await localforage.setItem(key, value)
            },
            (v) => v,
            ...variables
        )
    }, `${name} useResults.offlineCache`)

    result.cacheOfflineResult = createContextFunction(async function offlineCache(...variables) {
        const key = `${name}:${JSON.stringify(variables)}`
        const value = await result(...variables)
        await localforage.setItem(key, value)
        return value
    }, `${name} offlineCache`)

    result.useResults.notify = createContextFunction(function notify(notification, ...variables) {
        return useRun(name, notification, (v) => v, ...variables)
    }, `${name} useResults.notify`)
    result.useResults.busy = createContextFunction(function busy(description, ...variables) {
        return useRun(name, noop, (v) => v, withBusyMessage(description), ...variables)
    }, `${name} useResults.busy`)

    /*
     * A React hook that retrieves data using the wrapped function, while also tracking its loading and error states.
     * It leverages a new `useAsyncWithStatus` internally.
     * @param {...*} variables - Variables that are passed to the core function `fn`.
     * @returns {Object} - Returns an object with `data`, `error`, and `loading` properties.
     * @example
     * const { data, error, loading } = getMyQuery.useResults.status('param1', 'param2');
     * if (loading) return <Loader />;
     * if (error) return <Error message={error.message} />;
     * return <Component data={data} />;
     */

    result.useResults.status = function status(...variables) {
        return useStatus(name, ...variables)
    }

    return result

    function useStatus(name, ...variables) {
        const lastResult = useRef(null)
        const former = useRef(null)
        const [refetchToggle, setRefetchToggle] = useState(false)

        function refetch() {
            setRefetchToggle((prev) => !prev)
        }

        const refresh = HasInvalidated(name).after.useRefresh(noChange)
        const response = useAsyncWithStatus(runQuery, former.current, [
            refresh.id,
            JSON.stringify(variables),
            refetchToggle,
        ])
        return { ...response, refetch }

        async function runQuery() {
            try {
                const result = await fn(...variables)

                if (!Object.isEqual(lastResult.current, result)) {
                    lastResult.current = result
                    former.current = structuredClone(result)
                }

                return former.current
            } catch (e) {
                console.error(e)
                throw e
            }
        }
    }

    function useRun(name, ...params) {
        return useRunWithInitialValue(undefined, name, ...params)
    }

    function useRunWithInitialValue(initialValue, name, notification = noop, map = (v) => v, ...variables) {
        const lastResult = useRef(LOADING)
        const former = useRef(LOADING)
        const [variablesToUse, queryRunner, nameToUse, initialValueToUse, comparison] = processVariables(
            variables,
            former.current,
            initialValue,
            (a, b) => a === b
        )

        const refresh = HasInvalidated(nameToUse).after.useRefresh(noChange)
        const result = useAsync(map(queryRunner), former.current, [refresh.id, JSON.stringify(variablesToUse)])

        if (result instanceof Error) {
            console.error(result)
            return null
        }

        // noinspection JSIncompatibleTypesComparison
        return result === LOADING ? undefined : result

        async function runQuery(update) {
            try {
                let done = false
                if (initialValueToUse) {
                    Promise.resolve(resolveAsFunction(initialValueToUse)()).then((result) => {
                        if (!done && !!result) {
                            former.current = result
                            lastResult.current = result
                            update(result)
                        }
                    })
                }
                const result = await fn(...variablesToUse)
                if (comparison(result, lastResult.current)) return CancelAsync
                done = true
                if (lastResult.current !== result) {
                    lastResult.current = result
                    former.current = result ? structuredClone(result) : null
                }
                notification(former.current)
                update(former.current)
                return former.current
            } catch (e) {
                if (e instanceof TerminatedError) return former.current
                console.error(name, e)
                if (notification(e) === true) {
                    setTimeout(() => runQuery(update), 1000 * 60)
                }
                return former.current
            }
        }

        function processVariables(variables, currentValue, initialValue, comparison) {
            const parameters = {
                variables,
                queryRunner: runQuery,
                nameToUse: name,
                currentValue,
                initialValue,
                comparison,
            }
            variables = variables
                .map((variable) => {
                    parameters.variable = variable
                    ProcessVariable.raise(parameters)
                    return parameters.variable
                })
                .filter((v) => v !== REMOVE_VARIABLE)
            return [
                variables,
                parameters.queryRunner,
                parameters.nameToUse,
                parameters.initialValue,
                parameters.comparison,
            ]
        }
    }
}

window._logInvalidation = false

ProcessVariable.handleOnce(({ variable }) => {
    if (variable === FETCH) return { variable: REMOVE_VARIABLE }
    return undefined
})

ProcessVariable.handleOnce(({ variable, nameToUse }) => {
    if (variable === ONCE) {
        return { nameToUse: "unlikely." + nameToUse, variable: REMOVE_VARIABLE }
    }
    return undefined
})

HasInvalidated("*").handleOnce(function handle() {
    if (window._logInvalidation) {
        console.log(this.event)
    }
})
