import { useCallback } from "react"
import { setMemoryReference, useMemoryReference } from "lib/@hooks/use-memory-reference"

// Selection is slightly more complicated than using just the id of the item,
// as the same schedule (as represented by its _id) can appear in multiple parts
// of the tree, such as in search results or in other sets.  To cope with this
// we use an object to represent selections where each key is the _id of the schedule
// that has been selected and the value of the key is the "hint id" - this is a unique
// id that combines the folder and the item within it.  Using this method we can
// make sure schedules are only selected once, but still have some kind of way of understanding
// which one is currently selected; this means we can properly provide breadcrumbs.

// The useSelection hook returns a select function in its second position - this will take either
// an _id or an array where the first element should be the _id and the second the hint id.

export const SELECTION_REFERENCE_TYPE = "selection"

export function useSelection() {
    const [reference, , save] = useMemoryReference({ selectedItems: {} }, SELECTION_REFERENCE_TYPE)
    reference.selectedItems ||= {}
    isSelected.absolute = isAbsolutelySelected
    return [useCallback(isSelected, [reference]), select, reference.selectedItems || {}]

    function isSelected(idAndHint) {
        const id = Array.isArray(idAndHint) ? idAndHint[0] : idAndHint
        const value = reference.selectedItems[id]
        if (value === undefined || value === false) return false
        return Array.isArray(value) ? !!value.length : !!value
    }

    function isAbsolutelySelected(idAndHint) {
        const id = Array.isArray(idAndHint) ? idAndHint[0] : idAndHint
        const hint = Array.isArray(idAndHint) ? idAndHint[1] : true
        const value = reference.selectedItems[id]
        if (value === undefined || value === false) return false
        return Array.isArray(value) ? value.includes(hint) : value === hint
    }

    function select(idAndHint, isSelected = true) {
        const id = Array.isArray(idAndHint) ? idAndHint[0] : idAndHint
        const hint = Array.isArray(idAndHint) ? idAndHint[1] : idAndHint
        if (isSelected) {
            save((reference) => ({ ...reference, selectedItems: { ...reference.selectedItems, [id]: [hint] } }))
        } else {
            save((reference) => ({ ...reference, selectedItems: { ...reference.selectedItems, [id]: false } }))
        }
    }
}

/**
 * @callback SelectorFunction
 * @param {Record<string, string|boolean>} selectedItems - the currently selected items
 * @returns {Record<string, string|boolean>} the updated selections
 */

/**
 * Select current items
 * @param {SelectorFunction} selector - the function that will return the selected items
 */
export function selectItems(selector) {
    setMemoryReference((reference) => {
        reference.selectedItems ??= {}
        let selectedItems = selector(reference.selectedItems)
        if (Array.isArray(selectedItems)) {
            selectedItems = Object.fromEntries(selectedItems.map((id) => [id, id]))
        }
        reference.selectedItems = selectedItems
        return reference
    }, SELECTION_REFERENCE_TYPE)
}
