import { registerSystemTask } from "lib/register-system-task"
import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"
import { successSnackbar } from "lib/snackbar/success-snackbar"
import { errorSnackbar } from "lib/snackbar/error-snackbar"

registerSystemTask("Convert Database", async function convertDatabase() {
    try {
        if (
            await mutate(gql`
                mutation ConvertDatabase {
                    convertDatabaseToUTF8MB4(database: "sfg20")
                }
            `)
        ) {
            successSnackbar("Database converted")
        } else {
            errorSnackbar("Database conversion failed")
        }
    } catch (e) {
        errorSnackbar("Error converting database")
    }
})
