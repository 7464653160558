import { registerConformityStep } from "routes/conformity/lib/register-conformity-step"
import { Lazy } from "lib/make-lazy"

registerConformityStep({
    title: "Maintenance Tasks",
    content: (
        <Lazy
            importer={() => import("routes/conformity/plugins/maintenance-tasks/maintenance-tasks")}
            extract="MaintenanceTasks"
        />
    ),
    icon: "material-symbols:library-add-check-rounded",
    priority: 20,
})
