import { resolveAsFunction } from "lib/resolve-as-function"

export function and(...fns) {
    fns = fns.map(resolveAsFunction)
    return (...params) => fns.every((f) => !!f(...params))
}

export function or(...fns) {
    fns = fns.map(resolveAsFunction)
    return (...params) => fns.some((f) => !!f(...params))
}
