import PropTypes from "prop-types"
import { forwardRef } from "react"
// @mui
import { Link, Typography } from "@mui/material"
// utils
import GetFontValue from "minimals-template/components/utils/getFontValue"

// ----------------------------------------------------------------------

const TextMaxLine = forwardRef(function TextMaxLine(
    { asLink, variant = "body1", line = 2, persistent = false, children, sx, ...other },
    ref
) {
    const { lineHeight } = GetFontValue(variant)

    const style = {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: line,
        WebkitBoxOrient: "vertical",
        ...(persistent && {
            height: lineHeight * line,
        }),
        ...sx,
    }

    if (asLink) {
        return (
            <Link color="inherit" ref={ref} variant={variant} sx={{ ...style }} {...other}>
                {children}
            </Link>
        )
    }

    return (
        <Typography ref={ref} variant={variant} sx={{ ...style }} {...other}>
            {children}
        </Typography>
    )
})

TextMaxLine.propTypes = {
    asLink: PropTypes.bool,
    children: PropTypes.node.isRequired,
    line: PropTypes.number,
    persistent: PropTypes.bool,
    sx: PropTypes.object,
    variant: PropTypes.oneOf([
        "body1",
        "body2",
        "button",
        "caption",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "inherit",
        "overline",
        "subtitle1",
        "subtitle2",
    ]),
}

export default TextMaxLine
