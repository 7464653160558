import { ScheduleTopmost } from "routes/schedule/scheduleIntroduction"
import { Alert, Box, Button, Stack, Tooltip, Typography } from "@mui/material"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import Iconify from "minimals-template/components/Iconify"
import { PublishedItemRight, ScheduleItemRight } from "slot-definitions"
import { ListItemBox } from "lib/@components/ListItemBox"
import { Secure } from "lib/authorization/secure"
import { User } from "lib/user/user"
import { createDraft } from "controller/create-draft-schedule"
import { navigate } from "lib/routes/navigate"
import { uniqueId } from "library/database/split-id"
import { useSchedule } from "routes/schedule/lib/useSchedule"

ScheduleTopmost("**").plug(<ScheduleOutOfDate />)

function ScheduleOutOfDate() {
    const { target } = useBoundContext()
    return (
        target.mismatchedVersion && (
            <Box py={3}>
                <Alert
                    severity="warning"
                    sx={{
                        "& .MuiAlert-message": {
                            width: 1,
                        },
                    }}
                >
                    <ListItemBox width={1}>
                        <Stack spacing={1} sx={{ flex: 1, width: 1 }}>
                            <Typography component="div" variant="subtitle1" gutterBottom>
                                Schedule Out Of Date
                            </Typography>
                            <Typography component="div" sx={{ maxWidth: 500 }} variant="body" gutterBottom>
                                The schedule from which this one derives has changed and so this schedule should be
                                reviewed to ensure that it follows current legislation and best practice.
                            </Typography>
                        </Stack>
                        <Box>
                            <Secure
                                hide
                                demand="scheduleEditor"
                                fallback={
                                    <Box>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Published By
                                        </Typography>
                                        <User id={target?.metadata?.publishedBy} />
                                    </Box>
                                }
                            >
                                <Button onClick={review}>Review</Button>
                            </Secure>
                        </Box>
                    </ListItemBox>
                </Alert>
            </Box>
        )
    )

    async function review() {
        await createDraft(target._id)
        navigate(
            `/app/schedules/compare/draft!${uniqueId(target._id)}/scheduleHistory!${uniqueId(
                target.originatingScheduleId
            )}-${target.originatingScheduleVersion}/schedule!${uniqueId(target.originatingScheduleId)}`
        )
    }
}

PublishedItemRight.plug(<ListItemOutOfDate priority={-2} />)
ScheduleItemRight.plug(<ListItemOutOfDate priority={-2} />)

function ListItemOutOfDate() {
    const { target } = useBoundContext()
    const result = useSchedule(target._id, "no_v")
    const schedule = result?.schedule ?? { mismatchedVersion: undefined }
    const { mismatchedVersion: isMismatched } = schedule

    return (
        !!isMismatched && (
            <Tooltip arrow title="The original schedule, from which this derived, has changed">
                <Box pt={1}>
                    <Iconify icon="ep:warning-filled" sx={{ color: "warning.main", fontSize: 24 }} />
                </Box>
            </Tooltip>
        )
    )
}
