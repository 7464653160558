import { parentSome } from "library/tree"
import { getTreeItem } from "routes/schedule/lib/getTreeItem"

const COMMAND_WORDS = ["edit", "new", "timeline", "review"]

export function isActiveWhen(predicate, or = () => false) {
    return ({ pathname, location }) => {
        const rightPath = pathname.startsWith("/app/schedules")
        const orResult = or({ pathname, location })
        if (!rightPath || orResult) {
            return orResult
        }
        const parts = pathname.split("?")[0].split("/")
        const schedule = COMMAND_WORDS.includes(parts.at(-1)) ? parts.at(-2) : parts.at(-1)
        if (schedule === "schedules") {
            const url = new URL(location.href ?? `http://localhost${location.pathname}${location.search}`)
            if (url?.searchParams?.get("id")) {
                return parentSome(getTreeItem(url.searchParams.get("id")), predicate)
            }
            return false
        }
        if (schedule.includes("~")) return orResult || parentSome(getTreeItem(schedule), predicate)
        return parentSome(getTreeItem(schedule), predicate)
    }
}
