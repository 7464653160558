import { InputAdornment } from "@mui/material"
import { DebouncedTextField } from "lib/@components/debounced-text-field"
import { setFromValueParam } from "lib/setFromEvent"
import Iconify from "minimals-template/components/Iconify"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function HelpSearchBar() {
    const { search, setSearch } = useBoundContext()

    return (
        <DebouncedTextField
            InputProps={{
                sx: { color: "common.black" },
                startAdornment: (
                    <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: "text.disabled", width: 20, height: 20 }} />
                    </InputAdornment>
                ),
            }}
            size="small"
            fullWidth
            sx={{
                borderRadius: 1,
                backgroundColor: "common.white",
            }}
            id="helpBannerSearchBar"
            type="text"
            placeholder="Search..."
            onChange={setFromValueParam(setSearch)}
            value={search}
        />
    )
}
