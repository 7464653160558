import {
    RegimeButtons,
    ScheduleSelectorBeforeList,
    ScheduleSelectorPanel,
    ScheduleSelectorToolbarStart,
} from "slot-definitions"
import { isInRegime } from "routes/facilities/lib/when-parent-item"
import { ButtonGroup } from "@mui/material"
import { lazy } from "react"
import { ScheduleHeaderWrapper } from "event-definitions"
import { OneCard } from "lib/@components/one-card"
import { SearchFilter } from "routes/facilities/plugins/profiles/search-filter"

const Stats = lazy(() => import("./stats"))

ScheduleSelectorBeforeList("*").plug(<Stats priority={600} if={isInRegime} />)
ScheduleSelectorToolbarStart("*").plug(<Regime priority={1000} if={isInRegime} />)
ScheduleSelectorPanel("*").plug(<SearchFilter if={isInRegime} priority={1100} />)

ScheduleHeaderWrapper("*").handleOnce(({ parentItem }) => {
    if (isInRegime(parentItem)) {
        return { wrapper: <OneCard /> }
    }
    return null
})

function Regime() {
    return (
        <ButtonGroup size="small">
            <RegimeButtons.Slot />
        </ButtonGroup>
    )
}
