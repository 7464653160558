import { parentFind } from "library/tree"
import { PlanRoot } from "library/tree/constants"

export function getTreeIdFromItem(item) {
    return parentFind(item, (c) => c[PlanRoot])?.id
}

export function getTreeItemFromItem(item) {
    return parentFind(item, (c) => c[PlanRoot])
}
