import { Button } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { useState } from "react"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Feature } from "lib/@components/feature"
import { FEATURE_FLAG_DOWNLOAD_SCHEDULE_PDF } from "./download-schedule-pdf.runtime"
import { downloadSchedulePDF } from "routes/regime/pdf/controller/download-schedule-pdf"
import { useLocation } from "react-router"
import { useSearchParam } from "lib/@hooks/use-search-param"
import { parentFind } from "library/tree"
import { PlanRoot } from "library/tree/constants"

export function DownloadSchedulePDFButton() {
    const [downloading, setDownloading] = useState(false)
    const { schedule, schedulePath, currentId, parentItem } = useBoundContext()
    const { search } = useLocation()
    const [version] = useSearchParam("version")
    const planRoot = parentFind(parentItem, (c) => c[PlanRoot])

    return (
        !!planRoot && (
            <Feature feature={FEATURE_FLAG_DOWNLOAD_SCHEDULE_PDF}>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={handlePdfDownload}
                    startIcon={
                        !downloading ? (
                            <Iconify icon="ic:round-print" />
                        ) : (
                            <Iconify icon="ic:round-hourglass-empty" className="iconSpin" />
                        )
                    }
                    disabled={downloading}
                    data-cy="download-schedule-pdf-button"
                    sx={{ mr: 2.5 }}
                >
                    Print Schedule
                </Button>
            </Feature>
        )
    )

    async function handlePdfDownload() {
        try {
            setDownloading(true)
            const scheduleURL = encodeURI(schedulePath + search)
            const scheduleId = currentId?.split("!").at(-1)
            const regimeId = currentId?.split("!")[0]?.split("~").at(-1)
            const pdfString = await downloadSchedulePDF(scheduleURL, scheduleId, regimeId, version)
            const fileName = schedule?.title?.replaceAll(" ", "")
            downloadPDFObject(pdfString, fileName)
        } catch (e) {
            errorSnackbar("Error printing PDF")
        } finally {
            setDownloading(false)
        }
    }

    function downloadPDFObject(data, filename = "Schedule.pdf") {
        const dlAnchorElem = document.createElement("a")
        dlAnchorElem.setAttribute("href", data)
        dlAnchorElem.setAttribute("download", filename)
        dlAnchorElem.click()
    }
}
