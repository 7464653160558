import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getSharingLinkCoverInfo = api(async function getSharingLinkCoverInfo(id) {
    return query(
        gql`
            query GetSharingLinkCoverInfo($id: String!) {
                getSharingLinkCoverInfo(id: $id) {
                    name
                    clientName
                    clientCompany
                }
            }
        `,
        { id },
        { returns: "getSharingLinkCoverInfo" }
    )
})
