import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"

export async function createConformityDocument(facility, ids) {
    return mutate(
        gql`
            mutation CreateConformityDocument($facility: String!, $ids: [String]) {
                createConformityDocument(facility: $facility, ids: $ids) {
                    _id
                }
            }
        `,
        { facility, ids },
        { returns: "createConformityDocument._id" }
    )
}
