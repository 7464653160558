import { api } from "lib/graphql/api"
import { mutate } from "lib/graphql/mutate"
import { gql } from "@apollo/client"

export const deleteCustomSkillCode = api(async function deleteCustomSkillCode(id) {
    return mutate(
        gql`
            mutation DeleteSkillCode($id: String!) {
                deleteCustomSkillCode(id: $id)
            }
        `,
        { id },
        { returns: "deleteCustomSkillCode" }
    )
})
