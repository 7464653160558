import { ShareLinkOtherSettings, TreeToolbar } from "slot-definitions"
import { Feature } from "lib/@components/feature"
import { FEATURE_FLAG_PLANNING } from "routes/plan/plan.runtime"
import { ConformityDocumentsShareLinkSetting } from "./conformity-documents-share-link-setting"
import { AddConformityButton } from "./add-conformity-button"

TreeToolbar.plug(
    <Feature feature={FEATURE_FLAG_PLANNING} if={(c) => c.inFacility && !c.schedule}>
        <AddConformityButton />
    </Feature>
)

ShareLinkOtherSettings.plug(<ConformityDocumentsShareLinkSetting />)
