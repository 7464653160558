import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const scheduleOptions = api((id) =>
    query(
        gql`
            query ScheduleOptions($id: String!) {
                scheduleOptions(id: $id)
            }
        `,
        { id },
        { returns: "scheduleOptions" }
    )
)
