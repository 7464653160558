import { Box, ListItemButton } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import { FolderItemPart } from "routes/schedule/tree/schedules/folder-item-part"
import { FolderIcon } from "routes/schedule/tree/schedules/folder-icon"
import { prevent } from "lib/prevent"
import { ScheduleFolderIcon } from "event-definitions"
import { PropertyBox } from "lib/@components/property-box"
import {
    ScheduleFolderBelow,
    ScheduleFolderLeft,
    ScheduleFolderMain,
    ScheduleFolderMainExtra,
    ScheduleFolderRight,
} from "slot-definitions"
import { captureNow } from "FrozenRouter"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import Iconify from "minimals-template/components/Iconify"

export function registerIconForFolderType(type, icon) {
    ScheduleFolderIcon.handleOnce((info) => {
        if (info.data?.data?.type === type) {
            info.icon = icon
        }
        info.icon = info.data?.icon ? info.data.icon : info.icon
        info.icon = info.data?.data?.icon ? info.data.data.icon : info.icon
        if (typeof info.icon === "string") {
            info.icon = <Iconify sx={{ width: 32, height: 32, color: "myStuff.main" }} icon={info.icon} />
        }
    })
}

export function ScheduleFolder({ entry, icon, color, caption, children, last, Component = ListItemButton }) {
    const { noDivider, onClick, selected } = useBoundContext()
    last = last || noDivider
    return (
        <Component
            data-cy="schedule-folder"
            className="schedule-folder"
            divider={!last}
            onClick={captureNow(prevent(onClick))}
            selected={selected}
        >
            <PropertyBox spacing={0}>
                <ListItemBox spacing={1} pl={3}>
                    <ScheduleFolderLeft.Slot entry={entry}>
                        <FolderItemPart>
                            <FolderIcon sx={{ color }} icon={icon} />
                        </FolderItemPart>
                    </ScheduleFolderLeft.Slot>
                    <Box ml={4} />
                    <Box flex={1} ml={1}>
                        <ScheduleFolderMain.Slot entry={entry}>
                            <Box display="flex" alignItems="center" sx={{ wordBreak: "break-word" }}>
                                {caption}
                                <ScheduleFolderMainExtra.Slot entry={entry} />
                            </Box>
                            {children}
                        </ScheduleFolderMain.Slot>
                    </Box>
                    <ScheduleFolderRight.Slot entry={entry} />
                </ListItemBox>
                <ScheduleFolderBelow.Slot entry={entry} />
            </PropertyBox>
        </Component>
    )
}
