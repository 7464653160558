import { PERIOD_MULTIPLIER, VALID_CLASSIFICATIONS } from "library/SFG20/constants"
import { MapTasksForTiming } from "event-definitions"

export function tasksWithTimings(tasks) {
    const { result } = MapTasksForTiming.call({ result: tasks })
    return result.filter(Boolean).filter((c) => VALID_CLASSIFICATIONS.includes(c.classification))
}

export function tasksNoHeaders(tasks) {
    const { result } = MapTasksForTiming.call({ result: tasks })
    return result.filter(Boolean).filter((task) => !task.isHeader)
}

export function annualTiming(tasks) {
    const validTasks = tasksWithTimings(tasks)
    return validTasks.reduce(
        (time, task) =>
            time +
            ((PERIOD_MULTIPLIER[task.frequency.period] ?? 0) / (task.frequency.interval || 1)) * (task.minutes ?? 0),
        0
    )
}
