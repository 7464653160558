import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { PlanDetails } from "routes/plan/plan-details"
import { registerTab } from "lib/@components/tabs"

import { REVIEW_TABS } from "routes/plan/review-tabs"
import { OneCard } from "lib/@components/one-card"
import { ScheduleSelectorBeforeList } from "slot-definitions"
import { Box } from "@mui/material"

export function PlanDetailsWrapperRuntime() {
    const { plan, id } = useBoundContext()
    return (
        <Box pt={1}>
            <OneCard>
                <ScheduleSelectorBeforeList.Slot type={id} />
            </OneCard>
            <PlanDetails plan={plan} />
        </Box>
    )
}

registerTab({
    tab: REVIEW_TABS,
    id: "review-schedules",
    title: "Review Schedules",
    content: <PlanDetailsWrapperRuntime $lazy priority={50} />,
})
