import { registerTab } from "lib/@components/tabs"
import { ASSET_TAB_ID } from "routes/asset-register/asset-register"
import { Importer } from "routes/asset-register/plugins/excel-import/importer"
import { FileContentTab } from "routes/asset-register/plugins/excel-import/file-content-tab"

registerTab({
    tab: ASSET_TAB_ID,
    id: "import",
    title: "Import",
    content: <Importer $lazy priority={10} />,
})

registerTab({
    tab: ASSET_TAB_ID,
    id: "imported",
    title: "Imported Files",
    content: <FileContentTab $lazy priority={1000} />,
})
