import { useDialog } from "lib/@hooks/useDialog"
import { FacilityConfiguration } from "routes/schedule/my-schedules/my-group-types/facility/facility-configuration"
import { ContextMenuItem } from "lib/@components/context-menu-bar/context-menu-item"
import { storeGroupData } from "routes/schedule/controller/store-group-data"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function EditFacility() {
    const { parentItem } = useBoundContext()
    parentItem.data ??= {}
    parentItem.data.name = parentItem.label
    const editFacility = useDialog(
        <FacilityConfiguration parentItem={parentItem} title="Edit Facility" existingFacility={parentItem.data} />,
        {
            maxWidth: "lg",
            fullScreen: true,
            className: "facility-dialog",
        }
    )
    return parentItem.data?.type === "facility" && <ContextMenuItem icon="eva:edit-fill" label="Edit" onClick={edit} />

    async function edit() {
        const result = await editFacility()
        if (result) {
            await storeGroupData(parentItem.id, result, result.name)
        }
    }
}
