import { Avatar, Tooltip } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { useUserByIdOrEmail } from "lib/user/use-user"
import { UserAvatar } from "minimals-template/components/user-avatar"

export function UserIcon({ id, email }) {
    return id || email ? (
        <RenderUserIcon id={id} email={email} />
    ) : (
        <Tooltip title={email ?? "No User"}>
            <Avatar
                alt={"No User"}
                color="primary"
                sx={{
                    color: "#333",
                    backgroundColor: "grey.300",
                }}
            >
                <Iconify icon="mdi:user" />
            </Avatar>
        </Tooltip>
    )
}

function RenderUserIcon({ id, email }) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const user = useUserByIdOrEmail(id, email)
    if (!user || user.__NO_USER) return null
    return <UserAvatar user={user} />
}
