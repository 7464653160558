import { HelpTagsAvailable, HelpTagsRemoved } from "event-definitions"
import { useEffect } from "react"

export function HelpTag({ tags = [] }) {
    useEffect(() => {
        HelpTagsAvailable.raise(tags)
        return () => {
            HelpTagsRemoved.raise(tags)
        }
    }, [tags])
    return null
}
