import { api } from "lib/graphql/api"
import { mutate } from "lib/graphql/mutate"
import { gql } from "@apollo/client"

export const setCustomSkillCode = api(async function setCustomSkillCode(id, definition, client) {
    return mutate(
        gql`
            mutation SetSkillCode($id: String!, $definition: SkillCodeInput!, $client: String) {
                setCustomSkillCode(id: $id, definition: $definition, client: $client)
            }
        `,
        { id, definition, client },
        { returns: "setCustomSkillCode" }
    )
})
