import { FeatureEnabled } from "event-definitions"
import { checkPermission } from "library/authorization"
import { registerSystemAdminTab } from "routes/sfg20-admin/plugins/system-tab/register-system-admin-tab"

import { Lazy } from "lib/make-lazy"

FeatureEnabled.handle(({ feature, enabled }) => {
    if (!enabled) {
        if (checkPermission(`$feature-${feature}`)) {
            return { enabled: true }
        }
    }
    return undefined
})

const url = new URL(window.location.href)
const queryParams = new URLSearchParams(url.search)
const enabledFeatures = queryParams.getAll("feature")
console.log("TEMPORARILY ENABLED FEATURES: ", enabledFeatures)
FeatureEnabled.handle(({ feature, enabled }) => ({
    enabled: enabled || enabledFeatures.includes(feature),
}))

//Register lazy
registerSystemAdminTab("Features", <Lazy importer={() => import("./features.js")} extract="Features" />)
