import { useBoundValue } from "lib/@components/binding/use-bound-value"
import { ToggleButton, Tooltip } from "@mui/material"
import { toggleFilter } from "routes/sharing/plugins/filters/toggle-filter"

export function Filter({ filterName, children, tip }) {
    const [filters, setFilters] = useBoundValue("filters", [])
    return (
        <Tooltip title={tip ?? filterName} arrow>
            <ToggleButton
                size="small"
                color="primary"
                value="filterName"
                selected={filters.includes(filterName)}
                onClick={() => setFilters(toggleFilter(filterName))}
            >
                {children ?? filterName}
            </ToggleButton>
        </Tooltip>
    )
}
