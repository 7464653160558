import { Box, List, Paper, Stack, Typography } from "@mui/material"
import { ScheduleGroupInstance } from "routes/schedule/plugins/used-schedules/schedule-group-instance"
import { ListItemBox } from "../ListItemBox"
import { RequestPageRepeat } from "../request-page-repeat"
import { RelatedTree } from "./related-tree"

export function TreeListComponent({ items, setSkip, title, groupComponent = <ScheduleGroupInstance /> }) {
    return (
        !!items?.count && (
            <>
                <Typography variant="subtitle2" sx={{ p: 2 }}>
                    {title}
                </Typography>

                <Paper elevation={1} sx={{ overflow: "hidden", mb: 2, p: 0 }}>
                    <ListItemBox sx={{ width: 1, py: 0.3, bgcolor: "grey.50016", color: "text.secondary" }}>
                        <Typography sx={{ ml: 6 }} variant="caption">
                            Name
                        </Typography>
                        <Box flex={1} />
                    </ListItemBox>
                    <Stack spacing={2} sx={{ width: 1 }}>
                        <List sx={{ width: 1, px: 1 }}>
                            <RequestPageRepeat
                                pageSize={10}
                                list={items?.trees}
                                setSkip={setSkip}
                                total={items?.count}
                                item={<RelatedTree groupComponent={groupComponent} />}
                            />
                        </List>
                    </Stack>
                </Paper>
            </>
        )
    )
}
