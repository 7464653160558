import { UserLoggedOut } from "event-definitions"
import { noChange, useRefresh } from "lib/@hooks/useRefresh"
import { resolveAsFunction } from "lib/resolve-as-function"
import { createEvent } from "library/local-events"
import { useCallback } from "react"
import { onlyStandardProperties } from "routes/schedule/compare/register-task-comparitor"
import { clone } from "lib/clone"

let memory = {}

export const StoreChanged = createEvent("StoreChanged")

/**
 * Returns a state value from the store and a function to update it. The store is in memory
 * and cuts across concerns, allowing elements of the app to update without massive redraws.
 *
 * @param {string} key - The key of the state value to retrieve.
 * @param {*} initialValue - The initial value to use if the state value is not found in the store.
 *
 * @throws {Error} - Throws an error if the key is not specified.
 *
 * @returns {Array} - Returns an array containing the state value and a function to update it.
 */
export function useStoreState(key, initialValue) {
    if (!key) {
        throw new Error("Must specify a key")
    }
    const refresh = useRefresh(noChange)
    const doSetValue = useCallback(setValue, [key])
    StoreChanged(`**`).useEvent(
        (updatedKey) => {
            if (key.startsWith(updatedKey) || updatedKey.startsWith(key)) {
                refresh()
            }
        },
        [key]
    )

    return [Object.get(memory, key) ?? resolveAsFunction(initialValue)(), doSetValue]

    function setValue(v) {
        setStoreState(key, v)
    }
}

export function setStoreState(key, v) {
    v = resolveAsFunction(v)
    const existing = Object.get(memory, key)
    const before = onlyStandardProperties(existing)
    const value = v(existing)
    if (onlyStandardProperties(value) === before) return
    Object.set(memory, key, value)
    StoreChanged(key).raiseOnce(key)
}

export function getStoreState(key) {
    return clone(Object.get(memory, key))
}

UserLoggedOut.handleOnce(() => {
    memory = {}
    StoreChanged.raise("")
})
