import { Box, ListItemButton } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import { FolderItemPart } from "routes/schedule/tree/schedules/folder-item-part"
import { BasketIcon } from "routes/schedule/plugins/baskets/tree/basket-icon"
import { RefreshMainTree } from "event-definitions"
import { getIconForType } from "ioc"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function Basket({ label = "Favourites", last, type = "Basket", sx, Component = ListItemButton }) {
    const { refresh, onClick } = useBoundContext()
    const icon = getIconForType(type, <BasketIcon sx={sx} />, sx, false)
    RefreshMainTree.useEvent(refresh)
    return (
        <Component divider={!last} onClick={onClick}>
            <ListItemBox spacing={1}>
                <FolderItemPart />
                <FolderItemPart>{icon}</FolderItemPart>
                <Box flex={1}>{label}</Box>
            </ListItemBox>
        </Component>
    )
}
