/**
 * Wrapper for an onChange event that sends a target (like a text field)
 * @param {function} fn - function to call with the value
 * @returns {function(*): *} a function to pass to an onChange handler
 */
import { startTransition } from "react"

export function setFromEvent(fn) {
    return (e) => fn(e.target.value)
}

/**
 * Wrapper for an onChange event that passes the value in the second parameter
 * @param {function} fn - function to call with the value
 * @returns {function(*): *} a function to pass to an onChange handler
 */
export function setFromValueParam(fn) {
    return (e, v) => fn(v)
}

export function setFromValueParamOnlyTruthy(fn) {
    return (e, v) => !!v && fn(v)
}

export function withinTransition(fn) {
    return (...params) => startTransition(() => fn(...params))
}

export function setOnlyTruthy(fn) {
    return (v) => !!v && fn(v)
}
