import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const getSkillsRelatedTrees = api(async function getSkillsRelatedTrees({ id, client }) {
    return query(
        gql`
            query GetSkillsRelatedTrees($id: String!, $client: String) {
                getSkillsRelatedTrees(id: $id, client: $client) {
                    resultsId
                    count
                    trees {
                        id
                        treeId
                        name
                        count
                        groups {
                            name
                            id
                            groupId
                            schedulePrefix
                            schedules
                            icon
                        }
                    }
                }
            }
        `,
        { id, client },
        { returns: "getSkillsRelatedTrees" }
    )
})
