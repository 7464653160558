import { clone } from "lib/clone"
import { getSkillingData } from "../controller/get-skilling"
import { stringifyArray } from "lib/stringify-array"

export async function addSkillsToTask(tasks) {
    const skillingIdsSet = new Set()
    tasks.forEach((task) => {
        skillingIdsSet.add(task.skillSet)
    })

    let skillingIds = Array.from(skillingIdsSet)
    const skillsToString = stringifyArray(skillingIds)
    let amendedTasks = []

    if (skillsToString.length) {
        try {
            const skillingData = await getSkillingData(skillsToString)
            const processTasks = tasks.map((task) => {
                const clonedTask = clone(task)
                delete clonedTask.isHeader
                delete clonedTask.taskId
                const matchingEntry = skillingData.find((entry) => entry.SkillingID === clonedTask.skillSet)
                if (matchingEntry) {
                    clonedTask.skillSet = {
                        skillingId: matchingEntry.SkillingID.toString(),
                        skilling: matchingEntry.Skilling,
                    }
                }
                return clonedTask
            })
            amendedTasks = processTasks
        } catch (error) {
            console.error("Error fetching skilling data:", error)
        }
    }

    return amendedTasks
}
