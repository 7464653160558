import { registerTab } from "lib/@components/tabs"
import { Lazy } from "lib/make-lazy"

registerTab({
    tab: "sfg20AdminTabs",
    id: "apiAccessTokens",
    title: "API Tokens",
    demands: "admin-apitokens",
    content: <Lazy priority={4} importer={() => import("./api-tokens.js")} extract={"APITokens"} />,
})
