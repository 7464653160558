import { parentTreeItem } from "routes/schedule/tree/schedules/makeTreeItemsFromGroup"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function useScheduleGroup() {
    let { currentGroup } = useBoundContext()
    const parents = []
    while (currentGroup && currentGroup.data?.type !== "facility") {
        if (typeof currentGroup.label === "string") {
            parents.push(currentGroup.label)
        }
        currentGroup = currentGroup[parentTreeItem]
    }
    if (currentGroup) {
        parents.push(currentGroup.label)
    }
    return parents.reverse().join(" › ")
}
