function isPublishedId(id) {
    return id?.includes("|")
}

function isIdInGroup(id) {
    return id?.includes("!")
}

function isIdInTree(id) {
    return id?.includes("~")
}

function isSpecialId(id) {
    return isPublishedId(id) || isIdInGroup(id)
}

function getTreeId(id) {
    if (!id) return undefined
    if (id?.includes(":") && id?.includes("/")) return undefined
    if (!id?.includes("!")) return undefined
    if (isIdInGroup(id)) {
        if (isIdInTree(id)) {
            const parts = id.split("!")

            const tree =
                (parts.at(-2) ?? parts.at(-1)).split("~")[1]?.replaceAll("|", " ").trim().replaceAll(" ", "|") ??
                undefined
            if (!tree) {
                return undefined
            }
            return tree
        }
        return undefined
    }
    return id
}

module.exports.getTreeId = getTreeId
module.exports.isPublishedId = isPublishedId
module.exports.isIdInGroup = isIdInGroup
module.exports.isIdInTree = isIdInTree
module.exports.isSpecialId = isSpecialId
