import { gql } from "@apollo/client"

export const GetDraft = gql`
    query getDraft($id: String!) {
        schedule: getDraft(id: $id) {
            _id
            id
            title
            code
            unitOfMeasure
            version
            intro {
                content
                note
            }
            metadata {
                published
            }
            serviceTimings {
                frequency {
                    period
                    interval
                }
                minutes
            }
            type {
                Name
            }
            tasks {
                id
                content
                title
                note
                isHeader
                minutes
                classification
                frequency {
                    period
                    interval
                }
                skillSet
                skill {
                    Skilling
                    SkillingCode
                }
                unitOfMeasure
            }
            legislation {
                HTML
                URL
            }
        }
    }
`
