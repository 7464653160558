import { useSchedule } from "routes/schedule/lib/useSchedule"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Bound } from "lib/@components/binding/Bound"
import { Box, Stack, Typography } from "@mui/material"
import { Repeat } from "lib/@components/repeat"
import { OptionalTask } from "routes/asset-register/plugins/schedule-options/optional-task"
import { getTypeSchedulePayload } from "routes/asset-register/plugins/schedule-options/controller/get-type-schedule-payload"
import { noChange, useRefresh } from "lib/@hooks/useRefresh"
import { useSave } from "lib/@hooks/use-save"
import { setTypeSchedulePayload } from "routes/asset-register/plugins/schedule-options/controller/set-type-schedule-payload"

export function ListOptions({ options, scheduleId }) {
    const { schedule } = useSchedule(scheduleId)
    const { target, assetType } = useBoundContext()
    const payload = getTypeSchedulePayload.useResults({ id: target._id, type: assetType.name, scheduleId })
    const change = useSave({ target: payload, save })
    const refresh = useRefresh(noChange)

    return (
        !!schedule?.tasks &&
        payload && (
            <Bound schedule={schedule} target={payload} refresh={refresh} onChange={change}>
                <Box
                    className="styled-bullets"
                    sx={{
                        flex: 1,
                        fontSize: 13,
                        "& * > * > * > *": { fontSize: "13px !important" },
                        "& .MuiListItemButton-root": { py: 0 },
                        "& .min-height-box": { py: 0, minHeight: 34 },
                    }}
                >
                    <Box p={1}>
                        <Stack>
                            <Typography color="text.secondary" variant="caption" gutterBottom>
                                Some tasks may not be required, depending on the assets being used. You should deselect
                                any tasks that are not necessary.
                            </Typography>
                            <Repeat list={options} item={<OptionalTask schedule={schedule} />} />
                        </Stack>
                    </Box>
                </Box>
            </Bound>
        )
    )

    async function save(payload) {
        await setTypeSchedulePayload({ id: target._id, type: assetType.name, scheduleId, payload })
    }
}
