export const relevantDemands = ["accessAllRegimes", "manageAccess", "publishRegime", "canMakeShare", "manageRegime"]

export const relevantIcons = {
    accessAllRegimes: { icon: "ic:round-admin-panel-settings", description: "Access all regimes" },
    manageAccess: { icon: "ic:round-manage-accounts", description: "Manage access settings" },
    publishRegime: { icon: "ic:round-manage-search", description: "Review and publish maintenance regimes" },
    canMakeShare: { icon: "ic:round-share", description: "Create sharing links" },
    manageRegime: { icon: "ic:round-edit", description: "Manage maintenance regimes" },
}

export const RegimeAccessTableRowHeaders = [
    { id: "displayName", label: "Name", align: "left" },
    { id: "jobTitle", label: "Job Title", align: "left" },
    { id: "userRoles", label: "User Roles", align: "left", canOrderBy: false },
    { id: "access", label: "Access", align: "left", canOrderBy: false },
]

export const filterStates = {
    All: { label: "All", value: "" },
    On: { label: "On", value: "enabled" },
    Off: { label: "Off", value: "disabled" },
}
