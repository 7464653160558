import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { mutate } from "lib/graphql/mutate"
import { query } from "lib/graphql/query"

export const getBundles = api(() =>
    query(
        gql`
            query GetBundles {
                getBundles {
                    scheduleTypes
                    demands
                    features
                    name
                    _id
                }
            }
        `,
        {},
        { returns: "getBundles" }
    )
)

export const updateBundle = api(({ id, name, demands, scheduleTypes, features } = {}) =>
    mutate(
        gql`
            mutation UpdateBundle(
                $id: String!
                $name: String
                $demands: [String]
                $scheduleTypes: [String]
                $features: [String]
            ) {
                updateBundle(
                    id: $id
                    name: $name
                    demands: $demands
                    scheduleTypes: $scheduleTypes
                    features: $features
                ) {
                    _id
                    demands
                    name
                    scheduleTypes
                    features
                }
            }
        `,
        { id, name, demands, scheduleTypes, features },
        { returns: "updateBundle" }
    )
)

export const createBundle = api(({ name, demands, scheduleTypes, features } = {}) =>
    mutate(
        gql`
            mutation CreateBundle($name: String!, $demands: [String], $scheduleTypes: [String], $features: [String]) {
                createBundle(name: $name, demands: $demands, scheduleTypes: $scheduleTypes, features: $features) {
                    demands
                    name
                    scheduleTypes
                    features
                    _id
                }
            }
        `,
        { name, demands, scheduleTypes, features },
        { returns: "createBundle" }
    )
)

export const deleteBundle = api(({ id } = {}) =>
    mutate(
        gql`
            mutation DeleteBundle($id: String!) {
                deleteBundle(id: $id) {
                    _id
                    demands
                    name
                }
            }
        `,
        { id },
        { returns: "deleteBundle" }
    )
)

export function useBundles() {
    //AD: Example for stateful loading
    //eslint-disable-next-line
    const { data, error, loading } = getBundles.useResults.status()
    if (error) {
        return []
    }
    return data
}
