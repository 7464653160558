import { Box, Button, Menu, MenuItem, Portal, Stack } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { FilterSchedule, RefreshScheduleSelector } from "event-definitions"
import { getReference, useReference } from "lib/@hooks/use-reference"
import { useRef, useState } from "react"
import { Classification } from "routes/schedule/tasks/classification"
import { GROUP, SCHEDULE } from "library/constants"
import { findInChildren, parentSome } from "library/tree"
import { isInRegime } from "routes/facilities/lib/when-parent-item"

const MAPPING = {
    NotSpecified: "Grey",
}

export function showUnlicensed() {
    const { filterModes } = getReference({ filterModes: {} })
    return !!filterModes["+Unlicensed"]
}

FilterSchedule.handleOnce(({ items, skipDefault }) => {
    if (skipDefault) return { items }
    const { filterModes } = getReference({ filterModes: {} })
    const fullFilter = Object.fromEntries(Object.entries(filterModes).filter(([k]) => !k.startsWith("+")))

    const selected = Object.entries(fullFilter)
        .filter(([, value]) => !!value)
        .map(([key]) => key)

    return { items: items.filter(filterUnlicensed).filter(filterTaskTypes) }

    function filterUnlicensed(item) {
        // the notLicensed folder should always show all unlicensed schedules without the filter option on
        // so that the user can see what they could buy
        if (showUnlicensed() || item.id?.startsWith("notLicensed") || parentSome(item, (p) => p.showUnlicensed)) {
            return true
        }
        switch (item?.type || item.$?.type) {
            case SCHEDULE:
                return !item?.notLicensed
            case GROUP:
                return (
                    isInRegime(item) ||
                    findInChildren(item, (c) => c.type === SCHEDULE || c.$?.type === SCHEDULE).some(
                        (item) => !item?.notLicensed
                    )
                )
            default:
                return true
        }
    }

    function filterTaskTypes(item) {
        if (!selected.length) return true
        switch (item.taskTypes && (item?.type ?? SCHEDULE)) {
            case SCHEDULE:
                return selected.some((s) => item.taskTypes[s])
            case GROUP:
                return findInChildren(item, (c) => c.type === SCHEDULE || c.$?.type === SCHEDULE).some((item) =>
                    selected.some((s) => item?.taskTypes?.[s] || item?.$?.taskTypes?.[s])
                )
            default:
                break
        }
        switch (item.$?.type ?? SCHEDULE) {
            case SCHEDULE:
                return selected.some((s) => item.$.taskTypes[s])
            case GROUP:
                return findInChildren(item, (c) => c.type === SCHEDULE || c.$?.type === SCHEDULE).some((item) =>
                    selected.some((s) => item?.$?.taskTypes?.[s])
                )
            default:
                return true
        }
    }
})

export function ScheduleFilter() {
    const [{ filterModes }, save] = useReference({ filterModes: {} }, "filter")
    const [anchor, setAnchor] = useState(null)
    const ref = useRef()
    return (
        <>
            <Stack
                component={Button}
                className="schedule-filter"
                alignItems="center"
                onClick={open}
                sx={{
                    cursor: "pointer",
                    fontSize: "85%",
                    color: "text.primary",
                    width: "max-content",
                    flexWrap: "nowrap",
                }}
                role="menu"
                direction="row"
                spacing={1}
            >
                <Box sx={{ fontWeight: "bold" }}>Filter:</Box>
                <Box sx={{ fontWeight: "normal" }} ref={ref}>
                    {filterName()}
                </Box>
                <Box sx={{ fontSize: 24, lineHeight: 0 }}>
                    <Iconify icon="mdi:arrow-down-drop" />
                </Box>
            </Stack>
            <Portal>
                <Menu
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                    sx={{ zIndex: 3000 }}
                    onClose={close}
                    open={Boolean(anchor ? ref.current : null)}
                    anchorEl={anchor ? ref.current : null}
                >
                    <MenuItem selected={filterModes.Red} onClick={set("Red")}>
                        <Stack direction="row" spacing={1}>
                            <Classification classification="Red" />
                            <Box>Red</Box>
                        </Stack>
                    </MenuItem>
                    <MenuItem selected={filterModes.Pink} onClick={set("Pink")}>
                        <Stack direction="row" spacing={1}>
                            <Classification classification="Pink" />
                            <Box>Pink</Box>
                        </Stack>
                    </MenuItem>
                    <MenuItem selected={filterModes.Amber} onClick={set("Amber")}>
                        <Stack direction="row" spacing={1}>
                            <Classification classification="Amber" />
                            <Box>Amber</Box>
                        </Stack>
                    </MenuItem>
                    <MenuItem selected={filterModes.Green} onClick={set("Green")}>
                        <Stack direction="row" spacing={1}>
                            <Classification classification="Green" />
                            <Box>Green</Box>
                        </Stack>
                    </MenuItem>
                    <MenuItem selected={filterModes["+Unlicensed"]} onClick={set("+Unlicensed")}>
                        <Stack direction="row" spacing={1}>
                            <Iconify icon="tabler:license-off" sx={{ color: "primary.main", fontSize: 24, mx: 0.25 }} />
                            <Box>Include Unlicensed</Box>
                        </Stack>
                    </MenuItem>
                </Menu>
            </Portal>
        </>
    )

    function open() {
        setAnchor(ref.current)
    }

    function close() {
        setAnchor(null)
    }

    function set(value) {
        return function execute() {
            filterModes[value] = !filterModes[value]
            save({ filterModes })
            RefreshScheduleSelector.raiseOnce()
        }
    }

    function filterName() {
        const selected = Object.entries(filterModes)
            .filter(([, value]) => !!value)
            .map(([key]) => MAPPING[key] ?? key)
        if (selected.length === 0) return "None"
        return selected.join(", ")
    }
}
