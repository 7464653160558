import { HomePageTile } from "slot-definitions"
import { TimelineWidget } from "routes/timeline/timeline-widget"
import { appRoute } from "routes/app/home.runtime"
import { Lazy } from "lib/make-lazy"

appRoute.register(
    "timeline/:date",
    <Lazy importer={() => import("routes/timeline/timeline-route")} extract="TimelineRoute" />
)

HomePageTile.plug(<TimelineWidget />)
