import { useScheduleGroup } from "routes/facilities/lib/use-schedule-group"
import { Box, Button } from "@mui/material"
import { BoundTextEditor } from "minimals-template/components/editor"
import { useState } from "react"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function AdditionalInfo() {
    const name = useScheduleGroup()
    const { target, individualTask } = useBoundContext()
    const [show, setShow] = useState(!!target.introduction)
    return (
        !individualTask && (
            <Box p={1} pr={6}>
                {show ? (
                    <BoundTextEditor
                        updateOnBlur={true}
                        field="introduction"
                        label={`Additional introduction ${name ? "for" : ""} ${name}`}
                    />
                ) : (
                    <Button variant="outlined" size="small" onClick={() => setShow(true)}>
                        Add Introduction {!!name && "for"} {name}
                    </Button>
                )}
            </Box>
        )
    )
}
