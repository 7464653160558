import { Box, Chip, Stack, Typography } from "@mui/material"
import { Bound } from "lib/@components/binding/Bound"
import { BoundAutocomplete } from "lib/@components/binding/bound-components"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useBoundValue } from "lib/@components/binding/use-bound-value"
import { DialogBox } from "lib/@components/dialogBox"
import { ListItemBox } from "lib/@components/ListItemBox"
import { useRefresh } from "lib/@hooks/useRefresh"
import { htmlToText } from "lib/html-to-text"
import { BoundTextEditor } from "minimals-template/components/editor"
import { Fragment, useState } from "react"
import { ScrollIntoView } from "lib/@components/scrollIntoView"
import { UploadFileButton } from "lib/@components/upload-file-button"

export function ContactUsDialog() {
    const subjects = ["How do I", "New feature idea", "New content idea", "Question about content", "Other"]
    const [target] = useState({ message: "", subject: subjects[0], attachments: [] })
    const refresh = useRefresh()

    return (
        <Bound target={target} subjects={subjects} onChange={refresh}>
            <ContactUsContent />
        </Bound>
    )
}

export function ContactUsContent() {
    const { target, subjects } = useBoundContext()
    const [attachments, setAttachments] = useBoundValue("attachments", [])
    return (
        <DialogBox
            title="Contact Us"
            okCaption="Send"
            okIcon="ic:round-send"
            value={target}
            className="contactUsDialog"
            okDisabled={!htmlToText(target.message).trim()}
            sx={{ maxHeight: "725px", overflow: "hidden" }}
        >
            <Stack spacing={1} sx={{ mt: 2, flex: 1 }}>
                <BoundAutocomplete
                    id="contactUsSubjectAutocomplete"
                    options={subjects}
                    field="subject"
                    disableClearable
                />
                <BoundTextEditor
                    className="contactUsMessageEditor"
                    simple={true}
                    sx={{ flex: 1, "& .jodit-workplace": { height: "300px !important" } }}
                    field="message"
                />
                <Stack direction="row">
                    <Box flex={1} />
                    <UploadFileButton sx={{ minWidth: "max-content" }} onClick={handleAttachFile}>
                        Attach File
                    </UploadFileButton>
                </Stack>
                <ListItemBox>
                    {attachments.length > 0 && (
                        <Stack spacing={1}>
                            <Typography variant="caption">Attachments ({attachments.length}):</Typography>
                            {attachments.map((attachment, i) => (
                                <Fragment key={attachment.name + i}>
                                    <Chip
                                        sx={{ ml: 1, justifyContent: "space-between", width: "250px" }}
                                        onDelete={() => handleDeleteAttachment(attachment)}
                                        label={attachment.name}
                                        title={attachment.name}
                                    />
                                    <ScrollIntoView />
                                </Fragment>
                            ))}
                        </Stack>
                    )}
                </ListItemBox>
            </Stack>
        </DialogBox>
    )

    function handleAttachFile(url, file) {
        setAttachments((attachments) => [...attachments, { url, name: file.name }].unique("url"))
    }

    function handleDeleteAttachment(attachment) {
        setAttachments((attachments) => attachments.filter((a) => a !== attachment))
    }
}
