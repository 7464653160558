import { Box, Typography } from "@mui/material"
import DummyComponent from "lib/@components/debug-component"
import { openDialog } from "lib/@hooks/useDialog"
import { useClientInfo } from "minimals-template/components/contexts/NognitoContext"
import { useCurrentUser } from "lib/store/use-current-user"

export function Debug() {
    const user = useCurrentUser()
    const client = useClientInfo()
    return (
        <Box p={2}>
            <Typography variant="h6">Authorization</Typography>
            <DummyComponent {...window._authorization} />
            <Typography variant="h6">User</Typography>
            <DummyComponent {...user} />
            <Typography variant="h6">Client</Typography>
            <DummyComponent {...client} />
        </Box>
    )
}

export const debugInfo = () => openDialog(Debug, { maxWidth: "md" })
