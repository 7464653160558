import { ShareLinkActions, ShareLinkOtherSettings } from "slot-definitions"
import { Feature } from "lib/@components/feature"
import { registerFeature } from "lib/features"
import { DownloadRegimeAsXml } from "./download-regime-as-xml"
import { DownloadXmlShareLinkSetting } from "./download-xml-share-link-setting"
import { List, ListItem, ListItemText } from "@mui/material"

const featureFlagDesc = (
    <List disablePadding>
        <ListItem
            sx={{
                p: 0,
                pb: 1,
            }}
        >
            <ListItemText
                primary={`\u2022 Enables clients to download maintenance regimes as XML via a sharing link.`}
            />
        </ListItem>
        <ListItem
            sx={{
                p: 0,
                pb: 1,
            }}
        >
            <ListItemText primary={`\u2022 This feature is available via the add on product FIQXML.`} />
        </ListItem>
    </List>
)

export const FEATURE_FLAG_DOWNLOAD_XML = registerFeature({
    numericalId: 10,
    name: "Download XML",
    id: "download-xml",
    description: featureFlagDesc,
    readyForBeta: false,
    readyForProduction: true,
    internalUseOnly: false,
})

ShareLinkActions.plug(
    <Feature feature={FEATURE_FLAG_DOWNLOAD_XML} priority={60}>
        <DownloadRegimeAsXml />
    </Feature>
)

ShareLinkOtherSettings.plug(<DownloadXmlShareLinkSetting />)
