let dialogOpen = false

export function clearDialogs() {
    dialogOpen = false
}

export function setDialogOpen(isOpen) {
    dialogOpen = isOpen
}

export function isDialogOpen() {
    return dialogOpen
}
