import { Button, Tooltip } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { selectItems } from "routes/schedule/lib/useSelection"
import { BasketTopBar, TagTopBar } from "slot-definitions"
import { findBasket } from "routes/schedule/plugins/baskets/find-basket"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

if (process.env.REACT_APP_BOOLEAN_MODE) {
    TagTopBar.plug(<And />)
    TagTopBar.plug(<Or />)
    TagTopBar.plug(<Not />)
    TagTopBar.plug(<SelectAll />)
    BasketTopBar.plug(<And />)
    BasketTopBar.plug(<Or />)
    BasketTopBar.plug(<Not />)
    BasketTopBar.plug(<SelectAll />)
}

function SelectAll({ context: { shownItems } }) {
    const { parentId } = useBoundContext()
    if (!findBasket(parentId)?.children?.length) return null
    return (
        <Tooltip title="Select only these schedules">
            <Button
                variant="outlined"
                color="primary"
                onClick={selectOnly}
                startIcon={<Iconify icon="fluent-emoji-high-contrast:blue-square" />}
            >
                Select
            </Button>
        </Tooltip>
    )

    function selectOnly() {
        selectItems(() => shownItems.map("_id"))
    }
}

function And({ context: { shownItems } }) {
    const { parentId } = useBoundContext()
    if (!findBasket(parentId)?.children?.length) return null
    return (
        <Tooltip title="Select only schedules which are currently selected AND have this tag">
            <Button
                variant="outlined"
                color="primary"
                onClick={selectAnd}
                startIcon={<Iconify icon="fluent:shape-intersect-24-regular" />}
            >
                And
            </Button>
        </Tooltip>
    )

    function selectAnd() {
        selectItems((selected) => {
            const output = {}
            for (const shown of shownItems) {
                if (selected[shown._id]) {
                    output[shown._id] = selected[shown._id]
                }
            }
            return output
        })
    }
}

function Or({ context: { shownItems } }) {
    const { parentId } = useBoundContext()
    if (!findBasket(parentId)?.children?.length) return null
    return (
        <Tooltip title="Select schedules which are currently selected OR have this tag">
            <Button
                variant="outlined"
                color="primary"
                onClick={selectOr}
                startIcon={<Iconify icon="fluent:shape-union-24-regular" />}
            >
                Or
            </Button>
        </Tooltip>
    )

    function selectOr() {
        selectItems((selected) => {
            for (const shown of shownItems) {
                selected[shown._id] = selected[shown._id] || shown._id
            }
            return selected
        })
    }
}

function Not({ context: { shownItems } }) {
    const { parentId } = useBoundContext()
    if (!findBasket(parentId)?.children?.length) return null
    return (
        <Tooltip title="Select schedules which are currently selected but do not have this tag">
            <Button
                variant="outlined"
                color="primary"
                onClick={selectNot}
                startIcon={<Iconify icon="icon-park-outline:mask-two" />}
            >
                Not
            </Button>
        </Tooltip>
    )

    function selectNot() {
        selectItems((selected) => {
            for (const shown of shownItems) {
                delete selected[shown._id]
            }
            return selected
        })
    }
}
