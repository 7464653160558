import { useDialog } from "lib/@hooks/useDialog"
import { parentSome } from "library/tree"
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { createSubFolder } from "routes/schedule/folder-manager/controller/create-sub-folder"
import { ChooseFacilityOrSpace } from "routes/schedule/my-schedules/my-group-types/facility/chooseFacilityOrSpace"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useTreeItemIndex } from "routes/schedule/lib/useTreeItemIndex"

export function AddSpace({ content = "Space", subType = "space", icon = "fluent:conference-room-24-filled" }) {
    const { parentItem } = useBoundContext()
    const space = useDialog(<ChooseFacilityOrSpace type="space" />, {
        maxWidth: "md",
    })
    useTreeItemIndex()
    if (!parentSome(parentItem, (c) => c.data?.type === "facility")) {
        return null
    }
    return (
        <MenuItem onClick={add} color="primary">
            <ListItemIcon>
                <Iconify icon={icon} />
            </ListItemIcon>
            <ListItemText primary={content} />
        </MenuItem>
    )

    async function add() {
        const result = await space({ label: content })
        result.icon = icon
        result.subType = subType

        if (result) {
            await createSubFolder(parentItem, result.name, result)
        }
    }
}
