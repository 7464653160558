import { useParams } from "react-router-dom"
import { useTreeItem } from "routes/schedule/lib/useTreeItem"
import { Bound } from "lib/@components/binding/Bound"
import { usePlanFor } from "routes/plan/controller/use-plan"
import { useRefresh } from "lib/@hooks/useRefresh"
import { Lazy } from "lib/make-lazy"
import { PlanToolbar } from "slot-definitions"
import { BoundSimpleCombo } from "lib/@components/combos/boundSimpleCombo"

export function Plan() {
    const { id } = useParams()

    return (
        <Bound treeId={id}>
            <PlanFor id={id} />
        </Bound>
    )
}

export function PlanFor({ id, published, PlanContainer }) {
    const parentItem = useTreeItem(id)
    const [plan, save] = usePlanFor(parentItem, published)
    const refresh = useRefresh(save)
    return (
        !!parentItem &&
        !!plan && (
            <Bound target={plan} plan={plan} onChange={save} parentItem={parentItem} refresh={refresh}>
                <Lazy
                    importer={() => import("routes/plan/innerPlan")}
                    extract="InnerPlan"
                    PlanContainer={PlanContainer}
                />
            </Bound>
        )
    )
}

PlanToolbar.plug(<MinimumTime />)

function MinimumTime() {
    return (
        <>
            <BoundSimpleCombo
                variant="outlined"
                color="primary"
                label=" "
                field="minimumHours"
                defaultValue={24}
                sort="0"
                options={{
                    1: "Tasks An hour or more apart (show 1 per day)",
                    24: "Tasks a day or more apart",
                    36: "Tasks 3 days or more apart",
                    [24 * 7 - 1]: "Tasks a week or more apart",
                    [24 * 7 * 4 - 1]: "Tasks a month or more apart",
                }}
            />
        </>
    )
}
