import { useState } from "react"
import { BusySystem } from "event-definitions"
import { Box, Typography, useTheme } from "@mui/material"
import Logo from "minimals-template/components/Logo"
import { instanceId } from "lib/graphql/instance-id"
import LinearProgress from "@mui/material/LinearProgress"

BusySystem.handleOnce((...params) => console.log("BS", ...params))

export function BusyOverall() {
    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState("")
    const [progress, setProgress] = useState()
    const theme = useTheme()
    BusySystem.useEvent(
        ({ running, message, progress }) => {
            setVisible(running)
            setMessage(message)
            setProgress(!!running && progress)
        },
        [instanceId]
    )

    return (
        !!visible && (
            <Box
                position="fixed"
                left={0}
                top={0}
                right={0}
                bottom={0}
                bgcolor={`${theme.palette.background.paper}c0`}
                sx={{ zIndex: 1000000, backdropFilter: "blur(5px)" }}
            >
                <Box p={4}>
                    <Logo />
                </Box>
                <Box position="absolute" left="50%" top="50%" sx={{ transform: "translate3d(-50%, -50%, 0)" }}>
                    <Typography variant="h4" color="text.secondary">
                        {message}
                    </Typography>
                </Box>
                {progress !== undefined && (
                    <Box
                        sx={{
                            position: "absolute",
                            bottom: 64,
                            left: "50%",
                            width: "70vw",
                            transform: "translate3d(-50%, -50%, 0)",
                        }}
                    >
                        <LinearProgress variant="determinate" value={progress} />
                    </Box>
                )}
            </Box>
        )
    )
}
