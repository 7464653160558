import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"

export const fubspotEnabled = api((id) =>
    query(
        gql`
            query FubspotEnabled {
                fubspotEnabled
            }
        `,
        { id },
        { returns: "fubspotEnabled" }
    )
)
