/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"

export function useMounted(fn) {
    useEffect(() => {
        fn()
    }, [])
}

export function useUnmounted(fn) {
    useEffect(() => fn, [])
}
