import { ScheduleHeaderToolbar } from "slot-definitions"
import { useParams } from "react-router-dom"
import { Button } from "@mui/material"
import { gql } from "@apollo/client"
import { confirm } from "lib/@dialogs/confirm"
import { UnlockDraft } from "event-definitions"
import { MY_LIST } from "routes/schedule/my-schedules/my-custom-schedules/lib/constants"

import { navigate } from "lib/routes/navigate"
import { mutate } from "lib/graphql/mutate"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { getScheduleRecordId } from "library/get-unique-id-from-tree"

ScheduleHeaderToolbar.plug(<DeleteButton priority={1025} />)

export async function deleteDraft(id) {
    return mutate(
        gql`
            mutation delete($id: String!) {
                deleteDraft(id: $id)
            }
        `,
        { id },
        { returns: "deleteDraft" }
    )
}

export function DeleteButton() {
    const { id: paramId } = useParams()
    const { editMode } = useBoundContext()

    return (
        editMode && (
            <Button color="secondary" onClick={remove}>
                Delete
            </Button>
        )
    )

    async function remove() {
        if (await confirm("Are you sure?", "Delete Draft")) {
            UnlockDraft.raise()
            await deleteDraft(getScheduleRecordId(paramId))
            navigate(`/app/schedules?id=${MY_LIST}`, { replace: true })
        }
    }
}
