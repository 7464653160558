import { Box, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress"

export function PercentageBar({ text, partialValue, fullValue, color = "primary", dataCyPrepend = "" }) {
    const ColouredLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 7,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 700],
        },
        [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: theme.palette[color].main,
        },
    }))

    const value = Math.round((100 * partialValue) / fullValue)

    return (
        <Box py={1} data-cy={`${dataCyPrepend}PercentageBar`}>
            <Box sx={{ display: "flex" }}>
                <Typography variant="subtitle2">{text}</Typography>
                <Box flex={1} />
                <Typography variant="subtitle2" mr={0.5}>
                    {partialValue}
                </Typography>
                <Typography variant="body2">{`(${value}%)`}</Typography>
            </Box>
            <ColouredLinearProgress variant="determinate" value={value} />
        </Box>
    )
}
