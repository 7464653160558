import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const myConformityDocuments = api(function myConformityDocuments(
    onlyMine = false,
    includeSubmitted = false,
    includeDraft = true
) {
    return query(
        gql`
            query GetMyConformityDocuments($includeSubmitted: Boolean, $includeDraft: Boolean, $onlyMine: Boolean) {
                myConformityDocuments(
                    includeDraft: $includeDraft
                    includeSubmitted: $includeSubmitted
                    onlyMine: $onlyMine
                ) {
                    _id
                    modified
                    dateOfVisit
                    facility
                    submitted
                    jobId
                }
            }
        `,
        { includeSubmitted, includeDraft, onlyMine },
        { returns: "myConformityDocuments" }
    )
})
