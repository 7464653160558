import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"

export async function addItemsToBasket(id, items) {
    await mutate(
        gql`
            mutation addItems($id: String!, $items: [BasketItemInput]!) {
                addItemsToBasket(id: $id, items: $items)
            }
        `,
        {
            id,
            items,
        }
    )
}
