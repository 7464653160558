import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getSkillingData = api(async function getSkillingData(ids) {
    return query(
        gql`
            query GetSkillingData($ids: [String]!) {
                getSkillingData(ids: $ids) {
                    SkillingID
                    Skilling
                    SkillingCode
                    SkillingType
                }
            }
        `,
        { ids },
        { returns: "getSkillingData" }
    )
})
