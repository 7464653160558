import { CurrentClientChanged, Navigated } from "event-definitions"
import { noChange } from "lib/@hooks/useRefresh"
import { getGroupPayload } from "routes/schedule/controller/get-group-payload"
import { withComparison } from "lib/graphql/comparison-function"
import { isSame } from "library/normalized-stringify"
import { ONCE } from "lib/graphql/api"
import noop from "lib/noop"

export function usePayload(group, key, tree) {
    CurrentClientChanged.useRefresh(noChange)

    const [result, refetch] = getGroupPayload.useResults.withRefetch(
        group,
        key,
        tree,
        ONCE,
        withComparison((a, b) => isSame(a, b))
    ) ?? [null, noop]
    Navigated.after.useEvent(refetch, [refetch])
    return result
}
