import { omit } from "lib/graphql/omit"
import { getApolloClient } from "lib/graphql/client"

export async function mutate(mutation, variables = {}, options = {}) {
    const { data, error } = await getApolloClient().mutate({ mutation, variables, ...omit(options, "returns") })
    if (error) {
        console.error(error)
        throw error
    }
    if (!options.returns) {
        return data
    }
    return Object.get(data, options.returns, true)
}
