import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const downloadRegimeAsXml = api((shareLinkId, contentType, scheduleGroup) =>
    query(
        gql`
            query DownloadRegimeAsXml(
                $shareLinkId: String!
                $contentType: ContentTypeOption!
                $scheduleGroup: ScheduleGroupOption!
            ) {
                downloadRegimeAsXml(shareLinkId: $shareLinkId, contentType: $contentType, scheduleGroup: $scheduleGroup)
            }
        `,
        { shareLinkId, contentType, scheduleGroup },
        { fetchPolicy: "network-only", returns: "downloadRegimeAsXml" }
    )
)
