import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useEffect, useMemo, useRef } from "react"
import { Box } from "@mui/material"
import { PortalUpdated } from "lib/@components/request-page-repeat"
import { createEvent } from "library/local-events"

export const PaginationPortalLoaded = createEvent("PaginationPortalLoaded")

export function PaginationPortal(props) {
    const context = useBoundContext()
    const portal = useRef()
    useMemo(() => {
        context.paginationPortal = false
    }, [context])

    useEffect(() => {
        context.paginationPortal = portal.current
        PaginationPortalLoaded.raise()
    }, [context])

    return <Box ref={setPortal} flexShrink={0} {...props} />

    function setPortal(value) {
        if (!value) return
        context.paginationPortal = value
        portal.current = value
        PortalUpdated.raise()
    }
}
