import "./editor"
import "./display"
import "./add-schedule-to-regime"
import { ScheduleItemCode, ScheduleItemMainExtra, ScheduleItemRight } from "slot-definitions"
import { ScheduleCode, ScheduleStatus } from "routes/regime/schedule-code"
import { isInRegime } from "routes/facilities/lib/when-parent-item"
import { and } from "lib/logic"
import { replaceAllWith } from "lib/@components/slot/replace-all-with"
import { Frag } from "lib/@components/slot/frag"

ScheduleItemCode.plug(replaceAllWith(<Frag predicate={isInRegime} />))
ScheduleItemRight.plug(<ScheduleStatus if={and(isInRegime, (c) => !c.facility)} priority={150} />)
ScheduleItemMainExtra.plug(<ScheduleCode priority={200} if={and(isInRegime, (c) => !c.facility)} />)
