import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"

export const getScheduleTypes = api(() =>
    query(
        gql`
            query GetScheduleTypes {
                getScheduleTypes {
                    id: scheduleTypeId
                    name
                }
            }
        `,
        {},
        { returns: "getScheduleTypes" }
    )
)
