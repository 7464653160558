import { OpenDialog } from "event-definitions"
import { nanoid } from "nanoid"
import { AlertDialog } from "lib/@dialogs/alert-dialog"

/**
 * Renders a message and waits for a user to acknowledge
 * @param {string} message - the message to confirm
 * @param {string} title - the title of the confirmation box
 * @param severity
 * @returns {Promise<boolean>} the selection the user made
 */
export function alert(message, title = "", severity = undefined) {
    return new Promise((resolve) => {
        OpenDialog.raise({
            dialogProps: {
                maxWidth: "sm",
                fullWidth: true,
                boost: "boost",
                sx: {
                    bgcolor: (theme) =>
                        severity
                            ? theme.palette.mode === "dark"
                                ? `${severity}.darker`
                                : `${severity}.lighter`
                            : undefined,
                },
            },
            dialogId: nanoid(),
            content: <AlertDialog message={message} title={title} severity={severity} />,
            resolve,
        })
    })
}
