import { Bound } from "lib/@components/binding/Bound"
import { Box } from "@mui/material"
import { Outlet } from "react-router-dom"
import { register } from "lib/routes/register"
import { useTreeRoots } from "routes/schedule/lib/useTreeRoots"

export const pdfRoute = (window.PDFRoute = register("/pdf", <PDFLayout />))

function PDFLayout() {
    /* on or off */
    useTreeRoots()
    return (
        <Bound isPDF={true}>
            <Box
                sx={{
                    display: { lg: "flex" },
                    minHeight: { lg: 1 },
                }}
            >
                <Outlet />
            </Box>
        </Bound>
    )
}
