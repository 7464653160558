import { Box } from "@mui/material"
import { ScheduleIntroduction } from "routes/schedule/scheduleIntroduction"
import { ScheduleRightBar, ScheduleSummaryAdaptor } from "slot-definitions"
import { PrintableStack } from "lib/@components/pdf/printable-stack"
import { Bound } from "lib/@components/binding/Bound"
import { Watermark } from "lib/@components/watermark/watermark"
import { TaskList } from "./scheduleDetails"
import { SchedulePDFHeader } from "./schedule-pdf-header"

export function SchedulePDFDisplay({ schedule, id }) {
    return (
        <Bound key={id}>
            <PrintableStack key={schedule._id}>
                {!!schedule.retired && <Watermark text="Retired" sx={{ position: "fixed" }} />}
                <Box
                    className="schedule-details styled-bullets"
                    sx={{
                        overflowX: "hidden",
                        pr: 0,
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <SchedulePDFHeader schedule={schedule} />
                    <ScheduleIntroduction schedule={schedule} />
                    <TaskList schedule={schedule} />
                    <Box className="page-break" />
                    <ScheduleSummaryAdaptor.Wrapper>
                        <ScheduleRightBar.Slot Container={<Box className="one-page" />} />
                    </ScheduleSummaryAdaptor.Wrapper>
                </Box>
                <Box className="pdf-content-loaded" />
            </PrintableStack>
        </Bound>
    )
}
