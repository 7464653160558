import { SharingFilters } from "slot-definitions"
import { Filter } from "routes/sharing/plugins/filters/filter"
import Iconify from "minimals-template/components/Iconify"

SharingFilters.plug(
    <Filter filterName="active" tip="Enabled">
        <Iconify sx={{ width: 24, height: 24 }} icon="humbleicons:switch-on" />
    </Filter>
)
SharingFilters.plug(
    <Filter filterName="inactive" tip="Disabled">
        <Iconify sx={{ width: 24, height: 24 }} icon="humbleicons:switch-off" />
    </Filter>
)

SharingFilters.plug(
    <Filter filterName="inDate" tip="Currently available due to date">
        <Iconify sx={{ width: 24, height: 24 }} icon="clarity:calendar-solid" />
    </Filter>
)
SharingFilters.plug(
    <Filter filterName="outOfDate" tip="Currently unavailable due to date">
        <Iconify sx={{ width: 24, height: 24 }} icon="clarity:calendar-line" />
    </Filter>
)
