import { useTreeItem } from "routes/schedule/lib/useTreeItem"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function useTreeItemForConformity(id) {
    const treeId = useIdScopedByTreeId(id)
    return useTreeItem(treeId)
}

export function useIdScopedByTreeId(id) {
    const { treeId } = useBoundContext()
    if (!treeId) throw new Error("Must have a treeId in the bound context")
    const parts = id.split("!")
    return `${parts.at(-2)}~${treeId}!${parts.at(-1)}`
}
