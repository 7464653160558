import { Box } from "@mui/material"
import { PropertyBox } from "lib/@components/property-box"
import { ListItemBox } from "lib/@components/ListItemBox"
import { useEffect } from "react"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import Iconify from "minimals-template/components/Iconify"

export function HeaderBar({ label, children, compare = () => true, extra = null }) {
    const { taskStats, side } = useBoundContext()
    const comparison = compare()
    taskStats.lastTask = label
    taskStats.lastComparison = comparison
    useEffect(() => {
        taskStats.lastTask = null
    })
    return (
        <>
            {extra}
            <ListItemBox
                sx={{ position: "relative" }}
                className={`header-bar ${label?.toLowerCase().replaceAll(" ", "-")}`}
            >
                {!!side && (
                    <Box sx={{ fontSize: 40, color: "text.secondary" }}>
                        {side === "left" ? <Iconify icon="mdi:arrow-left" /> : <Iconify icon="mdi:arrow-right" />}{" "}
                    </Box>
                )}
                <PropertyBox>{children}</PropertyBox>
            </ListItemBox>
        </>
    )
}
