import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"

export const getAuditsForTypeAllClients = api(function getAuditsForTypeAllClients(type) {
    return query(
        gql`
            query GetAuditsForTypeAllClients($type: String) {
                getAuditsForTypeAllClients(type: $type) {
                    id
                    shortDescription
                    longDescription
                    referenceId
                    type
                    date
                    userId
                    userName
                }
            }
        `,
        { type },
        { returns: "getAuditsForTypeAllClients" }
    )
})
