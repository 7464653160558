import noop from "lib/noop"
import { cloneDeep } from "@apollo/client/utilities"
import { isEqual } from "lib/isEqual"
import { raiseOnce } from "library/local-events"
import { inTransition, useRefresh } from "lib/@hooks/useRefresh"
import { useEvent } from "lib/@hooks/useEvent"

import { useCallback } from "react"

export function useMemoryReference(initial = {}, type = "default", ...otherTypes) {
    if (typeof initial === "string") {
        type = initial
        initial = {}
    }
    const refresh = useRefresh(inTransition)
    useEvent(`MemoryReference.Updated.*`, function handleEvent() {
        if (this.event === `MemoryReference.Updated.${type}`) {
            refresh()
            return
        }
        if (otherTypes.some((type) => this.event === `MemoryReference.Updated.${type}`)) {
            refresh()
        }
    })
    const output = Object.merge({ ...initial }, memoryReferenceStorage)
    const typesKey = JSON.stringify(otherTypes)
    const save = useCallback(
        (item = {}) => {
            setMemoryReference(item, type, () => {
                for (const other of otherTypes) {
                    raiseOnce(`MemoryReference.Updated.${other}`)
                }
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [memoryReferenceStorage, type, typesKey]
    )
    const saveAll = useCallback(
        (fn) => {
            setMemoryReference(fn, type, () => {
                for (const other of otherTypes) {
                    raiseOnce(`MemoryReference.Updated.${other}`)
                }
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [memoryReferenceStorage, type, typesKey]
    )
    return [output, save, saveAll]
}

let memoryReferenceStorage = {}

export function setMemoryReference(item = {}, type = "default", wasDifferent = noop) {
    if (typeof item === "function") {
        const output = cloneDeep(memoryReferenceStorage)
        const newValue = item(output) ?? output
        if (!isEqual(memoryReferenceStorage, newValue)) {
            memoryReferenceStorage = { ...newValue }
            if (type !== false) {
                raiseOnce(`MemoryReference.Updated.${type}`)
                wasDifferent()
            }
        }
    } else {
        const reference = cloneDeep(memoryReferenceStorage)
        const newValue = { ...(reference || item), ...item }
        if (!isEqual(newValue, reference)) {
            memoryReferenceStorage = cloneDeep(newValue)
            if (type !== false) {
                raiseOnce(`MemoryReference.Updated.${type}`)
                wasDifferent()
            }
        }
    }
}

export function getMemoryReference(initial = {}) {
    return Object.merge({ ...initial }, memoryReferenceStorage)
}
