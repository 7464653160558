import { PageContainer } from "lib/@components/page-container"
import { Link } from "@mui/material"
import { companionAppRoute } from "companion/routes/companion-app-route"

companionAppRoute.register(
    "testing",
    <PageContainer title="Test Links">
        <Link href="sfg20://sfg20?phrase=ashen+evergreen+dainty">Open a task</Link>
    </PageContainer>
)
