import { Paragraph, Table, TableCell, TableRow } from "docx"
import { textFromHtmlNode } from "../text-from-html-node"
import { formatTextAndAddToParagraph } from "../format-text-and-add-to-paragraph"

export function formatTable(node, key) {
    const table = node.children
    const rows = table[0].children
    const docRows = []

    Array.from(rows).forEach((r) => {
        const docCells = []

        Array.from(r.children).forEach((c) => {
            const paragraph = new Paragraph({})

            const textArray = textFromHtmlNode(c)
            formatTextAndAddToParagraph(paragraph, textArray, key)
            const cell = new TableCell({
                children: [paragraph],
            })
            docCells.push(cell)
        })

        const newRow = new TableRow({
            children: docCells,
        })

        docRows.push(newRow)
    })

    const docTable = new Table({
        rows: docRows,
    })

    return docTable
}
