import { Box, ListItemButton } from "@mui/material"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { prevent } from "lib/prevent"
import { ListItemBox } from "lib/@components/ListItemBox"
import { FolderItemPart } from "routes/schedule/tree/schedules/folder-item-part"
import { FolderIcon } from "routes/schedule/tree/schedules/folder-icon"

export function ReadyTree({ last, Component = ListItemButton }) {
    const { onClick, selected, noDivider } = useBoundContext()
    last = last || noDivider
    return (
        <Component divider={!last} selected={selected} onClick={prevent(onClick)}>
            <ListItemBox spacing={1}>
                <FolderItemPart />
                <FolderItemPart>
                    <FolderIcon icon="icon-park-outline:spanner" sx={{ color: "myStuff.main" }} />
                </FolderItemPart>
                <Box flex={1}>Published Regime</Box>
            </ListItemBox>
        </Component>
    )
}
