export function set(object, path, value) {
    if (!path || !object) return
    const parts = path.split(".")
    for (let i = 0, l = parts.length - 1; i < l; i++) {
        const part = parts[i]
        if (object[part] && !Object.prototype.hasOwnProperty.call(object, part)) {
            object[part] = JSON.parse(JSON.stringify(object[part]))
        }

        if (!object[part]) {
            // eslint-disable-next-line no-multi-assign
            object = object[part] = {}
        } else {
            object = object[part]
        }
    }
    object[parts[parts.length - 1]] = value
}
