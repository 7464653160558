import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getTechnicalUpdateBulletin = api(async function getTechnicalUpdateBulletin(id, type) {
    return query(
        gql`
            query GetTechnicalUpdateBulletin($id: String, $type: String!) {
                getTechnicalUpdateBulletin(id: $id, type: $type) {
                    id
                    content
                    date
                    title
                    portedFromLegacy
                    type
                }
            }
        `,
        { id, type },
        { returns: "getTechnicalUpdateBulletin" }
    )
})
