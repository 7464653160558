import { memo, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
// @mui
import { Button, ClickAwayListener, InputAdornment, Slide, TextField } from "@mui/material"
import { styled } from "@mui/material/styles"
// utils
// components
import { SearchFor } from "event-definitions"
import { selectAll } from "lib/SelectAll"
import { setFromEvent } from "lib/setFromEvent"
import { IconButtonAnimate } from "minimals-template/components/animate"
import Iconify from "minimals-template/components/Iconify"
import cssStyles from "minimals-template/components/utils/cssStyles"
import { SearchBar } from "slot-definitions"
import { useMobile } from "lib/@components/mobile"
//

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64
const APPBAR_DESKTOP = 92

const SearchbarStyle = styled("div")(({ theme }) => ({
    ...cssStyles(theme).bgBlur(),
    top: 0,
    left: 0,
    zIndex: 99,
    width: "100%",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    height: APPBAR_MOBILE,
    padding: theme.spacing(0, 3),
    boxShadow: theme.customShadows.z8,
    [theme.breakpoints.up("md")]: {
        height: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}))

// ----------------------------------------------------------------------

function Searchbar() {
    const { pathname } = useLocation()
    const isMobile = useMobile()
    const [open, setOpen] = useState(false)

    const [searchQuery, setSearchQuery] = useState("")

    useEffect(() => {
        setSearchQuery("")
        if (open) {
            setOpen(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div>
                {!open && (
                    <IconButtonAnimate data-cy="general-search-button" onClick={() => setOpen((prev) => !prev)}>
                        <Iconify icon={"eva:search-fill"} width={20} height={20} />
                    </IconButtonAnimate>
                )}

                <Slide direction="down" in={open} mountOnEnter unmountOnExit>
                    <SearchbarStyle>
                        <SearchBar.Slot>
                            <TextField
                                fullWidth
                                autoFocus
                                onFocus={selectAll}
                                variant="standard"
                                value={searchQuery}
                                onChange={setFromEvent(setSearchQuery)}
                                placeholder={
                                    isMobile ? "Search..." : "Search for words, or a Uniclass, SFG20 or NRM code..."
                                }
                                onKeyUp={handleKeyUp}
                                InputProps={{
                                    disableUnderline: true,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Iconify
                                                icon="eva:search-fill"
                                                sx={{ color: "text.disabled", width: 20, height: 20 }}
                                            />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button onClick={search} color="primary">
                                                Search
                                            </Button>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </SearchBar.Slot>
                    </SearchbarStyle>
                </Slide>
            </div>
        </ClickAwayListener>
    )

    function search() {
        SearchFor.raise(searchQuery)
        setOpen(false)
    }

    function handleKeyUp(event) {
        if (event.key === "Enter") search()
    }
}

export default memo(Searchbar)

// ----------------------------------------------------------------------
