/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useCallback, useContext, useEffect, useState } from "react"
import noop from "lib/noop"
import { normalizedStringify } from "library/normalized-stringify"
import { clone } from "lib/clone"
import { UndoRedoChange } from "event-definitions"

export const UndoContext = createContext()

/**
 * Provides access to an UndoContext
 * @returns {unknown}
 */
export function useUndoContext() {
    return useContext(UndoContext) ?? {}
}

/**
 * Provided undo/redo support on a POJO
 * @param {object} initialTarget
 * @param ref
 * @param {function} change - a function to be called when there is a change (perhaps to set a dirty flag or update buttons)
 * @param {number} debounce=1000 - the amount of time to debounce between undo buffer entries
 * @returns {[object,{canUndo: boolean, undo: ((function(): void)|*), onChange: ((function(): void)|*), redo: ((function(): void)|*), canRedo: boolean}]} an array with the first element being the current state of the data and the second being a series of functions to undo, redo, flag change and test the state of the buffer
 */
export function useUndo({ initialTarget, ref = "standard", change = noop, debounce = 1000 }) {
    const [undoBuffer, setBuffer] = useState(() => ({
        lastTimeAddedUndo: 0,
        block: false,
        blockTimer: 0,
        initializeBuffer: true,
        list: [],
        current: null,
        position: -1,
        max: -1,
    }))
    const [target, setTarget] = useState(null)
    useEffect(() => {
        const source = clone(initialTarget ? clone(initialTarget) : null)
        const newBuffer = {
            lastTimeAddedUndo: 0,
            block: false,
            blockTimer: 0,
            initializeBuffer: true,
            list: [],
            current: source,
            position: -1,
            max: -1,
        }
        setBuffer(newBuffer)
        setTarget(source)
    }, [initialTarget])

    const addUndo = useCallback(doAddUndo, [undoBuffer, ref])
    const undo = useCallback(doUndo, [undoBuffer, ref])
    const redo = useCallback(doRedo, [undoBuffer, ref])
    const onChange = useCallback(doChange, [undoBuffer, ref])
    const canUndo = useCallback(doCanUndo, [undoBuffer, ref])
    const canRedo = useCallback(doCanRedo, [undoBuffer, ref])

    useEffect(() => {
        addUndo(undoBuffer.current)
    }, [addUndo])

    return [target, { onChange, undo, redo, canUndo, canRedo }]

    function doAddUndo(target) {
        if (!target || undoBuffer.block) return

        const current = normalizedStringify(target)

        if (undoBuffer.list[undoBuffer.position] === current) {
            return
        }
        if (!undoBuffer.initializeBuffer) change(target)
        if (undoBuffer.initializeBuffer) {
            setTimeout(() => {
                undoBuffer.initializeBuffer = false
            }, 100)
        }
        if (Date.now() - undoBuffer.lastTimeAddedUndo < debounce) {
            undoBuffer.list[undoBuffer.position] = current
        } else {
            undoBuffer.list[undoBuffer.position + 1] = current
            undoBuffer.position += 1
            undoBuffer.max = undoBuffer.position
            UndoRedoChange.raise(undoBuffer)
        }
        undoBuffer.lastTimeAddedUndo = Date.now()
    }

    function doUndo() {
        if (undoBuffer.position < 1) return
        blockAdding()
        undoBuffer.current = JSON.parse(undoBuffer.list[--undoBuffer.position])
        setTarget(undoBuffer.current)
        change()
    }

    function blockAdding() {
        undoBuffer.block = true
        clearTimeout(undoBuffer.blockTimer)
        undoBuffer.blockTimer = setTimeout(() => {
            undoBuffer.block = false
        }, 100)
    }

    function doRedo() {
        if (undoBuffer.position >= undoBuffer.max) return
        blockAdding()
        undoBuffer.current = JSON.parse(undoBuffer.list[++undoBuffer.position])
        setTarget(undoBuffer.current)
        change()
    }

    function doCanUndo() {
        return undoBuffer.position > 0
    }

    function doCanRedo() {
        return undoBuffer.position < undoBuffer.max
    }

    function doChange() {
        doAddUndo(undoBuffer.current)
    }
}
