import { AuditEntry, AuditLogEntry } from "slot-definitions"
import { getAuditPayload } from "routes/audit/controller/get-audits-by-reference-id"
import { IconButton } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { navigate } from "lib/routes/navigate"
import { replaceAllWithPredicate } from "lib/@components/slot/replace-all-with-predicate"
import { Frag } from "lib/@components/slot/frag"

AuditEntry("customSchedule").plug(<ShowInfo />)

function ShowInfo({ id, referenceId }) {
    const payload = getAuditPayload.useResults(id)
    const [scheduleId] = referenceId.split("-version:")
    return (
        !!payload?.previous && (
            <IconButton color="primary" size="small" onClick={goToCompare}>
                <Iconify icon="ic:outline-remove-red-eye" />
            </IconButton>
        )
    )

    function goToCompare() {
        navigate(
            `/app/schedules/compare/scheduleHistory!${scheduleId}-${payload.previous}/audit!${id}?right=Review Version&left=Previously Published Version`
        )
    }
}

AuditLogEntry("customSchedule").plug(replaceAllWithPredicate(onlyIfPublished, <Frag />))

function onlyIfPublished(_, { group }) {
    return !group?.content.some((c) => c.desc.toLowerCase().includes("published"))
}
