import { useMemo, useRef } from "react"
import { useRefresh } from "lib/@hooks/useRefresh"
import { TextField } from "@mui/material"

export function DebouncedTextField({ time = 600, value, onChange, ...props }) {
    const timer = useRef()
    const text = useRef(value)
    const refresh = useRefresh()
    useMemo(() => {
        text.current = value
    }, [value])

    return <TextField value={text.current} onChange={change} {...props} />

    function change(event) {
        text.current = event.target.value
        refresh()
        clearTimeout(timer.current)
        timer.current = setTimeout(() => {
            onChange({ target: { value: text.current } }, text.current)
        }, time)
    }
}
