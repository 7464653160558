import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"

import { makeBusyFunction } from "lib/@components/busy/make-busy-function"

export const importLegacyServiceModels = makeBusyFunction(
    api((subscriptionId, selectedIds) =>
        mutate(
            gql`
                mutation ImportLegacyServiceModels($subscriptionId: Int!, $selectedIds: [Int!]!) {
                    importLegacyServiceModels(subscriptionId: $subscriptionId, selectedIds: $selectedIds)
                }
            `,
            { subscriptionId, selectedIds },
            { returns: "importLegacyServiceModels" }
        )
    ),
    "Import Legacy Service Models"
)
