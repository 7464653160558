import { FolderContextMenu } from "routes/schedule/folder-manager/lib/folder-context-menu"
import { parentSome } from "library/tree"
import { sortables } from "routes/facilities/facility-page"
import { CreateSpace } from "routes/schedule/my-schedules/my-group-types/space/createSpace"
import { EditSpace } from "routes/schedule/my-schedules/my-group-types/space/editSpace"
import AppWelcome from "minimals-template/pages/sections/@dashboard/general/app/AppWelcome"
import { Box, Button, Stack } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { RegimeWelcome } from "routes/schedule/folder-manager/folder-management"
import { CanContainGroup, ScheduleFolderIcon } from "event-definitions"
import { and } from "lib/logic"
import { isInEditableRegime, isInPublishedRegime, isInRegimeFolder, not } from "routes/facilities/lib/when-parent-item"
import { PlanRoot } from "library/tree/constants"
import { replaceAllWith } from "lib/@components/slot/replace-all-with"
import { FACILITY, GROUP, SIMPLE, SPACE } from "library/constants"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

FolderContextMenu.plug(convertToSpace)
// Specify a character order for spaces
sortables.space = "M"
CanContainGroup.handleOnce((props) => {
    props.canContain &&= props.source.data?.type !== SPACE || parentSome(props.target, (i) => i.data?.type === FACILITY)
})

CanContainGroup.handleOnce((props) => {
    props.canContain &&= !props.target.isSmart
})

function convertToSpace({ context }) {
    return (
        context.parentItem &&
        !context.parentItem[PlanRoot] &&
        !isInPublishedRegime(context) &&
        (!context.parentItem.data?.type ||
            context.parentItem.data?.type === SIMPLE ||
            context.parentItem.data?.type === GROUP) &&
        parentSome(context.parentItem, (i) => i.data?.type === FACILITY) && <CreateSpace priority={1000} />
    )
}

FolderContextMenu.plug(<EditSpace if={and(isInEditableRegime, isInRegimeFolder, not(isInPublishedRegime))} />)

RegimeWelcome(SPACE).plug(replaceAllWith(<Welcome />))
RegimeWelcome(SIMPLE).plug(replaceAllWith(<WelcomeFolder />))

function Welcome({ toAdd, addSchedules }) {
    const { parentItem } = useBoundContext()

    const folderIcon = ScheduleFolderIcon.call({ icon: undefined, data: parentItem })

    const { icon } = folderIcon.icon.props
    return (
        <AppWelcome
            title={parentItem.data?.subType?.titleize()}
            img={
                <Box position="relative" overflow="hidden" flexGrow={1} width={150} height={160}>
                    <Box position="absolute" right={20} zIndex={200} opacity={0.5}>
                        <Iconify
                            sx={{
                                width: 90,
                                height: 90,
                                opacity: 0.3,
                                mr: 3,
                                transform: "rotate(15deg) scale(1.7) translateY(25px)",
                                color: "primary.dark",
                            }}
                            icon={icon}
                        />
                    </Box>
                </Box>
            }
            action={
                <Stack direction="row" spacing={1}>
                    {!!toAdd.length && (
                        <Button variant="contained" onClick={addSchedules}>
                            Add {toAdd.length} Selected Schedule{toAdd.length !== 1 ? "s" : ""}
                        </Button>
                    )}
                </Stack>
            }
            description={
                <>
                    <Box>
                        A {parentItem.data.subType} is used as a way to structure your maintenance regime, making it
                        easier to identify where items live and to represent real-life locations. Subfolders can be
                        inserted below this one, allowing for a tree structure with parent-child relationships. When
                        editing this {parentItem.data.subType} extra information such as location, contacts and
                        documents can be entered.
                    </Box>
                    <Box mt={1}>
                        Add some schedules to this {parentItem.data.subType} by selecting them and clicking on the Add
                        button on the toolbar.
                    </Box>
                </>
            }
        />
    )
}

function WelcomeFolder({ toAdd, addSchedules }) {
    const { parentItem } = useBoundContext()

    const folderIcon = ScheduleFolderIcon.call({ icon: undefined, data: parentItem })
    const { icon } = folderIcon.icon.props
    const parentItemType = parentItem.data.subType ?? "folder"
    return (
        <AppWelcome
            title={parentItemType.titleize()}
            img={
                <Box position="relative" overflow="hidden" flexGrow={1} width={150} height={160}>
                    <Box position="absolute" right={20} zIndex={200} opacity={0.5}>
                        <Iconify
                            sx={{
                                width: 90,
                                height: 90,
                                opacity: 0.3,
                                mr: 3,
                                transform: "rotate(15deg) scale(1.7) translateY(25px)",
                                color: "primary.dark",
                            }}
                            icon={icon}
                        />
                    </Box>
                </Box>
            }
            action={
                <Stack direction="row" spacing={1}>
                    {!!toAdd.length && (
                        <Button variant="contained" onClick={addSchedules}>
                            Add {toAdd.length} Selected Schedule{toAdd.length !== 1 ? "s" : ""}
                        </Button>
                    )}
                </Stack>
            }
            description={
                <>
                    <Box>
                        A {parentItemType} is used as a way to structure your maintenance regime, making it easier to
                        identify where items live and to represent the real-life grouping of items. Subfolders can be
                        inserted below this one, allowing for a tree structure with parent-child relationships.
                    </Box>
                    <Box mt={1}>
                        Add some schedules to this {parentItemType} by selecting them and clicking on the Add button on
                        the toolbar.
                    </Box>
                </>
            }
        />
    )
}
