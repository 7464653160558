import { cloneDeep } from "@apollo/client/utilities"
import { AuthorizationUpdated, ReferenceValueUpdated } from "event-definitions"
import { isEqual } from "lib/isEqual"
import noop from "lib/noop"
import { useEvent } from "lib/@hooks/useEvent"
import { inTransition, noChange, useRefresh } from "lib/@hooks/useRefresh"
import { generate } from "library/guid"
import { raise } from "library/local-events"

import { useCallback, useRef } from "react"

/**
 * A custom hook that provides a reference state for a given key. This state is stored in localStorage
 * and will be remembered over browser refreshes
 *
 * @param {string} key - The key to access the state.
 * @param {any} initialValue - The initial value for the state.
 * @param {string} [group] - The group identifier for the state.
 * @returns {[any, Function]} - An array containing the current value and a function to store a new value.
 */
export function useReferenceState(key, initialValue, group = generate()) {
    const lastValue = useRef()
    group ??= generate()
    const [{ [key]: value }] = useReference({ [key]: initialValue }, group)
    ReferenceValueUpdated(key).useRefresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const storeValueFn = useCallback(storeValue, [])
    lastValue.current = value
    return [value, storeValueFn]

    function storeValue(v) {
        if (typeof v === "function") {
            v = v(lastValue.current)
        }
        if (!isEqual(lastValue.current, v)) {
            setReference({ [key]: v }, false)
            ReferenceValueUpdated(key).raiseOnce()
        }
    }
}

export function useReference(initial = {}, type = "default", ...otherTypes) {
    if (typeof initial === "string") {
        type = initial
        initial = {}
    }
    const refresh = useRefresh(inTransition, noChange)
    useEvent(`Reference.Updated.*`, function handleEvent() {
        if (this.event === `Reference.Updated.${type}`) {
            refresh()
            return
        }
        if (otherTypes.some((type) => this.event === `Reference.Updated.${type}`)) {
            refresh()
        }
    })
    AuthorizationUpdated.handle(() => {
        refresh()
    })
    const output = Object.merge({ ...initial }, referenceStorage)
    const save = useCallback(
        (item = {}) => {
            setReference(item, type, () => {
                for (const other of otherTypes) {
                    raise(`Reference.Updated.${other}`)
                }
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [referenceStorage, type, JSON.stringify(otherTypes)]
    )
    const saveAll = useCallback(
        (fn) => {
            setReference(fn, type, () => {
                for (const other of otherTypes) {
                    raise(`Reference.Updated.${other}`)
                }
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [referenceStorage, type, JSON.stringify(otherTypes)]
    )
    return [output, save, saveAll]
}

let referenceStorage = window.initialReference ?? JSON.parse(localStorage.getItem(getReferenceId()) || "{}")

AuthorizationUpdated.handle(() => {
    referenceStorage = window.initialReference ?? JSON.parse(localStorage.getItem(getReferenceId()) || "{}")
})

function getReferenceId() {
    const email =
        window._authorization?.user?.attributes?.email ??
        window._authorization?.user?.email ??
        "test@testautomation.com"
    return `reference${email}`
}

window.setReference = (ref = {}) => {
    localStorage.setItem(getReferenceId(), JSON.stringify(ref))
    referenceStorage = ref
}

/**
 * Set the reference
 * @param {Record<string, any>|(Record<string, any>)=>Record<string, any>>)}item
 * @param {String} type
 * @param {Function} wasDifferent -a callback when different
 */
export function setReference(item = {}, type = "default", wasDifferent = noop) {
    if (typeof item === "function") {
        const output = cloneDeep(referenceStorage)
        const newValue = item(output) ?? output
        if (!isEqual(referenceStorage, newValue)) {
            referenceStorage = { ...newValue }
            localStorage.setItem(getReferenceId(), JSON.stringify(newValue))
            if (type !== false) {
                raise(`Reference.Updated.${type}`)
                wasDifferent()
            }
        }
    } else {
        const reference = cloneDeep(referenceStorage)
        const newValue = { ...(reference || item), ...item }
        if (!isEqual(newValue, reference)) {
            referenceStorage = cloneDeep(newValue)
            localStorage.setItem(getReferenceId(), JSON.stringify(newValue))
            if (type !== false) {
                raise(`Reference.Updated.${type}`)
                wasDifferent()
            }
        }
    }
}

export function getReference(initial = {}) {
    return Object.merge({ ...initial }, referenceStorage)
}

/**
 * Remove a reference based on a key
 * @param {String} key - the key of the item to remove
 * @param {String} type - the type of the item to remove
 * @param {Function} wasDifferent - a callback when different
 */
export function removeReference(key, type = "default", wasDifferent = noop) {
    const reference = cloneDeep(referenceStorage)
    if (key in reference) {
        delete reference[key]
        if (!isEqual(referenceStorage, reference)) {
            referenceStorage = { ...reference }
            localStorage.setItem(getReferenceId(), JSON.stringify(reference))
            if (type !== false) {
                raise(`Reference.Updated.${type}`)
                wasDifferent()
            }
        }
    }
}
