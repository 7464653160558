import { gql, useQuery } from "@apollo/client"

export function useTimelineHistory(id, first = 0, limit = 100) {
    const {
        data: { draftHistory } = {},
        loading,
        error,
    } = useQuery(
        gql`
            query history($id: String!, $first: Int, $limit: Int) {
                draftHistory(id: $id, first: $first, limit: $limit) {
                    count
                    history {
                        id
                        user
                        updated
                    }
                }
            }
        `,
        { variables: { id, first, limit } }
    )
    return { loading, error, draftHistory }
}
