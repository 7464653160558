import { SelectorTitleExtras } from "slot-definitions"
import { useParams } from "react-router-dom"
import { Button, Tooltip } from "@mui/material"
import { useTreeItem } from "routes/schedule/lib/useTreeItem"
import { parentSome } from "library/tree"

import { notSharing } from "routes/facilities/lib/when-parent-item"
import { navigate } from "lib/routes/navigate"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { CloseMenu } from "event-definitions"
import { uniqueId } from "library/database/split-id"
import { getGroupId, getScheduleRecordId } from "library/get-unique-id-from-tree"

SelectorTitleExtras.plug(<OriginalSchedule if={notSharing} priority={200} />)

function OriginalSchedule() {
    const { id } = useParams()
    const { currentGroup, schedule } = useBoundContext()
    const item = useTreeItem(getGroupId(id))
    if (!item) return null
    if (schedule?.retired) return null

    if (!currentGroup?.data?.type) {
        if (
            item.retired ||
            item._id?.includes("draft") ||
            parentSome(item, (c) => c?.id === "schedules") ||
            (item.id?.includes("Draft") && !schedule?.originatingScheduleId)
        ) {
            return null
        }
    }
    return (
        <Tooltip arrow title="View the unmodified schedule in the SFG20 Library">
            <Button size="small" color="primary" onClick={go}>
                {item.id === "search" ? "(View In Library)" : "(See Original Schedule)"}
            </Button>
        </Tooltip>
    )

    function go() {
        CloseMenu.raise()
        const scheduleId = uniqueId(schedule?.originatingScheduleId) ?? getScheduleRecordId(id)
        navigate(`/app/schedules/${scheduleId}`)
    }
}
