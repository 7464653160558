import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const changesSince = api(async (props) =>
    query(
        gql`
            query getChangesSince($from: Date!, $to: Date, $skip: Int, $take: Int, $ids: [String]) {
                changesSince(take: $take, skip: $skip, from: $from, to: $to, ids: $ids) {
                    total
                    totalSchedules
                    changes {
                        scheduleId
                        version
                        date
                        title
                        code
                        hasPrevious
                        previousVersion
                        inUse
                    }
                }
            }
        `,
        props,
        { returns: "changesSince" }
    )
)
