import { Box, Stack, Tooltip, Typography } from "@mui/material"
import { navigate } from "lib/routes/navigate"
import WidgetCard from "lib/widget-card"
import Iconify from "minimals-template/components/Iconify"

export function TechnicalUpdateWidgetCard({ title, portedFromLegacy, linkToContentPage, content, unread }) {
    const legacyClass = portedFromLegacy ? "legacy-technical-update" : ""
    return (
        <WidgetCard
            bannerColor="warning.main"
            noFlex
            onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                navigate(linkToContentPage)
            }}
            role="button"
            sx={{ height: "100%" }}
        >
            <Stack direction="column">
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        pb: 1,
                    }}
                >
                    <Typography variant="subtitle2">{title}</Typography>
                    {unread && (
                        <Tooltip arrow title={"This update has not been read."}>
                            <Box>
                                <Iconify
                                    data-cy="exclamation-mark-for-unread"
                                    sx={{ color: "error.main", height: 28, width: 28 }}
                                    icon="fa-solid:exclamation-circle"
                                />
                            </Box>
                        </Tooltip>
                    )}
                </Box>
                <Box
                    className={`force-urbanist ${legacyClass}`}
                    sx={{
                        height: 175,
                        cursor: "pointer",
                        overflow: "hidden",
                    }}
                    dangerouslySetInnerHTML={{
                        __html: content,
                    }}
                />
            </Stack>
        </WidgetCard>
    )
}
