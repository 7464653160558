import { findBasket } from "routes/schedule/plugins/baskets/find-basket"
import { waitForScheduleRefresh } from "routes/schedule/lib/wait-for-schedule-refresh"
import { filterSchedules } from "ioc"
import { useTreeItemIndex } from "routes/schedule/lib/useTreeItemIndex"
import { selectItems } from "routes/schedule/lib/useSelection"

import { useBoundContext } from "lib/@components/binding/use-bound-context"

import { useSelectedItemIds } from "routes/schedule/lib/useSelectedItems"

import { ActionMenu } from "slot-definitions"

import { addItemsToBasket } from "../controller/addItemsToBasket"

import { mapIdsToBasketSchedules } from "../controller/mapIdsToBasketSchedules"

import { removeItemsFromBasket } from "../controller/removeItemsFromBasket"
import { isABasket } from "routes/schedule/plugins/baskets/tree/basket-bar"
import { Box, ListItemText, MenuItem, Stack } from "@mui/material"
import { isATag } from "routes/schedule/plugins/baskets/tags/tagbar"
import { conditionalComponent } from "lib/@components/slot/conditional-component"
import { makeBusyFunction } from "lib/@components/busy/make-busy-function"
import Iconify from "minimals-template/components/Iconify"
// BasketTopBar.plug(<AddSelectedToCurrent priority={10} />)
// BasketTopBar.plug(<RemoveSelectedFromCurrent priority={11} />)
// TagTopBar.plug(<AddSelectedToCurrent message="Tagging Schedules" caption="Tag" priority={10} />)
// TagTopBar.plug(<RemoveSelectedFromCurrent message="Untagging Schedules" caption="Untag" priority={11} />)

ActionMenu.plug(conditionalComponent(isABasket, <AddSelectedToCurrent />))
ActionMenu.plug(conditionalComponent(isABasket, <RemoveSelectedFromCurrent />))
ActionMenu.plug(
    conditionalComponent(isATag, <AddSelectedToCurrent to="with" message="Tagging Schedules" caption="Tag" />)
)
ActionMenu.plug(
    conditionalComponent(
        isATag,
        <RemoveSelectedFromCurrent from="with" message="Untagging Schedules" caption="Untag" />
    )
)

function AddSelectedToCurrent({ basket, caption = "Add", to = "to", message = "Adding Schedules" }) {
    console.log("AddSelectedToCurrent")
    const find = useTreeItemIndex()?.retrieve
    const schedules = useSelectedItemIds()
        .map((item) => find(item))
        .filter(Boolean)
    const selection = filterSchedules(schedules).map("_id")
    const { parentId, parentItem } = useBoundContext()
    basket ??= findBasket(parentId)?.children ?? []
    const currentSchedules = new Set(basket.map("_id"))
    const toAddSelection = selection.filter((s) => !currentSchedules.has(s))

    return (
        !!toAddSelection.length && (
            <MenuItem onClick={makeBusyFunction(addSchedules, message, waitForScheduleRefresh)}>
                <Stack direction="row" alignItems="center">
                    <Box sx={{ lineHeight: 0 }} mr={1}>
                        <Iconify icon="mdi:add" />
                    </Box>
                    <ListItemText>
                        {caption} {toAddSelection.length} selected {"item".pluralize(toAddSelection.length)} {to}{" "}
                        <strong>{parentItem.label}</strong>
                    </ListItemText>
                </Stack>
            </MenuItem>
        )
    )

    async function addSchedules() {
        await addItemsToBasket(parentId, mapIdsToBasketSchedules(toAddSelection))
        selectItems(() => Object.fromEntries(toAddSelection.map((r) => [r, r])))
    }
}

function RemoveSelectedFromCurrent({ basket, caption = "Remove", message = "Removing Schedules", from = "from" }) {
    const selection = useSelectedItemIds()
    const { parentId, parentItem } = useBoundContext()
    basket ??= findBasket(parentId)?.children ?? []
    const currentSchedules = new Set(filterSchedules(basket).map("_id"))
    const toRemove = selection.filter((s) => currentSchedules.has(s))
    return (
        !!toRemove.length && (
            <MenuItem onClick={makeBusyFunction(removeSchedules, message, waitForScheduleRefresh)}>
                <Stack direction="row" alignItems="center">
                    <Box sx={{ lineHeight: 0 }} mr={1}>
                        <Iconify icon="mdi:delete" />
                    </Box>
                    <ListItemText>
                        {caption} {toRemove.length} selected {"item".pluralize(toRemove.length)} {from}{" "}
                        <strong>{parentItem.label}</strong>
                    </ListItemText>
                </Stack>
            </MenuItem>
        )
    )

    async function removeSchedules() {
        await removeItemsFromBasket(parentId, toRemove)
        selectItems(() => Object.fromEntries(toRemove.map((r) => [r, r])))
    }
}
