import { Box, ListItemButton, Typography } from "@mui/material"
import { PropertyBox } from "lib/@components/property-box"
import { ListItemBox } from "lib/@components/ListItemBox"
import { FolderItemPart } from "routes/schedule/tree/schedules/folder-item-part"
import { Selected } from "routes/schedule/components/selected"
import Iconify from "minimals-template/components/Iconify"
import { DraftItemBelow, DraftItemMain, DraftItemRight, ScheduleItemBeneath, ScheduleItemCode } from "slot-definitions"
import { DateDisplay } from "lib/@components/dateDisplay"
import { OpenDraft } from "event-definitions"
import { uniqueId } from "library/database/split-id"
import { UserIcon } from "lib/user/user-icon"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { ContextMenuBar } from "lib/@components/context-menu-bar"
import { BlockClicks } from "lib/@components/block-clicks"
import { TruncatedTypography } from "lib/@components/truncated-typography"
import { CustomScheduleStatusTag } from "./custom-schedule-status-tag"

export function DraftItem() {
    const { target } = useBoundContext()
    return (
        <ListItemButton onClick={edit} className="draft-item">
            <PropertyBox spacing={0}>
                <ListItemBox spacing={1}>
                    <FolderItemPart>
                        <Selected />
                    </FolderItemPart>
                    <FolderItemPart>
                        <Iconify
                            icon="mdi:file-document-edit-outline"
                            sx={{ width: 32, height: 32, color: "primary.dark" }}
                        />
                    </FolderItemPart>
                    <DraftItemMain.Slot schedule={target}>
                        <Box display="flex" alignItems="center" flex={1}>
                            <TruncatedTypography>{target.title || target._id}</TruncatedTypography>
                            <CustomScheduleStatusTag />
                        </Box>
                    </DraftItemMain.Slot>
                    <DraftItemRight.Slot schedule={target}>
                        <Box textAlign="right">
                            <ScheduleItemCode.Slot target={target}>
                                <Typography variant="caption">#{target.code}</Typography>
                            </ScheduleItemCode.Slot>
                        </Box>
                        {target.lastModifiedBy && (
                            <>
                                <Box width={40}>
                                    <UserIcon id={target.lastModifiedBy} />
                                </Box>

                                <Box width={90}>
                                    <Typography variant="caption">
                                        <DateDisplay date={target.modified} />
                                    </Typography>
                                </Box>
                            </>
                        )}
                        <ContextMenuBar type="draft" />
                    </DraftItemRight.Slot>
                </ListItemBox>
                <BlockClicks>
                    <ScheduleItemBeneath.Slot schedule={target} />
                </BlockClicks>
                <DraftItemBelow.Slot schedule={target} />
            </PropertyBox>
        </ListItemButton>
    )

    function edit() {
        OpenDraft(target._id).raiseAsync(`allDrafts!${uniqueId(target._id)}`)
    }
}
