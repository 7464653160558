import { Box, Tooltip } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { relevantDemands, relevantIcons } from "../regime-access-config"

export function RolesIcons({ demands }) {
    const enabled = "primary.main"
    const disabled = "lightgrey"
    return (
        <Box display="flex" alignItems="center" pl={1}>
            <Tooltip arrow title="View regimes">
                <span>
                    <Iconify
                        icon={"ic:round-remove-red-eye"}
                        sx={{
                            mr: 1,
                            color: enabled,
                            fontSize: 25,
                        }}
                    />
                </span>
            </Tooltip>
            {relevantDemands.map((demand, idx) => (
                <Tooltip key={idx} arrow title={relevantIcons[demand]?.description}>
                    <span>
                        <Iconify
                            icon={relevantIcons[demand]?.icon}
                            sx={{
                                mr: 1,
                                color: demands[demand] ? enabled : disabled,
                                fontSize: 25,
                            }}
                        />
                    </span>
                </Tooltip>
            ))}
        </Box>
    )
}
