import { Box, Typography } from "@mui/material"
import { DialogBox } from "lib/@components/dialogBox"
import { OneCard } from "lib/@components/one-card"
import { useState } from "react"
import Iconify from "minimals-template/components/Iconify"
import { getAllowedRegimeUsers } from "../controller/get-allowed-regime-users"
import { Bound } from "lib/@components/binding/Bound"
import { RegimeAccessTable } from "./regime-access-table"
import { getClientUsersForRegimeAccess } from "../controller/get-client-users-for-tree"

export function RegimeAccessTableDialog({ regime }) {
    const [changedUsers, setChangedUsers] = useState([])

    const permittedUsers = getAllowedRegimeUsers.useResults(regime)
    const users = getClientUsersForRegimeAccess.useResults({ regimeId: regime })

    const titleField = (
        <Box display="flex" alignItems="center">
            <Iconify icon="mdi:account" sx={{ mr: 1 }} />
            <Typography variant="subtitle1">Regime Access</Typography>
        </Box>
    )

    return (
        <Bound
            regimeUsers={users}
            permittedUsers={permittedUsers}
            changedUsers={changedUsers}
            setChangedUsers={setChangedUsers}
        >
            <DialogBox value={changedUsers} title={titleField} okDisabled={() => changedUsers.length === 0}>
                <Box flex={1}>
                    <OneCard sx={{ px: 1, pb: 2, pt: 3 }}>
                        <RegimeAccessTable />
                    </OneCard>
                </Box>
            </DialogBox>
        </Bound>
    )
}
