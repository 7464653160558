import { registerTab } from "lib/@components/tabs"
import { Lazy } from "lib/make-lazy"

registerTab({
    tab: "sfg20AdminTabs",
    id: "transfer",
    title: "Database Transfer",
    demands: "$admin-databasetransfer",
    content: <Lazy priority={10001} importer={() => import("./DatabaseTransfer")} extract="DatabaseTransfer" />,
})
