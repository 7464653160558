import { Paragraph } from "docx"
import { formatScheduleItem } from "./format-schedule-item"
import { wrapTextInSpan } from "../wrap-text-in-span"

export function formatSchedule(data) {
    if (!Array.isArray(data)) data = [data]

    let formattedData = []
    const naParagraph = "N/A"

    if (!data.length) {
        formattedData.push(new Paragraph(naParagraph))
    }

    data.forEach((item) => {
        const keys = Object.keys(item)
        const values = Object.values(item)
        const section = keys.map((key, index) => {
            let value = values[index]
            if (value.toString().trim() === "") value = "N/A"
            const textWrappedInSpan = wrapTextInSpan(value)
            const valueContent = []
            const parser = new DOMParser()
            const valueAsHtml = parser.parseFromString(textWrappedInSpan, "text/html")
            valueContent.push(...formatScheduleItem(value, valueAsHtml, key))

            return valueContent
        })

        const spacer = new Paragraph({ text: "" })
        formattedData.push(section.flat(), spacer, spacer)
    })

    return formattedData.flat()
}
