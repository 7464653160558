import { registerTab } from "lib/@components/tabs"
import { TabbedPage } from "routes/@lib/tabbed-page/tabbed-page.runtime"
import { ExportSchedule } from "routes/sfg20-admin/plugins/export-schedule-tab/export-schedule"
import { TechnicalBulletinBoard } from "routes/technical-updates/routes/technical-update-bulletin-board"

export function AuthoringAdmin() {
    return <TabbedPage tabId="authoringAdmin" pageTitle="Authoring Admin" />
}

registerTab({
    tab: "authoringAdmin",
    id: "exportSchedule",
    title: "Export Schedule",
    demands: "$authoringAdmin-exportSchedule",
    matchAllDemands: false,
    content: <ExportSchedule priority={20} />,
})

registerTab({
    tab: "authoringAdmin",
    id: "technicalBulletins",
    title: "Technical Update Bulletins",
    demands: "$authoringAdmin-bulletinEditor",
    content: <TechnicalBulletinBoard type={"technical"} priority={50} section="authoring-admin" />,
})

registerTab({
    tab: "authoringAdmin",
    id: "scheduleUpdateBulletins",
    title: "Schedule Update Bulletins",
    demands: "$authoringAdmin-bulletinEditor",
    content: <TechnicalBulletinBoard type={"schedule"} priority={60} section="authoring-admin" />,
})
