import { BoundStandardSwitch } from "lib/@components/binding/bound-components"
import { Frag } from "lib/@components/slot/frag"
import { Secure } from "lib/authorization/secure"
import { ShareLinkOtherSettings } from "slot-definitions"

ShareLinkOtherSettings.plug(<DownloadPDFShareLinkSetting />)

function DownloadPDFShareLinkSetting() {
    return (
        <Secure demand="$downloadPdf" fallback={<Frag />}>
            <BoundStandardSwitch field="canDownloadPDF" label="Can Download PDF" />
        </Secure>
    )
}
