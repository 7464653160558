import { ScheduleSelectorOverrideWrapper } from "slot-definitions"
import { Bound } from "lib/@components/binding/Bound"
import { registerTab, Tabs } from "lib/@components/tabs"
import { OriginalContent } from "routes/asset-register/plugins/regime-display/original-content"
import { isInRegime } from "routes/facilities/lib/when-parent-item"
import { Feature, isEnabled } from "lib/@components/feature"
import { FEATURE_FLAG_ASSET_MAPPING } from "routes/asset-register/feature-flag-asset-mapping"
import { Box } from "@mui/material"
import { Lazy } from "lib/make-lazy"
import { registerFeature } from "lib/features"
import {
    FEATURE_FLAG_REGIME_TASK_MANAGEMENT,
    FEATURE_FLAG_REGIME_TASK_MANAGEMENT_DESCRIPTION,
} from "routes/asset-register/plugins/regime-display/regime-task-management-feature"

registerFeature({
    numericalId: 11,
    name: "Regime Task Management",
    id: FEATURE_FLAG_REGIME_TASK_MANAGEMENT,
    description: FEATURE_FLAG_REGIME_TASK_MANAGEMENT_DESCRIPTION,
})

ScheduleSelectorOverrideWrapper.plug(<AssetWrapper if={isInRegime} />)

export const REGIME_ASSETS_TAB = "regime_assets"

function AssetWrapper({ children }) {
    return (
        <Feature feature={FEATURE_FLAG_ASSET_MAPPING} fallback={children}>
            <Bound normalChildren={children}>
                <Tabs searchParam id={REGIME_ASSETS_TAB} panelHolder={<Box mt={1} />} />
            </Bound>
        </Feature>
    )
}

registerTab({
    tab: REGIME_ASSETS_TAB,
    id: "original",
    title: "Schedules",
    content: <OriginalContent $lazy priority={10} />,
})

registerTab({
    tab: REGIME_ASSETS_TAB,
    id: "assets",
    title: "Assets",
    content: (
        <Lazy
            priority={110}
            importer={() => import("routes/asset-register/plugins/regime-display/asset-view")}
            extract="AssetView"
        />
    ),
})

registerTab({
    tab: REGIME_ASSETS_TAB,
    id: "asset-tasks",
    title: "Tasks",
    predicate: () => isEnabled(FEATURE_FLAG_REGIME_TASK_MANAGEMENT),
    content: (
        <Lazy
            priority={120}
            importer={() => import("routes/asset-register/plugins/regime-display/asset-task-view")}
            extract="AssetTaskView"
        />
    ),
})
