import {
    getGroupAndTreeId,
    getGroupId,
    getScheduleId,
    getScheduleRecordId,
    isGlobalTailoringId,
} from "library/get-unique-id-from-tree"
import { getTreeId } from "library/id-helpers"
import { useUndo } from "lib/undo"
import { useSave } from "lib/@hooks/use-save"
import { useCallback, useMemo } from "react"
import LoadingScreen from "minimals-template/components/LoadingScreen"
import { Bound } from "lib/@components/binding/Bound"
import { SaveIndicator } from "lib/@components/save-indicator/save-indicator"
import { UndoLive } from "routes/schedule/undo-portal"
import { Lock } from "minimals-template/components/lock/Lock"
import { getGroup } from "routes/schedule/controller/get-group"
import { saveGroupDataByIds } from "routes/schedule/controller/store-group-data"
import { setGroupPayloadUsingIds } from "routes/schedule/controller/set-group-payload-using-ids"
import { ScheduleExtension } from "routes/schedule/extendedSchedule"
import { Undo } from "routes/schedule/plugins/undo-redo/undo"

export function EditScheduleTailoring({
    payload,
    id,
    item,
    children,
    onConfigureStorage = (v) => v,
    storePayloadFunction,
}) {
    const [undoablePayload, { onChange, ...props }] = useUndo({
        ref: item,
        initialTarget: payload,
        change: (item) => {
            if (!item) storePayload()
        },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const storePayload = useSave(
        {
            id: "tailor",
            save: storePayloadFunction || store,
            target: undoablePayload,
        },
        [item, id]
    )

    const updateAndStore = useCallback(() => {
        onChange()
        storePayload()
    }, [onChange, storePayload])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const finalSave = useMemo(() => onConfigureStorage(updateAndStore, storePayloadFunction ?? store), [updateAndStore])
    const modifiedStore = useCallback(() => {
        finalSave(undoablePayload)
        return false
    }, [finalSave, undoablePayload])

    const globalTailoring = isGlobalTailoringId(id, true)

    return !payload ? (
        <LoadingScreen description="Has Payload" />
    ) : (
        <Bound
            {...props}
            currentId={id}
            currentGroup={item}
            isExtended={true}
            payload={undoablePayload}
            editExtension={true}
            showAll={true}
            storePayload={modifiedStore}
        >
            <SaveIndicator id="tailor" />
            <UndoLive>
                <Bound editMode={true}>
                    <Undo />
                </Bound>
            </UndoLive>
            <ScheduleExtension.Wrapper>
                <Lock id={id}>{children}</Lock>
            </ScheduleExtension.Wrapper>
        </Bound>
    )

    async function store(payload) {
        if (!globalTailoring) {
            const record = await getGroup(getGroupAndTreeId(id))
            const statuses = (record.data.statuses ??= {})
            statuses[getScheduleRecordId(id)] = payload.status
            await saveGroupDataByIds(getTreeId(id), getGroupId(id), record.data, undefined, getTreeId(id))
            await setGroupPayloadUsingIds(getTreeId(id), getGroupId(id), getScheduleId(id), payload)
        } else {
            await setGroupPayloadUsingIds(getTreeId(id), getGroupId(id), id, payload)
        }
    }
}
