import { useSelection } from "routes/schedule/lib/useSelection"
import { useTreeItemIndex } from "routes/schedule/lib/useTreeItemIndex"

export function useSelectedItemIds() {
    const [, , selected] = useSelection()
    const treeIndex = useTreeItemIndex(false)
    if (!treeIndex) {
        return Object.entries(selected)
            .filter((c) => !!c[1])
            .map("0")
    }
    return Object.entries(selected)
        .filter((c) => !!c[1])
        .map((i) => treeIndex.retrieve(...i)?._id)
        .filter(Boolean)
        .unique()
}

export function useSelectedItems() {
    const [, , selected] = useSelection()
    const treeIndex = useTreeItemIndex(false)
    return Object.entries(selected)
        .filter((c) => !!c[1])
        .map((i) => treeIndex.retrieve(...i))
        .filter(Boolean)
        .unique((c) => c._id)
}
