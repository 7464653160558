import { registerTab } from "lib/@components/tabs"
import { RegisterAccess } from "routes/client-admin/plugins/asset-register-access/register-access"
import { FEATURE_FLAG_ASSET_MAPPING } from "routes/asset-register/feature-flag-asset-mapping"
import { getDemandForFeature } from "lib/features"
import { checkPermission } from "library/authorization"

registerTab({
    tab: "clientAdmin",
    id: "asset-register-access",
    title: "Register Access",
    content: <RegisterAccess $lazy priority={150} />,
    predicate: () => checkPermission(getDemandForFeature(FEATURE_FLAG_ASSET_MAPPING)),
})
