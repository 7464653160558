import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { summariseTasks } from "routes/asset-register/plugins/regime-display/controller/summarise-tasks"
import { Divider, TableCell, TableRow, Typography } from "@mui/material"
import { Repeat } from "lib/@components/repeat"
import { SORT_FROM_CLASSIFICATION } from "library/SFG20/constants"
import { ClassificationEntry } from "routes/asset-register/plugins/regime-display/plugins/grouped-tasks/classification-entry"
import { compareStrings } from "library/compare-strings"
import { useReferenceState } from "lib/@hooks/use-reference"

export function ClassificationSummaryBox({ path, filter }) {
    const { regimeId, group } = useBoundContext()
    const [filters] = useReferenceState("task_filters", [], "filters")
    const result = summariseTasks.useResults({ filters, regimeId, group, summarise: [path] })
    const total = result?.reduce((a, c) => a + c.minutes, 0) ?? Infinity
    const list =
        result?.filter((s) => compareStrings(s.value[0], filter)).sortBy((c) => SORT_FROM_CLASSIFICATION[c.value[0]]) ??
        []
    return (
        <>
            {!!list.length && (
                <TableRow>
                    <TableCell colSpan={3}>
                        <Typography variant="body2" fontWeight="bold" component="div" sx={{ mt: 2 }}>
                            Criticality
                        </Typography>
                        <Divider />
                    </TableCell>
                </TableRow>
            )}

            <Repeat list={list} item={<ClassificationEntry total={total} />} />
        </>
    )
}
