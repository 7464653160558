import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { ReadonlyUOMAsset } from "routes/regime/assets/readonly-uom-asset"

export function ReadonlyAsset({ item, uom }) {
    const { target } = useBoundContext()

    const field = `${uom}_${item + 1}`
    target[field] ??= ""
    return <ReadonlyUOMAsset uom={uom} index={item} assetId={target[field]} />
}
