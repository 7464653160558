import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"

export const assetsFromManyRegisters = api(async function assets({
    registers,
    systems,
    manufacturers,
    facilities,
    spaces,
    floors,
    types,
    search,
    categories,
    skip,
    sort,
    descending,
    take,
}) {
    return query(
        gql`
            query AssetsFromManyRegisters(
                $registers: [String!]!
                $search: String
                $skip: Int
                $take: Int
                $sort: AssetSort
                $descending: Boolean
                $types: [String]
                $manufacturers: [String]
                $systems: [String]
                $categories: [String]
                $floors: [String]
                $facilities: [String]
                $spaces: [String]
            ) {
                assets(
                    skip: $skip
                    take: $take
                    registers: $registers
                    search: $search
                    sort: $sort
                    descending: $descending
                    manufacturers: $manufacturers
                    types: $types
                    systems: $systems
                    categories: $categories
                    spaces: $spaces
                    facilities: $facilities
                    floors: $floors
                ) {
                    totalCount
                    items {
                        id
                        tag
                        description
                        system
                        name
                        barcode
                        picture
                        manufacturer
                        model
                        modified
                        type
                        location
                        facility
                        floor
                        space
                        category
                        serialNumber
                        annualServiceDate
                        warrantyExpiry
                        sfg20Code
                        installationDate
                        expectedLifespan
                        condition
                        assetCriticality
                        carbon
                        energyEfficiency
                    }
                }
            }
        `,
        {
            registers,
            search,
            skip,
            take,
            sort,
            descending,
            systems,
            types,
            manufacturers,
            categories,
            floors,
            facilities,
            spaces,
        },
        {
            returns: "assets",
            transform: (data) => ({
                ...data,
                items: data.items.map((i) => ({
                    ...i,
                    picture: i.picture ? { documents: i.picture.map((o, i) => ({ href: o, id: i })) } : undefined,
                })),
            }),
        }
    )
})
