import { XmlTaskContentTypes } from "event-definitions"

XmlTaskContentTypes.handle(({ contentTypes }) => {
    contentTypes.push({
        type: "plainText",
        label: "Plain Text",
        description: "This outputs readable text with no formatting.",
        priority: 200,
    })
})
