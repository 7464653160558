import { splitId } from "library/database/split-id"
import { SCHEDULE } from "library/constants"

function mapPotentiallyCollidingId(id) {
    const split = splitId(id)
    if (split.table === "drafts") return `drf${split.id}`
    return split.id
}

export function mapIdsToBasketSchedules(items) {
    return items.map((scheduleId) => ({
        id: mapPotentiallyCollidingId(scheduleId),
        referenceId: scheduleId,
        payload: {
            _id: scheduleId,
            type: SCHEDULE,
        },
    }))
}
