import { TaskSummaries } from "slot-definitions"
import { ClassificationSummaryBox } from "routes/asset-register/plugins/regime-display/plugins/grouped-tasks/classification-summary-box"
import { SummaryBox } from "routes/asset-register/plugins/regime-display/plugins/grouped-tasks/summary-box"
import { sortDateLabel } from "routes/asset-register/plugins/regime-display/plugins/grouped-tasks/sort-date-label"
import { summariseAssets } from "routes/asset-register/plugins/regime-display/controller/summarise-assets"

TaskSummaries("task").plug(<ClassificationSummaryBox path="classification" title="Criticality" />)
TaskSummaries("task").plug(
    <SummaryBox path="frequency.label" sort={sortDateLabel} title="Frequency" icon="lets-icons:date-today-duotone" />
)
TaskSummaries("task").plug(<SummaryBox path="skill" title="Skills" icon="ant-design:tool-filled" />)
TaskSummaries("task").plug(<SummaryBox path="title" title="Task" icon="material-symbols:task-outline" />)

TaskSummaries("asset").plug(<SummaryBox path="system" title="System" method={summariseAssets} />)
TaskSummaries("asset").plug(<SummaryBox path="category" title="Category" method={summariseAssets} />)
TaskSummaries("asset").plug(<SummaryBox path="type" title="Type" method={summariseAssets} />)
