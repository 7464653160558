export const styles = {
    FONT: "Arial",
    FONT_BOLD: "Arial Bold",
    FONT_ITALIC: "Arial Italic",
    DEFAULT_FONT_COLOUR: "#000000",
    PRIMARY_MAIN: "#38939B",
    PRIMARY_DARK: "#27676D",
}

export const darkFont = {
    font: styles.FONT,
    color: styles.PRIMARY_DARK,
    size: 52,
    bold: true,
}

export const lightFont = {
    font: styles.FONT,
    color: styles.PRIMARY_MAIN,
    size: 52,
    bold: true,
}

export const inlineElements = {
    STRONG: "STRONG",
    SUB: "SUB",
    EM: "EM",
    B: "B",
    I: "I",
    U: "U",
    SMALL: "SMALL",
    MARK: "MARK",
    DEL: "DEL",
    INS: "INS",
    SUP: "SUP",
    CODE: "CODE",
    VAR: "VAR",
    SAMP: "SAMP",
    KBD: "KBD",
    Q: "Q",
    SPAN: "SPAN",
}
