// @mui
import { FormHelperText } from "@mui/material"
// type
import { UploadAvatar, UploadMultiFile, UploadSingleFile } from "minimals-template/components/upload"
import { useEffect, useState } from "react"
import { uploadFiles } from "lib/@components/uploadButton"
import noop from "lib/noop"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useBoundValue } from "lib/@components/binding/use-bound-value"

// ----------------------------------------------------------------------

export function RHFUploadAvatar({ name, publicFile, ...other }) {
    const { error } = useBoundContext()
    const [file, setFile] = useBoundValue(name)
    const [uploading, setUploading] = useState(false)
    const checkError = error && file
    return (
        <div>
            <UploadAvatar
                accept={{ "image/*": [] }}
                onDrop={handleDrop}
                onRemove={handleRemove}
                disabled={uploading}
                error={checkError}
                {...other}
                file={file}
            />
            {checkError && (
                <FormHelperText error sx={{ px: 2, textAlign: "center" }}>
                    {error.message}
                </FormHelperText>
            )}
        </div>
    )

    async function handleDrop(file) {
        setUploading(true)
        await uploadFiles(file, (url) => setFile(url, true), { publicFile })
        setUploading(false)
    }

    function handleRemove() {
        setFile(undefined, true)
    }
}

export function RHFUploadSingleFile({ name, refresh = noop, publicFile, ...other }) {
    const { error } = useBoundContext()
    const [file, setFile] = useBoundValue(name)
    const [uploading, setUploading] = useState(false)
    const checkError = error && file
    useEffect(() => {
        if (file) refresh()
    }, [file, refresh])
    return (
        <UploadSingleFile
            accept={{ "image/*": [] }}
            file={file}
            disabled={uploading}
            error={checkError}
            helperText={
                checkError &&
                file && (
                    <FormHelperText error sx={{ px: 2 }}>
                        {error.message}
                    </FormHelperText>
                )
            }
            onDrop={handleDrop}
            onRemove={handleRemove}
            {...other}
        />
    )

    async function handleDrop(file) {
        setUploading(true)
        await uploadFiles(file, (url) => setFile(url, true), { publicFile })
        setUploading(false)
    }

    function handleRemove() {
        setFile(undefined, true)
    }
}

// ----------------------------------------------------------------------

export function RHFUploadMultiFile({ name, transformer, publicFile, ...other }) {
    const { error } = useBoundContext()
    const [uploading, setUploading] = useState(false)
    const [files, setFiles] = useBoundValue(name, [])
    const checkError = error && files?.length

    return (
        <UploadMultiFile
            disabled={uploading}
            accept={{ "image/*": [] }}
            files={files}
            error={checkError}
            helperText={
                checkError &&
                files?.length && (
                    <FormHelperText error sx={{ px: 2 }}>
                        {error?.message}
                    </FormHelperText>
                )
            }
            onDrop={handleDrop}
            onRemove={handleRemove}
            {...other}
        />
    )

    async function handleDrop(acceptedFiles) {
        setUploading(true)
        const newFiles = []
        await uploadFiles(acceptedFiles, (url) => newFiles.push(url), { transformer, publicFile })
        setUploading(false)
        setFiles([...files, ...newFiles].unique())
    }

    function handleRemove(file) {
        setFiles(files.filter((f) => f !== file))
    }
}
