exports.getScheduleRecordId = function getUniqueId(id) {
    if (exports.isIdInGroup(id)) id = id.split("!").at(-1)
    if (id.endsWith("CS")) id = id.slice(0, -2)
    return exports.extractGlobalId(id)
}

exports.isGlobalTailoringId = function isGlobalTailoringId(id, onlyGlobalTailoringIdNotAReferenceToIt) {
    if (onlyGlobalTailoringIdNotAReferenceToIt && id && id.includes("!")) {
        const [groupAndTree] = id.split("!")
        const [group] = groupAndTree.split("~")
        if (group) return false
    }
    return id?.includes("*")
}

exports.isIdInGroup = function isIdInGroup(id) {
    return id?.includes("!")
}

exports.extractGlobalId = function extractGlobalId(id) {
    if (exports.isGlobalTailoringId(id)) {
        return id.split("*").at(-1)
    }
    return id
}

exports.getGroupId = function getGroupId(id) {
    if (exports.isGlobalTailoringId(id, true)) {
        return ""
    }
    if (exports.isIdInGroup(id)) {
        return id.split("!").at(-2).split("~")[0]
    }
    return ""
}

exports.getGroupAndTreeId = function getGroupAndTreeId(id) {
    if (exports.isGlobalTailoringId(id, true)) {
        return id.split("*").at(1)
    }
    if (exports.isIdInGroup(id)) {
        return id.split("!").at(-2)
    }
    return ""
}

exports.getScheduleId = function getScheduleId(id) {
    return id?.split("!").at(-1)
}
