import memoize from "memoizee"
import { query } from "lib/graphql/query"
import { gql } from "@apollo/client"
import { HasInvalidated } from "event-definitions"

export const getFilterSchedules = memoize(
    (codes) =>
        query(
            gql`
                query GetFilterSchedules($codes: [String!]!) {
                    multipleSchedules(codes: $codes) {
                        id
                        _id
                        version
                        code
                        tasks {
                            classification
                            skill {
                                Skilling
                            }
                            frequency {
                                label
                            }
                        }
                    }
                }
            `,
            { codes },
            { returns: "multipleSchedules" }
        ),
    { primitive: true, maxAge: 1000 * 60 * 0.5, promise: true }
)
HasInvalidated("schedule").handle(() => getFilterSchedules.clear())
