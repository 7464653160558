import { registerTab } from "lib/@components/tabs"
import { appRoute } from "routes/app/home.runtime"
import { Lazy } from "lib/make-lazy"
import AdministerClientsGuard from "minimals-template/components/guards/AdministerClientsGuard"

registerTab({
    tab: "sfg20AdminTabs",
    id: "users",
    title: "Users",
    demands: ["admin-users"],
    content: (
        <Lazy
            importer={() => import("routes/sfg20-admin/plugins/users-tab/components/admin-user-list")}
            extract="AdminUserList"
            priority={3}
        />
    ),
})

appRoute.register(
    "/app/sfg20-admin/users/:id",
    <AdministerClientsGuard>
        <Lazy
            importer={() => import("routes/sfg20-admin/plugins/users-tab/components/admin-user-profile")}
            extract="AdminUserProfile"
            hasRoles={true}
            hasDemands={true}
            hasBundles={true}
        />
    </AdministerClientsGuard>
)

appRoute.register(
    "/app/sfg20-admin/clients/:cid/users/:id",
    <AdministerClientsGuard>
        <Lazy
            importer={() => import("routes/sfg20-admin/plugins/users-tab/components/admin-user-profile")}
            extract="AdminUserProfile"
            hasRoles={true}
            hasDemands={true}
            hasBundles={true}
        />
    </AdministerClientsGuard>
)
