import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export async function unlockDraftFromTimeline(id) {
    await query(
        gql`
            query Query($id: String!) {
                unlockDraftFromTimeline(id: $id)
            }
        `,
        {
            id,
        },
        { returns: "unlockDraftFromTimeline" }
    )
}
