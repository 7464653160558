import { Feature } from "lib/@components/feature"
import { FEATURE_FLAG_DOWNLOAD_XML } from "./download-xml.runtime"
import { useBoundValue } from "lib/@components/binding/use-bound-value"
import { BoundStandardSwitch } from "lib/@components/binding/bound-components"
import { Alert } from "@mui/material"

export function DownloadXmlShareLinkSetting() {
    const [canDownloadXml] = useBoundValue("canDownloadXml")

    return (
        <Feature feature={FEATURE_FLAG_DOWNLOAD_XML}>
            <BoundStandardSwitch sideEffects field="canDownloadXml" label="Can Download XML" />
            {canDownloadXml && (
                <Alert severity="warning">
                    You are sharing your company&apos;s private data via XML to all people with access to this sharelink
                </Alert>
            )}
        </Feature>
    )
}
