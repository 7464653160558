import { StatsOverrides, StatsPanels } from "slot-definitions"
import { TabPanel } from "@mui/lab"
import { makeLazy } from "lib/make-lazy"
import { CheckForReports } from "./check-for-reports"

const ReportsTab = makeLazy(() => import("./reports-tab"), "ReportsTab")

StatsOverrides.plug(<CheckForReports />)
StatsPanels.plug(
    <TabPanel value="MaintenanceReports">
        <ReportsTab />
    </TabPanel>
)
