import { useEffect, useRef, useState } from "react"
import noop from "lib/noop"

export function useDebouncedValue(value, time = 300, cb = noop) {
    const [current, setValue] = useState(value)
    const [throttled, setThrottled] = useState(value)
    const timer = useRef()
    useEffect(() => {
        if (current === throttled) return undefined
        clearTimeout(timer.current)
        timer.current = setTimeout(() => {
            cb(current)
            setThrottled(current)
        }, time)

        return () => {
            clearTimeout(timer.current)
        }
    }, [throttled, time, current, cb])
    return [current, setValue, throttled]
}
