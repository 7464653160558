import { Button } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { treeSecurity } from "routes/regime/plugins/regime-task-security/controller/tree-security"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useDialog } from "lib/@hooks/useDialog"
import { SecuritySettings } from "routes/regime/plugins/regime-task-security/security-settings"
import { setTreeSecurity } from "routes/regime/plugins/regime-task-security/controller/set-tree-security"

export function SecureRegimeTasks() {
    const { parentItem, closeNow } = useBoundContext()
    const baseId = parentItem.id.split("|").at(-1)
    const rules = treeSecurity.useResults(baseId)?.rules ?? []
    const getRules = useDialog(<SecuritySettings rules={rules} />)
    return (
        <Button
            startIcon={
                <Iconify
                    sx={{ color: rules.includes("*") ? "warning.main" : undefined }}
                    icon={rules.length ? "fluent:phone-24-filled" : "fluent:phone-lock-24-filled"}
                />
            }
            color="primary"
            onClick={setRules}
        >
            App Security
        </Button>
    )

    async function setRules() {
        closeNow()
        const update = await getRules()
        if (update) {
            await setTreeSecurity(
                baseId,
                update.filter((v) => !!v.trim())
            )
        }
    }
}
