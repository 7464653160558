import { Box, Button, IconButton, Typography } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useDialog } from "lib/@hooks/useDialog"
import { AssetDialog } from "routes/regime/assets/asset-dialog"
import { useBoundValue } from "lib/@components/binding/use-bound-value"
import Iconify from "minimals-template/components/Iconify"
import { range } from "lib/range"
import { Secure } from "lib/authorization/secure"

export function ChooseAsset() {
    const { assetIndex, assetRegisters, assetField, refresh, uom, target } = useBoundContext()
    const addAsset = useDialog(<AssetDialog assetRegisters={assetRegisters} />)
    const [, setAssetId] = useBoundValue(assetField)

    return (
        <Secure demand="$professional-services" fallback={<Typography variant="body2">Unassociated Asset</Typography>}>
            <ListItemBox pl={1} minHeight={62}>
                <Button
                    size="small"
                    startIcon={<Iconify sx={{ width: 16, height: 16 }} icon="carbon:asset" />}
                    variant="outlined"
                    onClick={add}
                >
                    Associate Asset
                </Button>
                <Box flex={1} />
                <IconButton
                    onClick={remove}
                    color="secondary"
                    size="small"
                    disabled={target[uom] < 2}
                    data-cy="remove-asset-from-inventory"
                >
                    <Iconify icon="ic:round-clear" />
                </IconButton>
                <Box pl={2} />
            </ListItemBox>
        </Secure>
    )

    function remove() {
        const count = +target[uom]
        const assets = range(count).map((i) => target[`${uom}_${i + 1}`])
        assets.splice(assetIndex, 1)
        range(count).forEach((i) => (target[`${uom}_${i + 1}`] = assets[i]))
        target[uom] -= 1
        refresh()
    }

    async function add() {
        const result = await addAsset()
        if (result) {
            console.log({ result })
            setAssetId(result)
            refresh()
        }
    }
}
