import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { query } from "lib/graphql/query"

export const getLegacyServiceModels = api(async function getLegacyServiceModels(subscriptionId) {
    return query(
        gql`
            query GetLegacyServiceModels($subscriptionId: Int!) {
                getLegacyServiceModels(subscriptionId: $subscriptionId) {
                    subscriptionLibraryId
                    name
                    label
                    childIds
                }
            }
        `,
        { subscriptionId },
        { returns: "getLegacyServiceModels" }
    )
})
