import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"
import { useUserByEmail } from "lib/user/use-user-by-email"

export const getUser = api(async function getUser(id) {
    if (!id) return null
    return query(
        gql`
            query getUser($id: String!) {
                getUser(id: $id) {
                    displayName
                    email
                    client
                    company
                    photoURL
                    jobTitle
                    metadata
                    LastActivityDate
                    numberOfClients
                    isOverridingClient
                }
            }
        `,
        { id },
        { returns: "getUser" }
    )
})

export function useUser(id) {
    const user = getUser.useResults(id)
    return { loading: !user, error: null, user }
}

export function useUserByIdOrEmail(id, email) {
    const { user: idUser } = useUser(id)
    const { user: emailUser } = useUserByEmail(email)
    return idUser ?? emailUser
}
