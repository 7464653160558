import { ScheduleSelectorBeforeList } from "slot-definitions"
import { ContextMenu } from "lib/@components/context-menu-bar"
import { registerIconForFolderType } from "routes/schedule/my-schedules/my-custom-schedules/schedule-folder"
import { isParentItemType } from "routes/schedule/my-schedules/my-group-types/isParentItemType"
import { FacilityDisplay } from "routes/schedule/my-schedules/my-group-types/facility/facilityDisplay"

ScheduleSelectorBeforeList("*").plug(<FacilityDisplay if={isParentItemType("facility")} priority={400} />)

registerIconForFolderType("facility", "bxs:building")

export const FacilityCardMenu = ContextMenu("facilityCard")
