import { useEffect, useRef } from "react"
import { Box } from "@mui/material"
import { DATE_FORMATS } from "lib/@constants/date-format"

export function DateDisplay({ date, maxTime = Infinity, modify = (v) => v, ...props }) {
    const ref = useRef()
    useEffect(() => {
        updateTime()
        const timer = setInterval(updateTime, 1000)
        return () => clearInterval(timer)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return <Box display="inline-block" ref={ref} {...props} />

    function updateTime() {
        if (!ref.current) return
        if (!date) {
            ref.current.innerText = ""
            return
        }
        const actual = new Date(date)
        if (Date.now() - actual.getTime() > maxTime) {
            ref.current.innerText = ""
            return
        }
        if (actual.daysAgo() > 7) {
            ref.current.innerText = actual.format(DATE_FORMATS.medium)
            return
        }
        ref.current.innerText = modify(actual.relative(new Date().toISOString()))
    }
}
