import { registerTab } from "lib/@components/tabs"
import { makeLazy } from "lib/make-lazy"

const Details = makeLazy(() => import("routes/facilities/details"), "Details")

registerTab({
    tab: "Facility",
    id: "Details",
    predicate(/*context*/) {
        return true
    },
    content: <Details $lazy priority={30} />,
})
