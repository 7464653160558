import { useMemo, useRef, useState } from "react"
import { scopeUrl } from "lib/scope-url"
import { Box, CircularProgress, DialogContent, DialogTitle, Paper } from "@mui/material"
import LoadingScreen from "minimals-template/components/LoadingScreen"
import { useDialog } from "lib/@hooks/useDialog"
import { Done } from "lib/@components/done"
import { supportedFilePreviews, supportedImagePreviews } from "./file-types"

export function usePreview(filename, name = filename, extension = null) {
    return useDialog(<PreviewDialog name={name} url={filename} extension={extension} />, {
        maxWidth: "lg",
        fullScreen: true,
    })
}

export function PreviewDialog({ url, name, extension }) {
    const [iframe, setIframe] = useState(null)
    const [loading, setLoading] = useState(false)
    extension = extension ?? url.split(".").at(-1)
    const count = useRef(0)
    const [id, setId] = useState(1)
    useMemo(() => {
        if (!iframe) return
        count.current = 0
        setLoading(true)
        iframe.src = `https://docs.google.com/viewer?embedded=true&url=${scopeUrl(url)}`
        setTimeout(scanIframe, 1500)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iframe, url])

    const hasSupportedImage = supportedImagePreviews.includes(extension)
    const hasSupportedFile = supportedFilePreviews.includes(extension)

    return (
        <>
            <DialogTitle>{name}</DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
                {hasSupportedImage && (
                    <Box
                        component="img"
                        sx={{ border: "none", width: 1, flex: 1, maxHeight: "80vh", objectFit: "contain" }}
                        src={url}
                        alt={name}
                    />
                )}
                {hasSupportedFile && (
                    <Paper
                        key={id}
                        elevation={8}
                        component="iframe"
                        ref={setIframe}
                        sx={{ border: "none", borderRadius: 1, width: 1, flex: 1 }}
                        title="Preview Document"
                    >
                        <CircularProgress color="primary" />
                    </Paper>
                )}
                {loading && <LoadingScreen />}
            </DialogContent>
            <Done />
        </>
    )

    function scanIframe() {
        try {
            if (iframe.contentDocument.URL !== "about:blank") {
                if (iframe.contentDocument?.body?.childElementCount > 0) {
                    throw new Error("Loaded")
                }
            }
            if (count.current++ > 7) {
                setId((id) => id + 1)
                return
            }
            setTimeout(() => scanIframe(), 500)
        } catch (e) {
            setLoading(false)
        }
    }
}
