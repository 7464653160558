import { ClientPanels, ClientTabs, FubSpotPanelTop } from "slot-definitions"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Bound } from "lib/@components/binding/Bound"
import { useState } from "react"
import { Box, Stack, TableCell, TableRow, Typography } from "@mui/material"
import { StandardCheckBox } from "lib/@components/standard-checkbox"
import { getAvailableProductsArray } from "library/hubspot/get-products"
import { getFubspot } from "routes/settings/plugins/fubspot/controller/get-fubspot"
import { fubspotEnabled } from "routes/settings/plugins/fubspot/controller/fubspot-enabled"
import { SecureTab, SecureTabPanel } from "minimals-template/components/secure-tabs"
import { Frag } from "lib/@components/slot/frag"
import { UpdateCompanyDetails } from "./update-company-details"
import { TabledRepeat } from "lib/@components/tabled-repeat/tabled-repeat"
import { setFubspot } from "routes/settings/plugins/fubspot/controller/set-fubspot"
import { DebouncedTextField } from "lib/@components/debounced-text-field"

ClientTabs.plug(<SecureTab demand={"admin-fubspot"} hide={true} fallback={<Frag />} label="FubSpot" value="FubSpot" />)
FubSpotPanelTop.plug(<UpdateCompanyDetails />)

export function FubspotPanel() {
    const isFubspotEnabled = fubspotEnabled.useResults() ?? false
    console.log({ isFubspotEnabled })
    if (!isFubspotEnabled) {
        return <>FubSpot not enabled on this environment</>
    }
    return (
        <>
            <FubSpotPanelTop.Slot />
            <FubSpot />
        </>
    )
}

ClientPanels.plug(
    <SecureTabPanel demand={"admin-fubspot"} hide={true} fallback={<Frag />} value="FubSpot">
        <FubspotPanel priority={150} />
    </SecureTabPanel>
)

export function FubSpot() {
    const { clientId: client } = useBoundContext()
    const fubspotProducts = getAvailableProductsArray()
    const [newState] = useState({})
    const clientFubspotData = getFubspot.useResults(client) ?? newState

    const tableHeaders = [
        { title: "Sku", fieldKey: "sku", sortable: false },
        { title: "Quantity", fieldKey: "quantity", sortable: false },
        { title: "Enabled", fieldKey: "status", sortable: false },
        { title: "Description", fieldKey: "description", sortable: false },
    ]

    return (
        !!clientFubspotData && (
            <Bound clientFubspotData={clientFubspotData}>
                <Box className="fubspot-products" pt={1}>
                    <TabledRepeat
                        items={fubspotProducts}
                        itemPass="item"
                        itemComponent={<FubspotProduct />}
                        tableHeaders={tableHeaders}
                        searchKeys={[{ key: "sku", title: "Sku" }]}
                        paged
                    />
                </Box>
            </Bound>
        )
    )
}

function FubspotProduct({ item }) {
    const { clientFubspotData, clientId: client } = useBoundContext()
    clientFubspotData.settings ??= {}
    const setting = (clientFubspotData.settings[item.sku] ??= { ...item })

    const [quantityError, setQuantityError] = useState(isNaN(setting.quantity))

    return (
        <TableRow>
            <TableCell>
                <Typography variant="subtitle2">{item.sku}</Typography>
            </TableCell>
            <TableCell>
                {!item.hasQuantity && <Typography>N/A</Typography>}
                {item.hasQuantity && (
                    <DebouncedTextField
                        value={setting.quantity}
                        label="Quantity"
                        onChange={changeQuantity}
                        error={quantityError}
                        helperText={quantityError ? "Must be a number" : ""}
                    />
                )}
            </TableCell>
            <TableCell>
                <Stack alignItems={"flex-start"} pr={1}>
                    <StandardCheckBox
                        value={setting.status}
                        indeterminate={item.productStatus === "active"}
                        onChange={changeStatus}
                    />
                </Stack>
            </TableCell>
            <TableCell>
                {item.regimes !== 0 && <>{item.regimes} Regimes, </>}
                {item.createLicences !== 0 && <>{item.createLicences} Create, </>}
                {item.collaborateLicences !== 0 && <>{item.collaborateLicences} Collaborate, </>}
                {item.viewLicences !== 0 && <>{item.viewLicences} View, </>}
                {item.demands.length > 0 && <>Grants demands: {JSON.stringify(item.demands)}</>}
            </TableCell>
        </TableRow>
    )

    async function changeStatus(e) {
        clientFubspotData.settings[item.sku].status = e.target.value
        await setFubspot(client, clientFubspotData)
    }

    async function changeQuantity(e) {
        if (isNaN(e.target.value) || e.target.value.trim() === "") {
            setQuantityError(true)
        } else {
            setQuantityError(false)
            clientFubspotData.settings[item.sku].quantity = e.target.value
            await setFubspot(client, clientFubspotData)
        }
    }
}
