/**
 * Retrieves a value from a property string path
 * @param {object} object - the object from which to extract a value
 * @param {string} path - the property path in x.y.z format
 * @param {any} defaultValue - a default value to return if the property is not found
 * @returns {any} the value of the property for the object or the defaultValue if it was missing
 */
export function get(object, path = "", defaultValue = undefined) {
    const parts = path.split(".")
    for (const part of parts) {
        if (!object) return defaultValue
        object = object[part]
    }
    return object ?? defaultValue
}
