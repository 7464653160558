import { Button } from "@mui/material"
import { useDialog } from "lib/@hooks/useDialog"
import Iconify from "minimals-template/components/Iconify"
import { CreateNewTagDialog } from "../create-new-tag-dialog"

export function CreateTag() {
    const openNewTagDialog = useDialog(<CreateNewTagDialog />)

    return (
        <Button
            onClick={createTag}
            variant="contained"
            startIcon={<Iconify icon={"ic:round-add"} />}
            sx={{ mt: 2, mb: 3 }}
        >
            Create A New Tag
        </Button>
    )

    function createTag() {
        openNewTagDialog().catch(console.error)
    }
}
