import { Radio, Tooltip } from "@mui/material"
import { useReferenceState } from "lib/@hooks/use-reference"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function SelectedRadio({ selection }) {
    const { filterKey = "task_filters" } = useBoundContext()
    const [filters, setFilters] = useReferenceState(filterKey, [], "filters")
    const selected = filters.some((f) => Object.isEqual(selection, f))

    return (
        <Tooltip title="Click to Select / Shift+Click to Add" arrow enterDelay={500}>
            <Radio size="small" color="primary" checked={selected} onClick={change} />
        </Tooltip>
    )

    function change({ shiftKey }) {
        if (selected) {
            setFilters(filters.filter((f) => !Object.isEqual(f, selection)))
        } else {
            if (shiftKey) {
                setFilters((filters) => [...filters, selection])
            } else {
                setFilters([selection])
            }
        }
    }
}
