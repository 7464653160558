import { Avatar, Collapse, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import { useStoreState } from "lib/store"
import Iconify from "minimals-template/components/Iconify"
import { RotatingDropDownMenu } from "../../../../../lib/@components/rotating-dropdown-menu"
import { Bound } from "lib/@components/binding/Bound"
import { Repeat } from "lib/@components/repeat"
import { navigate } from "lib/routes/navigate"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useModifiedSchedule } from "routes/schedule/lib/use-modified-schedule"
import { ScheduleItem } from "routes/schedule/tree/schedules/scheduleItem"

export function SkillGroupInstance({ item }) {
    const { target } = useBoundContext()
    const { schedules } = item
    const uniqueId = `${item.groupId}${target.resultsId}`
    const [openSchedules, setOpenSchedules] = useStoreState(`related_schedules_${uniqueId}`, false)
    return (
        <>
            <ListItemButton onClick={go}>
                <ListItemBox>
                    <ListItemAvatar>
                        <Avatar>
                            <Iconify icon={item.icon ?? "bxs:building"} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText sx={{ flex: 1 }} primary={item.name} />
                    <RotatingDropDownMenu
                        tooltipMessage={"Tailored schedules in this group"}
                        state={openSchedules}
                        onClick={(e) => {
                            setOpenSchedules(!openSchedules)
                            e.stopPropagation()
                        }}
                        icon={<Iconify data-cy="related-schedule" icon="mdi:file-document-outline" />}
                        count={schedules.length}
                    />
                </ListItemBox>
            </ListItemButton>
            <Bound target={{ ...item, uniqueId }}>
                <Repeat list={schedules} item={<RelatedSchedule />} />
            </Bound>
        </>
    )

    function go() {
        navigate(`/app/schedules?id=${item.groupId}`)
    }
}

function RelatedSchedule({ item }) {
    const { target } = useBoundContext()
    console.log({ target, item })
    const [open] = useStoreState(`related_schedules_${target.uniqueId}`, false)
    const schedule = { ...useModifiedSchedule(target.schedulePrefix?.slice(0, -1), item), id: item }
    if (!schedule) return null
    schedule.id = `${target.schedulePrefix}${item}`
    return (
        <Collapse in={open} mountOnEnter sx={{ width: 1 }}>
            <Bound target={schedule}>
                <ScheduleItem schedule={schedule} />
            </Bound>
        </Collapse>
    )
}
