import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"

export const getIsPrimaryUser = api(async function getIsPrimaryUser() {
    return query(
        gql`
            query GetIsPrimaryUser {
                getIsPrimaryUser
            }
        `,
        {},
        { returns: "getIsPrimaryUser" }
    )
})

export function useIsPrimaryUser() {
    const { loading, error, data: isPrimaryUser } = getIsPrimaryUser.useResults.status()
    return { loading, error, isPrimaryUser }
}
