import { useDialog } from "lib/@hooks/useDialog"
import { SpaceConfiguration } from "routes/schedule/my-schedules/my-group-types/space/spaceConfiguration"
import { ContextMenuItem } from "lib/@components/context-menu-bar/context-menu-item"
import { storeGroupData } from "routes/schedule/controller/store-group-data"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function EditSpace() {
    const { parentItem } = useBoundContext()
    parentItem.data.name = parentItem.label
    const titleLabel = `Edit ${parentItem?.data?.subType?.titleize()}`

    const editSpace = useDialog(
        <SpaceConfiguration parentItem={parentItem} title={titleLabel} existingSpace={parentItem.data} />,
        {
            fullScreen: true,
        }
    )
    return parentItem.data?.type === "space" && <ContextMenuItem icon="eva:edit-fill" label="Edit" onClick={edit} />

    async function edit() {
        const result = await editSpace()
        if (result) {
            await storeGroupData(parentItem.id, result, result.name)
        }
    }
}
