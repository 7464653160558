import { useRefresh } from "lib/@hooks/useRefresh"
import { useState } from "react"
import { Bound } from "lib/@components/binding/Bound"
import { Box, DialogContent, DialogTitle, Grid, Stack, Tab } from "@mui/material"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { setFromValueParam } from "lib/setFromEvent"
import { BoundTextField } from "lib/@components/binding/bound-components"
import { BoundImageUploadInputPicture } from "lib/@components/image-upload-input"
import { BoundTextEditor } from "minimals-template/components/editor"
import { BoundDocumentListEditor } from "lib/@components/document-viewer/document-list-editor"
import { BoundPictureListEditor } from "lib/@components/document-viewer/picture-list-editor"
import { OkCancel } from "lib/@components/okCancel"

export function SpaceConfiguration({ parentItem, title = "Create space", existingSpace }) {
    const refresh = useRefresh()

    existingSpace.name ??= parentItem.label
    existingSpace.type = "space"
    parentItem.label = existingSpace.name
    const [tab, setTab] = useState("1")
    return (
        <Bound target={existingSpace} refresh={refresh}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent sx={{ minHeight: "50vh" }}>
                <TabContext value={tab}>
                    <Box sx={{ typography: "body2", mb: 2 }}>
                        <TabList onChange={setFromValueParam(setTab)}>
                            <Tab label="Info" value="1" />
                            <Tab label="Documents" value="2" />
                            <Tab label="Pictures" value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Grid sx={{ mt: 2 }} container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                    <BoundTextField sideEffects autoFocus field="name" />
                                    <BoundTextField field="location" multiline rows={3} />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <BoundImageUploadInputPicture
                                    fullWidth
                                    field="image"
                                    PreviewProps={{ sx: { maxHeight: 260 } }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <BoundTextEditor field="description" />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="2">
                        <BoundDocumentListEditor field="documents" />
                    </TabPanel>
                    <TabPanel value="3">
                        <BoundPictureListEditor field="pictures" />
                    </TabPanel>
                </TabContext>
            </DialogContent>
            <OkCancel value={existingSpace} okDisabled={() => !existingSpace.name} />
        </Bound>
    )
}
