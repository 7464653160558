import { Box, LinearProgress, TableCell, TableRow, Typography } from "@mui/material"
import { COLOR_FROM_CLASSIFICATION, ICON_FROM_CLASSIFICATION } from "library/SFG20/constants"
import Iconify from "minimals-template/components/Iconify"
import { formatTime } from "lib/format-time"
import { SelectedRadio } from "routes/asset-register/plugins/regime-display/plugins/grouped-tasks/selected-radio"

export function ClassificationEntry({ item: { key, value, minutes, count }, total }) {
    return (
        <>
            <TableRow sx={{ height: 8 }} />
            <TableRow>
                <TableCell>
                    <SelectedRadio selection={{ [key[0]]: value[0] }} />
                </TableCell>
                <TableCell>
                    <Box color={COLOR_FROM_CLASSIFICATION[value[0]]} sx={{ lineHeight: 0 }}>
                        <Iconify icon={ICON_FROM_CLASSIFICATION[value[0]]} />
                    </Box>
                </TableCell>
                <TableCell>
                    <Box>
                        <Typography variant="caption">{value[0]}</Typography>
                    </Box>
                </TableCell>
                <TableCell align="right">
                    <Typography variant="caption">{count}</Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={3} />
                <TableCell align="right">
                    <Typography variant="caption" color="text.secondary">
                        {formatTime(minutes)}
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={2} />
                <TableCell colSpan={2} align="right">
                    <LinearProgress
                        variant="determinate"
                        value={(minutes / total) * 100}
                        sx={{ width: 1 }}
                        color="primary"
                    />
                </TableCell>
            </TableRow>
            <TableRow sx={{ height: 8 }} />
        </>
    )
}
