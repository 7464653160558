import { createContext } from "react"
import { Navigated, OpenDialog } from "event-definitions"
import { useMobile } from "lib/@components/mobile"
import { isDialogOpen } from "lib/@dialogs/dialogOpen"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

let dialogId = 0

export function useDialog(content, props = {}) {
    const isMobile = useMobile()
    const context = useBoundContext()
    return function open(pass = {}) {
        return new Promise((resolve) => {
            OpenDialog.raiseLater({
                dialogProps: {
                    maxWidth: "sm",
                    fullWidth: true,
                    fullScreen: isMobile,
                    ...props,
                },
                context,
                initialProps: props,
                ...props,
                dialogId: dialogId++,
                content,
                resolve,
                pass,
            })
        })
    }
}

export function openDialog(content, props = {}, pass = {}) {
    return new Promise((resolve) => {
        OpenDialog.raise({
            dialogProps: {
                maxWidth: "sm",
                fullWidth: true,
                ...props,
            },
            initialProps: props,
            ...props,
            dialogId: dialogId++,
            content,
            resolve,
            pass,
        })
    })
}

function getLocation() {
    return `${window.location.pathname}${window.location.search}`
}

let currentLocation = getLocation()
Navigated.handleOnce(() => (currentLocation = getLocation()))

window.addEventListener("popstate", ({ state }) => {
    if (isDialogOpen() && getLocation() !== currentLocation) {
        window.history.pushState(state, "", currentLocation)
    }
})

export const OkCancelContext = createContext({})
