import { ScheduleTreeBar, ShareLinkOtherSettings } from "slot-definitions"
import { registerFeature } from "lib/features"
import { DownloadSchedulePDFButton } from "./download-schedule-pdf-button"
import { DownloadSchedulePDFShareLinkSetting } from "./download-schedule-pdf-share-link-setting"
import { hasDemand } from "lib/authorization/has-demand"
import { and } from "lib/logic"
import { isEnabled } from "lib/@components/feature"
import { SharingLinkDownloadSchedulePDF } from "./sharing-link-download-schedule-pdf"

export const FEATURE_FLAG_DOWNLOAD_SCHEDULE_PDF = registerFeature({
    numericalId: 12,
    name: "Print Schedule PDF",
    id: "downloadSchedulePdf",
    description: "Enables users to download individual schedule PDFs inside maintenance regimes",
})

ScheduleTreeBar.plug(<DownloadSchedulePDFButton priority={50} if={show("$!sharing")} />)

ScheduleTreeBar.plug(<SharingLinkDownloadSchedulePDF priority={50} if={show("$sharing")} />)

ShareLinkOtherSettings.plug(<DownloadSchedulePDFShareLinkSetting priority={150} />)

function show(demand) {
    return and(
        () => isEnabled(FEATURE_FLAG_DOWNLOAD_SCHEDULE_PDF),
        () => hasDemand(demand)
    )
}
