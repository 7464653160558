import {
    CanEditTaskItem,
    ChangeView,
    FilterSchedule,
    GetIcon,
    Navigation,
    RefreshTreeRoots,
    RoutesGet,
    SidebarTreeRoots,
    TaskListFilter,
} from "event-definitions"
import { BasketIcon } from "routes/schedule/plugins/baskets/tree/basket-icon"
import { defer } from "lib/defer"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

/**
 * Retrieves the items for the left navigation bar
 */
export function getLeftBarNavigationItems() {
    const { navigation } = Navigation.call({ navigation: [] })
    return navigation
}

/**
 * Get a list of filtered schedules based on active schedule filters
 */
export function filterSchedules(schedules) {
    const { items } = FilterSchedule.call({ items: schedules ?? [] })
    return items
}

/**
 * Gets a set of routes for a given parent route and default set
 */
export function getAllRoutes(initialRoutes, parent = "") {
    const { routes } = RoutesGet.call({
        routes: initialRoutes,
        parent,
    })
    return routes
}

/**
 * Given a schedule this returns the list of tasks in that schedule that pass active filters
 */
export function useFilteredTasksFromSchedule(schedule) {
    ChangeView.after.useRefresh()
    const context = useBoundContext()
    const { tasks } = TaskListFilter.call({ context, tasks: [...(schedule.tasks ?? [])], schedule })
    return tasks.sortBy("$sort")
}

/**
 * Given a type, this returns an icon to represent that type in the tree
 */
export function getIconForType(type, icon = <BasketIcon />, sx = {}, singular = true) {
    const { icon: result } = GetIcon.call({ type, icon, sx, singular })
    return result
}

let retrieval = Promise.resolve(true)

/**
 * Retrieves the roots of the tree view, calling with no parameters retrieve the base tree
 */
export async function getTreeRootElements() {
    await retrieval
    retrieval = defer()
    try {
        const roots = []
        await SidebarTreeRoots.raiseAsync({
            roots,
            add(root) {
                roots.push(root)
            },
        })

        return roots
    } finally {
        retrieval.resolve()
    }
}

/**
 * Refresh tree views
 */
export function refreshTree() {
    RefreshTreeRoots.raiseOnceDelay(100)
}

/**
 * Can a task item be edited
 */
export function canEditTaskItem(schedule, task, item) {
    const { canEdit } = CanEditTaskItem.call({ canEdit: true, schedule, task, item })
    return canEdit
}

/**
 * A hook to work out if a task or a field of a task is editable - see also the <Editing/> component which uses this
 * internally
 */
export function useEditable(field) {
    const { schedule, task, editMode } = useBoundContext()
    return editMode && canEditTaskItem(schedule, task, field)
}
