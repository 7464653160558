import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Stack, Typography } from "@mui/material"
import { generate } from "library/guid"
import { AssociatedAsset } from "routes/regime/assets/associated-asset"

export function ReadonlyUOMAsset({ index, assetId }) {
    const { target } = useBoundContext()
    target._id ??= generate()
    return (
        <Stack ml={2} my={1} direction="row" spacing={1} alignItems="center">
            <Typography variant="caption">{index + 1}.</Typography>
            {!assetId && <Typography color="text.secondary">n/a</Typography>}
            {assetId && <AssociatedAsset item={assetId} />}
        </Stack>
    )
}
