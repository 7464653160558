import { RegisterSystemTask } from "event-definitions"
import { handle } from "library/local-events"
import { mutate } from "lib/graphql/mutate"
import { gql } from "@apollo/client"
import { TaskEditor } from "slot-definitions"
import { successSnackbar } from "lib/snackbar/success-snackbar"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { warningSnackbar } from "lib/snackbar/warning-snackbar"
import { replaceAllWith } from "lib/@components/slot/replace-all-with"
import { Frag } from "lib/@components/slot/frag"

RegisterSystemTask.handleOnce(({ tasks }) => {
    const task = {
        name: "Soft reset server",
        event: "soft-reset-server",
        params: {},
    }
    tasks.push(task)
})

handle("soft-reset-server", async ({ ...params }) => {
    console.log("soft-reset-server", params)
    warningSnackbar("Running Server Soft Reset")
    if (
        await mutate(
            gql`
                mutation Mutation {
                    softResetServer
                }
            `,
            {},
            { returns: "softResetServer" }
        ).catch((e) => {
            console.error(e)
            errorSnackbar(e.message)
        })
    ) {
        successSnackbar("Server was Soft Reset")
    }
})

TaskEditor("soft-reset-server").plug(replaceAllWith(<Frag />))
