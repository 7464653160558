import { Box, Typography } from "@mui/material"
import { NoResults } from "./no-results/no-results"
import { PageBreadcrumbContainer } from "lib/page-breadcrumb-container"

export function ErrorPage({ error = "Nothing to see here", errorHeader = "An error occurred", title = "" }) {
    return (
        <PageBreadcrumbContainer title={title}>
            <Box flex={1}>
                <NoResults>
                    <Typography variant="body2" color="text.secondary" align="center">
                        {errorHeader}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" align="center">
                        {error}
                    </Typography>
                </NoResults>
            </Box>
        </PageBreadcrumbContainer>
    )
}
