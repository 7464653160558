import Iconify from "minimals-template/components/Iconify"
import { Navigation } from "event-definitions"

export function registerPageNavigationWithContent(content, path, title, icon, subPaths) {
    if (typeof icon === "string") {
        icon = <Iconify icon={icon} />
    }
    Navigation.handleOnce(({ navigation }) => {
        if (!subPaths.predicate || subPaths.predicate()) {
            navigation.push({ path, title, icon, subPaths, content })
        }
    })
}
