import { ContextMenuItem } from "lib/@components/context-menu-bar/context-menu-item"
import { confirm } from "lib/@dialogs/confirm"
import { storeGroupData } from "routes/schedule/controller/store-group-data"
import { FolderContextMenu } from "routes/schedule/folder-manager/lib/folder-context-menu"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { PlanRoot } from "library/tree/constants"
import { isInPublishedRegime } from "routes/facilities/lib/when-parent-item"

FolderContextMenu.plug(removeType)

function removeType({ context }) {
    return (
        context.parentItem &&
        !context.parentItem[PlanRoot] &&
        !!context.parentItem.data?.type &&
        context.parentItem.data?.type !== "simple" &&
        context.parentItem.data?.type !== "group" &&
        !isInPublishedRegime(context) && <RemoveType priority={1000} />
    )
}

function RemoveType() {
    const { parentItem } = useBoundContext()
    return <ContextMenuItem divider={true} icon="ic:sharp-clear" label="Convert to folder" onClick={convertBack} />

    async function convertBack() {
        if (
            await confirm(
                "Are you sure you want to convert this group back to a simple folder?  You will lose all of the additional configuration applied to it permanently.",
                "Convert to folder"
            )
        ) {
            await storeGroupData(parentItem.id, { image: parentItem.data?.image, type: "simple" })
        }
    }
}
