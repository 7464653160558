import { Stack } from "@mui/material"
import { ScheduleSelectorList, SelectedSchedulesTopBar } from "slot-definitions"

ScheduleSelectorList("selected").plug(<SelectedTopBar priority={10} />)

function SelectedTopBar(props) {
    return (
        <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 3 }}>
            <SelectedSchedulesTopBar.Slot {...props} />
        </Stack>
    )
}
