import "lib/@debug/browser-cls/async-tracking"
import "lib/@debug/browser-cls/get-stack-frame-locations"
import "lib/render-trap"
// i18n
import "./minimals-template/locales/i18n"

// These must be early in the import list >>>>>>
import "lib/@hooks/useEvent" // Must be first
import "./event-definitions"
// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
// lightbox
import "react-image-lightbox/style.css"

// slick-carousel
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"

// lazy image
import "react-lazy-load-image-component/src/effects/black-and-white.css"
import "react-lazy-load-image-component/src/effects/blur.css"
import "react-lazy-load-image-component/src/effects/opacity.css"

import ReactDOM from "react-dom/client"
import { HelmetProvider } from "react-helmet-async"
// @mui
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
// redux
// contexts
import { CollapseDrawerProvider } from "minimals-template/components/contexts/CollapseDrawerContext"
import { SettingsProvider } from "minimals-template/components/contexts/SettingsContext"

import { AuthorizationProvider, AuthProvider } from "minimals-template/components/contexts/NognitoContext"
import "library/sugary"

//
import App from "./App"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

import { ApolloProvider } from "@apollo/client"
import { Box } from "@mui/material"
import { getApolloClient } from "lib/graphql/client"
import { useEffect, useRef } from "react"

import TagManager from "react-gtm-module"
import { AppRefresh } from "event-definitions"
import "lib/framework"

import "./_startup"
import "./_runtime"
import "lib/cypress-test-support"

import { BrowserRouter } from "react-router-dom"
import useSettings from "minimals-template/components/@hooks/useSettings"

setTimeout(async () => {
    await import("./_lazy.js")
    AppRefresh.raise()
}, 50)

const tagManagerArgs = {
    gtmId: "GTM-KZSNDMF",
}

TagManager.initialize(tagManagerArgs)

// ----------------------------------------------------------------------

const rootElement = document.getElementById("root")
const root = (window._root = window._root ?? ReactDOM.createRoot(rootElement))

root.render(
    <SettingsProvider>
        <TopLevel>
            <BrowserRouter>
                <AuthProvider>
                    <ApolloProvider client={getApolloClient()}>
                        <AuthorizationProvider>
                            <HelmetProvider>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <CollapseDrawerProvider>
                                        <App />
                                    </CollapseDrawerProvider>
                                </LocalizationProvider>
                            </HelmetProvider>
                        </AuthorizationProvider>
                    </ApolloProvider>
                </AuthProvider>
            </BrowserRouter>
        </TopLevel>
    </SettingsProvider>
)

function TopLevel({ children }) {
    const box = useRef()
    const settings = useSettings()
    useEffect(() => {}, [settings])
    return (
        <Box ref={box} className={`styled-scrollbars ${settings.themeContrast === "bold" ? "high-contrast-mode" : ""}`}>
            {children}
        </Box>
    )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)
