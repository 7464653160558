import { ScheduleSelectorList } from "slot-definitions"
import { CreateTag } from "./create-tag"
import { notInSelector } from "routes/facilities/lib/when-parent-item"
import { ItemTypeLabel } from "event-definitions"

ScheduleSelectorList("basket-Tag").plug(<CreateTag priority={10} if={notInSelector} />)

ItemTypeLabel.handleOnce(({ label, parentItem }) => {
    if (parentItem?.id !== "basket-Tag") return { label }
    return { label: "Tags" }
})
