import { register } from "lib/routes/register"
import { Bound } from "lib/@components/binding/Bound"
import AuthGuard from "minimals-template/components/guards/AuthGuard"

import { AppLayout } from "routes/@layouts/app/app-layout"

export const companionAppRoute = (window.AppRoute = register(
    "/sfg20",
    <Bound dontNeedLicense companionApp>
        <AuthGuard>
            <AppLayout />
        </AuthGuard>
    </Bound>
))
