import { Typography } from "@mui/material"
import { getAuditPayload } from "routes/audit/controller/get-audits-by-reference-id"

export function DisplayReason({ id, desc }) {
    return desc.toLowerCase().includes("reject") && <InnerReason id={id} />
}

function InnerReason({ id }) {
    const payload = getAuditPayload.useResults(id)
    return (
        !!payload?.reason && (
            <Typography variant="caption" color="text.secondary" component="div" sx={{ mt: 1 }}>
                {payload.reason}
            </Typography>
        )
    )
}
