import { ClientTreeUpdated, RefreshNavBar } from "event-definitions"

let tree = null

ClientTreeUpdated.handleOnce((updatedTree) => {
    RefreshNavBar.raiseOnce()
    return (tree = updatedTree)
})

export function getFacilities(sourceTree = tree, all = false) {
    const facilities = []
    if (!sourceTree?.children?.length) return facilities
    recurseAdd(sourceTree)
    return facilities.sortBy("label")

    function recurseAdd(root, depth = 0) {
        if (
            (all || depth === 1) &&
            (root?.data?.type === "facility" ||
                (!root?.data?.type && root?.type === "group") ||
                root?.data?.type === "simple")
        ) {
            facilities.push(root)
        }
        for (const child of root?.children ?? []) {
            recurseAdd(child, depth + 1)
        }
    }
}

export function useRootFacilities(tree) {
    ClientTreeUpdated.after.useRefresh()
    return tree ? getFacilities(tree) : undefined
}
