import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"

export const getCurrentNamespace = api(async function getCurrentNamespace() {
    return query(
        gql`
            query getCurrentNamespace {
                getCurrentNamespace
            }
        `,
        {},
        { returns: "getCurrentNamespace" }
    )
})
