import { appRoute } from "routes/app/home.runtime"

import { Lazy } from "lib/make-lazy"
import { registerTab } from "lib/@components/tabs"
import AdministerClientsGuard from "minimals-template/components/guards/AdministerClientsGuard"

registerTab({
    tab: "sfg20AdminTabs",
    id: "companies",
    title: "Companies",
    demands: "admin-companies",
    content: <Lazy importer={() => import("./companies.ui.js")} extract="Companies" priority={2} />,
})

appRoute.register(
    "/app/sfg20-admin/clients/:cid",

    <AdministerClientsGuard>
        <Lazy importer={() => import("./tabs/profile/company-page.ui.js")} extract="CompanyProfilePage" />
    </AdministerClientsGuard>
)
