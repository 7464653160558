import { registerTab } from "lib/@components/tabs"

function Profile() {
    return <div>Profile</div>
}

registerTab({
    tab: "AdminTabContainer",
    id: "Profile",
    predicate() {
        return true
    },
    content: <Profile $lazy priority={5} />,
})
